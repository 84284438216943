import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { EndpointService } from "../../services/endpoint/endpoint.service";
import { AuthService } from "../../services/auth/auth.service";
import { forkJoin} from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-scores',
  templateUrl: './scores.component.html',
  styleUrls: ['./scores.component.scss']
})
export class ScoresComponent implements OnInit, AfterViewInit {

  public isInmo: boolean;
  public loadedInfo: boolean = false;
  public loadedInfoInmos: boolean = false;

  // Info de la propia inmo:
  public inmo: any;
  public puntuacion: any;
  public stars: any;
  public categorias: any; // puntuaciones de las categorias

  public categoriaSelected: string;
  public camposModulo: any;
  public valor: any;
  public currentValorToShow: any;

  // Inmos ordenadas por puntuacion:
  public allInmos: any;
  public inmoPropia = null;

  // Carousel Values
  public minElement = 0;
  public maxElement = 14;


  // Nuevas variables
  public showCategoria: boolean = false; // true --> se muestran detalles de la cat, false --> no se muestarn
  public staticTabs = [{nombre: 'Required Fields', id: 0}, {nombre: 'Diagnostic Fields', id: 1}, {nombre: 'Oportunities', id: 2}];

  // Variables Para Graph Grupos
  public graphID = 1;
  public graphHeader: string;

  public graphLabels = []; // Graph 1
  public graphValues = []; // Graph 1
  public graphTitle = "No se usa 1" // Lo quitamos porque se usa un selector

  public graph2Labels = []; // Graph 2
  public graph2Values = []; // Graph 2
  public graph2Title = "No se usa 2" // Lo quitamos porque se usa un selector

  public options = [];
  isOpen = false;
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  @ViewChild("fileUpload", { static: false }) fileUpload2: ElementRef;
  public selectedFile: any;

  // Graph Values
  @ViewChild("myCanvas") myCanvas: ElementRef;
  private context: CanvasRenderingContext2D;

  // Responsive options para el carousel de viviendas similares
  public responsiveOptions = [
    { breakpoint: '1500px', numVisible: 13, numScroll: 5 },
    { breakpoint: '1250px', numVisible: 10, numScroll: 5 },
    { breakpoint: '960px', numVisible: 7, numScroll: 5 },
    { breakpoint: '680px', numVisible: 5, numScroll: 5 },
    { breakpoint: '540px', numVisible: 4, numScroll: 4 },
    { breakpoint: '450px', numVisible: 3, numScroll: 3 }
  ];

  constructor(
    private endpointService: EndpointService,
    private authService: AuthService,
    public snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngAfterViewInit() {
    this.loadedInfoInmos = true;

    // Estaba comentado en el template, por lo que hay que comentarlo aquí también para que no de error
    //this.context = (this.myCanvas.nativeElement as HTMLCanvasElement).getContext("2d");

      /*
      this.endpointService.getGruposGraphInformation(this.authService.groupId).subscribe(data => {
        if(data['response'].length > 0) {
          this.calculateGraphValues(data['response']);
          this.changeGraph();
          this.loadedInfo = true;
        } else {
          this.loadedInfo = true;
        }
      });
      */
  }

  public initialize(): void {
    const p1 = new Promise<void>(((resolve, reject) => {
      this.endpointService.getInmos(this.authService.inmoId).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.allInmos = data['response'];
          const filtro = this.allInmos.filter(i => i['propia'] === 1);
          if (filtro.length > 0) {
            this.inmoPropia = filtro[0];
          } else {
          }
          //this.inmoPropia = this.allInmos.filter(i => i['propia'] === 1)[0];
        }
        resolve();
      });
    }));

    const p2 = new Promise<void>(((resolve, reject) => {
      this.endpointService.getInfoInmo(this.authService.inmoId).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.inmo = data['response'][0];

          if (this.inmo['logo_cuadrado']) {
            this.inmo['logo_cuadrado'] = encodeURI(this.inmo['logo_cuadrado']);
          } else {
            this.inmo['logo_cuadrado'] = null;
          }

        }
        resolve();
      });
    }));

    const p3 = new Promise<void>(((resolve, reject) => {
      this.endpointService.getPuntuacionInmo(this.authService.inmoId).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.categorias = data['response'][0];
          this.puntuacion = data['response'][1];
          this.stars = Math.round((this.puntuacion / 20) * 2) / 2;

          /*let i = 0;
          for (let c of  this.categorias) {
            if (c['id_puntuacion_modulo'] === 1 || c['id_puntuacion_modulo'] === 2) {
              if (c['max_valor'] > 0) {
                this.categorias[i]['punt100'] = c['valor'] / c['max_valor'] * 100;
              } else {
                this.categorias[i]['punt100'] = 0;
              }
            } else {
              this.categorias[i]['punt100'] = 0;
            }
            i += 1;
          }*/
        }
        resolve();
      });
    }));

    const observerArray = [];
    observerArray.push(p1);
    observerArray.push(p2);
    observerArray.push(p3);
    
    forkJoin(observerArray).subscribe(data => {
      if (this.inmoPropia === null) {
        this.inmoPropia = {orden: 0, nombre: this.inmo['nombre'], logo_cuadrado: this.inmo['logo_cuadrado']};
      }
      this.loadedInfo = true;
    });

    /* ENDPOINT DE LOS GRAPHS DE LOS GRUPOS
      this.endpointService.getGruposGraphInformation(this.authService.groupId).subscribe(data => {
        this.calculateGraphValues(data['response']);
        this.plotGraph(this.graphValues, this.graphLabels);
        this.loadedInfo = true;
      });
      */
  }

  public openCategoria(categoria: any) {
    if (this.showCategoria) {
      if (categoria !== this.categoriaSelected) {
        this.getInfoCategoria(categoria, false);
      } else {
        this.showCategoria = false;
      }
    } else {
      this.getInfoCategoria(categoria, true);
    }
  }

  public closeCategoria(): void {
    this.showCategoria = false;
  }

  public getInfoCategoria(categoria: any, show: boolean) {
    this.endpointService.getCamposModulo(this.inmo['id'], categoria['id_puntuacion_modulo']).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.camposModulo = data['response'];
        for (let i = 0; i<this.camposModulo.length; i++) { this.camposModulo[i] = Object.values(this.camposModulo[i]); }
        this.categoriaSelected = categoria;
        if (show) this.showCategoria = true;
      }
    });
  }

  public roundNumber(n: number): number {
    return Math.round(n);
  }

  public getElementsGreen(data: any): number {
    return (data.filter(elem => elem['contenido'] !== null)).length;
  }

  public onFileUpload(event, value: any): void {
    value['new-valor'] = 1;
    this.selectedFile = event.target.files[0];
  }

  public actualizarCampo(value: any, identificador: any, nombre: any, actualizar: boolean): void {
    const tabla = value['tabla'];
    const campo = value['campo'];
    const newValor = value['new-valor'];

    let id: any;
    if (this.categoriaSelected['id_puntuacion_modulo'] === 1) {
      id = this.authService.inmoId;
    } else if (this.categoriaSelected['id_puntuacion_modulo'] === 2) {
      id = value['vivienda_id'];
    }

    if (newValor !== undefined && newValor !== null && id != null) {
      if (value['tipo_dato'] === 'imagen') {
        // add image, upload files, upload file
        let idImagen = 0; // permite seleccionar a donde se sube la imagen: banner, logo, logo cuadrado, etc
        switch (value['id_grupo']) {
          case 20:
            idImagen = 3;
            break;
          case 23:
            idImagen = 4;
            break;
          case 24:
            idImagen = 2;
            break;
          case 25:
            idImagen = 0;
            break;
          case 27:
            idImagen = 1;
            break;
        }
        const file = { data: this.selectedFile, inProgress: false, progress: 0};
        const formData = new FormData();
        formData.append('file', file.data);
        file.inProgress = true;

        this.endpointService.changeInmoImage(this.authService.inmoId, file, formData, idImagen).subscribe(data =>{
          if (data['errorCode'] === 0) {
            this.snackBar.open('Campo actualizado con éxito.', 'X', {
              duration: 6000, panelClass: ['green-snackbar']
            });
            value['contenido'] = file.data.name;
            if (actualizar) {
              value['editar'] = false;
            }
          }
        });

      } else {
        this.endpointService.updateValue(id, tabla, campo, newValor, value['tipo_dato']).subscribe(data => {
          if (data['errorCode'] === 0) {
            this.snackBar.open('Campo actualizado con éxito.', 'X', {
              duration: 6000, panelClass: ['green-snackbar']
            });
            if (value['tipo_dato'] === 'enum') {
              value['contenido'] = this.options.filter(opt => opt.id === value['new-valor'])[0].nombre; // el nombre
            } else {
              value['contenido'] = newValor;
            }
            if (actualizar) {
              value['editar'] = false;
            }
          }
        });
      }
    }
  }

  public editarValor(value: any): void {
    value['editar'] = true;
    value['new-valor'] = value['contenido'];
  }

  public quitarEditar(value: any): void {
    value['editar'] = false;
  }

  public llenarOpciones(element: any): void {
    this.isOpen = false;
    this.options = [];
    this.endpointService.getOptionsSelect(element['campo'], element['id_puntuacion_modulo']).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.options = data['response'];
        this.isOpen = true;
      }
    });
  }

  public onPageChange($event): void {
    this.currentValorToShow =  this.valor.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }

  public actualizarValorMostrar(value: any): void {
    this.valor = value;
    this.currentValorToShow = this.valor.slice(0, 10);

    const aux = value.filter(elem => elem['tipo_dato'] === 'enum');
    if (aux.length > 0) {
      this.endpointService.getOptionsSelect(aux[0]['campo'], aux[0]['id_puntuacion_modulo']).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.options = data['response'];
          this.isOpen = true;
        }
      });
    }

  }

  // GRAPHS SCORES GRUPOS. Funciones para crear el graph.

  public calculateGraphValues(data) {
    this.graphValues = [];
    this.graphLabels = [];
    this.graph2Values = [];
    this.graph2Labels = [];

    // Graph 1. Inmos Afegidas.

    let cntInmos = 0;
    let prevMonth = this.getMonthFromStringDate(data[0][0].fecha_colaboracion);
    let yearMonth = this.getYearFromStringDate(data[0][0].fecha_colaboracion);
    // Contem el nombre de inmos i creem els labels pel graph.
    data[0].forEach(inmo=>{
      if (this.getMonthFromStringDate(inmo.fecha_colaboracion) == prevMonth) {
        cntInmos += 1;
      } else {
        this.graphValues.push(cntInmos);
        this.graphLabels.push(prevMonth+'-'+yearMonth);
        cntInmos = 0;
        prevMonth = this.getMonthFromStringDate(inmo.fecha_colaboracion);
        yearMonth = this.getYearFromStringDate(inmo.fecha_colaboracion);
        cntInmos += 1;
      }
    });
    this.graphValues.push(cntInmos);
    this.graphLabels.push(prevMonth+'-'+yearMonth);

    // Añadimos también valores 0 de entre las fechas.
    let start_month = parseInt(this.getMonthFromStringDate(data[2][0]),10);
    let start_year = parseInt(this.getYearFromStringDate(data[2][0]),10);
    let finish_month = parseInt(this.getMonthFromStringDate(data[2][1]),10);
    let finish_year = parseInt(this.getYearFromStringDate(data[2][1]),10);

    let completeLabels = [];
    let completeValues = [];

    let month = start_month;
    let year = start_year;
    while (month != finish_month || year != finish_year) {
      if (month < 10) {
        completeLabels.push('0'+month.toString()+'-'+year.toString());
      } else {
        completeLabels.push(month.toString()+'-'+year.toString());
      }

      if (month > 11) {
        month = 1;
        year += 1;
      } else {
        month += 1;
      }
    }

    if (month < 10) {
      completeLabels.push('0'+month.toString()+'-'+year.toString());
    } else {
      completeLabels.push(month.toString()+'-'+year.toString());
    }
    for (let i = 0; i< completeLabels.length; i++) {
      let index = this.graphLabels.indexOf(completeLabels[i]);
      if (index == -1) {
        completeValues.push(0);
      } else {
        completeValues.push(this.graphValues[index]);
      }
    }

    this.graphLabels = completeLabels;
    this.graphValues = completeValues;

    // Afegim la base al nombre calculat. Inmos del grup previas a la data.
    for(let i=0;i<this.graphValues.length; i++){
      this.graphValues[i] += data[3][0];
    }

    // Restem la inmo si te fecha fin, al mes que toca.
    data[0].forEach(inmo=> {
      if (inmo.fecha_fin != null) {
        let monthBaja = this.getMonthFromStringDate(inmo.fecha_fin);
        let yearBaja = this.getYearFromStringDate(inmo.fecha_fin);
        const label = monthBaja + '-' + yearBaja;
        let index = this.graphLabels.indexOf(label);
        this.graphValues[index] -= 1;
      }
    });

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // Graph 2. Viviendes Afegidas.

    let cntVivendes = 0;
    let prevMonth2 = this.getMonthFromStringDate(data[1][0].fecha_alta);
    let yearMonth2 = this.getYearFromStringDate(data[1][0].fecha_alta);
    // Contem el nombre de viviendes del grup i creem els labels pel graph.
    data[1].forEach(vivienda=>{
      if (this.getMonthFromStringDate(vivienda.fecha_alta) == prevMonth2) {
        cntVivendes += 1;
      } else {
        this.graph2Values.push(cntVivendes);
        this.graph2Labels.push(prevMonth2+'-'+yearMonth2);
        cntVivendes = 0;
        prevMonth2 = this.getMonthFromStringDate(vivienda.fecha_alta);
        yearMonth2= this.getYearFromStringDate(vivienda.fecha_alta);
        cntVivendes += 1;
      }
    });
    this.graph2Values.push(cntVivendes);
    this.graph2Labels.push(prevMonth2+'-'+yearMonth2);

    // Añadimos también valores 0 de entre las fechas.
    let start_month2 = parseInt(this.getMonthFromStringDate(data[2][0]),10);
    let start_year2 = parseInt(this.getYearFromStringDate(data[2][0]),10);
    let finish_month2 = parseInt(this.getMonthFromStringDate(data[2][1]),10);
    let finish_year2 = parseInt(this.getYearFromStringDate(data[2][1]),10);

    let completeLabels2 = [];
    let completeValues2 = [];

    let month2 = start_month2;
    let year2 = start_year2;
    while (month2 != finish_month2 || year2 != finish_year2) {
      if (month2 < 10) {
        completeLabels2.push('0'+month2.toString()+'-'+year2.toString());
      } else {
        completeLabels2.push(month2.toString()+'-'+year2.toString());
      }

      if (month2 > 11) {
        month2 = 1;
        year2 += 1;
      } else {
        month2 += 1;
      }
    }

    if (month2 < 10) {
      completeLabels2.push('0'+month2.toString()+'-'+year2.toString());
    } else {
      completeLabels2.push(month2.toString()+'-'+year2.toString());
    }
    for (let i = 0; i< completeLabels2.length; i++) {
      let index = this.graph2Labels.indexOf(completeLabels2[i]);
      if (index == -1) {
        completeValues2.push(0);
      } else {
        completeValues2.push(this.graph2Values[index]);
      }
    }

    this.graph2Labels = completeLabels2;
    this.graph2Values = completeValues2;

    // Afegim la base al nombre calculat. Viviendes del grup previas a la data.
    for(let i=0;i<this.graph2Values.length; i++){
      this.graph2Values[i] += data[3][1];
    }

    // Restem la inmo si te fecha fin, al mes que toca.
    data[1].forEach(vivienda=> {
      if (vivienda.fecha_baja != null) {
        let monthBaja2 = this.getMonthFromStringDate(vivienda.fecha_baja);
        let yearBaja2 = this.getYearFromStringDate(vivienda.fecha_baja);
        const label2 = monthBaja2 + '-' + yearBaja2;
        let index = this.graph2Labels.indexOf(label2);
        this.graph2Values[index] -= 1;
      }
    });

  }

  public plotGraph(graphVals, graphLabels) {

    const ctx = this.context;

    let x; // First Bar Position
    let width; // Bar Width
    const c_height = 400; // Total Canvas Height
    let c_width = 800; // Total Canvas Width

    x = 0.2 * (c_width / graphVals.length);
    width = 0.8 * (c_width / graphVals.length);

    // Passar Valors Graph Vals a ser representables en Plot de 400.
    const max = Math.max.apply(null, graphVals);
    let modifiedVals = [];
    graphVals.forEach(val => {
      if (val == max) {
        val = 340;
        modifiedVals.push(val);
      } else {
        val = (340 * val) / max;
        modifiedVals.push(Number(val.toFixed(2)));
      }
    });

    ctx.font = "11px Arial";
    ctx.fillStyle = "#0000";
    ctx.fillRect(0, 0, c_width, c_height);

    for (let i = 0; i < modifiedVals.length; i++) {
      let val = modifiedVals[i];
      ctx.fillStyle = '#dedede'; // Bar Color
      ctx.fillRect(x, c_height - val, width, val);
      ctx.save();
      ctx.translate(x + (width / 3), c_height - val - 30);
      ctx.rotate(-Math.PI / 4);
      ctx.textAlign = 'center';
      ctx.fillText(graphLabels[i], 0, 15 / 2);
      ctx.restore();

      ctx.save();
      ctx.translate(x + (width / 3) + 15, c_height - val - 30);
      ctx.rotate(-Math.PI / 4);
      ctx.textAlign = 'center';
      ctx.fillStyle = 'rgb(var(--secondary-color))'; // Bar Color
      ctx.fillText(graphVals[i], 0, 15 / 2);
      ctx.restore();

      x += width + 10;
    }
  }

  public changeGraph(){
    if (this.graphID == 1) {
      this.clearGraph();
      this.graphHeader = this.graphTitle;
      this.plotGraph(this.graphValues, this.graphLabels);
    } else if (this.graphID == 2) {
      this.clearGraph();
      this.graphHeader = this.graph2Title;
      this.plotGraph(this.graph2Values, this.graph2Labels);
    }
  }

  public clearGraph() {
    let c_height = 400; // Total Height Width
    let c_width = 800; // Total Height Width
    this.context.clearRect(0,0, c_width,c_height);
    this.context.fillStyle = "#0000";
    this.context.fillRect(0, 0, c_width, c_height);
  }


  public getYearFromStringDate(string) {
    const year = string.slice(0, 4);
    return year;
  }

  public getMonthFromStringDate(string) {
    const month = string.slice(5, 7);
    return month;
  }

  public getDayFromStringDate(string) {
    const day = string.slice(8, 10);
    return day;
  }

  public setPage(page){
    setTimeout(() => {
      let windowActual = window.innerWidth;
      if(windowActual > 1500) {
        this.minElement = page.page * 5 + 1;
        this.maxElement = this.minElement + 15;
      } else if(windowActual > 1250) {
        this.minElement = page.page * 5 + 1;
        this.maxElement = this.minElement + 13;
      } else if(windowActual > 960) {
        this.minElement = page.page * 5 + 1;
        this.maxElement = this.minElement + 10;
      } else if(windowActual > 680) {
        this.minElement = page.page * 5 + 1;
        this.maxElement = this.minElement + 7;
      }  else if(windowActual > 540) {
        this.minElement = page.page * 5 + 1;
        this.maxElement = this.minElement + 5;
      } else if(windowActual > 450) {
        this.minElement = page.page * 4 + 1;
        this.maxElement = this.minElement + 4;
      } else {
        this.minElement = page.page * 3 + 1;
        this.maxElement = this.minElement + 3;
      }
    });
  }

}
