<ng-container *ngIf="idQuery && loaded">
  <div *ngIf="!functionsService.downloadingStaticPage" fxLayout="column" fxFlex="100" [ngClass]="{'hide-options-header-table': !(search == true || paginatorOptions !== null) && tableData.length > 0}">
    <div fxLayout="row wrap" *ngIf="(search == true || paginatorOptions !== null) && tableData.length > 0" [ngClass]="'bottom-table-generic bottom-table-generic-' + structure[index]['id_functional_area']" ngClass.sm="bottom-table-generic-mb" ngClass.xs="bottom-table-generic-mb">
      <div fxFlex.sm="100" fxFlex.xs="100" *ngIf="search == true" class="bottom-table2" style="display: flex;">
        <ng-container [ngTemplateOutlet]="genericMassiveAction"></ng-container>
        <mat-form-field *ngIf="search && tableData" appearance="outline" [ngClass]="{'value-filter': filter.value !== '', 'input-filter': true}">
          <input #filter placeholder="Buscar..." matInput type="text" (keyup.enter)="doFilter($event.target.value); $event.stopPropagation()" value="{{this.dataSource.filter}}">
          <span class="generic-buttons-blue-revert button-filter-table" matPrefix *ngIf="(filter.value != '' || (dataSource.filter && dataSource.filter !== undefined && dataSource.filter != '' && dataSource.filter !== null)) && dataSource.filter != filter.value">
            <button mat-raised-button aria-label="Search" (click)="doFilter(filter.value); $event.stopPropagation()">
              Filtrar
            </button>
          </span>
          <button *ngIf="dataSource.filter != ''" matTooltip="{{dataSource.filter}}" matSuffix mat-icon-button aria-label="Clear" (click)="doFilter(''); filter.value=''; $event.stopPropagation()">
            <mat-icon style="color: #000;">search_off</mat-icon>
          </button>
          <button *ngIf="arrayFiltersFields.length > 0" class="table-filter" [matMenuTriggerFor]="filters" [matBadge]="filtersCount" [matBadgeHidden]="filtersCount < 1" matSuffix mat-icon-button aria-label="Filter" matTooltip="Filtrar resultados">
            <mat-icon style="color: #000;">tune</mat-icon>
          </button>
          <button *ngIf="arrayFiltersFields.length > 0" class="table-view" [matMenuTriggerFor]="views" matBadge="!" [matBadgeHidden]="!structure[index]['hasViewActive']" matSuffix mat-icon-button aria-label="Views" matTooltip="Vistas de la tabla">
            <mat-icon style="color: #000;">table_view</mat-icon>
          </button>
          <button *ngIf="structure[index]['canExportTables']" matSuffix mat-icon-button aria-label="Download" (click)="exporter.exportTable('xlsx', {fileName:'table-data-4dmovin'}); $event.stopPropagation()" matTooltip="Descargar contenido">
            <mat-icon style="color: #000;">download</mat-icon>
          </button>
        </mat-form-field>
        
        <mat-menu #filters="matMenu">
          <ng-container *ngFor="let e of this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['allFilters']; let i = index">
            <button mat-menu-item (click)="selectFilters(e); $event.stopPropagation()" style="text-transform: capitalize" [ngClass]="e.active  ? 'highlight' : ''">
              <mat-icon (click)="deleteFilter(i); $event.stopPropagation()">cancel</mat-icon> <mat-icon (click)="editFilter(e); $event.stopPropagation()">edit</mat-icon> {{ e['name'] }}
            </button>
          </ng-container>
          <button mat-menu-item (click)="openFilterTable(0); $event.stopPropagation()" style="text-transform: capitalize">
            <mat-icon style="color: #000;">add</mat-icon>Nuevo filtro
          </button>
          <button mat-menu-item (click)="resetFilters(); $event.stopPropagation()" style="text-transform: capitalize">
            <mat-icon class="reset-table-generic">backspace</mat-icon>Limpiar filtros
          </button>
        </mat-menu>

        <mat-menu #views="matMenu">
          <ng-container *ngFor="let e of this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views']; let i = index">
            <button mat-menu-item (click)="selectView(e); $event.stopPropagation()" style="text-transform: capitalize" [ngClass]="e.active  ? 'highlight' : ''">
              <mat-icon (click)="deleteView(i); $event.stopPropagation()">cancel</mat-icon> <mat-icon (click)="editView(e); $event.stopPropagation()">edit</mat-icon> {{ e['name'] }}
            </button>
          </ng-container>
          <button mat-menu-item (click)="openViewsTable(0); $event.stopPropagation()" style="text-transform: capitalize">
            <mat-icon style="color: #000;">add</mat-icon>Nueva vista
          </button>
          <button mat-menu-item (click)="resetViews(); $event.stopPropagation()" style="text-transform: capitalize">
            <mat-icon class="reset-table-generic">backspace</mat-icon>Limpiar vista
          </button>
        </mat-menu>
      </div>
      <mat-paginator [ngClass]="{'hidden': (dataSource !== undefined && dataSource.filteredData.length == 0) || paginatorOptions === null}" fxFlex.sm="100" fxFlex.xs="100" style="width: 50%;" [pageSizeOptions]="paginatorOptions" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onPageChange($event)"></mat-paginator>
    </div>
    <ng-container *ngIf="!(search == true || paginatorOptions !== null) && tableData.length > 0" [ngTemplateOutlet]="genericMassiveAction"></ng-container>
    <div class="generic-taules" [ngClass]="{'no-click-row': !structure[index]['id_function']}" [ngStyle]="{ 'height': this.getHeightV + 'px', 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? this.getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - 0.6vw - 28px)' }" [ngStyle.xs]="{ 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? this.getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - 0.6vw - 28px - 2.6em)' }"  [ngStyle.sm]="{ 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? this.getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - 0.6vw - 28px - 2.6em)' }">
      <div [ngClass]="'generic-taules-sub generic-taules-sub-' + structure[index]['id_functional_area']" [ngStyle]="{ 'height': 'calc(100% - ' + this.getHeightTableV + 'px - 2.6em - 3px)' }">
        <table [ngClass]="'mat-elevation-z2 mat-elevation-z3-' + structure[index]['id_functional_area']" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows matTableExporter #exporter="matTableExporter">
          <ng-container *ngFor="let key of displayedColumns; let i = index" matColumnDef="{{ key }}" >
            <!-- Checkbox Column -->
            <ng-container *ngIf="key == '0000-selectorCheckbox'">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row" [ngClass]="{ 'highlight': isHighlightRow(row[oldBdField], getRowId(dataSource.data.indexOf(row), row.originalRowID), row) }">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? this.checkSelectionMultiple(getRowId(dataSource.data.indexOf(row), row.originalRowID), row) : null"
                              [checked]="selection.isSelected(row)"
                              [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>
            
            <ng-container *ngIf="key != '0000-selectorCheckbox'">
              <ng-container *ngIf="!aux[i] || !aux[i].Sort; else elseBlock">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="aux[i].Tooltipp" [ngStyle]="structure[index]['child'][aux[i].Id]?.['styleParsed']?.['style']" [ngClass]="{'column-only-icon': (aux[i].icon && (!structure[index]['child'][aux[i].Id]['label'] || structure[index]['child'][aux[i].Id]['label'] == null))}">
                  <span *ngIf="this.authService.checkUserIsDeveloping == 1 && !structure[index]['creatingMode'] && this.genericService.devModeGeneric && ((structure[index]['child'][aux[i].Id] && genericService.evaluateIfElements(structure[index], aux[i].Id) && 
                  (genericService.checkChildActiveHideIntialOnly(structure[index]['child'][aux[i].Id] && genericService.checkChildActive(structure[index]['child'][aux[i].Id], false, false))) && 
                  structure[index]['child'][aux[i].Id]['id_functional_status_general'] !== 2) || structure[index]['child'][aux[i].Id]['showSub'])" style="position: relative;">
                    <mat-icon [cdkCopyToClipboard]="structure[index]['child'][aux[i].Id]['id_functional_area']" class="debbugingIdsIcon" (mouseover)="hovered = structure[index]['child'][aux[i].Id]['id_functional_area']" (mouseout)="hovered = -1">info</mat-icon>
                    <span [ngStyle]="hovered == structure[index]['child'][aux[i].Id]['id_functional_area'] ? {'display': 'block'} : {'display': 'none'}" class="debbugingIds">idFA: {{structure[index]['child'][aux[i].Id]['id_functional_area']}}</span>
                  </span>
                  <ng-container *ngIf="!structure[index]['child'][aux[i].Id]['readMode'] && aux[i].icon">
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] != 'nextStep' && structure[index]['child'][aux[i].Id]['type'] != 'prevStep'">
                      <button mat-button (click)="iconClick(aux[i].Id, $event); $event.stopPropagation()" style="width: auto;">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'nextStep'">
                      <button mat-button matStepperNext (click)="$event.stopPropagation()" style="width: auto;">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'prevStep'">
                      <button mat-button matStepperPrevious (click)="$event.stopPropagation()" style="width: auto;">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                  </ng-container>
                  <span *ngIf="this.structure[index]['child'][aux[i].Id]['label']" [innerHTML]="this.structure[index]['child'][aux[i].Id]['label'] | safeHtml"></span>
                </th>
              </ng-container>
              <ng-template #elseBlock>
                <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!aux[i].Sort" [matTooltip]="aux[i].Tooltipp" [ngStyle]="structure[index]['child'][aux[i].Id]?.['styleParsed']?.['style']" [ngClass]="{'column-only-icon': (aux[i].icon && (!structure[index]['child'][aux[i].Id]['label'] || structure[index]['child'][aux[i].Id]['label'] == null))}" (click)="onColumnSelectSort(key)">
                  <span *ngIf="this.authService.checkUserIsDeveloping == 1 && !structure[index]['creatingMode'] && this.genericService.devModeGeneric && ((structure[index]['child'][aux[i].Id] && genericService.evaluateIfElements(structure[index],aux[i].Id) && 
                  (genericService.checkChildActiveHideIntialOnly(structure[index]['child'][aux[i].Id] && genericService.checkChildActive(structure[index]['child'][aux[i].Id], false, false))) && 
                  structure[index]['child'][aux[i].Id]['id_functional_status_general'] !== 2) || structure[index]['child'][aux[i].Id]['showSub'])" style="position: relative;">
                    <mat-icon [cdkCopyToClipboard]="structure[index]['child'][aux[i].Id]['id_functional_area']" class="debbugingIdsIcon" (mouseover)="hovered = structure[index]['child'][aux[i].Id]['id_functional_area']" (mouseout)="hovered = -1" (click)="this.genericService.consoleLogFA(structure[index]['child'][aux[i].Id])">info</mat-icon>
                    <span [ngStyle]="hovered == structure[index]['child'][aux[i].Id]['id_functional_area'] ? {'display': 'block'} : {'display': 'none'}" class="debbugingIds">idFA: {{structure[index]['child'][aux[i].Id]['id_functional_area']}}</span>
                  </span>
                  <ng-container *ngIf="!structure[index]['child'][aux[i].Id]['readMode'] && aux[i].icon">
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] != 'nextStep' && structure[index]['child'][aux[i].Id]['type'] != 'prevStep'">
                      <button mat-button (click)="iconClick(aux[i].Id, $event); $event.stopPropagation()">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'nextStep'">
                      <button mat-button matStepperNext (click)="$event.stopPropagation()">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'prevStep'">
                      <button mat-button matStepperPrevious (click)="$event.stopPropagation()">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                  </ng-container>
                  <span *ngIf="this.structure[index]['child'][aux[i].Id]['label']" [innerHTML]="this.structure[index]['child'][aux[i].Id]['label'] | safeHtml"></span>
                </th>
              </ng-template>
              <ng-container *ngIf="structure[index]['child'][aux[i].Id] && structure[index]['child'][aux[i].Id]['bd_field']">
                <td mat-cell *matCellDef="let row" (click)="rowClick($event, row, getRowId(dataSource.data.indexOf(row), row.originalRowID))" [ngClass]="{ 'highlight': isHighlightRow(row[oldBdField], getRowId(dataSource.data.indexOf(row), row.originalRowID), row) }">
                  <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'image'; else notImage">
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['id_function']; else notFunctionImage">
                      <img (click)="rowClickImage($event, row, getRowId(dataSource.data.indexOf(row), row.originalRowID), aux[i].Id); $event.stopPropagation()" class="{{ structure[index]['child'][aux[i].Id]['class_custom'] }}" [ngStyle]="structure[index]['child'][aux[i].Id]['styleParsed']['style']" [matTooltip]="row[structure[index]['child'][aux[i].Id]['description']]" mat-card-image [src]="row[key]" (error)="row[key] = this.genericService.defaultNoImage;" (load)="this.calculateHeights()"/>
                    </ng-container>
                    <ng-template #notFunctionImage>
                      <img class="{{ structure[index]['child'][aux[i].Id]['class_custom'] }}" [ngStyle]="structure[index]['child'][aux[i].Id]['styleParsed']['style']" [matTooltip]="row[structure[index]['child'][aux[i].Id]['description']]" mat-card-image [src]="row[key]" (error)="row[key] = this.genericService.defaultNoImage;" (load)="this.calculateHeights()"/>
                    </ng-template>
                  </ng-container>
                  <ng-template #notImage>
                    <ng-container *ngIf="checkArray(row[key])">
                      <div *ngFor="let x of row[key]">{{ x === null ? "N/A" : x }}</div>
                    </ng-container>
                    <ng-container *ngIf="!checkArray(row[key])">
                      <ng-container *ngIf="row[key] == 'isbooleantrue'">
                        <div><span class="material-icons checked-generic">check_circle</span></div>
                      </ng-container>
                      <ng-container *ngIf="row[key] == 'isbooleanfalse'">
                        <div><span class="material-icons denied-generic">cancel</span></div>
                      </ng-container>
                      <ng-container *ngIf="row[key] != 'isbooleantrue' && row[key] != 'isbooleanfalse'">
                        <div class="text-column"><span *ngIf="row[key]" [innerHTML]="row[key] | safeHtml"></span></div>
                      </ng-container>
                    </ng-container>
                  </ng-template>
      
                </td>
              </ng-container>
              <ng-container *ngIf="!structure[index]['child'][aux[i].Id] || !structure[index]['child'][aux[i].Id]['bd_field']">
                <td mat-cell *matCellDef="let row; let t = dataIndex" (click)="$event.stopPropagation()" [ngClass]="{ 'highlight': isHighlightRow(row[oldBdField], getRowId(dataSource.data.indexOf(row), row.originalRowID), row) }">
                  <ng-container *ngIf="appearProfilePermission(structure[index]['child'][aux[i].Id]['id_functional_area'], dataSource.data[t])">
                    <app-generic [structure]="structure[index]['child'][aux[i].Id]" [param]="'' + structure[index]['id_functional_area'] + '.' + getRowId(dataSource.data.indexOf(row), row.originalRowID)"></app-generic>
                </ng-container>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>
    
          <ng-container *ngIf="displayedColumns && displayedColumns.length && expandedColumn()"  matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let row; let di = dataIndex" (click)="rowClick($event, row, getRowId(dataSource.data.indexOf(row), row.originalRowID))" [attr.colspan]="displayedColumns.length">
              <div style="width: 100%;" *ngIf="row == this.structure[this.index]['expandedElement']" class="element-detail" style="display: flex;" [@detailExpand]="row == this.structure[this.index]['expandedElement'] ? 'expanded' : 'collapsed'">
                <ng-container *ngIf="structure[index]['expandedElement'] !== null && structure[index]['expandedElement'] !== undefined">
                  <app-generic [structure]="expandedCol" [param]="'' + structure[index]['id_functional_area'] + '.' + getRowId(dataSource.data.indexOf(row), row.originalRowID)" style="width: 100%;"></app-generic>
                </ng-container>
              </div>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: sticky"></tr>
    
          <ng-container *ngIf="expandedCol === null">
            <ng-container *ngIf="this.structure[this.index]['type'] != 'checkbox'">
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </ng-container>
            <ng-container *ngIf="this.structure[this.index]['type'] == 'checkbox'">
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="this.checkSelectionMultiple(getRowId(dataSource.data.indexOf(row), row.originalRowID), row)"></tr>
            </ng-container>
          </ng-container>
    
          <ng-container *ngIf="expandedCol !== null">
            <ng-container *ngIf="this.structure[this.index]['type'] != 'checkbox'">
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" [class.example-expanded-row]="this.structure[this.index]['expandedElement'] === row" style="cursor: pointer;" ></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </ng-container>
            <ng-container *ngIf="this.structure[this.index]['type'] == 'checkbox'">
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="this.checkSelectionMultiple(getRowId(dataSource.data.indexOf(row), row.originalRowID), row)" class="element-row" [class.example-expanded-row]="this.structure[this.index]['expandedElement'] === row" style="cursor: pointer;" ></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" (click)="this.checkSelectionMultiple(getRowId(dataSource.data.indexOf(row), row.originalRowID), row)" class="detail-row"></tr>
            </ng-container>
          </ng-container>
          
          <!--<tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              <ng-container *ngIf="structure[index]['text'] !== null && structure[index]['text'] !== undefined; else noText"> 
                {{structure[index]['text']}}
              </ng-container>
              <ng-template #noText>
                No se han encontrado resultados.
              </ng-template>
            </td>
          </tr>-->
    
        </table>
        <p *ngIf="loadedAfterView && dataSource.filteredData.length == 0" [ngClass]="'mat-elevation-z2 noRowsGeneric noRowsGeneric-' + structure[index]['id_functional_area']">
          <ng-container *ngIf="structure[index]['text'] !== null && structure[index]['text'] !== undefined; else noText"> 
            <span [innerHTML]="this.structure[this.index]['text'] | safeHtml"></span>
          </ng-container>
          <ng-template #noText>
            No se han encontrado resultados.
          </ng-template>
        </p>
      </div>
    </div>
  </div>
  
  <ng-container *ngIf="functionsService.downloadingStaticPage">
    <table class="table-print mat-table mat-elevation-z2">
      <thead>
        <tr class="mat-header-row">
          <ng-container *ngFor="let key of displayedColumns; let i = index">
            <th *ngIf="this.structure[index]['child'][aux[i].Id]['label']" [innerHTML]="this.structure[index]['child'][aux[i].Id]['label'] | safeHtml" [ngStyle]="structure[index]['child'][aux[i].Id]?.['styleParsed']?.['style']"  [ngClass]="{'column-only-icon': (aux[i].icon && (!structure[index]['child'][aux[i].Id]['label'] || structure[index]['child'][aux[i].Id]['label'] == null))}"></th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of this.dataSource.filteredData | keyvalue: genericService.returnZero; let i = index;">
          <ng-container *ngFor="let element of row.value | keyvalue: genericService.returnZero; let j = index;">
            <td *ngIf="this.displayedColumns.includes(element.key)" [innerHTML]="element.value | safeHtml"></td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </ng-container>
</ng-container>

<div *ngIf="!loadedFilter" fxLayoutAlign="center center" fxFlex="100">
  <mat-spinner></mat-spinner>
</div>

<ng-template #genericMassiveAction>
  <div class="generic-buttons-blue-revert massive-action" style="padding-right: 10px; padding-top: 10px;" *ngIf="this.structure[this.index]['type'] == 'checkbox' && this.selection.selected && this.selection.selected.length && this.selection.selected.length > 0">
    <button mat-button type="button" (click)="massiveAction($event); $event.stopPropagation()">
      <mat-icon *ngIf="!structure[index]['label'] || structure[index]['label'] == ''" style="color: #000;">arrow_right_alt</mat-icon>
      <ng-container *ngIf="structure[index]['label'] && structure[index]['label'] != ''">
        <mat-icon *ngIf="structure[index]['icon'] && structure[index]['icon'] != ''" style="color: #000;">{{structure[index]['icon']}}</mat-icon>
        {{structure[index]['label']}}
      </ng-container>
    </button>
  </div>
</ng-template>