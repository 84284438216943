import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FunctionsService } from "../../../services/functions/functions.service";
import { GenericService } from "../../../services/generic/generic.service";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() structure = null;
  @Input() index: number;

  public maxArchives;
  public currentArchives;
  public imgURL = [];
  public imagePath: FileList;
  public file: File[] = [];
  public formData: FormData;
  public docsPath: FileList;
  public doc: File[] = [];
  public docURL = [];
  public acceptedImgTypes: string[];
  public acceptedImgTypesFormatted: string[] = [];
  public acceptedDocTypes: string[];
  public acceptedDocTypesFormatted: string[] = [];
  public maxSize;
  public maxSizeMB;
  public currentFiles: DataTransfer;

  private updateFormFieldSubscribe: Subscription;

  @ViewChild('uploaderForm') uploadForm;
  @ViewChild('docUpload') docUpload;
  @ViewChild('imgUpload') imgUpload;

  constructor(
    private functionsService: FunctionsService,
    private genericService: GenericService
  ) { }

  ngOnInit(): void {
    if (this.structure[this.index]['multiple'] == 1) this.maxArchives = this.structure[this.index]['max_length'] !== null && this.structure[this.index]['max_length'] !== undefined && this.structure[this.index]['config']['defaultMaxArchives'] > this.structure[this.index]['max_length'] ? this.structure[this.index]['max_length'] : this.structure[this.index]['config']['defaultMaxArchives'];
    else this.maxArchives = 1;
    this.acceptedImgTypes = this.structure[this.index]['config']['imageAcceptedType'];
    this.acceptedImgTypesFormatted = this.structure[this.index]['config']['imageAcceptedTypeFormatted'];
    this.acceptedDocTypes = this.structure[this.index]['config']['documentAcceptedType'];
    this.acceptedDocTypesFormatted = this.structure[this.index]['config']['documentAcceptedTypeFormatted'];
    this.maxSize = this.structure[this.index]['config']['maxSize'];
    this.maxSizeMB = this.structure[this.index]['config']['maxSize'] / 1000000;
    this.currentFiles = new DataTransfer();

    if(this.structure[this.index]['fileList'] && this.structure[this.index]['fileList'].length && this.structure[this.index]['fileList'].length > 0) {
      if(this.structure[this.index]['type'] === 'image') {
        this.imgURL = this.structure[this.index]['fileList'];
      } else {
        this.docURL = this.structure[this.index]['fileList'];
      }
    }

    this.updateFormFieldSubscribe = this.genericService.updateFormFields.subscribe((change) => {
      if (change) {
        this.structure[this.index]['fileList'] = []
        this.structure[this.index]['tmp_value'] = []
      }
    })
  }

  ngOnDestroy(): void {
    if (this.updateFormFieldSubscribe) {
      this.updateFormFieldSubscribe.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.structure[this.index]['form'] = this.uploadForm
    this.currentArchives = this.structure[this.index]['form']['nativeElement'][0]['files'].length
  }

  changeSelectedImage(event) {
    this.imgURL = [];
    this.file = [];
    let fileList: FileList = event.target.files;
    if (this.structure[this.index]['multiple'] == 1) {
      fileList = this.mergeFileLists(fileList, this.currentFiles.files);
      this.currentFiles.items.clear()
    }
    if (fileList.length > 0 && fileList.length <= this.maxArchives) {

      this.imagePath = fileList;
      const partName = 'uploadFile';
      this.formData = new FormData();
      for (let i = 0; i < fileList.length; i++) {
        if (this.acceptedImgTypes.includes(fileList[i]['type'])) {
          if (fileList[i]['size'] < this.maxSize) {
            const reader = new FileReader();
            reader.readAsDataURL(fileList[i]);
            reader.onload = (fileList) => {
              this.imgURL.push(reader.result);
            };
            this.file[i] = fileList[i];
            this.formData.append(partName + i, this.file[i], this.file[i].name);
            this.currentFiles.items.add(fileList[i])
            this.genericService.fileUploaded = true;
          }
          else {
            this.imgURL.splice(i, 1)
            this.functionsService.openSnackBar("El archivo " + fileList[i]['name'] + " supera los " + this.maxSizeMB + "MB permitidos", 10000, ["red-snackbar"])
          }
        }
        else {
          this.imgURL.splice(i, 1)
          this.functionsService.openSnackBar("Los archivos de tipo ." + fileList[i]['name'].split('.')[1] + " no son aceptados.", 10000, ["red-snackbar"])
        }
      }
    }
    else if (this.maxArchives == 1) this.functionsService.openSnackBar("Solamente puedes subir un fichero", 10000, ["red-snackbar"]);
    else this.functionsService.openSnackBar("El número máximo de archivos es " + this.maxArchives, 10000, ["red-snackbar"]);
    this.structure[this.index]['fileList'] = this.imgURL;
    event.target.files = fileList;
    this.structure[this.index]['tmp_value'] = this.formData
  }

  changeSelectedDoc(event) {
    let docList: FileList = event.target.files;
    this.docURL = [];
    this.doc = [];
    if (this.structure[this.index]['multiple'] == 1) {
      docList = this.mergeFileLists(docList, this.currentFiles.files);
      this.currentFiles.items.clear()
    }
    if (docList.length > 0 && docList.length <= this.maxArchives) {

      this.docsPath = docList;
      const partName = 'uploadFile';
      this.formData = new FormData();
      for (let i = 0; i < docList.length; i++) {
        if (this.acceptedDocTypes.includes(docList[i]['type'])) {
          if (docList[i]['size'] < this.maxSize) {
            const reader = new FileReader();
            reader.readAsDataURL(docList[i]);
            reader.onload = (fileList) => {
              this.docURL.push(docList[i].name);
            };
            this.doc[i] = docList[i];
            this.formData.append(partName + i, this.doc[i], this.doc[i].name);
            this.currentFiles.items.add(docList[i])
            this.genericService.fileUploaded = true;
          } else {
            this.docURL.splice(i, 1)
            this.functionsService.openSnackBar("El archivo " + docList[i]['name'] + " supera los " + this.maxSizeMB + "MB permitidos", 10000, ["red-snackbar"])
          }
        } else {
          this.docURL.splice(i, 1)
          this.functionsService.openSnackBar("Los archivos de tipo ." + docList[i]['name'].split('.')[1] + " no son aceptados.", 10000, ["red-snackbar"])
        }
      }
    }
    else if (this.maxArchives == 1) this.functionsService.openSnackBar("Solamente puedes subir un fichero", 10000, ["red-snackbar"]);
    else this.functionsService.openSnackBar("El número máximo de archivos es " + this.maxArchives, 10000, ["red-snackbar"]);
    this.structure[this.index]['fileList'] = this.docURL;
    event.target.files = docList;
    this.structure[this.index]['tmp_value'] = this.formData;
  }
  
  diselectImage(i) {
    this.imgUpload.nativeElement.value = '';
    this.imgURL.splice(i, 1);
    this.formData.delete("uploadFile" + i);
    this.currentFiles.items.remove(i);
    this.checkFileUploaded();
  }

  diselectDoc(i) {
    this.docUpload.nativeElement.value = '';
    this.docURL.splice(i, 1);
    this.formData.delete("uploadFile" + i);
    this.currentFiles.items.remove(i);
    this.checkFileUploaded();
  }

  onFileDropped(files) {
    let fake = {
      target: {
        files: files
      }
    };
    if (this.structure[this.index]['type'] == "image") this.changeSelectedImage(fake);
    else if (this.structure[this.index]['type'] == "document") this.changeSelectedDoc(fake);
  }

  mergeFileLists(incoming: FileList, actual: FileList) {
    if (actual !== null && actual !== undefined && actual.length > 0) {
      let names = [];
      let aux = new DataTransfer()
      for (let i in actual) {
        if (!isNaN(Number(i))) {
          names.push(actual.item(Number(i)).name)
          aux.items.add(actual.item(Number(i)))
        }
      }
      for (let j in incoming) {
        if (!isNaN(Number(j))) {
          if (!names.includes(incoming.item(Number(j)).name)) {
            aux.items.add(incoming.item(Number(j)))
            names.push(incoming.item(Number(j)).name)
          }
        }
      }
      return aux.files
    }
    return incoming
  }


  checkFileUploaded() {
    this.genericService.fileUploaded = this.structure[this.index]['fileList'].length > 0;
  }
}
