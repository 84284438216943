<ng-container *ngIf="structure[this.index]['type'] === 'text'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
        >
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
      <input
        matInput
        id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
        placeholder="{{ structure[this.index]['description'] }}"
        name="{{ structure[this.index]['name'] }}"
        formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
        (focusin)="focused = true"
        (focusout)="focused = false"
        [maxLength]="maxLength"
        #text
      >
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    <mat-hint *ngIf="validateHintLength()" class="{{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length == maxLength ? 'full' : (maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 0.1*maxLength || maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 20) ? 'almostFull' : ''}}" align="end"> {{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length }} / {{maxLength}} </mat-hint>
  </mat-form-field>
</ng-container>

<div style="display: flex; align-items: baseline;" [ngClass]="{'is-count-input-generic': this.structure[this.index]['type'] === 'number-buttons'}" *ngIf="structure[this.index]['type'] === 'number' || structure[this.index]['type'] === 'number-buttons'">
  <span class="material-icons" style="margin-left: 10px;" (click)="addOneInput(bdField, false)" *ngIf="!multiple && structure[this.index]['id_functional_status_general'] != 3 && structure[this.index]['type'] === 'number-buttons'">remove_circle</span>
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
        >
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
      <input
        matInput
        id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
        type="number"
        placeholder="{{ structure[this.index]['description'] }}"
        name="{{ structure[this.index]['name'] }}"
        formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
        (focusin)="focused = true"
        (focusout)="focused = false"
        [max]="maxlengthNum"
        [maxLength]="maxLength"
        maxLength="10"
        oninput="this.value = this.value.length > this.maxLength ? this.value.slice(0, this.maxLength) : this.value"
        #text>
    </ng-template>
    <span *ngIf="genericForm?.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    <mat-hint *ngIf="validateHintLength()" class="{{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length == maxLength ? 'full' : (maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 0.1*maxLength || maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 20) ? 'almostFull' : ''}}" align="end"> {{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length }} / {{maxLength}} </mat-hint>
  </mat-form-field>
  <span class="material-icons" style="margin-right: 10px;" (click)="addOneInput(bdField)" *ngIf="!multiple && structure[this.index]['id_functional_status_general'] != 3 && structure[this.index]['type'] === 'number-buttons'">add_circle</span>
</div>

<ng-container *ngIf="structure[this.index]['type'] === 'decimal'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label']"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          type="text"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"
          (focusin)="focused = true"
          (focusout)="focused = false"
          [max]="maxlengthNum"
          [maxLength]="maxLength"
          #text
          appTwoDecimal
        >
        <!-- JOAN: Estaba en el input pero ho trec perque dona problemes      oninput="this.value = this.value.length > this.maxLength ? this.value.slice(0, this.maxLength) : this.value"-->
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
      <input
        matInput
        id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
        type="text"
        placeholder="{{ structure[this.index]['description'] }}"
        name="{{ structure[this.index]['name'] }}"
        formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
        (focusin)="focused = true"
        (focusout)="focused = false"
        [max]="maxlengthNum"
        [maxLength]="maxLength"
        #text
        appTwoDecimal
        >
      <!-- JOAN: Estaba en el input pero ho trec perque dona problemes      oninput="this.value = this.value.length > this.maxLength ? this.value.slice(0, this.maxLength) : this.value"-->
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    <mat-hint *ngIf="validateHintLength()" class="{{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].toString().length == maxLength ? 'full' : (maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].toString().length <= 0.1*maxLength || maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].toString().length <= 20) ? 'almostFull' : ''}}"  align="end"> {{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].toString().length}} / {{maxLength}} ({{maxLengthDecimal}})</mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'email'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
        >
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
    <input
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      type="email"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      (focusin)="focused =true"
      (focusout)="focused =false"
      [maxLength]="maxLength"
      #text>
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    <mat-hint *ngIf="validateHintLength()" class="{{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length == maxLength ? 'full' : (maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 0.1*maxLength || maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 20) ? 'almostFull' : ''}}" align="end"> {{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length }} / {{maxLength}} </mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'password'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="this.structure[this.index].hasTmpValuePw == true; else elseBlock">
      <mat-chip-list>
        <mat-chip *ngIf="this.structure[this.index].hasTmpValuePw" [removable]="removable" (removed)="removePw()"
                  style="-webkit-text-security: disc"> {{ this.valuePw }}
          <mat-icon style="-webkit-text-security: none" matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
      <input
        matInput
        id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
        [type]="hide ? 'password' : 'text'"
        placeholder="{{ structure[this.index]['description'] }}"
        name="{{ structure[this.index]['name'] }}"
        formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
        (focusin)="focused = true"
        (focusout)="focused = false"
        [maxLength]="maxLength"
        #text>
      <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
            matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
        <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
      </span>
    </ng-template>
    <button mat-icon-button matSuffix (click)="hide = !hide">
      <mat-icon *ngIf="!this.structure[this.index].hasTmpValuePw">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    <mat-hint *ngIf="validateHintLength()" class="{{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length == maxLength ? 'full' : (maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 0.1*maxLength || maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 20) ? 'almostFull' : ''}}" align="end"> {{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length }} / {{maxLength}} </mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="(structure[this.index]['type'] === 'datepicker' || structure[this.index]['type'] === 'datepicker-month' || structure[this.index]['type'] === 'datepicker-year') && checkIfReferesh()">
  <mat-form-field [formGroup]="genericForm" appearance="{{structure[this.index]['class']}}"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>

    <input [matDatepicker]="picker"
           id="{{ this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
           formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
           matInput
           (click)="picker.open()"
           [matDatepickerFilter]="filterDates">
        <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
           matSuffix aria-label="Clear" class="clear-input" (click)="picker.select(undefined)">
            <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
        </span>
    <mat-datepicker-toggle matSuffix [for]="picker">
    </mat-datepicker-toggle>

    <mat-datepicker #picker [firstDayOfWeek]="1" startView="{{this.startViewDatepicker}}"></mat-datepicker>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'color'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
        >
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
    <input
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      type="color"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      >
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'datetime-local'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
        >
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
    <input
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      type="datetime-local"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      >
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'date'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"

          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
        >
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
    <input
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      type="date"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      >
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'month'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"

          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
        >
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
    <input
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      type="month"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      >
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'tel'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"

          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
        >
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
    <input
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      type="tel"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      >
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuxffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'time'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <!-- <mat-select formControlName="selectedTime">
      <button (click)="generateTimeOptions()">Click me to say hello!</button> 
      <mat-option *ngFor="let timeOption of timeOptions" [value]="timeOption">{{ timeOption }}</mat-option>
    </mat-select> -->
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"

          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
          >
        </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
      <!-- [disabled]="!filterTimes(genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField])"   -->
    <input
        #timeInput
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      type="time"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      >
      <!--[attr.disabled]="!filterTimes(genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField])"-->
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'url'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"

          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
        >
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
    <input
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      type="url"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      (focusin)="focused = true"
      (focusout)="focused = false"
      [maxLength]="maxLength"
      #text>
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    <mat-hint *ngIf="validateHintLength()" class="{{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length == maxLength ? 'full' : (maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 0.1*maxLength || maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 20) ? 'almostFull' : ''}}" align="end"> {{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length }} / {{maxLength}} </mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'week'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <ng-container *ngIf="multiple; else elseBlock">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let chip of chipsList" [selectable]="selectable"
                  [removable]="removable" (removed)="remove(chip)">
          {{chip.name}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
          placeholder="{{ structure[this.index]['description'] }}"
          name="{{ structure[this.index]['name'] }}"

          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [maxLength]="maxLength"
          #text
        >
      </mat-chip-list>
    </ng-container>
    <ng-template #elseBlock>
    <input
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      type="week"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      >
    </ng-template>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'textarea'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>

    <textarea
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      (focusin)="focused = true"
      (focusout)="focused = false"
      [maxLength]="maxLength"
      rows="{{structure[this.index]['min_rows'] ? structure[this.index]['min_rows'] : 1}}"
      #text
      ></textarea>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    <mat-hint *ngIf="validateHintLength()" class="{{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length == maxLength ? 'full' : (maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 0.1*maxLength || maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 20) ? 'almostFull' : ''}}" align="end"> {{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length }} / {{maxLength}} </mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'autosize-textarea'">
  <mat-form-field class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>

    <textarea
      matInput
      type=""
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      (focusin)="focused = true"
      (focusout)="focused = false"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="{{structure[this.index]['min_rows'] ? structure[this.index]['min_rows'] : 1}}"
      cdkAutosizeMaxRows="{{structure[this.index]['max_rows'] ? structure[this.index]['max_rows'] : 5}}"
      [maxLength]="maxLength"
      #text
    ></textarea>
    <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3"
      matSuffix aria-label="Clear" class="clear-input" (click)="clear(bdField)">
      <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined' : 'material-icons'}}">close</mat-icon>
    </span>
    <ng-container [ngTemplateOutlet]="genericPrefix" matPrefix></ng-container>
    <ng-container [ngTemplateOutlet]="genericSuffix" matSuffix></ng-container>
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    <mat-hint *ngIf="validateHintLength()" class="{{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length == maxLength ? 'full' : (maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 0.1*maxLength || maxLength - genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length <= 20) ? 'almostFull' : ''}}" align="end"> {{ genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField].length }} / {{maxLength}} </mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === '5-stars' || structure[this.index]['type'] === '10-stars' || structure[this.index]['type'] === '3-faces' || structure[this.index]['type'] === '5-faces'">
  <mat-form-field style="display: none;" class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
                  [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
    <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
    <input
      #inputStars
      matInput
      type="number"
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      (focusin)="focused = true"
      (focusout)="focused = false"
    >
    <mat-hint *ngIf="structure[this.index]['hint']" [innerHTML]="structure[this.index]['hint'] | safeHtml"></mat-hint>
    
  </mat-form-field>
  <div class="generic-puntuations-input">
    <div style="display: flex;" [ngClass]="{'own-required-invalid':this.structure[this.index].invalid }">
      <span [innerHTML]="structure[this.index]['label'] | safeHtml"></span>
      <span style="margin-left: 2px;" class="required-icon" *ngIf="this.structure[this.index]['id_functional_status_general'] === 5">*</span>
    </div>
    <div *ngIf="structure[this.index]['type'] === '5-stars' || structure[this.index]['type'] === '10-stars'" [ngClass]="{'disabled-stars-generic': this.structure[this.index]['id_functional_status_general'] === 3}">
      <ng-container *ngFor="let star of structure[index]['starsArray']; let i = index">
        <mat-icon style="color: #FFB200;" (click)="clickIcon(i)" (mouseenter)="mouseOverStars(i)" (mouseout)="mouseOutStars(i)" class="material-icons" *ngIf="star">star</mat-icon>
        <mat-icon style="color: #FFB200;" (mouseenter)="mouseOverStars(i)" class="material-icons" *ngIf="!star">star_outline</mat-icon>
      </ng-container>
      <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3 && this.structure[this.index]['id_functional_status_general'] != 5"
        aria-label="Clear" class="clear-input" (click)="clear(bdField)">
        <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined clear-stars-generic' : 'material-icons clear-stars-generic'}}">close</mat-icon>
      </span>
    </div>
    <div *ngIf="structure[this.index]['type'] === '3-faces' || structure[this.index]['type'] === '5-faces'" [ngClass]="{'disabled-stars-generic': this.structure[this.index]['id_functional_status_general'] === 3}">
      <ng-container *ngFor="let star of structure[index]['starsArray']; let i = index">
        <mat-icon [ngStyle]="{'color': structure[index]['iconFacesArray'][i]['color']}" (click)="clickIcon(i)" (mouseout)="mouseOutFaces(i)" class="material-icons generic-face" *ngIf="star">{{structure[index]['iconFacesArray'][i]['icon']}}</mat-icon>
        <mat-icon (mouseenter)="mouseOverFaces(i)" class="material-icons generic-face" *ngIf="!star">{{structure[index]['iconFacesArray'][i]['icon']}}</mat-icon>
      </ng-container>
      <span *ngIf="genericForm && genericForm.value && genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + bdField] && this.structure[this.index]['form_field_empty']== 1 && this.structure[this.index]['id_functional_status_general'] != 3 && this.structure[this.index]['id_functional_status_general'] != 5"
        aria-label="Clear" class="clear-input" (click)="clear(bdField)">
        <mat-icon class="{{appearance == 'outline' ? 'material-icons-outlined clear-stars-generic' : 'material-icons clear-stars-generic'}}">close</mat-icon>
      </span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="structure[this.index]['type'] === 'calendarpicker' && checkIfReferesh()">
  <mat-card style="width: calc(100% - 20px); margin: 10px;">
    <mat-form-field style="visibility: hidden; position: absolute; max-width: 100%; margin: 0;" class="example-full-width form-group" appearance="{{structure[this.index]['class']}}" [formGroup]="genericForm"
    [ngClass]="{'generic-input-disabled': this.structure[this.index]['id_functional_status_general'] === 3}">
      <mat-label [innerHTML]="structure[this.index]['label'] | safeHtml"></mat-label>
      <input
      matInput
      id="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      placeholder="{{ structure[this.index]['description'] }}"
      name="{{ structure[this.index]['name'] }}"
      formControlName="{{ this.structure[this.index]['id_functional_area'] + '-' + bdField }}"
      (focusin)="focused = true"
      (focusout)="focused = false"
      [maxLength]="maxLength"
      #text
      >
    </mat-form-field>
    <mat-calendar #calendar [selected]="genericForm.get(this.structure[this.index]['id_functional_area'] + '-' + bdField).value" (selectedChange)="updateFormDateCalendar($event)" [dateFilter]="filterDates"></mat-calendar>
  </mat-card>
</ng-container>

<ng-template #genericPrefix>
  <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
    <span *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 103 && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2"
    
    [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"
    [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
    [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
    [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
    [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
    [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"
  
    [ngClass]="structure[index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[index]['child'][e.key]['class_custom']"
    [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
    [id]="structure[index]['child'][e.key]['id_functional_area']"
    [matTooltip]="structure[index]['child'][e.key]['tooltip']">
      <mat-icon *ngIf="structure[index]['child'][e.key]['icon'] && structure[index]['child'][e.key]['icon'] != ''"
        class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
        structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
        structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
        structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
        structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
        (click)="onClick(structure[index]['child'][e.key], $event)">{{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
      {{ structure[index]['child'][e.key]['label'] }}
    </span>
  </ng-container>
</ng-template>
<ng-template #genericSuffix>
  <ng-container *ngFor="let e of structure[index]['child'] | keyvalue : genericService.returnZero">
    <span *ngIf="structure[index]['child'][e.key] && structure[index]['child'][e.key]['id_functional_type'] == 104 && genericService.evaluateIfElements(structure[index], e.key) && structure[index]['child'][e.key]['id_functional_status_general'] !== 2"
    
    [fxHide]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || structure[index]['child'][e.key]['hide'] == 1"
    [fxHide.xs]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xs']=='true' && structure[index]['child'][e.key]['hide_xs'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xs'] == 1"
    [fxHide.sm]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_s']=='true' && structure[index]['child'][e.key]['hide_s'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_s'] == 1"
    [fxHide.md]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_m']=='true' && structure[index]['child'][e.key]['hide_m'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_m'] == 1"
    [fxHide.lg]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_l']=='true' && structure[index]['child'][e.key]['hide_l'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_l'] == 1"
    [fxHide.xl]="(structure[index]['child'][e.key]['hide']=='true' && structure[index]['child'][e.key]['hide'] != 0) || (structure[index]['child'][e.key]['hide_xl']=='true' && structure[index]['child'][e.key]['hide_xl'] != 0) || structure[index]['child'][e.key]['hide'] == 1 || structure[index]['child'][e.key]['hide_xl'] == 1"

    [ngClass]="structure[index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[index]['child'][e.key]['class_custom'] + ' ' + 'mkt-no-available' : structure[index]['child'][e.key]['class_custom']"
    [ngStyle]="structure[index]['child'][e.key]['styleParsed']['style']"
    [id]="structure[index]['child'][e.key]['id_functional_area']"
    [matTooltip]="structure[index]['child'][e.key]['tooltip']">
      {{ structure[index]['child'][e.key]['label'] }}
      <mat-icon *ngIf="structure[index]['child'][e.key]['icon'] && structure[index]['child'][e.key]['icon'] != ''"
        class="{{structure[index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
        structure[index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
        structure[index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
        structure[index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
        structure[index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
        (click)="onClick(structure[index]['child'][e.key], $event)">{{ structure[index]['child'][e.key]['icon'] }}</mat-icon>
    </span>
  </ng-container>
</ng-template>