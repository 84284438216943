<ng-container *ngIf="loaded">
  <ng-container *ngIf="structure[this.index]['type'] === 'submit'"
    [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)"
    [matBadge]="this.structure[this.index]['badge']">
    <button mat-button type="button"
      [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']"
      type="submit" [attr.form]="'form'+structure['id_functional_parent_initial']"
      [disabled]="!functionFinish[this.index]['done']"
      [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)"
      matBadge="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon'] && functionFinish[this.index]['done']"
          class="{{structure[this.index]['icon_type']}}"> {{ structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml"
          *ngIf="functionFinish[this.index]['done'] && this.structure[this.index]['label']"></span>
        <mat-spinner style="margin:0 auto;" diameter="20" *ngIf="!functionFinish[this.index]['done']"></mat-spinner>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'button'">
    <button mat-button type="button"
      *ngIf="((this.structure[this.index]['id_function'] == 7 || this.structure[this.index]['id_function'] == 8 || this.structure[this.index]['id_function'] == 54) && (this.genericService.getNumChanges() > 0 || this.genericService.fileUploaded) && this.structure[this.index]['id_functional_parent_initial_dsb'] == 0) || (this.structure[this.index]['id_function'] != 7  && this.structure[this.index]['id_function'] != 8 || this.structure[this.index]['id_function'] == 54) || this.structure[this.index]['id_functional_parent_initial_dsb'] != 0 || this.structure[this.index]['creatingMode']"
      [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']"
      (click)="onClickEvent($event)" [disabled]="!functionFinish[this.index]['done']"
      [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)"
      [matBadge]="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon'] && functionFinish[this.index]['done']"
          class="{{structure[this.index]['icon_type']}}"> {{ structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml"
          *ngIf="functionFinish[this.index]['done'] && this.structure[this.index]['label']"></span>
        <mat-spinner style="margin:0 auto;" diameter="20" *ngIf="!functionFinish[this.index]['done']"></mat-spinner>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'nextStep' && !structure[this.index]['creatingMode']">
    <button mat-button type="button"
      [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']"
      matStepperNext (click)="onClickEvent($event)"
      [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)"
      [matBadge]="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon']" class="{{structure[this.index]['icon_type']}}"> {{
          structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml"
          *ngIf="this.structure[this.index]['label']"></span>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'previousStep' && !structure[this.index]['creatingMode']">
    <button mat-button type="button"
      [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']"
      matStepperPrevious (click)="onClickEvent($event)"
      [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)"
      [matBadge]="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon']" class="{{structure[this.index]['icon_type']}}"> {{
          structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml"
          *ngIf="this.structure[this.index]['label']"></span>
      </ng-container>
    </button>
  </ng-container>

  <ng-container
    *ngIf="structure[this.index]['type'] === 'menu' && structure[index]['child'] && structure[index]['child'].length > 0 && genericService.checkChildActive(structure[index])">
    <span fxHide="false" fxHide.sm="true" fxHide.xs="true">
      <button #button mat-button type="button" [matMenuTriggerFor]="submenu" #menuTrigger="matMenuTrigger"
        (click)="displayMenuActivate()" (menuClosed)="displayMenuDesActivate()"
        [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']"
        class="menu-button"
        [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)"
        [matBadge]="this.structure[this.index]['badge']">
        <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
        <ng-container *ngIf="structure[this.index]['url'] == null">
          <i *ngIf="structure[this.index]['icon']" class="{{structure[this.index]['icon_type']}}"> {{
            structure[this.index]['icon'] }} </i>
          <span [innerHTML]="this.structure[this.index]['label'] | safeHtml"
            *ngIf="this.structure[this.index]['label']"></span>
        </ng-container>
      </button>
      <mat-menu #submenu="matMenu" direction="down">
        <span *ngIf="displayMenu">
          <app-generic [structure]="structure[index]" [param]="param"></app-generic>
        </span>
      </mat-menu>
    </span>
    <span fxHide="true" fxHide.sm="false" fxHide.xs="false">
      <button #button mat-button type="button" (click)="openBottomSheet()"
        [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']"
        class="menu-button"
        [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)"
        [matBadge]="this.structure[this.index]['badge']">
        <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
        <ng-container *ngIf="structure[this.index]['url'] == null">
          <i *ngIf="structure[this.index]['icon']" class="{{structure[this.index]['icon_type']}}"> {{
            structure[this.index]['icon'] }} </i>
          <span [innerHTML]="this.structure[this.index]['label'] | safeHtml"
            *ngIf="this.structure[this.index]['label']"></span>
        </ng-container>
      </button>
    </span>
  </ng-container>

  <ng-container *ngIf="structure[this.index]['type'] === 'menu' && !structure[index]['child']">
    <button mat-menu-item
      *ngIf="((this.structure[this.index]['id_function'] == 7 || this.structure[this.index]['id_function'] == 8 || this.structure[this.index]['id_function'] == 54) && (this.genericService.getNumChanges() > 0 || this.genericService.fileUploaded) && (this.structure[this.index]['window'] == this.structure[this.index]['id_functional_parent_initial'])) || (this.structure[this.index]['id_function'] != 7  && this.structure[this.index]['id_function'] != 8 || this.structure[this.index]['id_function'] == 54) || (this.structure[this.index]['window'] != this.structure[this.index]['id_functional_parent_initial']) || this.structure[this.index]['creatingMode']"
      [ngClass]="genericService.highlightHeaderElements.includes(structure[index]['id_functional_area']) ? 'highlight' + ' ' + structure[this.index]['class'] : structure[this.index]['class']"
      (click)="onClickEvent($event)" [disabled]="!functionFinish[this.index]['done']"
      [matBadgeHidden]="!genericService.evaluateIfs(structure[this.index]['id_condition_badge'], structure[this.index]['id_functional_area'], structure[this.index], true)"
      [matBadge]="this.structure[this.index]['badge']">
      <img mat-card-image *ngIf="structure[this.index]['url'] != null" [src]="structure[this.index]['url']">
      <ng-container *ngIf="structure[this.index]['url'] == null">
        <i *ngIf="structure[this.index]['icon'] && functionFinish[this.index]['done']"
          class="{{structure[this.index]['icon_type']}}"> {{ structure[this.index]['icon'] }} </i>
        <span [innerHTML]="this.structure[this.index]['label'] | safeHtml"
          *ngIf="functionFinish[this.index]['done'] && this.structure[this.index]['label']"></span>
      </ng-container>
    </button>
  </ng-container>
</ng-container>