import { EventEmitter, Injectable, Output } from "@angular/core";
import { Values } from "../../../values/values";

// Serivce
import { RoutingService } from "../routing/routing.service";
import { ClientsFunctionsService } from "./clients-functions.service";
import { EndpointService } from "../endpoint/endpoint.service";
import { TemplateService } from "app/services/generic/custom/template-element";
import { JourneyService } from 'app/services/journey/journey.service';
import { RedsysService } from 'app/services/redsys.service';
import { PaycometService } from 'app/services/paycomet.service';
import { PrintTicketService } from 'app/services/generic/custom/print-ticket.service';

import 'jspdf-autotable';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import html2canvas from "html2canvas";

// Material
import { MatDialog } from "@angular/material/dialog";

// Component
import { DialogComponent } from "../../generic/components/dialog/dialog.component";
import { GenericService } from "../generic/generic.service";

import { MatSnackBar } from "@angular/material/snack-bar";

import { saveAs } from "file-saver";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { BottomSheetComponent } from "app/generic/components/bottom-sheet/bottom-sheet.component";
import { AuthService } from "../auth/auth.service";
import { Location } from "@angular/common";
import { SideNavService } from "app/utils/SideNavService";
// Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
//import { Subject } from "rxjs/Subject";
//import { EmptyObservable } from "rxjs-compat/observable/EmptyObservable";
import { EMPTY } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";

import { UserFunctionsService } from "./user-functions.service";
import { ColorPickerDialog } from "app/activities/subcomponents/color-picker-dialog/color-picker-dialog";
import { setCategories } from "app/activities/objects/category";
import { Router } from "@angular/router";
import { DemoDialogComponent } from "app/demo-dialog/demo-dialog.component";
import { BottomsheetMenuComponent } from "app/generic/components/button/bottomsheet-menu/bottomsheet-menu.component";


@Injectable({
  providedIn: "root",
})
export class FunctionsService {
  public dialogRef = null;
  public route = null;
  public id_route = null;
  public tmpArray = [];
  private removed = [];
  public lastLabel = "";
  public errorMessage = "";

  public bottomSheetRef = null;
  public bottomSheetRefMenu = null;

  public dateToStringDBDate: Date;
  public dateToday: string;
  public minDate: string;
  public functionFinish: boolean;

  public open = false;
  public downloadingStaticPage = false;
  private imageUpload = [];

  public portalConfigured = 0;

  public arrayImagenes = [];

  public arrayConditions = [];
  public arrayTables = [];
  public fieldNames = [];
  public arrayOperators = [];
  public arrayValues = [];
  public fecha_inicio = null;
  public fecha_fin = null;

  public fecha_inicio_reservas = null;
  public fecha_fin_reservas = null;
  public hora_inicio_reservas = null;
  public hora_fin_reservas = null;

  public historyInserts = [];

  public checkoutUrl = "checkout-payment-cart";

  public addConditionFormIsValid: boolean = false;

  @Output() update_category: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() openNoti: EventEmitter<any> = new EventEmitter();
  //@ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;

  constructor(
    private routingService: RoutingService,
    public dialog: MatDialog,
    public _bottomSheet: MatBottomSheet,
    public snackBar: MatSnackBar,
    public clientsFunctionsService: ClientsFunctionsService,
    private endpointService: EndpointService,
    private genericService: GenericService,
    private authService: AuthService,
    private location: Location,
    private sidenavService: SideNavService,
    private UserFunctionsService: UserFunctionsService,
    private router: Router,
    private clipboard: Clipboard,
    public templateService: TemplateService,
    public journeyService: JourneyService,
    public redsysService: RedsysService,
    public paycometService: PaycometService,
    public printTicketService: PrintTicketService
  ) { }

  public getFunction(structure, param = null, finished = null, idFunction = null, autoExecuteFunction = false, isFromField = false) {
    if (structure && structure !== undefined) structure['autoExecuteFunction'] = autoExecuteFunction;

    // if need intern_param use this function in your function case --> this.genericService.getInternParam(structure, param);
    // if need input_param use this function in your function case --> this.getInputParam(structure);

    if (!isFromField && event) event.stopPropagation();

    if(structure && structure["creatingMode"] !== undefined && structure["creatingMode"]) {
      this.finishFunction(finished);
      return;
    }

    if (structure !== undefined && !structure["autoExecuteFunction"] && (structure["readMode"] !== undefined && structure["readMode"])) {
      this.openSnackBar("Parece que solo tienes permiso de lectura.", 7000, ["lightred-snackbar",]);
      this.finishFunction(finished);
      return;
    }

    this.genericService.actualFunctionFinish = true;

    if (!this.genericService.actualFunctionFinish) {
      if (structure !== undefined || !structure["autoExecuteFunction"]) {
        this.openSnackBar("Parece que ya hay otra función en marcha.", 7000, ["lightred-snackbar",]);
      }
      this.finishFunction(finished);
      return;
    }

    this.genericService.actualFunctionFinish = false;
    let id = this.cloneVariable(idFunction);
    if (structure !== undefined && id == null) {
      id = this.cloneVariable(structure["id_function"]);
    }

    if (id) {
      let ids = id.toString().split(",");
      for (let i in ids) {
        this.executeFunction(ids[i], structure, param, finished, isFromField);
      }
    }
  }

  public executeFunction(id, structure, param, finished, isFromField) {
    id = parseInt(id);
    if (!(id > 0)) return;
    if (structure !== undefined && this.checkPermissions(structure, finished)) {
      console.log("ID FUNCTION EXECUTE", id, structure, param);
      if (this.bottomSheetRefMenu !== null) this.bottomSheetRefMenu.dismiss();
      if (id == 20 && structure["internal_routing"] && structure["internal_routing"] != null) id = 1;
      switch (id) {
        case 1:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            if (!structure["no_save_params"]) this.saveParams(structure, param, structure["id_functional_parent_initial"]);
            this.route = structure["internal_routing"];
            this.id_route = structure["internal_routing_id_functional_area"];
            this.routingService.previousModule = structure["id_functional_parent_initial"];
            if(structure['isPressingControl']) {
              this.openInExteranlURL(this.route);
            } else {
              this.goRouting(this.route, true);
            }
          }
          this.finishFunction(finished);
          break;
        case 2:
          if (structure && structure["id_functional_parent_initial_dsb"] == 0 && !structure["paramsAdded"]) {
            this.genericService.getInternParam(structure, param);
          }
          this.openDialog(structure, 1);
          this.finishFunction(finished);
          break;
        case 3:
          // Cerrar generic dialog
          this.dialogRef.close(structure, param);
          this.finishFunction(finished);
          break;
        case 4:
          let validReq = this.formsArray(structure, finished);
          if (validReq) {
            this.duplicateElementUnified(structure, finished, true, 1);
            this.dialogRef.close(structure, finished);
          }
          this.finishFunction(finished);
          break;
        case 5:
          if (structure["id_functional_parent_initial_dsb"] == 0 && !structure["paramsAdded"]) {
            this.genericService.getInternParam(structure, param);
          }
          this.openDialog(structure, 2);
          break;
        case 6:
          this.generateNewOutputParam(structure);
          this.genericService.updateParamControl();
          this.route = structure["internal_routing"];
          this.id_route = structure["internal_routing_id_functional_area"];

          this.routingService.previousModule =
            structure["id_functional_parent_initial"];
          if (param && param["tmp_param"]) {
            this.routingService.routeParam = null;
          }
          this.goRouting(this.route, true);
          this.finishFunction(finished);
          break;
        case 7:
          this.insertUpdateForm(structure, param, finished, 0, true, false);
          break;
        case 8:
          this.insertUpdateForm(structure, param, finished, 1, false, false);
          break;
        case 9:
          this.insertUpdateForm(structure, param, finished, 0, false, false);
          break;
        case 10:
          this.insertUpdateForm(structure, param, finished, 0, false, true, 1);
          break;
        case 11:
          this.duplicateElementUnified(structure, finished);
          this.finishFunction(finished);
          break;
        case 12:
          this.openDeferContainer(param, structure);
          this.finishFunction(finished);
          break;
        case 13:
          this.sidenavService.open(0);
          this.finishFunction(finished);
          break;
        case 14:
          this.sidenavService.close(0);
          this.finishFunction(finished);
          break;
        case 15:
          this.revertValues(structure);
          break;
        case 16:
          if (this.genericService.paramControlVariables[structure["id_functional_parent_initial"]]) {
            let par = this.genericService.paramControlVariables[structure["id_functional_parent_initial"]]
            for (let aux in param["tmp_param"]) {
              let found = false;
              for (let out of par["params"][par["indexParam"]]["output"]) {
                if (out["bd_field"] == aux) {
                  found = true;
                  out["value"] = param["tmp_param"][aux];
                }
              }
              if (!found) {
                par["params"][par["indexParam"]]["output"].push({
                  id_db: structure["id_db"],
                  bd_table: structure["bd_table"],
                  bd_field: aux,
                  value: param["tmp_param"][aux],
                });
              }
            }
          }
          this.genericService.updateParamControl();
          this.route = structure["internal_routing"];
          this.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule =
            structure["id_functional_parent_initial"];
          if (param && param["tmp_param"]) {
            this.routingService.routeParam = param["tmp_param"];
          }
          this.goRouting(this.route, true);
          break;
        case 17:
          this.deleteFunctionalArea(structure);
          this.finishFunction(finished);
          break;
        case 18:
          //Chat
          this.routingService.shouldOpenChat = true;
          this.finishFunction(finished);
          break;
        case 19:
          //Help
          this.routingService.shouldOpenHelp = true;
          this.finishFunction(finished);
          break;
        case 20:
          //External Window
          let paramsExternalWindowOld = this.cloneVariable(this.routingService.paramsExternalWindow);
          if (structure["customParams"] === undefined) {
            this.routingService.paramsExternalWindow = this.genericService.getInternParam(structure, param);
            this.genericService.fetchStructureExternalWindow(structure["internal_routing_id_functional_area"]);
            if (this.routingService.paramsExternalWindow != paramsExternalWindowOld) this.routingService.shouldOpenExternalWindow = true;
            this.finishFunction(finished);
          } else {
            this.routingService.paramsExternalWindow = structure["customParams"];
            this.genericService.fetchStructureExternalWindow(structure["internal_routing_id_functional_area"]);
            if (this.routingService.paramsExternalWindow != paramsExternalWindowOld) this.routingService.shouldOpenExternalWindow = true;
            this.finishFunction(finished);
          }
          break;
        case 21:
          this.finishFunction(finished);
          break;
        case 22:
          this.routingService.openButtonAutoPlantilla.next();
          this.finishFunction(finished);
          break;
        case 23:
          this.genericService.openAccountMode();
          this.finishFunction(finished);
          break;
        case 24:
          this.openColorPickerDialog(param);
          this.finishFunction(finished);
          break;
        case 25:
          this.duplicateFunctionalAreaDB(structure, param);
          break;
        case 26:
          this.openExternalUrl(structure);
          break;
        case 27:
          setTimeout(() => {
            this.reloadQueries(structure, param, finished);
          });
          break;
        case 33:
          this.deleteDataDialog(structure, 0);
          break;
        case 34:
          this.openBottomSheet(structure);
          this.finishFunction(finished);
          break;
        case 35:
          if (structure["id_functional_parent_initial_dsb"] == 0) {
            this.genericService.getInternParam(structure, param);
            if(structure["massive_action"]) {
              this.genericService.getInternParam(structure, null, 1);
            }
          }
          this.openDialog(structure, 3);
          break;
        case 36:
          this.openFile(structure, param, false);
          this.finishFunction(finished);
          break;
        case 37:
          this.openFile(structure, param, true);
          this.finishFunction(finished);
          break;
        case 38:
          this.closeBottomSheet(structure);
          this.finishFunction(finished);
          break;
        case 39:
          this.insertUpdateForm(structure, param, finished, 0, false, true, 2);
          break;
        case 40:
          this.insertUpdateForm(structure, param, finished, 0, false, true, 3);
          break;
        case 41:
          this.deleteDataDialog(structure, 1);
          break;
        case 42:
          this.deleteDataDialog(structure, 2);
          break;
        case 43:
          this.deleteDataDialog(structure, 3);
          break;
        case 44:
          this.openDialog(structure, 4, this.getInternParamDialog(structure, param));
          break;
        case 45:
          this.openDialog(structure, 5);
          break;
        case 46:
          this.openDialog(structure, 6, this.getInternParamDialog(structure, param));
          break;
        case 47:
          let from = 0;
          if (structure["id_functional_parent_initial"] == this.genericService.headerId || structure["id_functional_parent_initial"] == this.genericService.headerNoLoguedId) from = 1;
          if (structure["id_functional_parent_initial"] == this.genericService.footerId || structure["id_functional_parent_initial"] == this.genericService.footerNoLoguedId) from = 2;

          if (structure["id_functional_type"] != 3) {
            let struct = this.genericService.findElementWithId(finished, false, false, true);
            if (!struct) break;
            struct["expandedElement"] = struct["expandedElement"] === param ? null : param; // Si son iguales significa que hay que cerrar el expansion
          } else {
            structure["expandedElement"] = structure["expandedElement"] === param ? null : param; // Si son iguales significa que hay que cerrar el expansion
          }

          this.genericService.loadExpansion(param, from);
          break;
        case 48:
          this.genericService.fillExpansion(param, { 0: structure });
          let selBox = document.createElement("textarea");
          selBox.style.position = "fixed";
          selBox.style.left = "0";
          selBox.style.top = "0";
          selBox.style.opacity = "0";
          selBox.value = structure["description"];
          document.body.appendChild(selBox);
          selBox.focus();
          selBox.select();
          document.execCommand("copy");
          document.body.removeChild(selBox);
          this.openSnackBar(structure["text"], 30000, ["lightgreen-snackbar"]);
          break;
        case 49:
          /* VENTA */
          let productVenta = this.findProductFromParams(structure, param);
          this.productEgoStart(1, productVenta);
          this.finishFunction(finished);
          break;
        case 50:
          /* TUTORIAL */
          let productTutorial = this.findProductFromParams(structure, param);
          this.productEgoStart(2, productTutorial);
          this.finishFunction(finished);
          break;
        case 51:
          //Guardar vivienda como borrador
          this.insertUpdateForm(structure, param, finished, 1, false, false, null, 0);
          break;
        case 52:
          //Publicar vivienda
          this.insertUpdateForm(structure, param, finished, 1, false, false, null, 1);
          break;
        case 53:
          this.deleteDataDialog(structure, 4);
          break;
        case 54:
          this.insertUpdateForm(structure, param, finished, 2, false, false);
          break;
        case 55:
          this.copyTableField(structure, param);
          break;
        case 56:
          this.insertUpdateFormConfigPortales(structure, param, finished);
          break;
        case 57:
          this.productEgoStart(1, structure["internal_routing"]);
          this.finishFunction(finished);
          break;
        case 58:
          this.productEgoStart(2, structure["internal_routing"]);
          this.finishFunction(finished);
          break;
        case 59:
          this.downloadPDF(1);
          break;
        case 60:
          this.downloadPDF(0);
          break;
        case 61:
          this.sendEmailInmueble(structure, param, finished);
          break;
        case 62:
          this.sendWhatsAppClienteContactar(structure, param, finished);
          break;
        case 63:
          this.openFichaInmueble(structure, param, finished);
          break;
        case 64:
          this.getTextInmueble(structure, param, finished, 1); // whatsapp empresa
          break;
        case 65:
          this.getTextInmueble(structure, param, finished, 2); // contenido email empresa
          break;
        case 66:
          this.getTextInmueble(structure, param, finished, 3); //  asunto empresa
          break;
        case 67:
          this.updateImagePropertyCustom(finished);
          break;
        case 68:
          this.vincularGoogleMail(structure, param, finished);
          break;
        case 69:
          this.desvincularGoogleMail(structure, param, finished);
          break;
        case 70:
          this.updateConditionsCustom(finished, structure);
          break;
        case 71:
          this.showStaticReport(structure, param, finished);
          break;
        case 72:
          this.updatePlantillaElementsCustom(structure, param, finished);
          break;
        case 73:
          this.finishFunction(finished);
          break;
        case 74:
          this.endpointService
            .vincularTwitter(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              if (data["errorCode"] == 1)
                window.open(data["response"], "_self");
              if (data["errorCode"] === 0) {
                finished = true;
                this.openSnackBar(
                  "Cuenta de Twitter vinculada correctamente",
                  7000,
                  ["green-snackbar"]
                );
                this.genericService.refreshStructure(0);
              }
            });
          break;
        case 75:
          this.endpointService
            .desvincularTwitter(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              finished = true;
              this.openSnackBar(
                "Cuenta de Twitter desvinculada correctamente",
                7000,
                ["green-snackbar"]
              );
              this.genericService.refreshStructure(0);
            });
          break;
        case 76:
          this.endpointService
            .vincularInstagram(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              if (data["errorCode"] == 1)
                window.open(data["response"], "_self");
              if (data["errorCode"] === 0) {
                finished = true;
                this.openSnackBar(
                  "Cuenta de Instagram vinculada correctamente",
                  7000,
                  ["green-snackbar"]
                );
                this.genericService.refreshStructure(0);
              }
            });
          break;
        case 77:
          this.endpointService
            .desvincularInstagram(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              finished = true;
              this.openSnackBar(
                "Cuenta de Instagram desvinculada correctamente",
                7000,
                ["green-snackbar"]
              );
              this.genericService.refreshStructure(0);
            });
          break;
        case 78:
          this.endpointService
            .vincularFacebook(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              if (data["errorCode"] == 1)
                window.open(data["response"], "_self");
              if (data["errorCode"] === 0) {
                finished = true;
                this.openSnackBar(
                  "Cuenta de Facebook vinculada correctamente",
                  7000,
                  ["green-snackbar"]
                );
                this.genericService.refreshStructure(0);
              }
            });
          break;
        case 79:
          this.endpointService
            .desvincularFacebook(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              finished = true;
              this.openSnackBar(
                "Cuenta de Facebook desvinculada correctamente",
                7000,
                ["green-snackbar"]
              );
              this.genericService.refreshStructure(0);
            });
          break;
        case 80:
          this.endpointService
            .vincularLinkedin(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              if (data["errorCode"] == 1)
                window.open(data["response"], "_self");
              if (data["errorCode"] === 0) {
                finished = true;
                this.openSnackBar(
                  "Cuenta de Linkedin vinculada correctamente",
                  7000,
                  ["green-snackbar"]
                );
                this.genericService.refreshStructure(0);
              }
            });
          break;
        case 81:
          this.endpointService
            .desvincularLinkedin(
              this.authService.userId,
              this.authService.empresaId
            )
            .subscribe((data) => {
              finished = true;
              this.openSnackBar(
                "Cuenta de Linkedin desvinculada correctamente",
                7000,
                ["green-snackbar"]
              );
              this.genericService.refreshStructure(0);
            });
          break;
        case 82:
          this.duplicarInmueble(structure, param, finished);
          break;
        case 83:
          this.validateEmailBackend(structure, param, finished);
          break;
        case 84:
          this.sendWhatsAppClienteContactar(structure, param, finished);
          break;
        case 85:
          this.getTextInmueble(structure, param, finished, 4); // whatsapp inmueble
          break;
        case 86:
          this.getTextInmueble(structure, param, finished, 5); // contenido email inmueble
          break;
        case 87:
          this.getTextInmueble(structure, param, finished, 6); //  asunto inmueble
          break;
        case 88:
          this.saveTextInmueble(structure, param, finished, 1); //  mensaje whatsapp inmueble
          break;
        case 89:
          this.saveTextInmueble(structure, param, finished, 2); //  mensaje email inmueble
          break;
        case 90:
          this.saveTextInmueble(structure, param, finished, 3); //  asunto email inmueble
          break;
        case 91:
          this.insertUpdateForm(structure, param, finished, 0, false, false, null, null, true);
          break;
        case 92:
          this.demoInmo(param);
          this.finishFunction(finished);
          break;
        case 93:
          this.loadClientsFromFile(param, finished, structure);
          break;
        case 94:
          this.duplicatePlantillaEmail(param, finished, structure);
          break;
        case 95:
          this.duplicatePlantillaEmail(param, finished, structure, 1);
          break;
        case 96:
          this.openFichaPropietario(structure, param, finished);
          break;
        case 97:
          this.verForm(structure, param, finished);
          break;
        case 98:
          this.selectImageAndAddInEventsTemplateCustom(structure, param, finished);
          break;
        case 99:
          this.updateJourneyCustom(finished);
          break;
        case 100:
          this.archiveAllStatusDemandaFromViviendaCustom(finished, structure, param);
          break;
        case 101:
          this.finishFunction(finished);
          this.genericService.refreshStructure(0);
          break;
        case 102:
          this.finishFunction(finished);
          this.genericService.refreshStructure(1);
          break;
        case 103:
          this.finishFunction(finished);
          this.genericService.refreshStructure(2);
          break;
        case 104:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateNewImport(structure, param);
          });
          break;
        case 105:
          this.finishFunction(finished);
          this.startCheckOutReservaHotel(structure, param);
          break;
        case 106:
          this.addProductsToNotes(structure, param);
          this.finishFunction(finished);
          break;
        case 107:
          this.finishFunction(finished);
          this.updateProducts(finished);
          break;
        case 108:
          this.finishFunction(finished);
          setTimeout(() => {
            this.selectRooms(structure);
          });
          break;

        case 109:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            let structureClone = this.cloneVariable(structure);
            structureClone['id_function'] = 2;
            this.getFunctionFormField(structureClone);
          } else {
            this.finishFunction(finished);
          }
          break;
        case 110:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateNewImportTicket(structure, param);
          });
          break;
        case 111:
          this.finishFunction(finished);
          break;
        case 112:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openInfoDieta(structure, param);
          });
          break;
        case 113:
          this.importDeportActivityAssign(structure, param, finished)
          break;
        case 114:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateNewImportTicketActLudicas(structure, param);
          });
          break;
        case 115:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            setTimeout(() => {
              this.newPeriodoDietaSelected(structure, param);
            });
            this.finishFunction(finished);
          } else {
            this.finishFunction(finished);
          }
          break;
        case 116:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            setTimeout(() => {
              this.cleanDieta(structure, param);
            });
            this.finishFunction(finished);
          } else {
            this.finishFunction(finished);
          }
          break;
        case 117:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            setTimeout(() => {
              this.calculateImporteIVAEncargo(structure, param);
            });
            this.finishFunction(finished);
          } else {
            this.finishFunction(finished);
          }
          break;
        case 118:
          setTimeout(() => {
            this.calculateImporteManualTotalRest(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 119:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openIncidenciaReserva(structure, param);
          });
          break;
        case 120: // Pago-aceptado
          this.finishFunction(finished);
          this.updatePaymentMethod(structure, 1);
          break;
        case 121: // Pago-rechazado
          this.finishFunction(finished);
          this.updatePaymentMethod(structure, 0);
          break;
        case 122: // Volver a la página de pago
          this.finishFunction(finished);
          this.getPaymentPage(structure);
          break;
        case 123:
          this.insertUpdateForm(structure, param, finished, 99, false, false, 18);
          this.finishFunction(finished);
          break;
        case 124:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateNewImportDiferencia(structure, param);
          });
          break;
        case 125:
          this.finishFunction(finished);
          setTimeout(() => {
            this.setFieldsIDClienteEmpresa(structure, param);
          });
          break;
        case 126:
          this.finishFunction(finished);
          setTimeout(() => {
            this.cargarImportePendiente(structure, param);
          });
          break;
        case 127: //Realizar Pago
          this.finishFunction(finished);
          this.realizarPago(structure, param, finished, false, false);
          break;
        case 128:
          this.finishFunction(finished);
          setTimeout(() => {
            this.updatePagoTotal(structure, param);
          });
          break;
        case 129:
          setTimeout(() => {
            this.relacionaCampoIdCliente(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 130:
          setTimeout(() => {
            this.sumaHoraFecha(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 131:
          this.insertPedidoStaticRestaurant(structure);
          break;
        case 132:
          setTimeout(() => {
            this.removeTableRelationWhenValue(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 133:
          setTimeout(() => {
            this.toAllDayDate(structure);
          });
          this.finishFunction(finished);
          break;
        case 134:
          setTimeout(() => {
            this.changeHideButtonId33028(structure);
          });
          this.finishFunction(finished);
          break;
        case 135:
          setTimeout(() => {
            this.fechaFinActividad(structure);
          });
          this.finishFunction(finished);
          break;
        case 136:
          setTimeout(() => {
            this.changeHideButtonId33149(structure);
          });
          this.finishFunction(finished);
          break;
        case 137:
          setTimeout(() => {
            this.agendaEstadoInitUpdate(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 138:
          let x = { id_function: 35, internal_routing_id_functional_area: 33949, refresh_params: 1 };
          const now = new Date();
          this.addWindowParam(this.formatDate(now, true), 1, "actividades_calendario", "fecha_inicio", 89); // Hay que poner en el header porque el Dialog esta en el Header
          x['refresh_params'] = 2;
          this.getFunction(x, null);
          this.finishFunction(finished);
          break;
        case 139:
          setTimeout(() => {
            this.saveTakeAwayRestaurant(structure, param, finished);
          });
          this.finishFunction(finished);
          break;
        case 140:
          setTimeout(() => {
            this.changeHideButtonId34505(structure);
          });
          this.finishFunction(finished);
          break;
        case 141:
          this.finishFunction(finished);
          setTimeout(() => {
            this.assignPaymentImport(structure);
          });
          break;
        case 142:
          setTimeout(() => {
            this.insertTicketNoPagadoTPV(structure, param, finished);
          });
          break;
        case 143:
          this.saveInsertedValuesAndRouting(1, structure, param, finished);
          this.finishFunction(finished);
          break;
        case 144:
          this.addPropertyLocation(structure);
          break;
        case 145:
          this.addClientLocation(structure);
          break;
        case 146:
          this.saveInsertedValuesAndRouting(2, structure, param, finished);
          this.finishFunction(finished);
          break;
        case 147:
          this.printTicketRest(structure, param);
          this.finishFunction(finished);
          break;
        case 148:
          this.mergePedidosRestaurant(structure, param);
          this.finishFunction(finished);
          break;
        case 149:
          setTimeout(() => {
            this.movePedidoRestaurant(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 150:
          this.addToCheckoutRestaurant(structure, param);
          this.finishFunction(finished);
          break;
        case 151:
          this.pasarInmueble(structure, param);
          this.finishFunction(finished);
          break;
        case 152:
          this.finishFunction(finished);
          this.dialog.closeAll();
          this.genericService.updateResults(this.genericService.getInternParam(structure, param), structure['id_functional_parent_initial']);
          break;
        case 153:
          this.duplicarMatching(structure, param, finished);
          break;
        case 154:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calcNaveSuperficies(structure, param);
          });
          break;
        case 155:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateImportSaldoInicial(structure, param);
          });
          break;
        case 156:
          setTimeout(() => {
            // this.insertUpdateForm(structure, param, finished, 1, false, false);
            this.reloadTable(structure, param, finished);
          });
          break;
        case 157:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateImportesGestorHipotecario(structure, param);
          }, 200);
          break;
        case 162:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateNewImportTicketActLudic(structure, param);
          });
          break;
        case 163:
          setTimeout(() => {
            this.insertTicketNoPagadoActLudic(structure, param, finished);
          });
          this.finishFunction(finished);
          break;
        case 164:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateNewImportActLudic(structure, param);
          });
          break;
        case 165:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateNewImportDiferenciaActLudic(structure, param);
          });
          break;
        case 166:
          this.finishFunction(finished);
          setTimeout(() => {
            this.cargarImportePendienteActLudic(structure, param);
          });
          break;
        case 167:
          setTimeout(() => {
            this.changeHideButtonId42364(structure);
          });
          this.finishFunction(finished);
          break;
        case 168:
          setTimeout(() => {
            this.refreshCheckOutReservaHotel(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 169:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateImportPorParticipante(structure, param);
          });
          break;
        case 170:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateImportSaldoInicialActLudic(structure, param);
          });
          break;
        case 171:
          setTimeout(() => {
            this.calculateNewImportReservaHotel(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 173:
          setTimeout(() => {
            this.calculateImporteManualTotalActLudic(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 174:
          this.insertUpdateForm(structure, param, finished, 0, true, false);
          this.endIncidencia();
          this.finishFunction(finished);
          break;
        case 175:
          this.insertUpdateForm(structure, param, finished, 3, false, false);
          break;
        case 176:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openContratoReserva(structure, param);
          });
          break;
        case 177:
          setTimeout(() => {
            this.changeHideButtonId44329(structure);
          });
          this.finishFunction(finished);
          break;
        case 178:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openContratoReserva(structure, param, 2);
          });
          break;
        case 179:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openContratoReserva(structure, param, 3);
          });
          break;
        case 180:
          this.finishFunction(finished);
          setTimeout(() => {
            this.construirDireccion(structure, param);
          });
          break;
        case 181:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            setTimeout(() => {
              this.reloadQueries(structure, param, finished, 1);
            });
          } else {
            this.finishFunction(finished);
          }
          break;
        case 182:
          this.cambiarEmpresa(structure, param, finished);
          break;
        case 183:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            var internal_fas = structure["internal_routing_id_functional_area"].replace(/\s+/g, "").split(",");
            setTimeout(() => {
              for (let idFA of internal_fas) {
                let fake = { 'child': this.genericService.getAllStructures() };
                let copy_fields_idFA = this.findFunctionalArea(fake, idFA);
                this.assignValueFunctionalArea(copy_fields_idFA, structure['tmp_value']);
              }
            });
          } else {
            this.finishFunction(finished);
          }
          break;
        case 185:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateIngresoTotalGestorHipotecario(structure, param);
          }, 200);
          break;
        case 186:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 4);
          this.finishFunction(finished);
          break;
        case 187:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateOperacionGestorHipotecario(structure, param);
          }, 200);
          break;
        case 188:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 5);
          this.finishFunction(finished);
          break;
        case 189:
          setTimeout(() => {
            this.maxDateNow(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 190:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            this.finishFunction(finished);
            setTimeout(() => {
              this.SobranteAPropinas(structure, param);
            });
          } else {
            this.finishFunction(finished);
          }
          break;
        case 191:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 6);
          this.finishFunction(finished);
          break;
        case 192:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 7);
          this.finishFunction(finished);
          break;
        case 193:
          this.openCashRest();
          this.finishFunction(finished);
          break;
        case 194:
          this.finishFunction(finished);
          setTimeout(() => {
            this.ImporteAImporteFinal(structure, param);
          });
          break;
        case 195:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 8);
          this.finishFunction(finished);
          break;
        case 196:
          this.marcharTodos(structure, param);
          break;
        case 197:
          this.finishFunction(finished);
          setTimeout(() => {
            this.sumarNuevoImporte(structure, param);
          });
          break;
        case 198:
          this.printMovementRest(structure, param);
          break;
        case 199:
          const dt = new Date(param["date"]);
          return (dt.getFullYear() + "-" + (dt.getMonth() + 1 < 10 ? "0" : "") + (dt.getMonth() + 1) + "-" + (dt.getDate() < 10 ? "0" : "") + dt.getDate());
          break;
        case 200:
          this.printCierre(structure, param);
          break;
        case 201:
          this.printSnapshot(structure, param);
          break;
        case 202:
          setTimeout(() => {
            this.changeHideButtonId31875(structure);
          }, 200);
          this.finishFunction(finished);
          break;
        case 203:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 9);
          this.finishFunction(finished);
          break;
        case 204:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 10);
          this.finishFunction(finished);
          break;
        case 205:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 11);
          this.finishFunction(finished);
          break;
        case 206:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 12);
          this.finishFunction(finished);
          break;
        case 207:
          setTimeout(() => {
            this.insertUpdateForm(structure, param, finished, 0, true, false);
          }, 1000);
          break;
        case 208:
          setTimeout(() => {
            this.insertUpdateForm(structure, param, finished, 0, true, false);
          }, 1000);
          break;
        case 209:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 13);
          this.finishFunction(finished);
          break;
        case 210:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 14);
          this.finishFunction(finished);
          break;
        case 212:
          this.anadirProductoDirecto(structure, param, finished);
          break;
        case 213:
          this.productosEstadoCincoDirecto(structure, param, finished);
          break;
        case 214:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 15);
          this.finishFunction(finished);
          break;
        case 215:
          this.insertUpdateForm(structure, param, finished, 99, true, false, 16);
          this.finishFunction(finished);
          break;
        case 216:
          this.deleteAllPictures(structure, param);
          this.finishFunction(finished);
          break;
        case 217:
          setTimeout(() => {
            this.imprimirPedido(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 218:
          this.finishFunction(finished);
          setTimeout(() => {
            this.verCobro(structure, param);
          });
          break;
        case 219:
          this.deleteAllDocs(structure, param);
          this.finishFunction(finished);
          break;
        case 220:
          this.downloadAllPictures(structure, param);
          this.finishFunction(finished);
          break;
        case 222:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            this.finishFunction(finished);
            setTimeout(() => {
              this.calculateImportePrecioInmueble(structure, param);
            });
          } else {
            this.finishFunction(finished);
          }
          break;
        case 224:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            setTimeout(() => {
              this.setPrecioNocheEstanciaVacio(structure, param);
            });
            this.finishFunction(finished);
          } else {
            this.finishFunction(finished);
          }
          break;
        case 225:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openMovimientosPersonal(structure, param);
          });
          break;
        case 226:
          setTimeout(() => {
            this.setNumNoches(structure, param, finished);
          })
          this.finishFunction(finished);
          break;
        case 227:
          this.checkPinUsuario(structure, param, finished);
          this.finishFunction(finished);
          break;
        case 228:
          this.oneStepPayment(structure, param, finished, 6);
          this.finishFunction(finished);
          break;
        case 229:
          this.oneStepPayment(structure, param, finished);
          this.finishFunction(finished);
          break;
        case 230:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateIVA(structure, param);
          });
          break;
        case 231:
          this.finishFunction(finished);
          setTimeout(() => {
            this.setPrecioBase(structure, param);
          });
          break;
        case 232:
          this.finishFunction(finished);
          setTimeout(() => {
            this.setPrecioTotal(structure, param);
          });
          break;
        case 233:
          this.finishFunction(finished);
          setTimeout(() => {
            this.sumarImporteLinea(structure, param);
          });
          break;
        case 234:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openReportInfluencers(structure, param);
          });
          break;
        case 235:
          this.deleteAllPictures(structure, param, 1);
          this.finishFunction(finished);
          break;
        case 236:
          setTimeout(() => {
            this.fillEmailContent(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 237:
          setTimeout(() => {
            this.calcularPropina(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 238:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openReportDiasPorSemana(structure, param);
          });
          break;
        case 239:
          this.copyLinkForm(structure, param);
          break;
        case 240:
          this.copyLinkForm(structure, param, 1);
          break;
        case 241:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openReportDescuentos(structure, param);
          });
          break;
        case 242:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openReportActividadComercial(structure, param);
          });
          break;
        case 243:
          this.compartirInmueble(structure, param, finished);
          break;
        case 244:
          this.compartirInmueble(structure, param, finished, 1);
          break;
        case 245:
          setTimeout(() => {
            this.calcularTotalProducto(structure, param);
          });
          break;
        case 246:
          this.showReportHojaVisita(structure, finished);
          break;
        case 247:
          this.finishFunction(finished);
          this.copyServicePrice(structure, param);
          break;
        case 248:
          this.sendWhatsAppClienteContactar(structure, param, finished, 1);
          break;
        case 249:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openHistoricoPorCamarero(structure, param);
          });
          break;
        case 250:
          this.finishFunction(finished);
          setTimeout(() => {
            this.addFieldToAnotherField(structure);
          });
          break;
        case 251:
          let fas = structure['internal_routing'].split(",")
          for (let i in fas) {
            this.finishFunction(finished);
            let splited = fas[i].split(".");
            console.log(splited[1], splited[0]);
            this.genericService.findElementWithIdAndSetStatus(splited[1], splited[0]);
          }
          break;
        case 252:
          setTimeout(() => {
            this.calcPrecioTotalLineaCotizacion(structure, param);
          });
          this.finishFunction(finished);

          break;
        case 253:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calcTotalesCotizacion(structure, param);
          });
          break;
        case 254:
          this.openFichaCotizacion(structure, param, finished);
          break;
        case 255:
          this.finishFunction(finished);
          setTimeout(() => {
            this.genericFunctionRoutingStatic(structure, param);
          });
          break;
        case 257:
          this.saveParamsClientOnly(structure, structure["id_functional_parent_initial"]);
          this.route = structure["internal_routing"];
          this.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule = structure["id_functional_parent_initial"];
          this.goRouting(this.route, true);
          this.finishFunction(finished);
          break;
        case 262:
          let paramIntern = [];
          let intern_data = this.genericService.getInternParam(structure, paramIntern);

          let email = null;
          for (let i in intern_data) {
            if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "usuarios" && intern_data[i]["bd_field"] == "email") {
              email = intern_data[i]["value"];
              break;
            }
          }
          this.UserFunctionsService.setPassword(null, email);
          break;
        case 267:
          this.UserFunctionsService.vincularGoogle();
          //this.finishFunction(finished);
          break;
        case 268:
          this.UserFunctionsService.desvincularGoogle();
          //this.finishFunction(finished);
          break;
        case 269:
          this.UserFunctionsService.vincularMicrosoft();
          break;
        case 270:
          this.UserFunctionsService.desvincularMicrosoft();
          break;
        case 271:
          setTimeout(() => {
            this.addFieldToAnotherField(structure);
            if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
              this.reloadQueries(structure, param, finished, 1);
            }
          });
          this.finishFunction(finished);
          break;
        case 272:
          this.duplicarEstancia(structure, param, finished);
          break;
        case 273:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openInformacionReservas(structure, param);
          });
          break;
        case 274:
          setTimeout(() => {
            this.removeTableRelationWhenValue(structure, param);
            this.reloadQueries(structure, param, finished);
          });
          break;
        case 275:
          this.finishFunction(finished);
          setTimeout(() => {
            this.openOcupacionEstancias(structure, param);
          });
          break;
        case 276:
          this.insertUpdateForm(structure, param, finished, 99, false, false, 17);
          break;
        case 277:
          this.finishFunction(finished);
          this.checkOcupacionReservasRestaurante(structure, param, finished);
          break;
        case 278:
          setTimeout(() => {
            this.calculateAndChangeTicketPrice(structure, param);
          });
          this.finishFunction(finished);
          break;
        case 279:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            setTimeout(() => {
              this.checkCantidadDisponibleEstancias(structure, param);
            })
            this.finishFunction(finished);
          }
          break;
        case 280:
          this.deleteDataDialog(structure, 5);
          break;
        case 281:
          this.finishFunction(finished);
          setTimeout(() => {
            this.aplicarDescuento(structure, param);
          });
          break;
        case 282:
          setTimeout(() => {
            this.calculateImportAndSetField(structure);
          });
          this.finishFunction(finished);
          break;
        case 283:
          setTimeout(() => {
            if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
              this.calculateImportAndSetField(structure);
            }
          });
          this.finishFunction(finished);
          break;
        case 284:
          setTimeout(() => {
            this.sumAllFieldsAndSetField(structure);
          });
          this.finishFunction(finished);
          break;
        case 285:
          setTimeout(() => {
            if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
              this.sumAllFieldsAndSetField(structure);
            }
          });
          this.finishFunction(finished);
          break;
        case 286:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            let structureClone = this.cloneVariable(structure);
            structureClone['id_function'] = 5;
            this.getFunctionFormField(structureClone);
          } else {
            this.finishFunction(finished);
          }
          break;
        case 287:
          setTimeout(() => {
            this.recalcularImporteTarifaActLudic(structure);
          });
          this.finishFunction(finished);
          break;
        case 288:
          //Help
          this.routingService.shouldOpenSearch = true;
          this.finishFunction(finished);
          break;
        case 289:
          this.finishFunction(finished);
          setTimeout(() => {
            this.selectTarjetaRegalo(structure);
          });
          break;
        case 290:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateNewImportTicketServicios(structure, param);
          });
          break;
        case 291:
          setTimeout(() => {
            this.insertTicketNoPagadoActividadesServicios(structure, param, finished);
          });
          this.finishFunction(finished);
          break;
        case 292:
          this.finishFunction(finished);
          setTimeout(() => {
            this.sumarNuevoImporteServicios(structure, param);
          });
          break;
        case 293:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateNewImportDiferenciaServicios(structure, param);
          });
          break;
        case 294:
          setTimeout(() => {
            this.changeHideButtonId56976(structure);
          });
          this.finishFunction(finished);
          break;
        case 295:
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            this.finishFunction(finished);
            setTimeout(() => {
              this.SobranteAPropinasServicios(structure, param);
            });
          } else {
            this.finishFunction(finished);
          }
          break;
        case 296:
          this.finishFunction(finished);
          setTimeout(() => {
            this.cargarImportePendienteServicios(structure, param);
          });
          break;
        case 297:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateImportSaldoInicialServicios(structure, param);
          });
          break;
        case 298:
          this.finishFunction(finished);
          setTimeout(() => {
            this.concatFields(structure);
          });
          break;
        case 299:
          this.finishFunction(finished);
          setTimeout(() => {
            this.genericFunctionRoutingStatic(structure, param, false, false, true);
          });
          break;
        case 300:
          this.printTicketActLudic(structure, param);
          this.finishFunction(finished);
          break;
        case 301:
          let FA = this.genericService.findElementWithId(structure["internal_routing_id_functional_area"], false, false, true);
          console.log("FAAAA", FA);
          FA['showSub'] = true;
          if (FA['id_functional_status_general'] != 2) FA['id_functional_status_general_statusInitUpdate'] = this.cloneVariable(FA['id_functional_status_general']);
          FA['id_functional_status_general'] = 2;
          this.fecha_inicio_reservas = null;
          this.fecha_fin_reservas = null;
          this.hora_inicio_reservas = null;
          this.hora_fin_reservas = null;
          let params = {intern: []};
          this.genericService.findInterns(structure["idFAParamsInterns"], params, null, structure["id_functional_parent_initial_dsb"]);
          if(params && params['intern'] && params['intern'].length > 0) {
            let params2 = params['intern'];
            for(let i in params2) {
              if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_fecha_inicio_reserva' && params2[i]['value']) {
                this.fecha_inicio_reservas = params2[i]['value'];
              }
              if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_hora_inicio_reserva' && params2[i]['value']) {
                this.hora_inicio_reservas = params2[i]['value'];
              }
              if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_fecha_fin_reserva' && params2[i]['value']) {
                this.fecha_fin_reservas = params2[i]['value'];
              }
              if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_hora_fin_reserva' && params2[i]['value']) {
                this.hora_fin_reservas = params2[i]['value'];
              }
            }
            console.log(params2, "sdfsdfsdf", this.fecha_inicio_reservas, this.fecha_fin_reservas, this.hora_inicio_reservas, this.hora_fin_reservas);
          }
          setTimeout(() => {
            FA['id_functional_status_general'] = FA['id_functional_status_general_statusInitUpdate'];
            FA['wasInitialized'] = false;
            FA['showSub'] = false;
          }, 0);
          this.finishFunction(finished);
          break;
        case 302:
          // Menu
          this.routingService.shouldOpenMenu = true;
          this.finishFunction(finished);
          break;
        case 303:
          this.insertUpdateForm(structure, param, finished, 0, false, false, 1, null, true);
          break;
        case 304:
          this.finishFunction(finished);
          setTimeout(() => {
            this.calculateHonorariosPorMeses(structure, param);
          });
          break;  
        case 782:
          let panelClass = [];
          if (this.routingService.shouldOpenExternalWindow)
            panelClass.push("overAllDialog");
          this.dialog.open(DemoDialogComponent, {
            panelClass: panelClass,
          });
          break;
        case 783:
          // Se llama estática desde el código de notis
          if (param && param !== null && param.actividad && param.actividad[0] && param.actividad[0].id && param.actividad[0].id > 0) {
            this.addWindowParam(param.actividad[0].id, 1, "actividades_calendario", "id", 89);
            let x = { id_function: 5, internal_routing_id_functional_area: 34400, refresh_params: 2 };
            this.getFunction(x, null, finished);
          } else {
            this.openSnackBar("Actividad no encontrada", 5000, ["red-snackbar",]);
            this.finishFunction(finished);
          }
          break;
      }
    }

    if (structure !== undefined && id > 0 && !isFromField) {
      /*this.endpointService.insertMonitoringIdFunction(this.authService.userId, structure["id_functional_area"], id).subscribe((data) => {
        console.log("monitoring_functions", data);
      });*/
    }
  }

  private addPropertyLocation(structure) {
    let id_inmueble_idFA = this.genericService.findFAWithTableField(1, "actividades_viviendas", "id_vivienda", structure["id_functional_parent_initial_dsb"]);
    if (id_inmueble_idFA && id_inmueble_idFA['tmp_value']) {
      let id_inmueble = this.cloneVariable(id_inmueble_idFA['tmp_value'][0]);
      this.endpointService.getPropertyLocation(id_inmueble).subscribe((data) => {
        if (data['response'] && data['response'][0] && data['response'][0]['direccion']) {
          let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario", "localizacion", structure["id_functional_parent_initial_dsb"]);
          this.assignValueFunctionalArea(idFA, data['response'][0]['direccion']);
        }
        else {
          this.openSnackBar("Este inmueble no tiene dirección", 5000, [
            "red-snackbar",
          ]);
        }
      });
    }
  }

  private addClientLocation(structure) {
    let id_cliente_idFA = this.genericService.findFAWithTableField(1, "actividades_clientes", "id_cliente", structure["id_functional_parent_initial_dsb"]);
    if (id_cliente_idFA && id_cliente_idFA['tmp_value']) {
      let id_cliente = this.cloneVariable(id_cliente_idFA['tmp_value'][0]);
      this.endpointService.getClientLocation(id_cliente).subscribe((data) => {
        if (data['response'] && data['response'][0] && data['response'][0]['direccion']) {
          let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario", "localizacion", structure["id_functional_parent_initial_dsb"]);
          this.assignValueFunctionalArea(idFA, data['response'][0]['direccion']);
        }
        else {
          this.openSnackBar("Este cliente no tiene dirección", 5000, [
            "red-snackbar",
          ]);
        }
      });
    }
  }

  private openDeferContainer(param, structure) {
    let idFA = this.genericService.findElementWithId(structure["internal_routing"], false, false, true);
    if (idFA["id_functional_type"] == 106) {
      this.genericService.lastDeferContainer = idFA["id_functional_area"];
      idFA["id_functional_status_general"] = 1;
      if ((!this.genericService.staticHTML && this.genericService.breadcrumbs[this.genericService.breadcrumbs.length - 1]["parent"] === null) || this.genericService.breadcrumbs[this.genericService.breadcrumbs.length - 1]["parent"] === undefined) this.genericService.breadcrumbs.pop();
      this.genericService.breadcrumbs[this.genericService.breadcrumbs.length - 1]["defer"] = param;
      this.genericService.breadcrumbs.push({
        url: this.routingService.urlWithoutHashtag.split("/")[1],
        name: structure["label"],
      });
      this.location.replaceState(this.routingService.urlWithoutHashtag + "#" + idFA["id_functional_area"]);
      this.endpointService.insertMonitoringInformation(this.authService.getLoggedInUserId(), idFA["id_functional_area"], idFA["id_functional_area"], this.genericService.staticHTML, this.genericService.paramControlVariables).subscribe((data) => {
        idFA["monitoreo"] = data["response"];
      });
    }
  }

  private finishFunction(finished) {
    if (finished !== null && typeof finished != "string") finished.done = true;
  }

  private openFile(structure, param, download) {
    let fileName = null;
    if (param !== null) {
      for (let e in param) {
        if (e.split("-")[1] == structure["id_functional_url"]["bd_field"] || e == structure["id_functional_url"]["bd_field"]) {
          fileName = param[e];
        }
      }
    } else {
      fileName = this.genericService.findFAWithTableField(structure["id_functional_url"]["id_db"], structure["id_functional_url"]["bd_table"], structure["id_functional_url"]["bd_field"], structure["id_functional_parent_initial_dsb"])["tmp_value"];
    }
    if (fileName !== null && structure["id_functional_url"]["url"] !== null) {
      if (download) {
        this.endpointService.getFileFromUrl(structure["id_functional_url"]["url"] + fileName, structure["id_functional_url"]["path"] + fileName).subscribe((data) => {
          console.log(data, "data")
          saveAs(new Blob([data]), fileName);
        });
      } else window.open(structure["id_functional_url"]["url"] + fileName);
    } else
      this.openSnackBar("No se ha encontrado este fichero", 5000, [
        "red-snackbar",
      ]);
  }

  public formsArray(structure, finished, validate = true) {
    let valid = true;
    this.tmpArray = [];
    this.removed = [];
    this.imageUpload = [];
    let useStructure = [];
    let validReq = true;

    useStructure = this.genericService.getAllStructures();
    for (let s in useStructure) {
      if (!this.eachRecursiveBool(useStructure[s], validate, structure["id_functional_parent_initial_dsb"])) {
        if (validate) {
          validReq = false;
          if (this.errorMessage && this.errorMessage != null && this.errorMessage != "") {
            this.openSnackBar(this.errorMessage, 6000, ["red-snackbar"]);
          } else if (this.lastLabel && this.lastLabel != null && this.lastLabel != "") {
            this.openSnackBar("Falta completar el campo: " + this.lastLabel, 6000, ["red-snackbar"]);
          } else {
            this.openSnackBar("Parece que hay campos sin completar", 6000, ["red-snackbar"]);
          }
        }
      }
    }
    return validReq;
  }

  public buildValuesGeneric(obj, validate): boolean {
    let formField = 0;
    let database = null;
    let table = null;
    let field = null;
    let value = null;
    let relation = null;
    let form = null;
    let status = true;

    if (obj['form_field'] && obj['form_field'] == 1) formField = 1;
    if (obj['id_db'] && obj['id_db'] > 0) database = obj['id_db'];
    if (obj['bd_table'] && obj['bd_table'] !== undefined && obj['bd_table'] !== null && obj['bd_table'] !== "") table = obj['bd_table'];
    if (obj['bd_field'] && obj['bd_field'] !== undefined && obj['bd_field'] !== null && obj['bd_field'] !== "") field = obj['bd_field'];
    if (obj['tmp_value'] && obj['tmp_value'] !== undefined && obj['tmp_value'] !== null && obj['tmp_value'] !== "") value = obj['tmp_value'];
    if (obj['id_table_relations'] && obj['id_table_relations'] > 0) relation = obj['id_table_relations'];
    if (obj['form'] && obj['form'] !== undefined) form = obj['form'];


    if (obj["id_functional_type"] == 15) obj["multiple"] == 0;

    if (obj["id_functional_type"] != 113 && obj["id_functional_type"] != 146 && field != null && table != null && database != null) {
      console.log(obj, obj["id_functional_area"] + "-" + field, "jeeee")
      if (formField == 1 && form !== null && !form["controls"][obj["id_functional_area"] + "-" + field].valid && (form["value"][obj["id_functional_area"] + "-" + field] === null || form["value"][obj["id_functional_area"] + "-" + field] == "") && obj["id_db"] !== null && obj["bd_table"] !== null) {
        form["controls"][obj["id_functional_area"] + "-" + field]["touched"] = true;
        if (obj["id_functional_type"] == 15 || obj["id_functional_type"] == 12 || obj["id_functional_type"] == 6) obj["invalid"] = true;
        if (status) {
          let validation = true;
          if (form && form["controls"] && form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]) validation = form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]["valid"];
          else console.log("!!! NO CONTROL !!! => FIELD NO FORM", obj["id_functional_area"])
          if (!validate) validation = true;
          if (obj["bd_table"] !== null) {
            if (obj["id_functional_type"] == 6 && (obj["type"] == "datepicker" || obj["type"] == "datepicker-month" || obj["type"] == "datepicker-year" || obj["type"] == "datetime-local")) {
              let ndate = this.formatDate(value);
              value = ndate;
            }
            if (obj["id_functional_type"] == 6 && obj["type"] == "time") {
              value = value + ":00";
            }
            if (obj["type"] == "email" && value && value != "") {
              if (!this.validateEmail(value) && validate) {
                validation = false;
                this.errorMessage = "El email es inválido";
              } else {
                validation = true;
                this.errorMessage = "";
              }
            }
            // Gestionar el caracter decimal
            if (obj["type"] == "decimal" && value && value != "" && typeof value == "string") {
              value = value.replace(",", ".");
            }
            if (obj["multiple"] == 1) {
              let found = false;
              for (let i in obj["originalValue"]) {
                found = false;
                for (let j in obj["tmp_value"]) {
                  if (obj["originalValue"][i] == obj["tmp_value"][j]) {
                    found = true;
                    break;
                  }
                }
                if (!found) {
                  this.removed.push({
                    functionalArea: obj["id_functional_area"],
                    database: database,
                    table: table,
                    field: field,
                    value: obj["originalValue"][i],
                    relation: relation,
                    label: obj["label"],
                  });
                }
              }
              if (obj["id_functional_status_general"] == 5 && obj["id_functional_type"] == 12 && obj["type"] == "autocomplete" && (!obj["tmp_value"] || !Array.isArray(obj["tmp_value"]) || obj["tmp_value"].length < 1) && validate) validation = false;
            }
          }
          if (obj["id_functional_type"] == 121) {
            this.extractGeoFields(obj, database, table, relation, validation);
          }
          if (obj["id_functional_type"] != 121 && obj["timestamp"] === 0) {
            // -999
            //if(obj['id_functional_type'] == 12 && value == "") value = null;
            this.tmpArray.push({
              functionalArea: obj["id_functional_area"],
              database: database,
              table: table,
              field: obj["label"],
              value: value,
              relation: relation,
              valid: validation,
              label: obj["label"],
            });
          }
        }
        if (validate) status = false;
      } else if (formField == 1 && status && obj["id_functional_type"] != 113 && obj["id_db"] !== null && obj["bd_table"] !== null) {
        let validation = true;
        if (form && form["controls"] && form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]) validation = form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]["valid"];
        else console.log("!!! NO CONTROL !!! => FIELD NO FORM", obj["id_functional_area"])
        if (!validate) validation = true;
        if (obj["bd_field"] !== null) {
          if (obj["id_functional_type"] == 6 && (obj["type"] == "datepicker" || obj["type"] == "datepicker-month" || obj["type"] == "datepicker-year" || obj["type"] == "datetime-local")) {
            let ndate = this.formatDate(value);
            value = ndate;
          }
          if (obj["id_functional_type"] == 6 && obj["type"] == "time") {
            value = value + ":00";
          }
          if (obj["type"] == "email" && value && value != "") {
            if (!this.validateEmail(value) && validate) {
              validation = false;
              this.errorMessage = "El email es inválido";
            } else {
              validation = true;
              this.errorMessage = "";
            }
          }
          // Gestionar el caracter decimal
          if (obj["type"] == "decimal" && value && value != "" && typeof value == "string") {
            value = value.replace(",", ".");
          }
          if (obj["multiple"] == 1) {
            let found = false;
            for (let i in obj["originalValue"]) {
              found = false;
              for (let j in obj["tmp_value"]) {
                if (obj["originalValue"][i] == obj["tmp_value"][j]) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                this.removed.push({
                  functionalArea: obj["id_functional_area"],
                  database: database,
                  table: table,
                  field: field,
                  value: obj["originalValue"][i],
                  relation: relation,
                  label: obj["label"],
                });
              }
            }
            if (obj["id_functional_status_general"] == 5 && obj["id_functional_type"] == 12 && obj["type"] == "autocomplete" && (!obj["tmp_value"] || !Array.isArray(obj["tmp_value"]) || obj["tmp_value"].length < 1) && validate) validation = false;
          }
        }
        if (obj["id_functional_type"] == 121) {
          this.extractGeoFields(obj, database, table, relation, validation);
        }
        if (obj["id_functional_type"] != 121 && obj["bd_field"] !== null && obj["timestamp"] === 0) {
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: value,
            relation: relation,
            valid: validation,
            label: obj["label"],
          });
        }
        status = true;
        if (!validation && validate) status = false;
      }
    }

    if (!status && obj["id_functional_type"] == 7) {
      obj["expansion_start_opened"] = 1;
    }

    if (!status && obj["id_functional_type"] == 95) {
      let fa = this.genericService.findElementWithId(obj["id_functional_area"], true, false, false);
      fa["parent"]["selectedIndex"] = fa["key"];
    }

    if (status && obj["id_functional_type"] == 113 && field != null && table != null && database != null) {
      if (obj["fileList"] !== null && obj["fileList"] !== undefined && obj["fileList"].length > 0) {
        this.imageUpload.push(obj);
        let p = 0;
        let tempFile = [];
        if (obj["fileList"].length > 1) {
          for (let t in obj["fileList"]) {
            tempFile.push(this.imageUpload.length - 1 + p);
            p++;
          }
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: tempFile,
            relation: relation,
            valid: true,
            label: obj["label"],
          });
        } else {
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: this.imageUpload.length - 1,
            relation: relation,
            valid: true,
            label: obj["label"],
          });
        }
      } else if ((obj["required"] || obj["id_functional_status_general"] == 5) && obj["bd_field"] !== null) {
        if (validate) status = false;
        this.tmpArray.push({
          functionalArea: obj["id_functional_area"],
          database: database,
          table: table,
          field: "archivos",
          value: value,
          relation: relation,
          valid: false,
          label: obj["label"],
        });
      }
    }

    if (obj["id_functional_type"] == 146 && field != null && table != null && database != null) {
      if (obj["tmp_value_signature"].toData().length !== 0) {
        obj["tmp_value"] = obj["tmp_value_signature"].toDataURL();
        value = obj["tmp_value"];
        this.tmpArray.push({
          functionalArea: obj["id_functional_area"],
          database: database,
          table: table,
          field: field,
          value: value,
          relation: relation,
          valid: true,
          label: obj["label"],
        });
      } else {
        if (validate) status = false;
      }
    }

    if (!validate) return true;
    return status;
  }

  public formsArrayNEW(structure, finished, validate = true) {
    this.tmpArray = [];
    this.removed = [];
    this.imageUpload = [];

    let useStructure = this.genericService.getAllStructures(1, true);
    for (let s in useStructure) {
      if (useStructure[s] !== null && useStructure[s] !== undefined && useStructure[s]["id_functional_status_general"] != 2 && this.genericService.checkParentsStatus(useStructure[s]) && useStructure[s]["id_functional_parent_initial_dsb"] == structure["id_functional_parent_initial_dsb"] && !this.buildValuesGeneric(useStructure[s], validate)) {
        if (validate) {
          if (this.errorMessage && this.errorMessage != null && this.errorMessage != "") {
            this.openSnackBar(this.errorMessage, 6000, ["red-snackbar"]);
          } else if (useStructure[s]["label"] && useStructure[s]["label"] != null && useStructure[s]["label"] != "") {
            this.openSnackBar("Falta completar el campo: " + useStructure[s]["label"], 6000, ["red-snackbar"]);
          } else {
            this.openSnackBar("Parece que hay campos sin completar", 6000, ["red-snackbar"]);
          }
          return false;
        }
      }
    }
    return true;
  }

  private checkPreviewModeFunction(finished = null) {
    if (this.genericService.previewModeUser) {
      this.openSnackBar("Debes desactivar el modo previsualización para realizar esta acción.", 6000, ["red-snackbar",]);
      this.finishFunction(finished);
      return true;
    }
  }

  public pushInfoBackendInsertUpdateForm(data, structure) {
    let id_dsb = -1;
    if(structure['id_functional_parent_initial_dsb']) id_dsb = structure['id_functional_parent_initial_dsb'];
    let insert = {
      data: data,
      structure: structure,
      time: this.formatDate(new Date()),
      id_dsb: id_dsb,
    }
    let id = structure['id_functional_parent_initial'];
    console.log("PUSH INFO BACKEND", insert, id, this.historyInserts)
    if(this.historyInserts[id] === undefined) this.historyInserts[id] = [];
    this.historyInserts[id].push(insert);
  }


  private insertUpdateForm(structure, param, finished, refresh = 0, par = false, dialog = false, type = null, publicar = null, addParamStatic = false, valuesLoaded = false) {
    if (this.checkPreviewModeFunction(finished)) {
      return;
    }
    let messageSucced = "Guardado con éxito";
    if (structure['description'] && structure['description'] != null && structure['description'] != undefined) messageSucced = structure['description'];
    //  this.insertUpdateForm(structure, param, finished, 1, false, false);
    let structure_pre = this.cloneVariable(structure);
    const v = this.insertFormData(structure, param, finished, type, publicar, valuesLoaded);
    if (v != null) {
      v.subscribe((data) => {
        if(data["extraInfo"]) {
          this.pushInfoBackendInsertUpdateForm(data["extraInfo"], structure);
        }
        this.finishFunction(finished);
        if (data["errorCode"] == 0 || data["errorCode"] == 3 || data["errorCode"] == 5) {
          if (data["response"] != 0 && data["errorCode"] == 3) {
            if (data["errorMessage"] !== null && data["errorMessage"] !== "") this.openSnackBar(data["errorMessage"], 6000, ["red-snackbar"]);
          } else {
            //Casos generals generic
            if (refresh == 99) { // Para casos donde hay que hacer otras funciones
              if (type == 4) this.printCommandRest(structure, param);
              if (type == 5) {
                this.printCommandRest(structure, param);
                refresh = 1;
              }
              if (type == 6) {
                this.openCashRest();
                refresh = 1;
              }
              if (type == 7) {
                this.openCashRest();
              }
              if (type == 8) {
                this.openCashRest();
                refresh = 2;
              }
              if (type == 9) {
                this.printRestCierreInserted(data["response"]);
              }
              if (type == 10) {
                this.printRestCierreInserted(data["response"]);
                refresh = 1;
              }
              if (type == 11) {
                this.printRestSnapshotInserted(data["response"]);
              }
              if (type == 12) {
                this.printRestSnapshotInserted(data["response"]);
                refresh = 1;
              }
              if (type == 13) {
                this.printRestMovementInserted(data["response"]);
              }
              if (type == 14) {
                this.printRestMovementInserted(data["response"]);
                refresh = 1;
              }
              if (type == 15) this.printCommandCancelRest(structure, param);
              if (type == 16) {
                this.printCommandCancelRest(structure, param);
                refresh = 1;
              }
              if (type == 17) {
                this.genericFunctionRoutingStatic(structure, param);
              }
              if (type == 18) {
                this.genericFunctionRoutingStatic(structure, param, false, true);
              }

            } else {
              this.dialog.closeAll();
            }
            this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
            if (structure["id_functional_area"] == 7052 && data["response"] > 0) {
              // Solo para el registro
              this.sendSuccedSnackbarInsert({ "errorMessage": "¡Bienvenid@ a 4DMOVIN!" }, messageSucced);
              localStorage.setItem("_i_", data["response"].toString());
              this.genericService.refreshStructure(2);
            } else if (!dialog && structure["internal_routing"] !== null && structure["internal_routing"] !== undefined && publicar != 1 && !this.routingService.shouldOpenExternalWindow) {
              this.sendSuccedSnackbarInsert(data, messageSucced, 6000, data["errorCode"] == 5);
              if (par && !this.genericService.staticHTML) this.saveParams(structure, param);
              if (refresh == 2) this.goPrev(false, structure["internal_routing"], false);
              if (this.genericService.staticHTML) {
                let url = "";
                if (addParamStatic) {
                  let idAdd = -1;
                  if (Array.isArray(data["response"])) {
                    let newValues = data["response"];
                    for (let v in newValues) {
                      if (newValues[v]["bd_table"] && newValues[v]["bd_table"] == structure["bd_table"] && newValues[v]["database"] && newValues[v]["database"] == structure["id_db"] && newValues[v]["bd_field"] && newValues[v]["bd_field"] == structure["bd_field"] && newValues[v]["responseId"]) idAdd = newValues[v]["responseId"];
                      if (idAdd != -1) break;
                    }
                  }
                  if (idAdd != -1) {
                    let idLanguage = this.authService.labelLanguage;
                    let idEmpresa = this.authService.getIdEmpresa();
                    let nombreEmpresa = this.authService.companyGenericName;
                    url = "../" + idLanguage + "/" + nombreEmpresa + "/" + structure['internal_routing'] + "/sth/" + idEmpresa + "-" + this.authService.userId + "&&" + idAdd;
                  }
                }
                if(url != "") {
                  if(type == 1) {
                    window.open(url, '_self');
                  } else {
                    window.open(url);
                  }
                }
              } else {
                if(!structure['id_functional_parent_initial_dsb'] || !(structure['id_functional_parent_initial_dsb'] > 0)) {
                  let id_pantalla = structure['id_functional_parent_initial'];
                  let indexStruct = this.genericService.paramControlVariables[id_pantalla]["indexParam"];
                  if (this.genericService.arrayFunctionalParentsLoaded.hasOwnProperty(id_pantalla)) {
                    this.genericService.arrayFunctionalParentsLoaded[id_pantalla].splice(indexStruct, 1);
                  }
                  if (Array.isArray(data["response"])) {
                    this.genericService.paramControlVariables[id_pantalla]['params'][this.genericService.paramControlVariables[id_pantalla]['indexParam']]['output'] = [];
                    let newValues = data["response"];
                    let params = null;
                    for (let v in newValues) {
                      params = {id_db: newValues[v]["database"], bd_table: newValues[v]["bd_table"], bd_field: newValues[v]["bd_field"], value: newValues[v]["responseId"]};                      
                      this.genericService.paramControlVariables[id_pantalla]['params'][this.genericService.paramControlVariables[id_pantalla]['indexParam']]['output'].push(params);
                    }
                  }
                }
                this.goRouting(structure["internal_routing"], false);
              }
            } else if (!dialog) {
              if (refresh == 1 || refresh == 3) {
                this.sendSuccedSnackbarInsert(data, messageSucced, 6000, data["errorCode"] == 5);
                if (refresh == 1) this.genericService.refreshStructure(0);
                else this.genericService.refreshStructure(3);
                this.genericService.originalInputsValues = [];
              } else if (refresh == 2) {
                this.goPrev(false, structure["internal_routing"], false);
              } else {
                this.sendSuccedSnackbarInsert(data, messageSucced, 6000, data["errorCode"] == 5);
                if (refresh != 4) {
                  this.genericService.updateResults(this.genericService.getInternParam(structure, param), structure['id_functional_parent_initial']);
                }
                this.genericService.refreshOriginalInputsValues(this.genericService.getAllStructures());
                this.genericService.updateFormFields.next(true);
                this.genericService.updateFormFields.next(false);
                this.genericService.originalInputsValues = [];
              }
            } else if (structure["id_functional_parent_initial_dsb"]) {
              this.sendSuccedSnackbarInsert(data, messageSucced, 6000, data["errorCode"] == 5);
              let idDialogParent = this.genericService.findElementWithId(structure["internal_routing_id_functional_area"], false, false, true);
              let fakeParent = this.cloneVariable(structure);
              fakeParent["child"] = Array(idDialogParent);
              fakeParent["id_function"] = 2; //Type == 1
              if (type == 2) fakeParent["id_function"] = 45;
              if (type == 3) fakeParent["id_function"] = 46;
              this.getFunction(fakeParent, null, finished);
            }
          }
        } else if (data["errorCode"] == 1) {
          //INSERT/UPDATE INMUEBLE INCORRECTO
          if (this.genericService.parentStructure[0]["id_functional_parent_initial"] == 17) {
            if (data["response"] == 1) this.focusFA({ child: this.genericService.parentStructure }, 2663);
            if (data["response"] == 2 || data["response"] == 3) this.focusFA({ child: this.genericService.parentStructure }, 4985);
          } else if (this.genericService.parentStructure[0]["id_functional_parent_initial"] == 13) {
            if (data["response"] == 1) this.focusFA({ child: this.genericService.parentStructure }, 5078);
            if (data["response"] == 2 || data["response"] == 3) this.focusFA({ child: this.genericService.parentStructure }, 5060);
          }
          this.openSnackBar(data["errorMessage"], 6000, ["red-snackbar"]);
          this.finishFunction(finished);
          structure['id_functional_status_general'] = 2;
          setTimeout(() => {
            structure['id_functional_status_general'] = 1;
          }, 500);
        } else if (data["errorCode"] == 2) {
          //INSERT/UPDATE INMUEBLE CORRECTO
          if (data["errorMessage"] !== null && data["errorMessage"] !== "") {
            this.saveParams(structure, param);
            this.addWindowParam(data["errorMessage"], 1, "vivienda", "id", 13, true, "output");
            this.addWindowParam(data["errorMessage"], 1, "vivienda", "id", 17, true, "input", true);
            this.addWindowParam(data["errorMessage"], 1, "vivienda", "id", 17, true, "intern");
            this.sendSuccedSnackbarInsert({ "errorMessage": "Inmueble guardado correctamente.\n Indica en qué portales quieres publicarlo." }, messageSucced);
            this.genericService.updateParamControl();
            this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
            this.dialog.closeAll();
            this.finishFunction(finished);
            console.log(structure, "structure")
            if (structure["internal_routing"]) this.goRouting(structure["internal_routing"], false);
          }
        } else if (data["errorCode"] == 4) {
          window.open(data["response"], "_self");
          this.finishFunction(finished);
          this.dialog.closeAll();
        }
      });
    }
  }

  private sendSuccedSnackbarInsert(data, messageSucced, seconds = 6000, red = false) {
    if (data["errorMessage"], data["errorMessage"] !== null && data["errorMessage"] !== "") messageSucced = data["errorMessage"];
    if (red) this.openSnackBar(messageSucced, seconds, ["red-snackbar",]);
    else this.openSnackBar(messageSucced, seconds, ["green-snackbar",]);
  }
  private insertUpdateFormConfigPortales(structure, param, finished, refresh = 0, type = null, publicar = null) {
    const v = this.insertFormData(structure, param, finished, type, publicar);
    if (v != null) {
      v.subscribe((data) => {
        switch (data["errorCode"]) {
          case 0:
            let idPortal = null;
            let dataAcc = this.genericService.getDataAccount();
            for (let t in this.tmpArray) if (this.tmpArray[t]["database"] == 1 && this.tmpArray[t]["table"] == "portales" && this.tmpArray[t]["field"] == "id") idPortal = this.tmpArray[t]["value"];
            if (idPortal !== null) {
              this.endpointService.updateViviendaConfigPortalesMassive(idPortal, dataAcc).subscribe((data2) => {
                if (data2["response"] == 1) {
                  if (data2["errorCode"] == 0) {
                    this.openSnackBar("Los datos se han guardado correctamente y la publicación en el portal se ha actualizado.\n Revisa y gestiona desde esta pantalla la publicación del portal.", 12000, ["green-snackbar"]);
                    this.portalConfigured = idPortal;
                    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
                    this.dialog.closeAll();
                    this.finishFunction(finished);
                    this.goRouting("gestionar-inmuebles-en-portales", false);
                  } else {
                    if (data2["errorMessage"] !== null && data2["errorMessage"] !== "") {
                      this.openSnackBar(
                        "Los datos se han guardado correctamente pero ha habido el siguiente error al actualizar alguno de los inmuebles: " +
                        data2["errorMessage"] +
                        ".\n Soluciona el error y actualízalos manualmente. Si persiste el error, ponte en contacto con nosotros en el chat de soporte.",
                        20000,
                        ["red-snackbar"]
                      );
                      this.portalConfigured = idPortal;
                      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
                      this.dialog.closeAll();
                      this.finishFunction(finished);
                      this.goRouting("gestionar-inmuebles-en-portales", false);
                    }
                  }
                } else {
                  if (data2["errorCode"] == 0) {
                    this.openSnackBar(
                      "Los datos se han guardado correctamente y se han despublicado los inmuebles del portal.",
                      6000,
                      ["green-snackbar"]
                    );
                    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
                    this.dialog.closeAll();
                    this.finishFunction(finished);
                  }
                }
              });
            }
            break;
          case 1:
            this.finishFunction(finished);
            break;
        }
      });
    }
  }

  focusFA(structure, id) {
    let found = false;
    if (structure) {
      if (structure["id_functional_area"] == id) {
        return true;
      } else {
        let lastChild = null;
        for (let t in structure["child"]) {
          if (!found) found = this.focusFA(structure["child"][t], id);
          if (found) {
            lastChild = t;
            break;
          }
        }
        if (found) {
          if (structure["id_functional_type"] == 96) {
            structure["selectedIndex"] = lastChild;
          }
          if (structure["id_functional_type"] == 7) {
            structure["expansion_start_opened"] = lastChild;
          }
          if (structure["id_functional_type"] == 8) {
            structure["child"][0]["tabActive"] = lastChild;
          }
        }
      }
    }
    return found;
  }

  async goRouting(route, checkRouting, validate = true) {
    if (route) {
      if (route[0] && route[0] == "#") {
        this.routingService.urlHashtag = route.slice(1);
        this.focusFA({ child: this.genericService.parentStructure }, route.slice(1));
        this.genericService.scroll(route.slice(1));
        const url = this.routingService.urlWithoutHashtag;
        this.location.replaceState(url + "#" + this.routingService.urlHashtag);
      } else {
        this.genericService.refreshStructureFinished = true;
        if (validate && this.genericService.getNumChanges() > 0) {
          const status = await this.genericService.openWarningDialog(1, 1);
          if (status) this.genericService.go(route, checkRouting, this.id_route);
        } else {
          this.genericService.go(route, checkRouting, this.id_route);
        }
      }
    } else {
      this.genericService.refreshStructure(0);
    }
  }

  openDialog(structure, type, params = null) {
    let dialogFA = null;
    let internal_routing_id_functional_area = null;
    if (structure['internal_routing_id_functional_area']) {
      internal_routing_id_functional_area = structure['internal_routing_id_functional_area'];
    }

    if (structure["child"]) {
      for (let i in structure["child"]) {
        if (structure["child"][i]["id_functional_type"] == 85)
          dialogFA = structure["child"][i];
      }
    }

    if (dialogFA === null && internal_routing_id_functional_area !== null) {
      dialogFA = this.genericService.findElementWithId(+structure['internal_routing_id_functional_area'], false, false, true);
      if (dialogFA['id_functional_parent_initial'] != structure['id_functional_parent_initial'] && (structure['refresh_params'] === undefined || structure['refresh_params'] !== 2)) {
        // Si esta en otra pantalla, debe recargar los parametros (por ahora, los casos donde puede llamarse un popup de otra pantalla son: headers y footers)
        this.genericService.getInternParam(dialogFA, null);
      }
    }

    if (dialogFA !== null) this.loadDialogStructure(dialogFA, type, dialogFA["id_functional_parent_initial_dsb"], params, structure['id_functional_parent_initial']);
    else console.log("PARECE QUE NO SE HA ECONTRADO EL DIALOG CON ID_FUNCTIONAL_AREA = ", internal_routing_id_functional_area);
  }

  private loadDialogStructure(structure, type, dialogId, params, moduleId) {
    if (structure !== null && structure !== undefined) {
      if (structure["close_dialog_out"] == 1) {
        structure["class"] = [structure["class"], "dialog-material-generic-close-out", "dialog-material-generic",];
      } else {
        structure["class"] = [structure["class"], "dialog-material-generic-no-close-out", "dialog-material-generic",];
      }
    }

    if (structure !== undefined) {
      if (type == 4 || type == 6) {
        if (!this.genericService.paramControlVariables[moduleId]) {
          this.genericService.paramControlVariables[moduleId] = this.genericService.createNewParamVariable();
        }
        if (!this.genericService.paramControlVariables[moduleId]['params'][this.genericService.paramControlVariables[moduleId]['indexParam']]) {
          this.genericService.paramControlVariables[moduleId]['params'][this.genericService.paramControlVariables[moduleId]['indexParam']] = this.genericService.initializeParams();
        }
        this.genericService.paramControlVariables[moduleId]['params'][this.genericService.paramControlVariables[moduleId]['indexParam']]['intern'] = params;
      }
      if (this.routingService.shouldOpenExternalWindow) structure["class"].push("overAllDialog");
      this.dialog.closeAll();
      this.dialogRef = this.dialog.open(DialogComponent, {
        width: "auto",
        height: "auto",
        panelClass: structure["class"],
        data: {
          structure: structure,
          type: type,
          dialogId: dialogId,
          moduleId: moduleId
        },
        hasBackdrop: structure["close_dialog_out"] == 1,
      });

      this.dialogRef.afterClosed().subscribe((data) => {
        if (this.genericService.formsChanged[structure["id_functional_parent_initial_dsb"]]) this.genericService.formsChanged[structure["id_functional_parent_initial_dsb"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
        this.genericService.emptyStructuresArrays(2);
      });
    }
  }

  compareArrays(arr1: (string | number)[], arr2: (string | number)[]): boolean {
    // Verifica si ambos son arrays y tienen la misma longitud
    if (Array.isArray(arr1) && Array.isArray(arr2) && arr1.length === arr2.length) {
      // Utiliza every para comparar los elementos
      return arr1.every((valor, indice) => valor === arr2[indice]);
    } else {
      // Si no son arrays o tienen diferente longitud, son diferentes
      return false;
    }
  }

  openBottomSheet(structure: any) {
    let panelClass = [structure["class"]];
    if (this.routingService.shouldOpenExternalWindow) panelClass.push("overAllDialog");
    this.bottomSheetRef = this._bottomSheet.open(BottomSheetComponent, {
      panelClass: panelClass,
      data: {
        structure: structure["child"],
      },
    });
  }

  closeBottomSheet(strucuture: any) {
    this.bottomSheetRef.dismiss();
  }

  formatDate(date, withSeconds = true) {
    return this.genericService.formatDate(date, withSeconds)
  }

  eachRecursiveBool(obj, validate, idDSB, parentHide = 0): boolean {
    let child = null;
    let formField = 0;
    let database = null;
    let table = null;
    let field = null;
    let value = null;
    let relation = null;
    let form = null;
    let status = true;
    let formValid = true;
    if (this.genericService.dataValidityPendingToExecute[obj["id_functional_area"]]) {
      this.genericService.dataValidityPendingToExecute[obj["id_functional_area"]];
      this.genericService.setStatusToElement(obj, this.genericService.dataValidityPendingToExecute[obj["id_functional_area"]]);
      delete this.genericService.dataValidityPendingToExecute[obj["id_functional_area"]];
    }
    if (obj['child'] && typeof obj['child'] === 'object') child = obj['child'];
    if (obj['form_field']) formField = obj['form_field'];
    if (obj['id_db']) database = obj['id_db'];
    if (obj['bd_table']) table = obj['bd_table'];
    if (obj['bd_field']) field = obj['bd_field'];
    if (obj['tmp_value'] || obj['tmp_value'] === 0) {
      value = obj['tmp_value'];
      if (value === undefined) value = null;
      if ((value == "" || value === undefined) && field == "id_product_mkt") value = 0;
      if (value == "" && field == "id_db") value = null;
    }
    if (obj['id_table_relations']) relation = obj['id_table_relations'];
    if (obj['form']) form = obj['form'];
    if (obj["id_functional_parent_initial_dsb"] != idDSB) {
      status = true;
      if (child) {
        for (let j in child) {
          if ((status && (child[j] && child[j]["id_functional_status_general"] && child[j]["id_functional_status_general"] != 2) || this.hasDSB(child[j]))) status = this.eachRecursiveBool(child[j], validate, idDSB, obj["hide"]);
        }
      }
      return status;
    }

    this.lastLabel = obj["label"];

    if (obj["id_functional_type"] == 15) {
      obj["multiple"] == 0;
      if (!value) value = 0;
    }
    if (obj["id_functional_status_general"] != 3 && parentHide != 1 && obj["hide"] != 1 && form && form["controls"] && form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]) formValid = form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]["valid"];
    if (obj["id_functional_type"] != 113 && obj["id_functional_type"] != 146) {
      if (formField == 1 && form !== null && !formValid && (form["value"][obj["id_functional_area"] + "-" + field] === null || form["value"][obj["id_functional_area"] + "-" + field] == "") && obj["id_db"] !== null && obj["bd_table"] !== null) {
        form["controls"][obj["id_functional_area"] + "-" + field]["touched"] = true;
        if (obj["id_functional_area"] == 2627) console.log("vaaaalue", value, this.tmpArray);
        if (obj["id_functional_type"] == 15 || obj["id_functional_type"] == 12 || obj["id_functional_type"] == 6) obj["invalid"] = true;
        if (status) {
          let validation = true;
          if (form && form["controls"] && form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]) validation = formValid;
          else console.log("!!! NO CONTROL !!! => FIELD NO FORM", obj["id_functional_area"])
          if (!validate) validation = true;
          if (obj["bd_table"] !== null) {
            if (obj["id_functional_type"] == 6 && (obj["type"] == "datepicker" || obj["type"] == "datepicker-month" || obj["type"] == "datepicker-year" || obj["type"] == "datetime-local")) {
              let ndate = this.formatDate(value);
              value = ndate;
            }
            if (obj["id_functional_type"] == 6 && obj["type"] == "time") {
              value = value + ":00";
            }
            if (obj["type"] == "email" && value && value != "") {
              if (!this.validateEmail(value) && validate) {
                validation = false;
                this.errorMessage = "El email es inválido";
              } else {
                validation = true;
                this.errorMessage = "";
              }
            }
            // Gestionar el caracter decimal
            if (obj["type"] == "decimal" && value && value != "" && typeof value == "string") {
              value = value.replace(",", ".");
            }
            if (obj["multiple"] == 1) {
              let found = false;
              for (let i in obj["originalValue"]) {
                found = false;
                for (let j in obj["tmp_value"]) {
                  if (obj["originalValue"][i] == obj["tmp_value"][j]) {
                    found = true;
                  }
                }
                if (!found) {
                  this.removed.push({
                    functionalArea: obj["id_functional_area"],
                    database: database,
                    table: table,
                    field: field,
                    value: obj["originalValue"][i],
                    relation: relation,
                    label: obj["label"],
                  });
                }
              }
              if (obj["id_functional_status_general"] == 5 && obj["id_functional_type"] == 12 && obj["type"] == "autocomplete" && (!obj["tmp_value"] || !Array.isArray(obj["tmp_value"]) || obj["tmp_value"].length < 1) && validate) validation = false;
            }
          }
          if (obj["id_functional_type"] == 121) {
            this.extractGeoFields(obj, database, table, relation, validation);
          }
          if (obj["id_functional_type"] != 121 && obj["timestamp"] === 0) {
            // -999
            //if(obj['id_functional_type'] == 12 && value == "") value = null;
            this.tmpArray.push({
              functionalArea: obj["id_functional_area"],
              database: database,
              table: table,
              field: obj["label"],
              value: value,
              relation: relation,
              valid: validation,
              label: obj["label"],
            });
          }
        }
        if (validate) status = false;
      } else if (formField == 1 && status && obj["id_functional_type"] != 113 && obj["id_db"] !== null && obj["bd_table"] !== null) {
        let validation = true;
        if (form && form["controls"] && form["controls"][obj["id_functional_area"] + "-" + obj["bd_field"]]) validation = formValid;
        else console.log("!!! NO CONTROL !!! => FIELD NO FORM", obj["id_functional_area"])
        if (!validate) validation = true;
        if (obj["bd_field"] !== null) {
          if (obj["id_functional_type"] == 6 && (obj["type"] == "datepicker" || obj["type"] == "datepicker-month" || obj["type"] == "datepicker-year" || obj["type"] == "datetime-local")) {
            let ndate = this.formatDate(value);
            value = ndate;
          }
          if (obj["id_functional_type"] == 6 && obj["type"] == "time") {
            value = value + ":00";
          }
          if (obj["type"] == "email" && value && value != "") {
            if (!this.validateEmail(value) && validate) {
              validation = false;
              this.errorMessage = "El email es inválido";
            } else {
              validation = true;
              this.errorMessage = "";
            }
          }
          // Gestionar el caracter decimal
          if (obj["type"] == "decimal" && value && value != "" && typeof value == "string") {
            value = value.replace(",", ".");
          }
          if (obj["multiple"] == 1) {
            if(obj["id_functional_type"] == 121) {
              obj["originalValue"] = obj["tmp_value_init"];
            }
            let found = false;
            for (let i in obj["originalValue"]) {
              found = false;
              for (let j in obj["tmp_value"]) {
                if (obj["originalValue"][i] == obj["tmp_value"][j]) {
                  found = true;
                }
              }
              if (!found) {
                this.removed.push({
                  functionalArea: obj["id_functional_area"],
                  database: database,
                  table: table,
                  field: field,
                  value: obj["originalValue"][i],
                  relation: relation,
                  label: obj["label"],
                });
              }
            }
            if (obj["id_functional_status_general"] == 5 && obj["id_functional_type"] == 12 && obj["type"] == "autocomplete" && (!obj["tmp_value"] || !Array.isArray(obj["tmp_value"]) || obj["tmp_value"].length < 1) && validate) validation = false;
          }
        }
        if (obj["id_functional_type"] == 121) {
          this.extractGeoFields(obj, database, table, relation, validation);
        }

        if (obj["id_functional_type"] != 121 && obj["bd_field"] !== null && obj["timestamp"] === 0) {
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: value,
            relation: relation,
            valid: validation,
            label: obj["label"],
          });
        }
        status = true;
        if (!validation && validate) status = false;
      } else if (child && obj["id_functional_type"] != 113) {
        for (let j in child) {
          if (status && ((child[j] && child[j]["id_functional_status_general"] && child[j]["id_functional_status_general"] != 2) || this.hasDSB(child[j]))) status = this.eachRecursiveBool(child[j], validate, idDSB, obj["hide"]);
        }
      }
    }

    if (!status && obj["id_functional_type"] == 7) {
      obj["expansion_start_opened"] = 1;
    }

    if (!status && obj["id_functional_type"] == 95) {
      let fa = this.genericService.findElementWithId(obj["id_functional_area"], true, false, false);
      fa["parent"]["selectedIndex"] = fa["key"];
    }

    if (status && obj["id_functional_type"] == 113) {
      if (obj["fileList"] !== null && obj["fileList"] !== undefined && obj["fileList"].length > 0) {
        this.imageUpload.push(obj);
        let p = 0;
        let tempFile = [];
        if (obj["fileList"].length > 1) {
          for (let t in obj["fileList"]) {
            tempFile.push(this.imageUpload.length - 1 + p);
            p++;
          }
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: tempFile,
            relation: relation,
            valid: true,
            label: obj["label"],
          });
        } else {
          this.tmpArray.push({
            functionalArea: obj["id_functional_area"],
            database: database,
            table: table,
            field: field,
            value: this.imageUpload.length - 1,
            relation: relation,
            valid: true,
            label: obj["label"],
          });
        }
      } else if ((obj["required"] || obj["id_functional_status_general"] == 5) && obj["bd_field"] !== null) {
        if (validate) status = false;
        this.tmpArray.push({
          functionalArea: obj["id_functional_area"],
          database: database,
          table: table,
          field: "archivos",
          value: value,
          relation: relation,
          valid: false,
          label: obj["label"],
        });
      }
    }

    if (obj["id_functional_type"] == 146) {
      if (obj["tmp_value_signature"].toData().length !== 0) {
        obj["tmp_value"] = obj["tmp_value_signature"].toDataURL();
        value = obj["tmp_value"];
        this.tmpArray.push({
          functionalArea: obj["id_functional_area"],
          database: database,
          table: table,
          field: field,
          value: value,
          relation: relation,
          valid: true,
          label: obj["label"],
        });
      } else {
        if (validate) status = false;
      }
    }

    if (!validate) return true;
    return status;
  }

  hasDSB(struct) {
    if (struct && struct["child"] && struct["child"] !== null && struct["child"] !== undefined) {
      for (let t in struct["child"]) {
        if (struct["child"][t]["id_functional_type"] == 85 || struct["child"][t]["id_functional_type"] == 92 || struct["child"][t]["id_functional_type"] == 98) return true;
      }
      return false;
    }
  }

  private extractGeoFields(obj, database, table, relation, validation) {
    let geo_country_code = null;
    let geo_admin1_code = null;
    let geo_admin2_code = null;
    let geo_admin3_code = null;
    let geo_admin4_code = null;
    let name = null;
    let fullname = null;

    if (obj["tmp_value"] !== null && obj["tmp_value"] !== undefined) {
      if (!obj["tmp_value"]["country_code"] && !obj["tmp_value"]["admin1_code"] && !obj["tmp_value"]["admin2_code"] && !obj["tmp_value"]["admin3_code"] && !obj["tmp_value"]["admin4_code"] && !obj["tmp_value"]["name"] && !obj["tmp_value"]["fullname"]) return; // Per si ja hi havia un valor i no s'ha tocat el camp, que no ho maxaqui

      if (obj["tmp_value"]["country_code"] !== null && obj["tmp_value"]["country_code"] !== undefined) geo_country_code = obj["tmp_value"]["country_code"];
      if (obj["tmp_value"]["admin1_code"] !== null && obj["tmp_value"]["admin1_code"] !== undefined) geo_admin1_code = obj["tmp_value"]["admin1_code"];
      if (obj["tmp_value"]["admin2_code"] !== null && obj["tmp_value"]["admin2_code"] !== undefined) geo_admin2_code = obj["tmp_value"]["admin2_code"];
      if (obj["tmp_value"]["admin3_code"] !== null && obj["tmp_value"]["admin3_code"] !== undefined) geo_admin3_code = obj["tmp_value"]["admin3_code"];
      if (obj["tmp_value"]["admin4_code"] !== null && obj["tmp_value"]["admin4_code"] !== undefined) geo_admin4_code = obj["tmp_value"]["admin4_code"];
      if (obj["tmp_value"]["name"] !== null && obj["tmp_value"]["name"] !== undefined) name = obj["tmp_value"]["name"];
      if (obj["tmp_value"]["fullname"] !== null && obj["tmp_value"]["fullname"] !== undefined) fullname = obj["tmp_value"]["fullname"];
    }
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_country_code",
      value: geo_country_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin1_code",
      value: geo_admin1_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin2_code",
      value: geo_admin2_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin3_code",
      value: geo_admin3_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_admin4_code",
      value: geo_admin4_code,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_name",
      value: name,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
    this.tmpArray.push({
      functionalArea: obj["id_functional_area"],
      database: database,
      table: table,
      field: "geo_fullname",
      value: fullname,
      relation: relation,
      valid: validation,
      label: obj["label"],
    });
  }

  public getTodayDate() {
    let today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let todayDate = day + "-" + month + "-" + year;

    return todayDate;
  }

  public openSnackBar(msg, duration, panelClass = ["lightgreen-snackbar"]) {
    this.genericService.openSnackBar(msg, duration, panelClass);
  }

  //Usar solo como parametro param.
  public applyFilter(filterValue: string, param: any): void {
    param.dataSource.filter = filterValue.trim().toLowerCase();
    if (param.dataSource.paginator) {
      param.dataSource.paginator.firstPage();
    }
  }

  //Gabri: puede recibir una string o un id como parametro y que corresponda a cada pantalla
  //con este id hacer un switch i adaptar aqui la funcion de format date poniendo esa funcion en el switch
  iniAllDates() {
    //getTodayDate
    //setMinDate
    /*const dt = new Date();
    this.dateToStringDBDate.getFullYear()
      + '-' +  ((this.dateToStringDBDate.getMonth() + 1) < 10 ? '0' : '') + (this.dateToStringDBDate.getMonth() + 1)
      + '-' +  (this.dateToStringDBDate.getDate() < 10 ? '0' : '') + this.dateToStringDBDate.getDate();*/
    let today = new Date();

    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    this.dateToday = day + "-" + month + "-" + year;

    let monthString = month.toString();
    let dayString = day.toString();
    if (day < 10) dayString = "0" + dayString;
    if (month < 10) monthString = "0" + monthString;
    this.minDate = dayString + "-" + monthString + "-" + (year - 100);
  }

  private findCount(current) {
    let max = 0;
    if (current) {
      for (let child in current["child"]) {
        let ext = 0;
        let max_child = this.findCount(current["child"][child]);
        let id = (current["child"][child]["id_functional_area"] + "").split(".");
        if (id.length > 1) ext = Number(id[1]);
        max = Math.max(ext, max_child);
      }
      return max;
    }
  }

  private duplicateChilds(subtree, count, origin_duplicated_id_functional_area, tmp_data = false) {
    if (subtree) {
      for (let key in subtree["child"]) {
        // this.fixElementDuplicated(subtree["child"][key], subtree["child"][key]['idFAParams'], subtree["child"][key]['idFAParamsInterns'], key);
        if (subtree["child"][key]) this.duplicateChilds(subtree["child"][key], count, origin_duplicated_id_functional_area, tmp_data);

        let idFa = subtree["child"][key]["id_functional_area"];
        if (subtree["child"][key]["id_functional_area_original"] && subtree["child"][key]["id_functional_area_original"] !== undefined) idFa = subtree["child"][key]["id_functional_area_original"];
        subtree["child"][key]["validatedForms"] = false;
        subtree["child"][key]["validatedFrontend"] = false;
        subtree["child"][key]['addedNewElementArray'] = false;
        subtree["child"][key]["id_functional_area"] = idFa + "." + count;
        if (subtree["child"][key]["id_query"]) {
          let query = this.cloneVariable(this.genericService.selectOptions[subtree["child"][key]["id_query"]]);
          let idQuery = this.cloneVariable(subtree["child"][key]["id_query"]).toString();
          let idQuery2 = idQuery.substring(0, idQuery.lastIndexOf('.')) + "." + count;
          if (subtree["child"][key]["ngForIdIndex"] && subtree["child"][key]["ngForIdIndex"] > 0) idQuery = idQuery2;
          else idQuery += "." + count;
          subtree["child"][key]["id_query"] = idQuery;
          this.genericService.selectOptions[idQuery] = query;
        }
        subtree["child"][key]["ngForIdIndex"] = count;
        subtree["child"][key]["id_functional_parent"] = subtree["child"][key]["id_functional_parent"] + "." + count;
        subtree["child"][key]["origin_duplicated_id_functional_area"] = origin_duplicated_id_functional_area + "." + count;
        subtree["child"][key]["duplicate_id_functional_area"] = subtree["child"][key]["duplicate_id_functional_area"] + "." + count;
        subtree["child"][key]["duplicated_frontend"] = 1;
        subtree["child"][key]["ngForId"] = origin_duplicated_id_functional_area + "." + count;
        subtree["child"][key]['initializedFormAttribute'] = false;
        subtree["child"][key]['initializedElementFirstTime'] = false;
        if (this.genericService.elementsThatAffectOthers[subtree["child"][key]["id_functional_area"]]) this.genericService.elementsThatAffectOthers[subtree["child"][key]["id_functional_area"]] = this.cloneVariable(this.genericService.elementsThatAffectOthers[subtree["child"][key]["id_functional_area"]]);

        for (let i in this.genericService.affectedElements) {
          for (let j in this.genericService.affectedElements[i]) {
            if (Object.keys(this.genericService.affectedElements[i][j]).includes(subtree["child"][key]["id_functional_area"])) {
              this.genericService.affectedElements[i][subtree["child"][key]["id_functional_area"]] = this.genericService.affectedElements[i][j];
            }
          }
        }
        if (tmp_data == null || tmp_data == false || (subtree["child"][key] && !(subtree["child"][key]["bd_field"] == tmp_data["field"] && subtree["child"][key]["bd_table"] == tmp_data["table"] && subtree["child"][key]["id_db"] == tmp_data["database"]) && (subtree["child"][key]["id_table_relations"] == 0 || subtree["child"][key]["id_table_relations"] == null))) {
          subtree["child"][key]["tmp_value"] = "";
          if (subtree["child"][key]["bd_field"] != "" && subtree["child"][key]["form"] !== undefined && subtree["child"][key]["form"]["controls"] !== undefined) {
            if (subtree["child"][key]["form"]["controls"][subtree["child"][key]["bd_field"]]) {
              subtree["child"][key]["form"]["controls"][subtree["child"][key]["bd_field"]]["value"] = "";
            }
          }
        }
      }
    }
  }

  private findElementWithDb(current, data) {
    if (current && current["child"]) {
      let val = {};
      current = current["child"];
      for (let i in current) {
        if (current[i] && current[i]["bd_field"] == data["field"] && current[i]["bd_table"] == data["table"] && current[i]["id_db"] == data["database"]) {
          return { parent: current, key: i, child: current[i] };
        } else {
          let data2 = this.findElementWithDb(current[i], data);
          if (data2) val = data2;
        }
      }
      return val;
    }
  }

  private setTmpValueToElement(element, data, index, isArray) {
    if (isArray) {
      element["tmp_value"] = data["value"][index];
    } else {
      element["tmp_value"] = data["value"];
    }
  }

  private checkPermissions(structure, finished) {
    if (!structure["id_functional_status_mkt"]) return true;
    const mkt_status = structure["id_functional_status_mkt"];
    switch (mkt_status) {
      case 1:
        //this.openSnackBar("¡Todo bien!", 2000, ["green-snackbar"]);
        return true;
      //break;
      case 2:
        this.openSnackBar("¡Vaya, parece que no te quedan unidades!", 2000, [
          "red-snackbar",
        ]);
        this.finishFunction(finished);
        break;
      case 3:
        this.openSnackBar(
          "¡Vaya, parece que este producto ha caducado!",
          2000,
          ["red-snackbar"]
        );
        this.finishFunction(finished);
        break;
      case 4:
        this.openSnackBar(
          "¡Vaya, parece que no has comprado este producto!",
          2000,
          ["red-snackbar"]
        );
        this.finishFunction(finished);
        break;
      case 5:
        this.openSnackBar(
          "¡Vaya, parece que este producto no está disponible!",
          2000,
          ["red-snackbar"]
        );
        this.finishFunction(finished);
        break;
      case 6:
        this.openSnackBar(
          "¡Vaya, parece que este producto no está disponible para tu tipo de empresa!",
          2000,
          ["red-snackbar"]
        );
        this.finishFunction(finished);
        break;
    }

    return false;
  }

  private duplicateElementUnified(structure, finished, hasParameters = false, type = 0) {
    let node, tmp_data;
    let size = 0;
    if (type == 1) size = this.tmpArray.length;
    if (size == 0) size = 1;

    // Controlaremos el size
    for (let i = 0; i < size; ++i) {
      if (this.tmpArray[i]) tmp_data = this.tmpArray[i];
      let length = 1; //Inicialitzem a 1 pel cas de que no tingui parametres
      console.log("tmp_data", this.tmpArray, tmp_data);
      if (hasParameters && tmp_data && tmp_data.value && Array.isArray(tmp_data.value)) {
        length = tmp_data.value.length; // en cas de que sigui form amb parametres s'actualitza el size
      }
      for (let j = 0; j < length; ++j) {
        if (hasParameters && tmp_data) tmp_data["route_param"] = this.routingService.routeParam;
        node = this.genericService.findElementWithId(structure["duplicate_id_functional_area"], true, true, false);
        console.log("node !!!", node, structure["duplicate_id_functional_area"])
        if (node == null) continue;
        let max = this.findCount(node["parent"]) + 1;
        let subtree2 = this.cloneVariable(node["child"]);
        this.fixElementDuplicated(subtree2, subtree2['idFAParams'], subtree2['idFAParamsInterns'], max);
        this.duplicateChilds(subtree2, String(max), structure["duplicate_id_functional_area"]);
        let element;
        if (hasParameters && tmp_data) element = this.findElementWithDb(subtree2, tmp_data);

        console.log(node, "node", structure["duplicate_id_functional_area"], structure);
        node["parent"]["child"][max] = subtree2; // asigno el nou subtree duplicate
        node["parent"]["child"][max]["id_functional_area"] = structure["duplicate_id_functional_area"] + "." + max;
        node["parent"]["child"][max]["id_functional_status_general"] = 1;
        node["parent"]["child"][max]["origin_duplicated_id_functional_area"] = structure["duplicate_id_functional_area"] + "." + max;
        node["parent"]["child"][max]["duplicate_id_functional_area"] = structure["duplicate_id_functional_area"] + "." + max;
        node["parent"]["child"][max]["duplicated_frontend"] = 1;
        node["parent"]["child"][max]["ngForId"] = node["parent"]["child"][max]["id_functional_area"];
        node["parent"]["child"][max]["ngForIdIndex"] = max;
        node["parent"]["child"][max]['validatedForms'] = false;
        node["parent"]["child"][max]['validatedFrontend'] = false;
        node["parent"]["child"][max]['addedNewElementArray'] = false;
        node["parent"]["child"][max]['initializedFormAttribute'] = false;
        node["parent"]["child"][max]['initializedElementFirstTime'] = false;
        //this.genericService.asignFrontend(node["parent"]["child"], node["parent"]["child"][max]['id_functional_parent_initial'], false, node["parent"]['idFAParams'], node["parent"]['idFAParamsInterns']);
        this.genericService.finishLoadedStructure(node["parent"]["child"][max]['id_functional_parent_initial']);

        if (this.genericService.elementsThatAffectOthers[structure["id_functional_area"]]) {
          this.genericService.elementsThatAffectOthers[node["parent"]["child"][max]["id_functional_area"]] = this.cloneVariable(this.genericService.elementsThatAffectOthers[structure["id_functional_area"]]);
        }
        for (let i in this.genericService.affectedElements) {
          for (let j in this.genericService.affectedElements[i]) {
            if (Object.keys(this.genericService.affectedElements[i][j]).includes(structure["duplicate_id_functional_area"])) {
              this.genericService.affectedElements[i][node["parent"]["child"][max]["id_functional_area"]] = this.genericService.affectedElements[i][j];
            }
          }
        }

        if (hasParameters) {
          if (!element || Object.keys(element).length === 0) {
            this.openSnackBar(
              "No existe ningun elemento con id_db, bd_table, bd_field",
              2000,
              ["red-snackbar"]
            );
          } else {
            this.setTmpValueToElement(element["child"], tmp_data, j, Array.isArray(tmp_data["value"]));
          }

          let value_tmp = tmp_data["value"];
          if (Array.isArray(tmp_data["value"])) value_tmp = tmp_data["value"][j];
          let params = {
            id_functional_area: structure["duplicate_id_functional_area"],
            id_language: this.authService.languageId,
            id_query_language: node["parent"]["child"][max]["id_query_language"],
            onlyOwner: structure["only_owner"] && structure["only_owner"] == 1 && structure["id_owner_field_filter"],
            id_owner_field_filter: structure["id_owner_field_filter"],
            tmp_data: {
              database: tmp_data["database"],
              field: tmp_data["field"],
              relation: tmp_data["relation"],
              table: tmp_data["table"],
              valid: tmp_data["valid"],
              value: value_tmp,
              route_param: tmp_data["route_param"],
            },
          };
          this.endpointService.parseLanguageTag(params).subscribe((data) => {
            let response = data["response"];
            let keys = Object.keys(response);
            keys.forEach((key) => {
              if (key != "id_functional_area") node["parent"]["child"][max][key] = response[key];
            });
            if (Array.isArray(tmp_data["value"])) tmp_data["value"][j] = "";
            else tmp_data["value"] = "";
            this.finishFunction(finished);
          });
        }

        // Construim de nou els forms del child per no tenir conflictes en les referencies
        node['parent']['validatedForms'] = true;
        this.genericService.initElementInFrontend(node["parent"]["child"][max], max, node["parent"], true);
        if (node["parent"]['id_functional_status_general'] == 2) node["parent"]['id_functional_status_general'] = node["parent"]['id_functional_status_initial'];
        this.finishFunction(finished);
        if (node && node !== null) {
          //this.updateFormHistory(node["parent"]["child"][max], node["parent"]["id_functional_parent_initial_dsb"], node["parent"], node["parent"]['id_functional_area'], "duplicated", null, null, null, null, null, node["parent"]["child"][max]["label"], node["parent"]["child"][max]["label"], hasParameters);
        }
      }
    }
  }

  private fixElementDuplicated(element, arrayParams, arrayParamsInterns, key) {
    if (this.genericService.checkFields(element)) {
      if (element['param'] && element['param'] == 1) arrayParams.push(element['id_functional_area']);
      if (element['param_intern'] && element['param_intern'] == 1) arrayParamsInterns.push(element['id_functional_area']);
    }
    element['idFAParams'] = arrayParams;
    element['idFAParamsInterns'] = arrayParamsInterns;
    this.genericService.addNewElementArray(element, key);
  }

  public async deleteFunctionalArea(structure) {
    if (structure["ngForId"]) {
      const id = structure["ngForId"];
      if (structure !== null) {
        const confirm = await this.genericService.openWarningDialog(4, 2, structure);
        if (confirm) {
          console.log("structure", structure, id)
          this.genericService.findElementWithIdAndSetStatus(id, 2);
          if (structure["duplicated_frontend"] != 1) {
            let subStructure = structure;
            if (structure["id_functional_type"] == 5) subStructure = this.genericService.findElementWithId(structure["id_functional_parent"], false, false, true);
            let formFields = this.getParamsFA(subStructure);
            this.endpointService.deleteData(formFields).subscribe((data) => {
              this.openSnackBar(
                data["errorMessage"],
                7000,
                data["errorCode"] == 0 ? ["lightgreen-snackbar"] : ["red-snackbar"]
              );
            });
          }
        }
      }
    }
  }

  public demoInmo(param): void {
    this.endpointService.createInmoDemo(this.authService.userId).subscribe((data) => {
      if (data["errorCode"] === 0) {
        this.snackBar.open(
          "Se ha creado una nueva demo. Entre con el ususario " +
          data["response"],
          "x",
          { duration: 6000, panelClass: ["lightgreen-snackbar"] }
        );
      } else {
        this.snackBar.open(
          "Error al crear la demo. Código de error " + data["errorCode"],
          "x",
          { duration: 6000, panelClass: ["red-snackbar"] }
        );
      }
    });
  }

  public loadClientsFromFile(param, finished, structure) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let dataAcc = this.genericService.getDataAccount();
      let interns = null;
      interns = this.genericService.getInternParam(structure, []);
      this.endpointService.loadClientsFromFile(dataAcc, this.tmpArray, this.removed, this.imageUpload, interns).subscribe((data) => {
        if (data["errorCode"] === 0) {
          this.snackBar.open(
            "Se han importado correctamente todos los clientes.",
            "x",
            { duration: 6000, panelClass: ["lightgreen-snackbar"] }
          );
        } else {
          this.snackBar.open(
            "Se han importado todos los usuarios menos los de las filas: " +
            data["errorMessage"] +
            ". Puedes arreglar estas filas y volver a subirlas. Asegura de guardar las filas con error pues este mensaje no lo podrás volver a ver.",
            "x",
            { panelClass: ["red-snackbar"] }
          );
        }
        this.finishFunction(finished);
      });
    } else {
      this.finishFunction(finished);
    }
  }

  public openColorPickerDialog(param): void {
    let panelClass = [];
    if (this.routingService.shouldOpenExternalWindow) panelClass.push("overAllDialog");
    const dialogRef = this.dialog.open(ColorPickerDialog, {
      width: "auto",
      height: "auto",
      data: param.type,
      autoFocus: true,
      panelClass: panelClass,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // type is null => add new category
      if (param.type == null && result != null) {
        const name = result["name"];
        const color = result["color"];
        const isPrivate = result["isPrivate"];
        const signable = result["signable"];
        const portalVisitCompatibility = result["portalVisitCompatibility"];
        const profileId = this.authService.getLoggedInUserId();
        this.endpointService
          .addNewCategoryType(
            {
              name: name,
              color: color,
              isPrivate: isPrivate,
              signable: signable,
              portalVisitCompatibility: portalVisitCompatibility,
            },
            profileId,
            this.authService.returnActiveAccountId(),
            this.authService.returnActiveAccountType()
          )
          .subscribe((data) => {
            this.endpointService
              .getCategories(
                profileId,
                this.authService.returnActiveAccountId(),
                this.authService.returnActiveAccountType(),
                this.authService.isOrphan
              )
              .subscribe((data_cat) => {
                setCategories(data_cat);
                this.openSnackBar("Categorí­a añadida", 6000, [
                  "lightgreen-snackbar",
                ]);
              });
          });
      } else if (param.type != null && result != null) {
        const name = result["name"];
        const color = result["color"];
        const isPrivate = result["isPrivate"];
        const signable = result["signable"];
        const portalVisitCompatibility = result["portalVisitCompatibility"];

        const typeId = param.type.id;
        const profileId = this.authService.getLoggedInUserId();
        this.endpointService
          .updateCategoryType(
            {
              name: name,
              color: color,
              isPrivate: isPrivate,
              signable: signable,
              portalVisitCompatibility: portalVisitCompatibility,
            },
            profileId,
            typeId
          )
          .subscribe((data) => { });
        this.endpointService
          .getCategories(
            profileId,
            this.authService.returnActiveAccountId(),
            this.authService.returnActiveAccountType(),
            this.authService.isOrphan
          )
          .subscribe((data) => {
            setCategories(data);
          });
      }
      this.update_category.emit(true);
    });
  }

  public insertFormData(structure, param, finished, type, publicar, valuesLoaded = false) {
    let validReq = true;
    if (!valuesLoaded) validReq = this.formsArray(structure, param);
    if (validReq) {
      if (publicar !== null) this.updateStatusVivienda(publicar);
      let dataAcc = this.genericService.getDataAccount();
      let interns = null;
      interns = this.genericService.getInternParam(structure, []);
      return this.endpointService.insertFormData(dataAcc, this.tmpArray, this.removed, this.imageUpload, interns);
    } else {
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  public updateStatusVivienda(type) {
    for (let tmp in this.tmpArray) {
      if (this.tmpArray[tmp]["field"] == "estado" && this.tmpArray[tmp]["table"] == "vivienda" && this.tmpArray[tmp]["database"] == 1) {
        if (type == 0) {
          if (this.tmpArray[tmp]["value"] == 4) this.tmpArray[tmp]["value"] = 9;
        }
        else if (type == 1) this.tmpArray[tmp]["value"] = 4;
      }
    }
  }

  public saveParams(structure, tableParams, id_functional_parent_initial = 0) {
    if (id_functional_parent_initial == 0) id_functional_parent_initial = this.routingService.moduleId;
    this.routingService.routeParam = [];
    if (!this.genericService.paramControlVariables[id_functional_parent_initial]) {
      this.genericService.paramControlVariables[id_functional_parent_initial] = this.genericService.createNewParamVariable();
    }
    this.generateNewOutputParam(structure);
    let par = this.genericService.paramControlVariables[id_functional_parent_initial];
    if (!par["params"][par["indexParam"]]['output']) par["params"][par["indexParam"]]['output'] = [];

    if (structure["id_functional_type"] != 3) {
      let tableParams2 = null;
      if (tableParams && tableParams["tmp_param"]) tableParams2 = tableParams["tmp_param"];
      this.genericService.findInterns(structure["idFAParams"], par["params"][par["indexParam"]], tableParams2, structure["id_functional_parent_initial_dsb"], "output");
    } else {
      if (tableParams && tableParams["tmp_param"]) this.genericService.getParamInternTable(tableParams["tmp_param"], par['params'][par["indexParam"]], structure, 'output');
    }
    this.routingService.routeParam = this.genericService.paramControlVariables[id_functional_parent_initial]["params"][this.genericService.paramControlVariables[id_functional_parent_initial]["indexParam"]]["output"];
    this.route = structure["internal_routing"];
    this.id_route = structure["internal_routing_id_functional_area"];
    this.routingService.previousModule = id_functional_parent_initial;
  }

  public saveParamsClientOnly(structure, id_functional_parent_initial = 0) {
    // Sirve solamente para poner en el cliente de tipo empresa a clientes.id
    if (id_functional_parent_initial == 0) id_functional_parent_initial = this.routingService.moduleId;
    this.routingService.routeParam = [];
    if (!this.genericService.paramControlVariables[id_functional_parent_initial]) {
      this.genericService.paramControlVariables[id_functional_parent_initial] = this.genericService.createNewParamVariable();
    }
    this.generateNewOutputParam(structure);
    let par = this.genericService.paramControlVariables[id_functional_parent_initial];
    par["params"][par["indexParam"]]['output'] = [{ id_db: 1, bd_table: "clientes", bd_field: "id", value: structure['tmp_value_multi_chip'] }];
    this.routingService.routeParam = par["params"][par["indexParam"]]["output"];
    this.route = structure["internal_routing"];
    this.id_route = structure["internal_routing_id_functional_area"];
    this.routingService.previousModule = id_functional_parent_initial;
  }

  public duplicateFunctionalAreaDB(structure, finished) {
    this.formsArray(structure, finished);
    let idToDuplicate = this.tmpArray[1]["value"];
    let idParent = this.tmpArray[0]["value"];
    let data = { idToDuplicate, idParent };

    this.endpointService.duplicateFunctionalAreaDB(data).subscribe((data) => {
      this.finishFunction(finished);
      this.openSnackBar(
        "Â¡LO TENGO, YA TE LO HE DUPLICADO Y SUBIDO! ;)",
        6000,
        ["green-snackbar"]
      );
    });
  }

  private getInternParamDialog(structure, param) {
    let par = {
      id_pantalla: 0,
      params: {
        [0]: {
          intern: []
        }
      },
      indexParam: 0
    };
    if (structure["id_functional_type"] != 3) {
      let paramTable = null;
      if (param && param["param_intern"]) paramTable = param["param_intern"];
      this.genericService.findInterns(structure["idFAParamsInterns"], par["params"][par["indexParam"]], paramTable, structure["id_functional_parent_initial_dsb"]);
    } else {
      //table
      par["params"][par["indexParam"]]["intern"] = [];
      if (param && param["param_intern"]) this.genericService.getParamInternTable(param["param_intern"], par['params'][par["indexParam"]], structure);
    }
    return par["params"][par["indexParam"]]["intern"];
  }

  private generateNewOutputParam(structure) {
    if (!this.genericService.paramControlVariables[structure['id_functional_parent_initial']]) {
      this.genericService.paramControlVariables[structure['id_functional_parent_initial']] = this.genericService.createNewParamVariable();
    } else {
      if (!this.genericService.paramControlVariables[structure['id_functional_parent_initial']]['params'] || !this.genericService.paramControlVariables[structure['id_functional_parent_initial']]['params'][0]) {
        this.genericService.paramControlVariables[structure['id_functional_parent_initial']]['params'][0] = this.genericService.initializeParams();
        this.genericService.paramControlVariables[structure['id_functional_parent_initial']]['indexParam'] = 0
      } else {
        let lengthParam = this.genericService.paramControlVariables[structure['indexParam']];
        this.genericService.paramControlVariables[structure['id_functional_parent_initial']]['params'][lengthParam] = this.genericService.initializeParams();
      }
    }
  }

  openExternalUrl(structure) {
    if (structure["internal_routing"].includes("movin.cloud") || structure["internal_routing"].includes("4dmovin.com")) structure["internal_routing"] = structure["internal_routing"].replaceAll(" ", "-");
    window.open(structure["internal_routing"], "_blank");
  }

  findFunctionalArea(structure, id) {
    let str = null;
    if (structure["id_functional_area"] == id) str = structure;
    else {
      if (structure["child"]) {
        for (let e in structure["child"]) {
          if (str === null)
            str = this.findFunctionalArea(structure["child"][e], id);
        }
      }
    }
    return str;
  }

  revertValues(structure) {
    this.genericService.revertDuplicateFromValue(structure["id_functional_parent_initial"], null, structure);
  }

  deleteDataDialog(fa, type) {
    if (this.checkPreviewModeFunction()) return;
    let dialog = this.genericService.findElementWithId(fa["window"], false, false, true);
    let formFields = this.getParamsFA(dialog);
    this.endpointService.deleteData(formFields).subscribe((data) => {
      this.openSnackBar(
        data["errorMessage"],
        7000,
        data["errorCode"] == 0 ? ["lightgreen-snackbar"] : ["red-snackbar"]
      );
      if (data["errorCode"] == 0) {
        if (type == 0) {
          this.genericService.refreshStructure(0);
        } else if (type == 4) {
          this.genericService.refreshStructure(2);
        } else if (type == 5) {
          this.route = fa["internal_routing"];
          this.id_route = fa["internal_routing_id_functional_area"];
          this.routingService.previousModule = fa["id_functional_parent_initial"];
          this.goRouting(this.route, true);
        } else {
          let idDialogParent = this.genericService.findElementWithId(fa["internal_routing_id_functional_area"], true, false, true);
          if (type == 1)
            this.getFunction({ child: Array(idDialogParent), id_function: 2 });
          if (type == 2)
            this.getFunction({ child: Array(idDialogParent), id_function: 45 });
          if (type == 3)
            this.getFunction({ child: Array(idDialogParent), id_function: 35 });
        }
      }
    });
  }

  public importDeportActivityAssign(structure, param, finished) {
    let validReq = this.formsArray(structure, param, false);
    let allData = {
      paramInterns: this.genericService.getInternParam(structure, param),
      actualValueFields: JSON.parse(JSON.stringify(this.tmpArray)),
      dataAccount: this.genericService.getDataAccount(),
      faWithQueriesToRefresh: structure["internal_routing_id_functional_area"],
      staticHTML: this.genericService.staticHTML,
    };
    for (let i in allData.paramInterns) {
      if (allData.paramInterns[i]["id_db"] == 1 && allData.paramInterns[i]["bd_table"] == "act_depor_actividad_precio" && allData.paramInterns[i]["bd_field"] == "precio") {
        if (allData.paramInterns[i]["value"] == null || allData.paramInterns[i]["value"] == "" || allData.paramInterns[i]["value"] == -1) {
          this.authService.importDeportActivity = allData.paramInterns[i]["value"];
        } else {
          this.authService.importDeportActivity = allData.paramInterns[i]["value"];
        }
      }
    }
    this.reloadQueries(structure, param, finished);
  }

  public reloadQueries(structure, param, finished, searchEmpresa = 0) {
    let faWithQueriesToRefresh = structure["internal_routing_id_functional_area"];
    let validReq = this.formsArray(structure, param, false);
    let allData = {
      paramInterns: this.genericService.getInternParam(structure, param),
      actualValueFields: JSON.parse(JSON.stringify(this.tmpArray)),
      dataAccount: this.genericService.getDataAccount(),
      faWithQueriesToRefresh: faWithQueriesToRefresh,
      staticHTML: this.genericService.staticHTML,
    };

    if (searchEmpresa > 0) {
      let actualNgForId = this.getNgForId(structure);

      let idFaVivienda = this.genericService.findFAWithTableField(1, "vivienda", "id_empresa", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      if (idFaVivienda && idFaVivienda['tmp_value']) allData['dataAccount']['idCompanyGeneric'] = idFaVivienda['tmp_value'];

      let idFaClientes = this.genericService.findFAWithTableField(1, "clientes", "id_empresa", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      if (idFaClientes && idFaClientes['tmp_value']) allData['dataAccount']['idCompanyGeneric'] = idFaClientes['tmp_value'];
    }

    this.endpointService.reloadQueries(allData).subscribe((data) => {
      if (data["response"] != null && data["response"] != "") {
        //Refrescamos el service de queries
        for (let key in data["response"]) {
          let idQuery = data["response"][key]["idQuery"];
          if (structure['ngForIdIndex'] && structure['ngForIdIndex'] != -1) {
            idQuery = idQuery + "." + structure['ngForIdIndex'];
          }
          this.genericService.selectOptions[idQuery] = data["response"][key]["result"];
          this.genericService.selectOptionsHeader[idQuery] = data["response"][key]["result"];
          this.genericService.selectOptionsFooter[idQuery] = data["response"][key]["result"];
          this.genericService.selectOptionsChange = !this.genericService.selectOptionsChange;
          this.genericService.selectOptionsChangeEvent.next(this.genericService.selectOptionsChange);
          this.getFunctionalAreaFindElementsWithIdQueryAndAssignValuesLoop(idQuery, data["response"][key]["result"], structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        }
      }
      this.finishFunction(finished);
    });
  }

  public getFunctionalAreaFindElementsWithIdQueryAndAssignValuesLoop(idQuery, value, idDSB, NGForIndex) {
    if(!NGForIndex) NGForIndex = -1;
    if (idQuery != null && value != null) {
      let current = this.genericService.getAllStructures(1);
      for (let i in current) {
        if (current[i] && current[i]["id_query"] && current[i]["id_query"] == idQuery && current[i]["form_field"] == 1) {
          if((NGForIndex == -1 || NGForIndex == current[i]['duplicate_id_functional_area']) && current[i]["id_functional_parent_initial_dsb"] == idDSB) {
            this.treatFAWhenQuery(current[i], value);
          }
        }
      }
    }
  }

  public treatFAWhenQuery(current, value) {
    if (value.length && value.length == 1 && value[0]["value"] && value[0]["value"] != -1) {
      if (!current['tmp_value'] || value[0]["value"] != current['tmp_value']) this.assignValueFunctionalArea(current, value[0]["value"]);
    } else if (current["tmp_value"] && current["multiple"] !== 1) {
      let founded = false;
      for (let i in value) {
        if (current["tmp_value"] == value[i]["value"]) {
          founded = true;
          break;
        }
      }
      let currentValue = current["form"]["controls"][current["id_functional_area"] + "-" + current["bd_field"]];
      if (!founded && currentValue != -1 && current['tmp_value'] != -1) {
        this.assignValueFunctionalArea(current, -1);
      }
    } else if ((current["tmp_value_init"] || current["tmp_value_init"] === 0) && current["multiple"] !== 1) {
      let foundedInit = false;
      for (let i in value) {
        if (current["tmp_value_init"] == value[i]["value"]) {
          foundedInit = true;
          break;
        }
      }
      if (!foundedInit && current['tmp_value_init'] != -1) this.assignValueFunctionalArea(current, -1);
      else this.assignValueFunctionalArea(current, current["tmp_value_init"]);
    }

    if (current["id_functional_type"] == 12 || current["id_functional_type"] == 14) {
      if (value.length && value.length > 0) {
        let status = current["id_functional_status_initial"];
        let hide = current["hide_initial"];
        if (this.authService.checkUserIsDeveloping == 1) {
          hide = 0;
        }
        current["id_functional_status_general"] = status;
        current["hide"] = hide;
        this.genericService.updateStatusFormControl(current, status);
        this.changeHide0(current);
      } else {
        current["id_functional_status_general"] = 2;
        current["hide"] = 1;
      }
    } else { // Hay que hacerlo para recargar el componente
      current["refreshing"] = true;
    }
  }

  private getParamsFA(dialog) {
    let params = [];
    params = this.getFormFields(dialog);
    let formFields = [];
    for (let g in params) {
      let formField = {
        database: params[g]["id_db"],
        table: params[g]["bd_table"],
        field: params[g]["bd_field"],
        value: params[g]["tmp_value"],
        relation: 0,
        valid: true,
      };
      formFields.push(formField);
    }
    return formFields;
  }

  getFormFields(subStructure) {
    let aux = [];
    if (subStructure) {
      if (subStructure["form_field"] == 1) aux.push(subStructure);
      if (subStructure["child"])
        for (let w in subStructure["child"])
          aux = aux.concat(this.getFormFields(subStructure["child"][w]));
    }
    return aux;
  }

  validateEmail(email) {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  updateConditionsCustom(finished, structure) {
    this.endpointService
      .updateConditionsCustom(this.arrayConditions)
      .subscribe((data) => {
        this.finishFunction(finished);
        this.openSnackBar("Guardado con éxito", 15000, ["green-snackbar"]);
        if (data["response"] != null && data["response"] != "") {
          let idFA = this.genericService.findFAWithTableField(1, "eventos", "consulta_autogenerada_condiciones", 0);
          if (idFA == null || idFA == undefined) idFA = this.genericService.findFAWithTableField(1, "eventos_reportes", "consulta_autogenerada_condiciones", 0);
          this.assignValueFunctionalArea(idFA, data["response"]);
        }
      });
  }

  updatePlantillaElementsCustom(structure, param, finished) {
    this.templateService.generateHTMLResult();
    this.endpointService.updatePlantillaElementsCustom(this.templateService.arrayElementsPlantilla, this.templateService.arrayElementsPlantillaSubject, this.templateService.plantillaInfo).subscribe((data) => {
      this.finishFunction(finished);
      if (data["errorMessage"] !== "OK!") {
        this.openSnackBar(data["errorMessage"], 7000, ["red-snackbar"]);
      }
      this.openSnackBar("Guardado con éxito", 7000, ["green-snackbar"]);
    });
  }

  updateJourneyCustom(finished) {
    let cleanElementsJourney = [];
    let valid = true;
    for (let i in this.journeyService.elementsJourney) {
      if (this.journeyService.elementsJourney[i]['id_type'] == 1) {
        this.journeyService.elementsJourney[i].formCreator = {};
        cleanElementsJourney.push(this.journeyService.elementsJourney[i]);
      }
      if (this.journeyService.elementsJourney[i]['id_type'] == 3) {
        valid = false;
        break;
      }
    }
    if (valid) {
      this.endpointService.updateJourneysCreator(cleanElementsJourney).subscribe((data) => {
        this.finishFunction(finished);
        if (data["errorCode"] == 0) {
          this.openSnackBar("Guardado con éxito", 7000, ["green-snackbar"]);
          this.genericService.refreshStructure(0);
        } else {
          this.openSnackBar(data["errorMessage"], 7000, ["red-snackbar"]);
        }
      });
    } else {
      this.finishFunction(finished);
      this.openSnackBar("No puedes dejar campos sin rellenar después de un IF/ELSE. En caso de que no quieras añadir nada, puedes añadir un elmento de tipo 'Finalizar Journey'.", 15000, ["red-snackbar"]);
    }
  }

  archiveAllStatusDemandaFromViviendaCustom(finished, structure, param) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let idVivienda = null;
      let motivoCancelacion = "Demanda cancelada automáticamente de manera masiva a partir del inmueble";
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id") {
          idVivienda = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario" && values[i]["field"] == "can_motivo") {
          motivoCancelacion = values[i]["value"];
        }
      }

      this.endpointService.archiveAllStatusDemandaFromViviendaCustom(idVivienda, motivoCancelacion).subscribe((data) => {
        this.finishFunction(finished);
        if (data["errorCode"] == 0) {
          this.openSnackBar("Guardado con éxito", 7000, ["green-snackbar"]);
          this.genericService.refreshStructure(0);
        } else {
          this.openSnackBar(data["errorMessage"], 7000, ["red-snackbar"]);
        }
      });
    } else {
      this.finishFunction(finished);
    }
  }

  calculateNewImport(structure, param) {
    let validReq = this.formsArray(structure, param);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let cantidad = 0;
    let precio = 0;
    let importeTotal = 0;
    let tablesOpciones = ["rest_pedidos_productos_opciones_medidas", "rest_pedidos_productos_opciones_suplementos", "rest_pedidos_productos_opciones_opciones", "rest_pedidos_productos_opciones_preparacion"];
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_productos" && values[i]["field"] == "cantidad") {
        cantidad = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_productos" && values[i]["field"] == "precio") {
        precio = values[i]["value"];
      }
      for (let t of tablesOpciones) {
        if (values[i]["database"] == 1 && values[i]["table"] == t && values[i]["field"] == "id_opcion") {
          if (!values[i]["value"]) continue;
          if (!values[i]["value"][0]) values[i]["value"] = [values[i]["value"]];
          for (let j of values[i]["value"]) {
            let idFA = this.genericService.findFAWithTableField(1, t, "id_opcion", structure["id_functional_parent_initial_dsb"]);
            for (let k of this.genericService.selectOptions[idFA['id_query']]) {
              if (j == k['value'] && k['price'] && k['price'] > 0) {
                precio = +precio + +k['price'];
                break;
              }
            }
          }
        }
      }
    }

    importeTotal = cantidad * precio;
    importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
    let idFA = this.genericService.findFAWithTableField(1, "rest_pedidos_productos", "importe", structure["id_functional_parent_initial_dsb"]);
    this.assignValueFunctionalArea(idFA, importeTotal);
    // idFA = this.genericService.findFAWithTableField(1, "rest_pedidos_productos", "importe_unitario", structure["id_functional_parent_initial_dsb"]);
    // this.assignValueFunctionalArea(idFA, precio);
  }

  calculateNewImporteTarifas(structure) {
    let cantidad = 0;
    let precio = 0;
    let importeTotal = 0;
    let idFATarifa = this.genericService.findFAWithTableField(1, 'act_ludic_prereservas', 'id_tarifa', structure['id_functional_parent_initial_dsb']);
    let idFACantidad = this.genericService.findFAWithTableField(1, 'act_ludic_prereservas', 'num_ninos', structure['id_functional_parent_initial_dsb']);
    if (idFACantidad && idFACantidad['tmp_value']) cantidad = idFACantidad['tmp_value'];
    if (idFATarifa) {
      let result = this.genericService.getRegsitrySelectionFromFA(idFATarifa);
      if (result['importe']) precio = result['importe'];
    }
    importeTotal = cantidad * precio;
    importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
    let idFA = this.genericService.findFAWithTableField(1, "act_ludic_prereservas", "total_tarifas", structure["id_functional_parent_initial_dsb"]);
    console.log("DANI2: ", cantidad, precio, importeTotal, idFA);
    this.assignValueFunctionalArea(idFA, importeTotal);
  }

  calculateImportAndSetField(structure) {
    let actualNgForId = this.getNgForId(structure);
    let operation = this.cloneVariable(structure['internal_routing']).replaceAll(' ', '');
    let fieldsToOperate = this.extraerVariablesOperation(operation);
    for (let i in fieldsToOperate) {
      let cleanNumber = true;
      let splited = fieldsToOperate[i].split(".");
      let idFA = this.genericService.findFAWithTableField(splited[0], splited[1], splited[2], structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      let val: any = 0;
      if (idFA) {
        if (idFA['tmp_value']) {
          val = this.cloneVariable(idFA['tmp_value']);
          if (idFA['id_functional_type'] == 15) {
            val = !(val['value'] == '0' || val['value'] == "'0'" || val['value'] == 'false');
            if (val) val = 1;
            else val = 0;
          } else if (idFA['id_functional_type'] == 12 && splited[3]) {
            let result = this.genericService.getRegsitrySelectionFromFA(idFA);
            if (result[splited[3]]) val = result[splited[3]];
            else val = 0;
          } else if(idFA['id_functional_type'] == 6) {
            if(idFA['type'] == "datepicker" || idFA['type'] == "datepicker-month") {
              val = new Date(this.formatDate(val)).getTime() / (24 * 60 * 60 * 1000); // Num days
            }
          }
        }
      }
      console.log("valval", val, idFA, fieldsToOperate[i]);
      val = val.toString().replaceAll(',', '.');
      val = Math.round((+val + Number.EPSILON) * 100) / 100;
      operation = operation.replaceAll(fieldsToOperate[i], val.toString());      
    }

    operation = operation.toLowerCase();
    operation = operation.toString().replaceAll('ceil', 'Math.ceil');
    operation = operation.toString().replaceAll('round', 'Math.round');
    operation = operation.toString().replaceAll('floor', 'Math.floor');
    operation = operation.toString().replaceAll('abs', 'Math.abs');

    console.log(operation, "operationoperation")
    let total = eval(operation);
    total = Math.round((total + Number.EPSILON) * 100) / 100;
    let db = null;
    let table = null;
    let field = null;
    let splited = structure['internal_routing_id_functional_area'].split('.');
    if (splited[0]) db = splited[0];
    if (splited[1]) table = splited[1];
    if (splited[2]) field = splited[2];
    let idFa = this.genericService.findFAWithTableField(db, table, field, structure["id_functional_parent_initial_dsb"], actualNgForId, false);
    console.log("valval 2", idFa, total, db, table, field);
    this.assignValueFunctionalArea(idFa, total);
  }

  private extraerVariablesOperation(operation) {
    // Encuentra todas las ocurrencias de palabras entre los operadores
    const regex = /\b\d*\.\b[a-zA-Z_][a-zA-Z0-9_]*(?:\.[a-zA-Z_][a-zA-Z0-9_]*)+\b/g
    let vars = operation.match(regex) || [];
    // Elimina los duplicados
    vars = Array.from(new Set(vars));
    return vars;
  }

  sumAllFieldsAndSetField(structure) {
    console.log("DANI0: entro");
    let total = this.sumRecursiveNgFor(0, structure['id_db'], structure['bd_table'], structure['bd_field'], structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
    total = Math.round((total + Number.EPSILON) * 100) / 100;
    let db = null;
    let table = null;
    let field = null;
    let splited = structure['internal_routing_id_functional_area'].split('.');
    if (splited[0]) db = splited[0];
    if (splited[1]) table = splited[1];
    if (splited[2]) field = splited[2];
    let idFa = this.genericService.findFAWithTableField(db, table, field, structure["id_functional_parent_initial_dsb"], -1, false);
    this.assignValueFunctionalArea(idFa, total);
  }

  sumRecursiveNgFor(total, db, table, field, idDSB, ngForId, i = 0) {
    console.log("DANI1: entro");
    let actualNgForId: any = '';
    if (i > 0) actualNgForId = ngForId.substring(0, ngForId.lastIndexOf('.')) + '.' + i;
    let idFa = this.genericService.findFAWithTableField(db, table, field, idDSB, actualNgForId);
    console.log('asdasdasddas', idFa, ngForId, actualNgForId, i, db, table, field, idDSB, actualNgForId)
    if (idFa || i == 0) {
      if (idFa && idFa['tmp_value'] && this.genericService.checkStatus(idFa['id_functional_area'])) total = total + +(idFa['tmp_value'].toString().replaceAll(',', '.'));
      total = this.sumRecursiveNgFor(total, db, table, field, idDSB, ngForId, ++i);
    }
    return total;
  }

  imprimirPedido(structure, param) {
    let intern_data = this.genericService.getInternParam(structure, param);;
    console.log("intern_data ", intern_data);
    let idPedido = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido") {
        idPedido = intern_data[i]["value"];
        console.log("idPedido", idPedido);
        break;
      }
    }
    if (idPedido > 0) {
      this.printTicketService.printPedidoRestaurant(idPedido);
    }
    else this.openSnackBar("No se ha encontrado ningún pedido a imprimir", 4000, ["lightred-snackbar"]);
  }

  calculateNewImportActLudic(structure, param) {
    let validReq = this.formsArray(structure, param);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let cantidad = 0;
    let precio = 0;
    let importeTotal = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_servicios" && values[i]["field"] == "cantidad") {
        cantidad = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_servicios_precio" && values[i]["field"] == "precio") {
        precio = values[i]["value"];
      }
    }

    importeTotal = cantidad * precio;
    importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
    let idFA = this.genericService.findFAWithTableField(1, "act_ludic_reservas_servicios", "importe", structure["id_functional_parent_initial_dsb"]);
    this.assignValueFunctionalArea(idFA, importeTotal);
    // idFA = this.genericService.findFAWithTableField(1, "rest_pedidos_productos", "importe_unitario", structure["id_functional_parent_initial_dsb"]);
    // this.assignValueFunctionalArea(idFA, precio);
  }

  setFieldsIDClienteEmpresa(structure, param) {
    //if(!structure['setFieldsIDClienteEmpresa']) {
      structure['setFieldsIDClienteEmpresa'] = false;
      let validReq = this.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
      let values = this.cloneVariable(valuesOriginal);
      let id = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "clientes" && values[i]["field"] == "id_cliente_empresa" && values[i]["value"] && values[i]["value"] > 0) {
          id = values[i]["value"];
          //structure['setFieldsIDClienteEmpresa'] = true;
          break;
        }
      }
  
      let idFA = this.genericService.findFAWithTableField(1, "clientes_empresa", "id_cliente_empresa", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, id);
    //}
  }

  calculateNewImportTicket(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe = 0;
    let descuento = 0;
    let tipo_descuento = 0;
    let importeTotal = 0
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
        importe = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "descuento" && values[i]["value"] && values[i]["value"] > 0) {
        descuento = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "id_descuento_unidad") {
        tipo_descuento = values[i]["value"];
      }
    }

    if (tipo_descuento == 1) {
      importeTotal = importe - descuento;
    } else if (tipo_descuento == 2) {
      importeTotal = importe - (importe * descuento / 100);
    } else {
      importeTotal = importe;
    }
    if (importeTotal < 0) importeTotal = 0;
    if (importeTotal >= 0) {
      importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "rest_pedidos_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotal);
    }
  }

  calculateNewImportTicketServicios(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe = 0;
    let descuento = 0;
    let tipo_descuento = 0;
    let importeTotal = 0
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
        importe = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "descuento" && values[i]["value"] && values[i]["value"] > 0) {
        descuento = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "id_descuento_unidad") {
        tipo_descuento = values[i]["value"];
      }
    }

    if (tipo_descuento == 1) {
      importeTotal = importe - descuento;
    } else if (tipo_descuento == 2) {
      importeTotal = importe - (importe * descuento / 100);
    } else {
      importeTotal = importe;
    }
    if (importeTotal < 0) importeTotal = 0;
    if (importeTotal >= 0) {
      importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotal);
    }
  }

  ImporteAImporteFinal(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
        importe = values[i]["value"];
      }
    }
    if (importe >= 0) {
      let idFA = this.genericService.findFAWithTableField(1, "rest_pedidos_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importe);
    }
  }

  calculateNewImportTicketActLudic(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe = 0;
    let descuento = 0;
    let tipo_descuento = 0;
    let importeTotal = 0
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
        importe = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "descuento" && values[i]["value"] && values[i]["value"] > 0) {
        descuento = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "id_descuento_unidad") {
        tipo_descuento = values[i]["value"];
      }
    }

    if (tipo_descuento == 1) {
      importeTotal = importe - descuento;
    } else if (tipo_descuento == 2) {
      importeTotal = importe - (importe * descuento / 100);
    } else {
      importeTotal = importe;
    }
    if (importeTotal < 0) importeTotal = 0;
    if (importeTotal >= 0) {
      importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "act_ludic_reservas_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotal);
    }
  }

  calculateNewImportDiferencia(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importeDiferencia = 0;
    let importePagado = 0;
    let importeAPagar = 0;
    let importePropina = 0;
    let indexNgForEfectivo = null;
    let hasEfectivo = false;

    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets_payment_methods" && values[i]["field"] == "id_payment_method" && values[i]["value"] && values[i]["value"] > 0 && values[i]["value"] == 5) {
        let indexNgForEfectivoArray = values[i]["functionalArea"].split(".");
        indexNgForEfectivo = indexNgForEfectivoArray[indexNgForEfectivoArray.length - 1];
        break;
      }
    }
    console.log("values[i]]", values);
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "importe_final" && values[i]["value"]) {
        console.log("values[i]]", values[i]["value"]);
        importeAPagar = +values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets_payment_methods" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
        importePagado = importePagado + +values[i]["value"];
        let indexNgForEfectivoArrayImporte = values[i]["functionalArea"].split(".");
        let indexNgForEfectivoImporte = indexNgForEfectivoArrayImporte[indexNgForEfectivoArrayImporte.length - 1];
        if (indexNgForEfectivo != null && indexNgForEfectivoImporte == indexNgForEfectivo) {
          hasEfectivo = true;
        }
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "propina" && values[i]["value"] && values[i]["value"] > 0) {
        importePagado = importePagado - +values[i]["value"];
        importePropina = +values[i]["value"];
      }
    }


    importeDiferencia = importeAPagar - importePagado;
    if (!isNaN(importeDiferencia) && isFinite(importeDiferencia)) {
      importeDiferencia = Math.round((+importeDiferencia + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "comodin_box_propina", "comodin_box_propina", structure["id_functional_parent_initial_dsb"], -1, true, true);
      if (idFA !== null) {
        if (hasEfectivo) {
          idFA['id_functional_status_general'] = 2;
          if (importeDiferencia < 0 && !this.genericService.snackbarOpened) this.openSnackBar('Hay dinero sobrante y no puedes marcar "Cobrado", pero no puedes añadir propina si tienes indicado "Efectivo". Debes retirar el efectivo primero.', 50000, ["lightred-snackbar"]);
          importePropina = 0;
          let idFA2 = this.genericService.findFAWithTableField(1, "rest_pedidos_tickets", "propina", structure["id_functional_parent_initial_dsb"], -1, true);
          this.assignValueFunctionalArea(idFA2, 0);
        } else {
          idFA['id_functional_status_general'] = 1
          this.snackBar.dismiss();
        }
      }
      let importeTotalDecimal = importeDiferencia.toString().replace('.', ',');
      let idFA2 = this.genericService.findFAWithTableField(1, "comodin_diferencia", "comodin_diferencia_importe", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA2, importeTotalDecimal);
      if (importePropina > 0 && importeDiferencia != 0 && !hasEfectivo) this.SobranteAPropinas(structure, param, 0);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  calculateNewImportDiferenciaServicios(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importeDiferencia = 0;
    let importePagado = 0;
    let importeAPagar = 0;
    let importePropina = 0;
    let indexNgForEfectivo = null;
    let hasEfectivo = false;

    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets_payment_methods" && values[i]["field"] == "id_payment_method" && values[i]["value"] && values[i]["value"] > 0 && values[i]["value"] == 5) {
        let indexNgForEfectivoArray = values[i]["functionalArea"].split(".");
        indexNgForEfectivo = indexNgForEfectivoArray[indexNgForEfectivoArray.length - 1];
        break;
      }
    }
    console.log("values[i]]", values);
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "importe_final" && values[i]["value"]) {
        console.log("values[i]]", values[i]["value"]);
        importeAPagar = +values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets_payment_methods" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
        importePagado = importePagado + +values[i]["value"];
        let indexNgForEfectivoArrayImporte = values[i]["functionalArea"].split(".");
        let indexNgForEfectivoImporte = indexNgForEfectivoArrayImporte[indexNgForEfectivoArrayImporte.length - 1];
        if (indexNgForEfectivo != null && indexNgForEfectivoImporte == indexNgForEfectivo) {
          hasEfectivo = true;
        }
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "propina" && values[i]["value"] && values[i]["value"] > 0) {
        importePagado = importePagado - +values[i]["value"];
        importePropina = +values[i]["value"];
      }
    }


    importeDiferencia = importeAPagar - importePagado;
    if (!isNaN(importeDiferencia) && isFinite(importeDiferencia)) {
      importeDiferencia = Math.round((+importeDiferencia + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "comodin_box_propina", "comodin_box_propina", structure["id_functional_parent_initial_dsb"], -1, true, true);
      if (idFA !== null) {
        if (hasEfectivo) {
          idFA['id_functional_status_general'] = 2;
          if (importeDiferencia < 0 && !this.genericService.snackbarOpened) this.openSnackBar('Hay dinero sobrante y no puedes marcar "Cobrado", pero no puedes añadir propina si tienes indicado "Efectivo". Debes retirar el efectivo primero.', 50000, ["lightred-snackbar"]);
          importePropina = 0;
          let idFA2 = this.genericService.findFAWithTableField(1, "actividades_calendario_tickets", "propina", structure["id_functional_parent_initial_dsb"], -1, true);
          this.assignValueFunctionalArea(idFA2, 0);
        } else {
          idFA['id_functional_status_general'] = 1
          this.snackBar.dismiss();
        }
      }
      let importeTotalDecimal = importeDiferencia.toString().replace('.', ',');
      let idFA2 = this.genericService.findFAWithTableField(1, "comodin_diferencia", "comodin_diferencia_importe", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA2, importeTotalDecimal);
      if (importePropina > 0 && importeDiferencia != 0 && !hasEfectivo) this.SobranteAPropinas(structure, param, 0);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  SobranteAPropinas(structure, param, val = null) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importePropina = 0;
    let found = false;
    let found2 = false;
    if (val == null) {
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
          importePropina = importePropina + +values[i]["value"];
          found = true;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "propina" && values[i]["value"]) {
          importePropina = importePropina - +values[i]["value"];
          found2 = true;
        }
        if (found && found2) break;
      }
    } else {
      importePropina = val;
    }

    if (!isNaN(importePropina) && isFinite(importePropina)) {
      importePropina = Math.round((+importePropina + Number.EPSILON) * -100) / 100;
      let importeTotalDecimal = importePropina.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, "rest_pedidos_tickets", "propina", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  SobranteAPropinasServicios(structure, param, val = null) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importePropina = 0;
    let found = false;
    let found2 = false;
    if (val == null) {
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
          importePropina = importePropina + +values[i]["value"];
          found = true;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets" && values[i]["field"] == "propina" && values[i]["value"]) {
          importePropina = importePropina - +values[i]["value"];
          found2 = true;
        }
        if (found && found2) break;
      }
    } else {
      importePropina = val;
    }

    if (!isNaN(importePropina) && isFinite(importePropina)) {
      importePropina = Math.round((+importePropina + Number.EPSILON) * -100) / 100;
      let importeTotalDecimal = importePropina.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario_tickets", "propina", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  calcularPropina(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe_propina = 0;
    let importe_total = 0;
    let importe_pago = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_importe_total" && values[i]["value"]) {
        importe_total = +values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_importe_pago" && values[i]["value"]) {
        importe_pago = +values[i]["value"];
      }
    }

    importe_propina = importe_pago - importe_total;

    if (!isNaN(importe_propina) && isFinite(importe_propina)) {
      if (importe_propina < 0) importe_propina = 0;
      importe_propina = Math.round((+importe_propina + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importe_propina.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, "rest_pedidos_tickets", "propina", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  calculateHonorariosPorMeses(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importeTotal = 0;
    let meses = 0;
    let importeRenta = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "gest_alquiler_reservas" && values[i]["field"] == "renta_total" && values[i]["value"] && values[i]["value"] > 0) {
        importeRenta = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "gest_alquiler_reservas" && values[i]["field"] == "meses_alquiler_comodin" && values[i]["value"] && values[i]["value"] > 0) {
        meses = values[i]["value"];
      }
    }

    if (!isNaN(importeRenta) && isFinite(importeRenta)) {
      let porcentaje = 100;
      if (meses == 1 || meses == 2) porcentaje = 60;
      if (meses == 3 || meses == 4) porcentaje = 80;
      if (meses > 5) porcentaje = 120;
      importeTotal = Math.round((+importeRenta) * porcentaje / 100);
      let importeTotalDecimal = importeTotal.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, "gest_alquiler_reservas", "honorarios", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  calculateImporteManualTotalActLudic(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let tableInsert = "act_ludic_cierres_caja";
    let importeTotal = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && (values[i]["table"] == "act_ludic_cierres_caja_payment_method" || values[i]["table"] == "act_ludic_cierres_caja_payment_method_snapshot") && values[i]["field"] == "importe_manual" && values[i]["value"] && values[i]["value"] > 0) {
        importeTotal = importeTotal + +values[i]["value"];
        if (values[i]["table"] == "act_ludic_cierres_caja_payment_method_snapshot") tableInsert = "act_ludic_cierres_caja_snapshot";
      }
    }

    if (!isNaN(importeTotal) && isFinite(importeTotal)) {
      importeTotal = Math.round((+importeTotal + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importeTotal.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, tableInsert, "importe_manual", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }


  calculateImportSaldoInicialActLudic(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe5c = 0;
    let importe10c = 0;
    let importe20c = 0;
    let importe50c = 0;
    let importe1e = 0;
    let importe2e = 0;
    let importe5e = 0;
    let importe10e = 0;
    let importe20e = 0;
    let importe50e = 0;
    let importe100e = 0;
    let importe200e = 0;
    let importe500e = 0;
    let importe_saldo_inicial = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe5c = values[i]["value"] * 0.05;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe10c = values[i]["value"] * 0.10;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe20c = values[i]["value"] * 0.20;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe50c = values[i]["value"] * 0.50;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_1_euro" && values[i]["value"] && values[i]["value"] > 0) {
        importe1e = values[i]["value"] * 1;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_2_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe2e = values[i]["value"] * 2;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe5e = values[i]["value"] * 5;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe10e = values[i]["value"] * 10;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe20e = values[i]["value"] * 20;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe50e = values[i]["value"] * 50;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_100_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe100e = values[i]["value"] * 100;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_200_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe200e = values[i]["value"] * 200;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_500_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe500e = values[i]["value"] * 500;
      }
    }

    importe_saldo_inicial = importe5c + importe10c + importe20c + importe50c + importe1e + importe2e + importe5e + importe10e + importe20e + importe50e + importe100e + importe200e + importe500e;
    if (!isNaN(importe_saldo_inicial) && isFinite(importe_saldo_inicial)) {
      importe_saldo_inicial = Math.round((+importe_saldo_inicial + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importe_saldo_inicial.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, "act_ludic_cierres_caja", "importe_inicial", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  calculateImportPorParticipante(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importeTotal = 0;
    let participantes = 0;
    let importeParticipante = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "importe_final" && values[i]["value"] && values[i]["value"] > 0) {
        importeTotal = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets" && values[i]["field"] == "importe_final" && values[i]["value"] && values[i]["value"] > 0) {
        importeTotal = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_act_ludic_reservas" && values[i]["field"] == "comodin_act_ludic_participantes" && values[i]["value"] && values[i]["value"] > 0) {
        participantes = values[i]["value"];
      }
    }

    importeParticipante = importeTotal / participantes;
    if (!isNaN(importeParticipante) && isFinite(importeParticipante)) {
      importeParticipante = Math.round((+importeParticipante + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importeParticipante.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, "comodin_act_ludic_reservas", "comodin_act_ludic_total_participante", structure["id_functional_parent_initial_dsb"]);
      console.log("cooooo", idFA, importeParticipante);
      this.assignValueFunctionalArea(idFA, importeParticipante);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  calculateNewImportDiferenciaActLudic(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importeDiferencia = 0;
    let importePagado = 0;
    let importeAPagar = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "comodin_a_pagar" && values[i]["value"] && values[i]["value"] > 0) {
        importeAPagar = +values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets_payment_methods" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
        importePagado = importePagado + +values[i]["value"];
      }
    }

    importeDiferencia = importeAPagar - importePagado;
    if (!isNaN(importeDiferencia) && isFinite(importeDiferencia)) {
      importeDiferencia = Math.round((+importeDiferencia + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importeDiferencia.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, "comodin_diferencia", "comodin_diferencia_importe", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }


  calculateImportSaldoInicial(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe5c = 0;
    let importe10c = 0;
    let importe20c = 0;
    let importe50c = 0;
    let importe1e = 0;
    let importe2e = 0;
    let importe5e = 0;
    let importe10e = 0;
    let importe20e = 0;
    let importe50e = 0;
    let importe100e = 0;
    let importe200e = 0;
    let importe500e = 0;
    let importe_saldo_inicial = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe5c = values[i]["value"] * 0.05;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe10c = values[i]["value"] * 0.10;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe20c = values[i]["value"] * 0.20;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe50c = values[i]["value"] * 0.50;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_1_euro" && values[i]["value"] && values[i]["value"] > 0) {
        importe1e = values[i]["value"] * 1;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_2_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe2e = values[i]["value"] * 2;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe5e = values[i]["value"] * 5;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe10e = values[i]["value"] * 10;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe20e = values[i]["value"] * 20;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe50e = values[i]["value"] * 50;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_100_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe100e = values[i]["value"] * 100;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_200_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe200e = values[i]["value"] * 200;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_500_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe500e = values[i]["value"] * 500;
      }
    }

    importe_saldo_inicial = importe5c + importe10c + importe20c + importe50c + importe1e + importe2e + importe5e + importe10e + importe20e + importe50e + importe100e + importe200e + importe500e;
    if (!isNaN(importe_saldo_inicial) && isFinite(importe_saldo_inicial)) {
      importe_saldo_inicial = Math.round((+importe_saldo_inicial + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importe_saldo_inicial.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, "rest_pedidos_cierres_caja", "importe_inicial", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  calculateImportSaldoInicialServicios(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe5c = 0;
    let importe10c = 0;
    let importe20c = 0;
    let importe50c = 0;
    let importe1e = 0;
    let importe2e = 0;
    let importe5e = 0;
    let importe10e = 0;
    let importe20e = 0;
    let importe50e = 0;
    let importe100e = 0;
    let importe200e = 0;
    let importe500e = 0;
    let importe_saldo_inicial = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe5c = values[i]["value"] * 0.05;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe10c = values[i]["value"] * 0.10;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe20c = values[i]["value"] * 0.20;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_centimos" && values[i]["value"] && values[i]["value"] > 0) {
        importe50c = values[i]["value"] * 0.50;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_1_euro" && values[i]["value"] && values[i]["value"] > 0) {
        importe1e = values[i]["value"] * 1;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_2_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe2e = values[i]["value"] * 2;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe5e = values[i]["value"] * 5;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe10e = values[i]["value"] * 10;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe20e = values[i]["value"] * 20;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe50e = values[i]["value"] * 50;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_100_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe100e = values[i]["value"] * 100;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_200_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe200e = values[i]["value"] * 200;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_500_euros" && values[i]["value"] && values[i]["value"] > 0) {
        importe500e = values[i]["value"] * 500;
      }
    }
    console.log("DANI 00: ", importe5c, importe10c, importe20c, importe50c, importe1e, importe2e, importe5e, importe10e, importe20e, importe50e, importe100e, importe200e, importe500e);
    importe_saldo_inicial = importe5c + importe10c + importe20c + importe50c + importe1e + importe2e + importe5e + importe10e + importe20e + importe50e + importe100e + importe200e + importe500e;
    if (!isNaN(importe_saldo_inicial) && isFinite(importe_saldo_inicial)) {
      importe_saldo_inicial = Math.round((+importe_saldo_inicial + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importe_saldo_inicial.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario_cierres_caja", "importe_inicial", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  calculateImportePrecioInmueble(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let precio = 0;
    let precio_m2 = 0;
    let m2_construido = 0;

    let m2_construidosFounded = false;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "eur_m2" && values[i]["value"] && values[i]["value"] > 0) {
        precio_m2 = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && (values[i]["field"] == "m2_apublicar" || values[i]["field"] == "m2_construidos") && values[i]["value"] && values[i]["value"] > 0) {
        m2_construido = values[i]["value"];
        if (values[i]["field"] == "m2_construidos") m2_construidosFounded = true;
      }
      if (precio_m2 != 0 && m2_construido != 0 && m2_construidosFounded) break;
    }
    console.log(m2_construido, precio_m2, "vprecio_m2precio_m2precio_m2");

    precio = +m2_construido * +precio_m2;
    if (!isNaN(precio) && isFinite(precio)) {
      precio = Math.round((+precio + Number.EPSILON) * 100) / 100;
      precio = precio;
      let importeTotalDecimal = precio.toString().replace('.', ',');
      let idFA7 = this.genericService.findFAWithTableField(1, "vivienda", "precio", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA7, importeTotalDecimal);
    } else {
      console.log("El Precio del inmueble no es un número válido.");
    }
  }

  addFieldToAnotherField(structure) {
    let actualNgForId = this.getNgForId(structure);
    let idFaInitial = this.cloneVariable(structure);
    let valToChangeM = idFaInitial['internal_routing'].split(",");
    for (let i in valToChangeM) {
      let valToChange = valToChangeM[i];
      let splited0Bar = valToChange.split("/");
      // El primer valor de la barra "/" es el campo que se busca para rellenar
      // El segundo valor de la barra "/" es el campo del cual se coje el valor. Si no hay barra es porque el valor se coje del mismo idFa, ya que es un formField
      if (splited0Bar[1] !== undefined) { // Se hace por si hay que cojer el campo de un botón, que no es el mismo idFa el que tiene que llenar
        let splited0 = splited0Bar[1].split(".");
        idFaInitial = this.genericService.findFAWithTableField(splited0[0], splited0[1], splited0[2], structure["id_functional_parent_initial_dsb"], actualNgForId, false);
        valToChange = splited0Bar[0];
      }
      if (idFaInitial['form_field'] == 1 && idFaInitial["tmp_value"]) {
        let val = idFaInitial["tmp_value"];
        if (val['value']) val = val['value'];
        let splited = valToChange.split(".");
        let idFA = this.genericService.findFAWithTableField(splited[0], splited[1], splited[2], idFaInitial["id_functional_parent_initial_dsb"], actualNgForId, false);
        if (!idFA) {
          console.error('"NO SE HA ENCONTRADO EL FA CON EL CAMPO "', splited[0], splited[1], splited[2]);
          console.log(idFA, "DATOS: ", idFaInitial["id_functional_parent_initial_dsb"], actualNgForId);
        }
        else if (idFA['id_functional_type'] == 15) {
          val = !(val == '0' || val == "'0'" || val == 'false');
        }
        console.log(idFA, val, "VALOR A ASIGNAR")
        this.assignValueFunctionalArea(idFA, val);
      }
    }
  }

  concatFields(structure) {
    let res = "";
    let actualNgForId = this.getNgForId(structure);
    let idFaInitial = this.cloneVariable(structure);
    // El primer valor de la barra "/" es el campo que se busca para rellenar
    // El segundo valor de la barra "/" es el campo del cual se coje el valor. Si no hay barra es porque el valor se coje del mismo idFa, ya que es un formField
    let splitedBar = idFaInitial['internal_routing'].split("/");
    
    // Cogemos la info del campo destino
    let splitedBarDestination = splitedBar[0].split(".");
    let idFAToSave = this.genericService.findFAWithTableField(splitedBarDestination[0], splitedBarDestination[1], splitedBarDestination[2], idFaInitial["id_functional_parent_initial_dsb"], actualNgForId, false);
    
    //Cogemos la info de los campos a concatenar
    let fieldsToConcat = splitedBar[1].split(",");
    for (let i in fieldsToConcat) {
      let splitedToConcat = fieldsToConcat[i].split(".");
      let idFAToConcat = this.genericService.findFAWithTableField(splitedToConcat[0], splitedToConcat[1], splitedToConcat[2], idFaInitial["id_functional_parent_initial_dsb"], actualNgForId, false);
      if(res == "") res = idFAToConcat["tmp_value"];
      else res = res + " " + idFAToConcat["tmp_value"];
    }
    console.log(idFAToSave, res.toString());
    this.assignValueFunctionalArea(idFAToSave, res.toString());
  }

  calculateImportesGestorHipotecario(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe_compraventa = 0;
    let importe_iva_itp = 0;
    let importe_hipoteca = 0;
    let gastos_estimados_cv = 0;
    let tipo_interes = 0;
    let plazo = 0;
    let cuota_estimada = 0;
    let cuota_gastos = 0;
    let ingresos_mensuales = 0;
    let importe_final1 = 0;
    let importe_final3 = 0;
    let importe_final4 = 0;
    let falta_compra = 0;
    let fondos_propios = 0;
    let valor_tasacion = 0;
    let importe_estimado = 0;
    let cobertura = 0;
    let aportacion = 0;
    let tasacion_minima = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "compra_venta_cv" && values[i]["value"] && values[i]["value"] > 0) {
        importe_compraventa = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "impost_iva_itp" && values[i]["value"] && values[i]["value"] > 0) {
        importe_iva_itp = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "tipo_interes" && values[i]["value"] && values[i]["value"] > 0) {
        tipo_interes = values[i]["value"] / 12;
        tipo_interes = tipo_interes / 100;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "plazo_anos" && values[i]["value"] && values[i]["value"] > 0) {
        plazo = values[i]["value"] * 12;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "cuota_gastos" && values[i]["value"] && values[i]["value"] > 0) {
        cuota_gastos = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "ingresos_mensuales" && values[i]["value"] && values[i]["value"] > 0) {
        ingresos_mensuales = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "fondos_propios" && values[i]["value"] && values[i]["value"] > 0) {
        fondos_propios = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo" && values[i]["field"] == "id_encargo_cobertura_solicitada" && values[i]["value"] && values[i]["value"] > 0) {
        let idFA = this.genericService.findFAWithTableField(values[i]["database"], values[i]["table"], values[i]["field"], structure["id_functional_parent_initial_dsb"]);
        for (let k of this.genericService.selectOptions[idFA['id_query']]) {
          if (values[i]["value"] == k['value'] && k['valor'] && k['valor'] > 0) {
            cobertura = +k['valor'];
            break;
          }
        }
      }
    }

    importe_estimado = (+importe_compraventa * (cobertura / 100));
    if (!isNaN(importe_estimado) && isFinite(importe_estimado)) {
      importe_estimado = Math.round((+importe_estimado + Number.EPSILON) * 100) / 100;
      importe_hipoteca = importe_estimado;
      let importeTotalDecimal7 = importe_estimado.toString().replace('.', ',');
      let idFA7 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_encargo", "importe_estimado_hipoteca", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA7, importeTotalDecimal7);
    } else {
      console.log("El importe total no es un número válido.");
    }


    importe_final1 = (importe_compraventa * importe_iva_itp) / 100 + 2500;
    if (!isNaN(importe_final1) && isFinite(importe_final1)) {
      importe_final1 = Math.round((+importe_final1 + Number.EPSILON) * 100) / 100;
      gastos_estimados_cv = importe_final1;
      let importeTotalDecimal1 = importe_final1.toString().replace('.', ',');
      let idFA1 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_encargo", "gastos_estimados_cv", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA1, importeTotalDecimal1);
    } else {
      console.log("El importe total no es un número válido.");
    }


    importe_final3 = importe_hipoteca * (tipo_interes * (1 + tipo_interes) ** plazo) / ((1 + tipo_interes) ** plazo - 1);
    if (!isNaN(importe_final3) && isFinite(importe_final3)) {
      importe_final3 = Math.round((+importe_final3 + Number.EPSILON) * 100) / 100;
      cuota_estimada = importe_final3;
      let importeTotalDecimal3 = importe_final3.toString().replace('.', ',');
      let idFA3 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_encargo", "cuota_estimada", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA3, importeTotalDecimal3);
    } else {
      console.log("El importe total no es un número válido.");
    }

    importe_final4 = ((+cuota_gastos + +cuota_estimada) / +ingresos_mensuales) * 100;
    if (!isNaN(importe_final4) && isFinite(importe_final4)) {
      importe_final4 = Math.round((+importe_final4 + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal4 = importe_final4.toString().replace('.', ',');
      let idFA4 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_encargo", "ratio_endeudamiento", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA4, importeTotalDecimal4);
    } else {
      console.log("El importe total no es un número válido.");
    }

    falta_compra = (+importe_compraventa + +gastos_estimados_cv - +importe_hipoteca - +fondos_propios);
    if (!isNaN(falta_compra) && isFinite(falta_compra)) {
      falta_compra = Math.round((+falta_compra + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal6 = falta_compra.toString().replace('.', ',');
      let idFA6 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_encargo", "falta_compra", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA6, importeTotalDecimal6);
    } else {
      console.log("El importe total no es un número válido.");
    }

    valor_tasacion = +importe_hipoteca * (80 / 100);
    if (!isNaN(valor_tasacion) && isFinite(valor_tasacion)) {
      valor_tasacion = Math.round((+valor_tasacion + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal5 = valor_tasacion.toString().replace('.', ',');
      let idFA5 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_encargo", "ratio_tasacion", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA5, importeTotalDecimal5);
    } else {
      console.log("El importe total no es un número válido.");
    }

    aportacion = +importe_compraventa + +gastos_estimados_cv - +importe_hipoteca;
    if (!isNaN(aportacion) && isFinite(aportacion)) {
      aportacion = Math.round((+aportacion + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal6 = aportacion.toString().replace('.', ',');
      let idFA5 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_encargo", "aportacion", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA5, importeTotalDecimal6);
    } else {
      console.log("El importe total no es un número válido.");
    }
    tasacion_minima = +importe_hipoteca * (79 / 100);
    if (!isNaN(tasacion_minima) && isFinite(tasacion_minima)) {
      tasacion_minima = Math.round((+tasacion_minima + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal6 = tasacion_minima.toString().replace('.', ',');
      let idFA5 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_encargo", "tasacion_minima", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA5, importeTotalDecimal6);
    } else {
      console.log("El importe total no es un número válido.");
    }

  }

  calculateOperacionGestorHipotecario(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe_total_inicial = 0;
    let importe_total = 0;
    let plazo = 0;
    let importe_operacion = 0;
    let interes_inicial = 0;
    let interes_posterior = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo_solicitudes_operaciones" && values[i]["field"] == "importe_solicitado" && values[i]["value"] && values[i]["value"] > 0) {
        importe_operacion = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo_solicitudes_operaciones" && values[i]["field"] == "plazos" && values[i]["value"] && values[i]["value"] > 0) {
        plazo = values[i]["value"] * 12;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo_solicitudes_operaciones" && values[i]["field"] == "interes_inicial" && values[i]["value"] && values[i]["value"] > 0) {
        interes_inicial = values[i]["value"] / 12;
        interes_inicial = interes_inicial / 100;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_encargo_solicitudes_operaciones" && values[i]["field"] == "interes_posterior" && values[i]["value"] && values[i]["value"] > 0) {
        interes_posterior = values[i]["value"] / 12;
        interes_posterior = interes_posterior / 100;
      }

    }
    importe_total_inicial = importe_operacion * (interes_inicial * (1 + interes_inicial) ** plazo) / ((1 + interes_inicial) ** plazo - 1);
    if (!isNaN(importe_total_inicial) && isFinite(importe_total_inicial)) {
      importe_total_inicial = Math.round((+importe_total_inicial + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importe_total_inicial.toString().replace('.', ',');
      let idFA3 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_encargo_solicitudes_operaciones", "cuota_estimada_primer_periodo", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA3, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }

    importe_total = importe_operacion * (interes_posterior * (1 + interes_posterior) ** plazo) / ((1 + interes_posterior) ** plazo - 1);
    if (!isNaN(importe_total) && isFinite(importe_total)) {
      importe_total = Math.round((+importe_total + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importe_total.toString().replace('.', ',');
      let idFA3 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_encargo_solicitudes_operaciones", "cuota_estimada_posterior", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA3, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }

  }

  calculateIngresoTotalGestorHipotecario(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe_total = 0;
    let pagas = 0;
    let importe = 0;
    let otros_ingresos = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_ingresos" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
        importe = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_ingresos" && values[i]["field"] == "pagas" && values[i]["value"] && values[i]["value"] > 0) {
        pagas = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "asesor_hipotecario_ingresos" && values[i]["field"] == "otros" && values[i]["value"] && values[i]["value"] > 0) {
        otros_ingresos = values[i]["value"];
      }

    }
    importe_total = (+importe * (pagas / 12) + +otros_ingresos);
    if (!isNaN(importe_total) && isFinite(importe_total)) {
      importe_total = Math.round((+importe_total + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal4 = importe_total.toString().replace('.', ',');
      let idFA4 = this.genericService.findFAWithTableField(1, "asesor_hipotecario_ingresos", "total", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA4, importeTotalDecimal4);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  sumarNuevoImporte(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe_total = 0;
    let importePendiente = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_tickets_payment_methods" && values[i]["field"] == "importe" && values[i]["value"]) {
        importe_total = +values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_ticket_nuevo_importe" && values[i]["field"] == "comodin_nuevo_importe" && values[i]["value"]) {
        importePendiente = +values[i]["value"];
      }
    }

    if (!isNaN(importePendiente) && isFinite(importePendiente)) {
      importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "rest_pedidos_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
      let val = this.cloneVariable(idFA["tmp_value"]).replace(',', '.')
      if (val) importePendiente = +importePendiente + +val;
      importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importePendiente.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);

      let idFA2 = this.genericService.findFAWithTableField(1, "comodin_ticket_nuevo_importe", "comodin_nuevo_importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
      this.assignValueFunctionalArea(idFA2, '');

    }
  }

  sumarNuevoImporteServicios(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe_total = 0;
    let importePendiente = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario_tickets_payment_methods" && values[i]["field"] == "importe" && values[i]["value"]) {
        importe_total = +values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_ticket_nuevo_importe" && values[i]["field"] == "comodin_nuevo_importe" && values[i]["value"]) {
        importePendiente = +values[i]["value"];
      }
    }

    if (!isNaN(importePendiente) && isFinite(importePendiente)) {
      importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
      let val = this.cloneVariable(idFA["tmp_value"]).replace(',', '.')
      if (val) importePendiente = +importePendiente + +val;
      importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importePendiente.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);

      let idFA2 = this.genericService.findFAWithTableField(1, "comodin_ticket_nuevo_importe", "comodin_nuevo_importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
      this.assignValueFunctionalArea(idFA2, '');

    }
  }

  cargarImportePendiente(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importePendiente = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
        importePendiente = +values[i]["value"];
      }
    }

    if (!isNaN(importePendiente) && isFinite(importePendiente)) {
      importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "rest_pedidos_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
      let val = this.cloneVariable(idFA["tmp_value"]).replace(',', '.')
      if (val) importePendiente = +importePendiente + +val;
      importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importePendiente.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    }
  }

  cargarImportePendienteServicios(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importePendiente = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
        importePendiente = +values[i]["value"];
      }
    }

    if (!isNaN(importePendiente) && isFinite(importePendiente)) {
      importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
      let val = this.cloneVariable(idFA["tmp_value"]).replace(',', '.')
      if (val) importePendiente = +importePendiente + +val;
      importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importePendiente.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    }
  }

  calcPrecioTotalLineaCotizacion(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let precio = 0;
    let margen = 1;
    let tipo = 0;
    let cantidadMargen = 0;
    console.log('valueees', values)
    for (let i in values) {
      let idFaSearch = this.genericService.findElementWithId(values[i]["functionalArea"], false, false, true);
      if (idFaSearch !== null && idFaSearch['duplicate_id_functional_area'] && idFaSearch['duplicate_id_functional_area'] == structure['duplicate_id_functional_area']) {
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones_lineas" && values[i]["field"] == "precio" && values[i]["value"]) {
          precio = +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones_lineas" && values[i]["field"] == "cantidad_margen" && values[i]["value"]) {
          margen = +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones_lineas" && values[i]["field"] == "tipo" && values[i]["value"]) {
          tipo = +values[i]["value"];
        }
      }
    }

    if (!isNaN(precio) && isFinite(precio) && !isNaN(margen) && isFinite(margen)) {
      precio = Math.round((+precio + Number.EPSILON) * 100) / 100;
      let precioFinal = 0;
      if (tipo === 1) {//Valor absoluto
        precioFinal = precio + margen;
        cantidadMargen = margen;
      }
      else { //Porcentaje
        precioFinal = precio + (precio * (margen / 100));
        cantidadMargen = precio * (margen / 100);
      }

      let idFA = this.genericService.findFAWithTableField(1, "gest_log_cotizaciones_lineas", "cantidad_margen_anadir", structure['id_functional_parent_initial_dsb'], structure['duplicate_id_functional_area']);
      cantidadMargen = Math.round((+cantidadMargen + Number.EPSILON) * 100) / 100;
      let cantidadMargenDecimal = cantidadMargen.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA, cantidadMargenDecimal);

      let idFA2 = this.genericService.findFAWithTableField(1, "gest_log_cotizaciones_lineas", "precio_final", structure['id_functional_parent_initial_dsb'], structure['duplicate_id_functional_area']);
      precioFinal = Math.round((+precioFinal + Number.EPSILON) * 100) / 100;
      let precioFinalDecimal = precioFinal.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA2, precioFinalDecimal);


      console.log(precio, margen, tipo, idFA, idFA2, "idFAidFAidFAidFA", cantidadMargenDecimal, precioFinalDecimal)
    }
  }

  calcTotalesCotizacion(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let precioTotal = 0;
    let cantidadTotal = 0;
    let precioTotalFinal = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones_lineas" && values[i]["field"] == "precio" && values[i]["value"]) {
        precioTotal += +values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones_lineas" && values[i]["field"] == "precio_final" && values[i]["value"]) {
        precioTotalFinal += +values[i]["value"];
      }
    }

    if (!isNaN(precioTotal) && isFinite(precioTotal) && !isNaN(precioTotalFinal) && isFinite(precioTotalFinal)) {

      let idFA = this.genericService.findFAWithTableField(1, "gest_log_cotizaciones", "total_precio", 0);
      precioTotal = Math.round((+precioTotal + Number.EPSILON) * 100) / 100;
      let precioTotalDecimal = precioTotal.toString().replace('.', ',');
      console.log("idFA precio total: ", idFA, precioTotalDecimal);
      this.assignValueFunctionalArea(idFA, precioTotalDecimal);

      let idFA2 = this.genericService.findFAWithTableField(1, "gest_log_cotizaciones", "total_precio_final", 0);
      precioTotalFinal = Math.round((+precioTotalFinal + Number.EPSILON) * 100) / 100;
      let precioTotalFinalDecimal = precioTotalFinal.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA2, precioTotalFinalDecimal);

      let idFA3 = this.genericService.findFAWithTableField(1, "gest_log_cotizaciones", "margen_total", 0);
      cantidadTotal = precioTotalFinal - precioTotal;
      cantidadTotal = Math.round((+cantidadTotal + Number.EPSILON) * 100) / 100;
      let cantidadTotalDecimal = cantidadTotal.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA3, cantidadTotalDecimal);

      let porcentajeMargenTotal = cantidadTotal * 100 / precioTotal;
      let idFA4 = this.genericService.findFAWithTableField(1, "gest_log_cotizaciones", "porcentaje_margen_total", 0);
      porcentajeMargenTotal = Math.round((+porcentajeMargenTotal + Number.EPSILON) * 100) / 100;
      let porcentajeMargenTotalDecimal = porcentajeMargenTotal.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA4, porcentajeMargenTotalDecimal);
    }
  }

  cargarImportePendienteActLudic(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importePendiente = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
        importePendiente = +values[i]["value"];
      }
    }

    if (!isNaN(importePendiente) && isFinite(importePendiente)) {
      importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "act_ludic_reservas_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
      let val = this.cloneVariable(idFA["tmp_value"]).replace(',', '.')
      if (val) importePendiente = +importePendiente + +val;
      importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importePendiente.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    }
  }


  updatePagoTotal(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let precio = 0;
    let num_unidades = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "act_depor_reservas" && values[i]["field"] == "precio_actividad" && values[i]["value"]) {
        precio = +values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "act_depor_reservas" && values[i]["field"] == "num_unidades" && values[i]["value"]) {
        num_unidades = +values[i]["value"];
      }
    }

    let importeTotal = num_unidades * precio;
    if (!isNaN(importeTotal) && isFinite(importeTotal)) {
      importeTotal = Math.round((+importeTotal + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "act_depor_reservas", "importe_total", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
      let importeTotalDecimal = importeTotal.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
      this.genericService.paymentImport = importeTotal;
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  assignValueFunctionalArea(idFA, value) {
    this.genericService.assignValueFunctionalArea(idFA, value);
  }

  calculateNewImportTicketActLudicas(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe = 0;
    let descuento = 0;
    let tipo_descuento = 0;
    let importeTotal = 0
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "importe" && values[i]["value"] && values[i]["value"] > 0) {
        importe = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "descuento" && values[i]["value"] && values[i]["value"] > 0) {
        descuento = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "act_ludic_reservas_tickets" && values[i]["field"] == "id_descuento_unidad") {
        tipo_descuento = values[i]["value"];
      }
    }

    if (tipo_descuento == 1) {
      importeTotal = importe - descuento;
    } else if (tipo_descuento == 2) {
      importeTotal = importe - (importe * descuento / 100);
    } else {
      importeTotal = importe;
    }
    if (importeTotal < 0) importeTotal = 0;
    if (importeTotal >= 0) {
      importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;
      let idFA = this.genericService.findFAWithTableField(1, "act_ludic_reservas_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotal);
    }
  }

  assignPaymentImport(structure) {
    // let importeTotal = 0;
    let importeTotal = this.cloneVariable(structure['tmp_value']).replaceAll(',', '.');
    console.log(importeTotal, "importeTotalimporteTotalimporteTotal", structure, structure['tmp_value'])
    if (importeTotal && importeTotal > 0) {
      this.genericService.paymentImport = importeTotal;
    } else {
      console.log("El importe total no es un número válido.");
    }
  }


  relacionaCampoIdCliente(structure, param) {
    let idCliente = null;
    if (structure['tmp_value'] && structure['tmp_value'] > 0) idCliente = structure['tmp_value'];
    let idFA = this.genericService.findFAWithTableField(1, "clientes", "id", structure["id_functional_parent_initial_dsb"]);
    this.assignValueFunctionalArea(idFA, idCliente);
  }

  changeHideButtonId33028(structure) {
    let val = this.cloneVariable(structure['tmp_value']).replaceAll(",", ".");
    let idFA = this.genericService.findElementWithId(33028, false, false, true);
    if (idFA !== null) {
      if (val !== undefined && val == 0) {
        idFA['hide'] = 0;
        this.changeHide0(idFA);
      }
      else idFA['hide'] = 1;
    }
    let idFA2 = this.genericService.findElementWithId(38997, false, false, true);
    if (idFA2 !== null) {
      let val2 = 0;
      if (idFA2['tmp_value'] !== undefined) val2 = this.cloneVariable(idFA2['tmp_value']).replaceAll(",", ".");
      if ((val !== undefined && val <= 0) || (val2 !== undefined && val2 > 0)) {
        idFA2['hide'] = 0;
        this.changeHide0(idFA);
      }
      else idFA2['hide'] = 1;
    }
  }

  changeHideButtonId56976(structure) {
    let val = this.cloneVariable(structure['tmp_value']).replaceAll(",", ".");
    let idFA = this.genericService.findElementWithId(56976, false, false, true);
    if (idFA !== null) {
      if (val !== undefined && val == 0) {
        idFA['hide'] = 0;
        this.changeHide0(idFA);
      }
      else idFA['hide'] = 1;
    }
    let idFA2 = this.genericService.findElementWithId(57024, false, false, true);
    if (idFA2 !== null) {
      let val2 = 0;
      if (idFA2['tmp_value'] !== undefined) val2 = this.cloneVariable(idFA2['tmp_value']).replaceAll(",", ".");
      if ((val !== undefined && val <= 0) || (val2 !== undefined && val2 > 0)) {
        idFA2['hide'] = 0;
        this.changeHide0(idFA);
      }
      else idFA2['hide'] = 1;
    }
  }

  changeHideButtonId33149(structure) {
    let val = this.cloneVariable(structure['tmp_value']).replaceAll(",", ".");
    let idFA = this.genericService.findElementWithId(33149, false, false, true);
    if (idFA !== null) {
      if (val !== undefined && val == 0) idFA['hide'] = 0;
      else idFA['hide'] = 1;
    }
    let idFA2 = this.genericService.findElementWithId(46099, false, false, true);
    if (idFA2 !== null) {
      let val2 = 0;
      if (idFA2['tmp_value'] !== undefined) val2 = this.cloneVariable(idFA2['tmp_value']).replaceAll(",", ".");
      if ((val !== undefined && val <= 0) || (val2 !== undefined && val2 > 0)) {
        idFA2['hide'] = 0;
        this.changeHide0(idFA);
      }
      else idFA2['hide'] = 1;
    }
  }

  changeHideButtonId42364(structure) {
    let idFA = this.genericService.findElementWithId(42364, false, false, true);
    if (idFA !== null) {
      let val = this.cloneVariable(structure['tmp_value']).replaceAll(",", ".");
      if (val !== undefined && val <= 0) {
        idFA['hide'] = 0;
        this.changeHide0(idFA);
      }
      else idFA['hide'] = 1;
    }
  }

  changeHideButtonId44329(structure) {
    let val = this.cloneVariable(structure['tmp_value']).replaceAll(",", ".");
    let idFA = this.genericService.findElementWithId(44329, false, false, true);
    if (idFA !== null) {
      if (val !== undefined && val == 0) {
        idFA['hide'] = 0;
        this.changeHide0(idFA);
      }
      else idFA['hide'] = 1;
    }
    let idFA2 = this.genericService.findElementWithId(46003, false, false, true);
    if (idFA2 !== null) {
      let val2 = 0;
      if (idFA2['tmp_value'] !== undefined) val2 = this.cloneVariable(idFA2['tmp_value']).replaceAll(",", ".");
      if ((val !== undefined && val <= 0) || (val2 !== undefined && val2 > 0)) {
        idFA2['hide'] = 0;
        this.changeHide0(idFA);
      }
      else idFA2['hide'] = 1;
    }
  }

  changeHideButtonId34505(structure) {
    let idFA = this.genericService.findElementWithId(34505, false, false, true);
    if (idFA !== null) {
      let val = this.cloneVariable(structure['tmp_value']).replaceAll(",", ".");
      if (val !== undefined && val <= 0) {
        idFA['hide'] = 0;
        this.changeHide0(idFA);
      }
      else idFA['hide'] = 1;
    }
  }

  changeHideButtonId31875(structure) {
    let val = structure['tmp_value']
    if (typeof structure['tmp_value'] == "string" && structure['tmp_value'].includes(",")) val = this.cloneVariable(structure['tmp_value']).replaceAll(",", ".");
    val = +val;
    let hide = 1;
    if (val > 0) hide = 0;

    let idFA = this.genericService.findElementWithId(31875, false, false, true);
    if (idFA !== null) {
      idFA['hide'] = hide;
      this.changeHide0(idFA);
    }

    let idFA2 = this.genericService.findElementWithId(31790, false, false, true);
    if (idFA2 !== null) {
      idFA2['hide'] = hide;
      this.changeHide0(idFA);
    }
  }

  changeHide0(fa) {
    if (fa['hide'] == 0 && fa['child'] && fa['child'].length > 0) {
      for (let i in fa['child']) {
        fa['child'][i]['hide'] = fa['child'][i]['hide_initial'];
        this.changeHide0(fa['child'][i]);
      }
    }
  }

  saveTakeAwayRestaurant(structure, param, finished, refresh = 0, type = null, publicar = null) {
    if (this.checkPreviewModeFunction(finished)) {
      return;
    }
    let structure_pre = this.cloneVariable(structure);
    const v = this.insertFormData(structure, param, finished, type, publicar);
    if (v != null) {
      v.subscribe((data) => {
        if (data["errorCode"] == 0 && data["response"] && data["response"].length && data["response"].length > 0) {
          let inserts = data["response"];
          for (let i in inserts) {
            if (inserts[i]['bd_table'] == "rest_pedidos" && inserts[i]['database'] == "1" && inserts[i]['bd_field'] == "id_pedido" && inserts[i]['type'] == "insert" && inserts[i]['responseId'] && inserts[i]['responseId'] > 0) {
              this.addWindowParam(inserts[i]['responseId'], 1, "rest_pedidos", "id_pedido", structure['id_functional_parent_initial'], true, 'input');
            }
          }
          this.openSnackBar("Guardado con éxito", 6000, ["green-snackbar",]);
          this.genericService.refreshStructure();
        }
      });
    }
  }

  saveInsertedValuesAndRouting(actionPost, structure, param, finished, refresh = 0, type = null, publicar = null) {
    if (this.checkPreviewModeFunction(finished)) {
      return;
    }
    let structure_pre = this.cloneVariable(structure);
    const v = this.insertFormData(structure, param, finished, type, publicar);
    if (v != null) {
      v.subscribe((data) => {
        if (data["errorCode"] == 0 && data["response"] && data["response"].length && data["response"].length > 0) {
          let inserts = data["response"];
          for (let i in inserts) {
            if (inserts[i]['type'] == "insert" && inserts[i]['responseId'] && inserts[i]['responseId'] > 0) {
              if (actionPost == 1) this.addWindowParam(inserts[i]['responseId'], inserts[i]['database'], inserts[i]['bd_table'], inserts[i]['bd_field'], structure['id_functional_parent_initial'], false, 'output');
              else this.addWindowParam(inserts[i]['responseId'], inserts[i]['database'], inserts[i]['bd_table'], inserts[i]['bd_field'], structure['id_functional_parent_initial'], false, 'input');
            }
          }
          if (actionPost == 1) {
            this.route = structure["internal_routing"];
            this.id_route = structure["internal_routing_id_functional_area"];
            this.routingService.previousModule = structure["id_functional_parent_initial"];
            this.goRouting(this.route, false);
          } else {
            this.openSnackBar("Guardado con éxito", 6000, ["green-snackbar"]);
            this.genericService.refreshStructure();
          }
        } else {
          if (data["errorMessage"] !== null && data["errorMessage"] !== "") this.openSnackBar(data["errorMessage"], 6000, ["red-snackbar"]);
          this.finishFunction(finished);
        }
      });
    }
  }

  insertTicketNoPagadoTPV(structure, param, finished, refresh = 0, type = null, publicar = null) {
    if (this.checkPreviewModeFunction(finished)) {
      return;
    }
    let structure_pre = this.cloneVariable(structure);
    const v = this.insertFormData(structure, param, finished, type, publicar);
    if (v != null) {
      v.subscribe((data) => {
        if (data["errorCode"] == 0 && data["response"] && data["response"].length && data["response"].length > 0) {
          let inserts = data["response"];
          for (let i in inserts) {
            if (inserts[i]['bd_table'] == "rest_pedidos_tickets" && inserts[i]['database'] == "1" && inserts[i]['bd_field'] == "id_ticket" && inserts[i]['type'] == "insert" && inserts[i]['responseId'] && inserts[i]['responseId'] > 0) {
              this.addWindowParam(inserts[i]['responseId'], 1, "rest_pedidos_tickets", "id_ticket", structure['id_functional_parent_initial']);
              //this.addWindowParam(inserts[i]['responseId'], 1, "rest_pedidos_tickets", "id_ticket", structure['internal_routing_id_functional_area']);
              structure['refresh_params'] = 2;
              this.openDialog(structure, 2);
              this.openSnackBar("Ticket generado correctamente", 6000, ["green-snackbar"]);
              break;
            }
          }
        }
      });
    }
  }

  insertTicketNoPagadoActLudic(structure, param, finished, refresh = 0, type = null, publicar = null) {
    if (this.checkPreviewModeFunction(finished)) {
      return;
    }
    let structure_pre = this.cloneVariable(structure);
    const v = this.insertFormData(structure, param, finished, type, publicar);
    if (v != null) {
      v.subscribe((data) => {
        if (data["errorCode"] == 0 && data["response"] && data["response"].length && data["response"].length > 0) {
          let inserts = data["response"];
          for (let i in inserts) {
            if (inserts[i]['bd_table'] == "act_ludic_reservas_tickets" && inserts[i]['database'] == "1" && inserts[i]['bd_field'] == "id_ticket" && inserts[i]['type'] == "insert" && inserts[i]['responseId'] && inserts[i]['responseId'] > 0) {
              this.addWindowParam(inserts[i]['responseId'], 1, "act_ludic_reservas_tickets", "id_ticket", structure['id_functional_parent_initial']);
              //this.addWindowParam(inserts[i]['responseId'], 1, "act_ludic_reservas_tickets", "id_ticket", structure['internal_routing_id_functional_area']);
              structure['refresh_params'] = 2;
              this.openDialog(structure, 2);
              this.openSnackBar("Ticket generado correctamente", 6000, ["green-snackbar"]);
              break;
            }
          }
        }
      });
    }
  }

  insertTicketNoPagadoActividadesServicios(structure, param, finished, refresh = 0, type = null, publicar = null) {
    if (this.checkPreviewModeFunction(finished)) {
      return;
    }
    let structure_pre = this.cloneVariable(structure);
    const v = this.insertFormData(structure, param, finished, type, publicar);
    if (v != null) {
      v.subscribe((data) => {
        if (data["errorCode"] == 0 && data["response"] && data["response"].length && data["response"].length > 0) {
          let inserts = data["response"];
          for (let i in inserts) {
            if (inserts[i]['bd_table'] == "actividades_calendario_tickets" && inserts[i]['database'] == "1" && inserts[i]['bd_field'] == "id_ticket" && inserts[i]['type'] == "insert" && inserts[i]['responseId'] && inserts[i]['responseId'] > 0) {
              this.addWindowParam(inserts[i]['responseId'], 1, "actividades_calendario_tickets", "id_ticket", structure['id_functional_parent_initial']);
              //this.addWindowParam(inserts[i]['responseId'], 1, "actividades_calendario_tickets", "id_ticket", structure['internal_routing_id_functional_area']);
              structure['refresh_params'] = 2;
              this.openDialog(structure, 2);
              this.openSnackBar("Ticket generado correctamente", 6000, ["green-snackbar"]);
              break;
            }
          }
        }
      });
    }
  }

  sumaHoraFecha(structure, param) {
    let fecha_fin = null;
    if (structure['tmp_value']) {
      let tmpValue = this.cloneVariable(structure['tmp_value'])
      let originalDate: Date = new Date(tmpValue);
      fecha_fin = new Date(originalDate.getTime() + (60 * 60 * 1000));
      let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario", "fecha_fin", structure["id_functional_parent_initial_dsb"]);
      let dateOldVal = this.cloneVariable(idFA['tmp_value']);
      let dateold: Date = new Date(dateOldVal);
      if (!idFA['tmp_value'] || idFA['tmp_value'] == null || dateold < originalDate || idFA['myVal'] == dateOldVal) {
        fecha_fin = this.formatDate(fecha_fin);
        this.assignValueFunctionalArea(idFA, fecha_fin);
        idFA['myVal'] = this.cloneVariable(fecha_fin);
      }
    }
  }

  getPaymentData(structure, param) {
    let validReq = this.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    let paymentData = { id_payment: null, importe: null, id_empresa: this.authService.getIdEmpresa() };
    if (validReq) {
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "act_depor_reservas" && values[i]["field"] == "id_forma_pago") {
          paymentData.id_payment = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "act_depor_actividad_precio" && values[i]["field"] == "precio") {
          paymentData.importe = values[i]["value"];
        }
      }
    }
    return paymentData;
  }

  urlCheckOutReservaHotel(structure, param, first) {
    let validReq = this.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    if (validReq) {
      let fechaInicio = null;
      let fechaFin = null;
      let n_adultos = null;
      let n_ninos = 0;
      let n_bebes = 0;
      let idLanguage = "es";
      let idEmpresa = "0";
      let nombreEmpresa = "4DMOVIN";
      let idUser = "0";
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "fecha_entrada") {
          fechaInicio = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "fecha_salida") {
          fechaFin = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_adultos_habitacion") {
          n_adultos = values[i]["value"];
          if (typeof n_adultos === 'string') {
            n_adultos = parseInt(n_adultos, 10);
          }
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_ninos_habitacion") {
          n_ninos = values[i]["value"];
          if (typeof n_ninos === 'string') {
            n_ninos = parseInt(n_ninos, 10);
          }
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_bebes_habitacion") {
          n_bebes = values[i]["value"];
          if (typeof n_bebes === 'string') {
            n_bebes = parseInt(n_bebes, 10);
          }
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "idiomas" && values[i]["field"] == "label") {
          idLanguage = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "empresa" && values[i]["field"] == "id" && values[i]["value"]) {
          idEmpresa = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "empresa" && values[i]["field"] == "nombre" && values[i]["value"]) {
          nombreEmpresa = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "usuarios" && values[i]["field"] == "id" && values[i]["value"]) {
          idUser = values[i]["value"];
        }
      }


      this.authService.reservaInfo.push({ 'fecha_entrada': fechaInicio, 'fecha_salida': fechaFin, 'adultos': n_adultos, 'ninos': n_ninos, 'bebes': n_bebes, 'personas': (n_adultos) + (n_ninos) + n_bebes });
      this.authService.numPersonas = n_adultos + n_ninos + n_bebes;
      this.authService.numAdultos = n_adultos;
      this.authService.numNinos = n_ninos;
      this.authService.numBebes = n_bebes;
      const date1: Date = new Date(fechaInicio);
      const date2: Date = new Date(fechaFin);

      // Calculate the difference in milliseconds
      const differenceInMilliseconds: number = Math.abs(date2.getTime() - date1.getTime());
      const numberOfDays: number = Math.ceil(differenceInMilliseconds / (24 * 60 * 60 * 1000));

      console.log("NUMMMMMMMMMM DAYS", numberOfDays, "INFOOO", this.authService.numPersonas);
      this.authService.daysCounter = numberOfDays;

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + structure['internal_routing'] + "/sth/" + idEmpresa + "-" + idUser + "&&" + fechaInicio + "&&" + fechaFin + "&&" + n_adultos + "&&" + n_ninos + "&&" + n_bebes;
      return url;
    }
    return null;
  }

  startCheckOutReservaHotel(structure, param) {
    let url = this.urlCheckOutReservaHotel(structure, param, true);
    if (url != null) {
      localStorage.clear();
      localStorage.setItem("lastUrl", JSON.stringify(url));
      localStorage.setItem("numPersonas", JSON.stringify(this.authService.numPersonas));
      localStorage.setItem("numAdultos", JSON.stringify(this.authService.numAdultos));
      localStorage.setItem("numNinos", JSON.stringify(this.authService.numNinos));
      localStorage.setItem("numBebes", JSON.stringify(this.authService.numBebes));
      localStorage.setItem("daysCounter", JSON.stringify(this.authService.daysCounter));
      localStorage.setItem("reservaInfo", JSON.stringify(this.authService.reservaInfo));

      window.open(url);

    }
  }

  openInfoDieta(structure, param) {
    let n_dieta = null;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "medicina_clientes_dietas_periodo" && intern_data[i]["bd_field"] == "id_cliente_dieta_periodo" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        n_dieta = intern_data[i]["value"];
        break;
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "medicina_clientes_dieta" && intern_data[i]["bd_field"] == "id_cliente_dieta" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        n_dieta = intern_data[i]["value"];
        break;
      }
    }

    //Casos generals generic
    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

    let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "dieta" + "/sth/" + idEmpresa + "-0&&" + n_dieta + "&&0";
    window.open(url);
  }

  openMovimientosPersonal(structure, param) {
    let fecha_inicial = 0;
    let fecha_final = 0;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    console.log("ENTRO 0: ", intern_data);
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_inicial = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_final = intern_data[i]["value"];
        }
      }

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "movimientos-personal" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
      window.open(url);
    }
  }

  openInformacionReservas(structure, param) {
    let fecha_inicial = 0;
    let fecha_final = 0;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_inicial = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_final = intern_data[i]["value"];
        }
      }

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "informacion-reservas" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
      window.open(url);
    }
  }

  openOcupacionEstancias(structure, param) {
    let fecha_inicial = 0;
    let fecha_final = 0;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_inicial = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_final = intern_data[i]["value"];
        }
      }

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "ocupacion-estancias" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
      window.open(url);
    }
  }

  openHistoricoPorCamarero(structure, param) {
    console.log("ENTRO: ", structure);
    let fecha_inicial = 0;
    let fecha_final = 0;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    console.log("ENTRO 0: ", intern_data);
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_inicial = intern_data[i]["value"];
          console.log("ENTRO1: ", fecha_inicial);

        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_final = intern_data[i]["value"];
        }
      }

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "report-historico-por-camarero" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
      window.open(url);
    }
  }

  genericFunctionRoutingStatic(structure, param, valiadteForm = false, executeSameWindow = false, firstParams = false) {
    let initialParams = "&&0&&0";
    if(firstParams) initialParams = "";
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = true;
    if (valiadteForm) validReq = this.formsArray(structure, param);
    let fields = [];
    if (structure['internal_routing']) fields = structure['internal_routing'].split(',');
    let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + structure['internal_routing_id_functional_area'] + "/sth/" + idEmpresa + "-" + this.authService.userId + initialParams;

    if (validReq) {
      for (let i in fields) {
        let field = fields[i].split('.');
        let found = false;
        for (let i in intern_data) {
          if (intern_data[i]["id_db"] == field[0] && intern_data[i]["bd_table"] == field[1] && intern_data[i]["bd_field"] == field[2] && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
            url += "&&" + intern_data[i]["value"];
            found = true;
            break;
          }
        }
        if (!found) url += "&&0";
      }

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
      if (executeSameWindow) window.location.href = url;
      else window.open(url);
    }
  }

  openReportInfluencers(structure, param) {
    let fecha_inicial = 0;
    let fecha_final = 0;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_inicial = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_final = intern_data[i]["value"];
        }
      }

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "report-influencers" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
      window.open(url);
    }
  }

  openReportDescuentos(structure, param) {
    let fecha_inicial = 0;
    let fecha_final = 0;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_inicial = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_final = intern_data[i]["value"];
        }
      }

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "control-descuentos" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
      window.open(url);
    }
  }

  openReportActividadComercial(structure, param) {
    console.log("ENTRO: ");
    let id_usuario = 0;
    let fecha_inicial = 0;
    let fecha_final = 0;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "usuarios" && intern_data[i]["bd_field"] == "id" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          id_usuario = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_inicial = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_final = intern_data[i]["value"];
        }
      }

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "actividad-comercial" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + id_usuario + "&&" + fecha_inicial + "&&" + fecha_final;
      window.open(url);
    }
  }

  openReportDiasPorSemana(structure, param) {
    let fecha_inicial = 0;
    let fecha_final = 0;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_inicial" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_inicial = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_fecha" && intern_data[i]["bd_field"] == "comodin_fecha_final" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          fecha_final = intern_data[i]["value"];
        }
      }

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "report-historico-dias-por-semana" + "/sth/" + idEmpresa + "-0&&" + "-0&&" + "-0&&" + fecha_inicial + "&&" + fecha_final;
      window.open(url);
    }
  }

  setNumNoches(structure, param, finished) {
    let idFAInicio = this.genericService.findFAWithTableField(1, "hotel_reservas", "fecha_entrada", structure["id_functional_parent_initial_dsb"]);
    let idFAFin = this.genericService.findFAWithTableField(1, "hotel_reservas", "fecha_salida", structure["id_functional_parent_initial_dsb"]);
    let idFANumNoches = this.genericService.findFAWithTableField(1, "hotel_reservas", "num_noches", structure["id_functional_parent_initial_dsb"]);

    if (idFAInicio['tmp_value'] && idFAInicio['tmp_value'] != null && idFAInicio['tmp_value'] != "") {
      this.fecha_inicio = this.formatDate(idFAInicio['tmp_value']);
    }

    if (idFAFin['tmp_value'] && idFAFin['tmp_value'] != null && idFAFin['tmp_value'] != "") {
      this.fecha_fin = this.formatDate(idFAFin['tmp_value']);
    }

    if (this.fecha_inicio && this.fecha_fin) {

      var fecha1 = new Date(this.fecha_inicio);
      var fecha2 = new Date(this.fecha_fin);

      var tiempo1 = new Date(fecha1.toDateString()).getTime();
      var tiempo2 = new Date(fecha2.toDateString()).getTime();

      var num_noches_milisegundos = tiempo2 - tiempo1;
      var num_noches = Math.floor(num_noches_milisegundos / (1000 * 60 * 60 * 24));

      if (num_noches < 0) {
        num_noches = 0;
        this.openSnackBar("La 'Fecha entrada' es mayor que la 'Fecha salida'", 5000, ["red-snackbar"]);
      }
      this.assignValueFunctionalArea(idFANumNoches, num_noches);
    }
    this.reloadQueries(structure, param, finished);
  }

  checkPinUsuario(structure, param, finished) {
    let userId = null;
    let pin = null;
    let isCorrect = 0;
    let intern_data = this.genericService.getInternParam(structure, param);
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "empresa_usuarios_entradas" && intern_data[i]["bd_field"] == "id_usuario" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        userId = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "usuarios_comodin" && intern_data[i]["bd_field"] == "pin" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        pin = intern_data[i]["value"];
      }
    }
    if (userId != null && pin != null && userId != "" && pin != "") {
      this.endpointService
        .getPinWaiter(pin, userId)
        .subscribe((data) => {
          isCorrect = data["response"][0]["res"];
          if (isCorrect == 1) {
            this.insertUpdateForm(structure, param, finished, 1, false, false);
          }
          else {
            this.openSnackBar("Pin incorrecto", 5000, ["red-snackbar"]);
          }
        });
    }
  }

  checkCantidadDisponibleEstancias(structure, param) {
    let cantidadDisponible = null;
    let cantidadSolicitada = null;
    let precioUnitario = null;
    let precioUnitarioDecimal = null;
    let idFADisponible = this.genericService.findFAWithTableField(1, "comodin_hotel_reservas_estancias_tipo", "cantidad_disponible", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
    let idFASolicitada = this.genericService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "cantidad", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
    let idFAPrecioUnidad = this.genericService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "precio_unidad", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
    let idFAPrecioTotal = this.genericService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "precio_total", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);

    if (idFADisponible['tmp_value'] && idFADisponible['tmp_value'] != null && idFADisponible['tmp_value'] != "") {
      cantidadDisponible = idFADisponible['tmp_value'];
    }

    if (idFASolicitada['tmp_value'] && idFASolicitada['tmp_value'] != null && idFASolicitada['tmp_value'] != "") {
      cantidadSolicitada = idFASolicitada['tmp_value'];
    }

    if (idFAPrecioUnidad['tmp_value'] && idFAPrecioUnidad['tmp_value'] != null && idFAPrecioUnidad['tmp_value'] != "") {
      precioUnitario = idFAPrecioUnidad['tmp_value'];
      precioUnitarioDecimal = parseFloat(precioUnitario.replace(',', '.'));
    }

    if (cantidadDisponible != null && cantidadSolicitada != null) {
      if (cantidadSolicitada < 0) {
        cantidadSolicitada = 0;
      }
      if (cantidadSolicitada > cantidadDisponible) {
        cantidadSolicitada = cantidadDisponible;
      }
      console.log(precioUnitarioDecimal, typeof (precioUnitarioDecimal));
      if (!isNaN(precioUnitarioDecimal) && precioUnitarioDecimal !== null && precioUnitarioDecimal !== undefined) {
        let precioTotal = cantidadSolicitada * precioUnitarioDecimal;
        this.assignValueFunctionalArea(idFAPrecioTotal, precioTotal);
      }
      this.assignValueFunctionalArea(idFASolicitada, cantidadSolicitada);
      this.selectRooms(structure);
    }
  }

  checkOcupacionReservasRestaurante(structure, param, finished) {
    let numPersonas = null;
    let idUbicacion = null;
    let fecha = null;
    let hora = null;
    let idMesa = null;
    let idEmpresa = this.authService.getIdEmpresa();
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let intern_data = this.genericService.getInternParam(structure, param);
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_reservas" && intern_data[i]["bd_field"] == "num_personas" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          numPersonas = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_reservas" && intern_data[i]["bd_field"] == "id_ubicacion" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          idUbicacion = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_reservas" && intern_data[i]["bd_field"] == "fecha" && intern_data[i]["value"]) {
          fecha = this.formatDate(this.cloneVariable(intern_data[i]["value"]));
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_reservas" && intern_data[i]["bd_field"] == "hora" && intern_data[i]["value"]) {
          hora = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_reservas" && intern_data[i]["bd_field"] == "id_mesa" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
          idMesa = intern_data[i]["value"];
        }
      }
      if (numPersonas != null && fecha != null && hora != null && idEmpresa != null) {
        if (idMesa != null) {
          this.endpointService.checkOcupacionMesaReservasRestaurante(idMesa, fecha, hora, idEmpresa).subscribe((data) => {
            this.finishFunction(finished);

            console.log("responseee:", data["response"]["maximo_ocupantes"], data["response"]["hay_disponibilidad"]);

            if (data["response"]["maximo_ocupantes"] != null && data["response"]["maximo_ocupantes"] < numPersonas) {
              let restaOcupantes = numPersonas - data["response"]["maximo_ocupantes"];
              let errorMsg = "Hay " + restaOcupantes + " personas más de las que puede haber en la mesa";
              this.openSnackBar(errorMsg, 10000, ["red-snackbar"]);
            }
            else if (data["response"]["hay_disponibilidad"] != null && data["response"]["hay_disponibilidad"] > 0) {
              let errorMsg = "La mesa no está disponible";
              this.openSnackBar(errorMsg, 10000, ["red-snackbar"]);
            }
            else {
              this.openSnackBar("¡Mesa disponible!", 3000);
            }
          });
        }
        else if (idUbicacion != null) {
          this.endpointService.checkOcupacionUbiReservasRestaurante(idUbicacion, fecha, hora, idEmpresa).subscribe((data) => {
            this.finishFunction(finished);
            if (data["response"]["count_disponibilidad"] != null && data["response"]["count_disponibilidad"] < 0) {
              let restaOcupantes = -1 * data["response"]["count_disponibilidad"];
              let errorMsg = "Hay " + restaOcupantes + " personas más de las que puede haber en la ubicación " + data["response"]["nombre"];
              this.openSnackBar(errorMsg, 10000, ["red-snackbar"]);
            } else {
              this.openSnackBar("¡Hay disponibilidad para esta fecha!", 3000);
            }
          });
        }
      }
    }
  }

  aplicarDescuento(structure, param) {
    let importeAGastarMaximo = null;
    let importeAGastar = null;
    let importeTicket = null;
    let esRest = false;
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let intern_data = this.genericService.getInternParam(structure, param);
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_tickets" && intern_data[i]["bd_field"] == "importe_final") {
          importeTicket = intern_data[i]["value"];
          esRest = true;
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "actividades_calendario_tickets" && intern_data[i]["bd_field"] == "importe_final") {
          importeTicket = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_rest_tarjeta_regalo_clientes" && intern_data[i]["bd_field"] == "comodin_maximo_importe" && intern_data[i]["value"]) {
          importeAGastarMaximo = intern_data[i]["value"];
        }
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_rest_tarjeta_regalo_clientes" && intern_data[i]["bd_field"] == "comodin_importe" && intern_data[i]["value"]) {
          importeAGastar = intern_data[i]["value"];
        }
      }
      let idFAImporteRestante = this.genericService.findFAWithTableField(1, "tarjetas_regalo_clientes", "importe_restante", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
      let idFAImporteTicket = this.genericService.findFAWithTableField(1, "actividades_calendario_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
      if(esRest) {
        idFAImporteTicket = this.genericService.findFAWithTableField(1, "rest_pedidos_tickets", "importe_final", structure["id_functional_parent_initial_dsb"]);
      }
      importeAGastar = this.valueToInteger(importeAGastar);
      importeAGastarMaximo = this.valueToInteger(importeAGastarMaximo);
      importeTicket = this.valueToInteger(importeTicket);
      if (importeAGastar > importeAGastarMaximo) {
        this.openSnackBar("El importe que quiere gastar es mayor al disponible", 4000, ["lightred-snackbar"]);
      } else if (importeAGastar > importeAGastarMaximo) {
        this.openSnackBar("El importe que quiere gastar es mayor al disponible", 4000, ["lightred-snackbar"]);
      } else {
        let res = importeTicket - importeAGastar;
        if (res < 0) {
          this.assignValueFunctionalArea(idFAImporteRestante, (importeAGastarMaximo - importeAGastar - res));
          this.assignValueFunctionalArea(idFAImporteTicket, 0);
        }
        else {
          this.assignValueFunctionalArea(idFAImporteRestante, (importeAGastarMaximo - importeAGastar));
          this.assignValueFunctionalArea(idFAImporteTicket, res);
        }
      }
    }
  }

  valueToInteger(value) {
    if (!value) return 0;
    value = value.toString().replace(',', '.');
    value = +value;
    if (value && value > 0) {
      return value;
    } else {
      return 0;
    }
  }

  oneStepPayment(structure, param, finished, method = 5) {
    let idPedido = null;
    let propina = 0;
    let importe_pagado = 0;
    let importe_ticket = 0;
    let idEmpresa = this.authService.getIdEmpresa();
    let idTicket = null;
    let idCreador = null;
    let imprime = null;

    let intern_data = this.genericService.getInternParam(structure, param);
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPedido = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_tickets" && intern_data[i]["bd_field"] == "propina" && intern_data[i]["value"]) {
        propina = this.cloneVariable(intern_data[i]["value"]).toString().replace(',', '.');
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_rest_pedidos" && intern_data[i]["bd_field"] == "comodin_importe_pago" && intern_data[i]["value"]) {
        importe_pagado = this.cloneVariable(intern_data[i]["value"]).toString().replace(',', '.');
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_rest_pedidos" && intern_data[i]["bd_field"] == "comodin_importe_total" && intern_data[i]["value"]) {
        importe_ticket = this.cloneVariable(intern_data[i]["value"]).toString().replace(',', '.');
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_creador" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idCreador = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_comodin" && intern_data[i]["bd_field"] == "rest_imprimir_comodin" && intern_data[i]["value"]) {
        imprime = intern_data[i]["value"];
      }
    }

    if (idPedido > 0) {
      this.endpointService.insertTicketPedido(idPedido, method, propina, idEmpresa, idCreador, importe_pagado, importe_ticket).subscribe((data) => {
        this.finishFunction(finished);
        if (data['response']) idTicket = data['response'];
        //Error id_ticket = -2 --> no hay ninguna caja abierta
        if (idTicket == null || idTicket == -1) {
          this.openSnackBar("Parece que el cobro no se ha podido procesar", 4000, ["lightred-snackbar"]);
        }
        else if (idTicket == -2) {
          this.openSnackBar("No hay cajas abiertas", 4000, ["lightred-snackbar"]);
        }
        else if (idTicket == -3) {
          this.openSnackBar("El importe pagado debe ser superior al importe del ticket", 4000, ["lightred-snackbar"]);
        }
        else {
          if (imprime == 1) {
            this.printTicketService.printTicketRestaurant(idTicket); // TODO ALEIX: Solo si la empresa lo quiere
          }
          this.openCashRest();
          this.route = structure["internal_routing"];
          this.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule = structure["id_functional_parent_initial"];
          this.goRouting(this.route, true);
        }
      });
    }
    else this.openSnackBar("No se ha encontrado ningún pedido a imprimir", 4000, ["lightred-snackbar"]);
  }

  calculateIVA(structure, param) {
    let iva = null;
    let precioBase = null;
    let ivaCalculado = null;
    let idFAIvaCalculado = this.genericService.findFAWithTableField(1, "facturas_recibos", "iva_calculado", structure["id_functional_parent_initial_dsb"]);
    let intern_data = this.genericService.getInternParam(structure, param);

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "facturas_recibos" && intern_data[i]["bd_field"] == "iva" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        iva = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "facturas_recibos" && intern_data[i]["bd_field"] == "base" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        precioBase = intern_data[i]["value"];
      }
      if (iva != null && precioBase != null) {
        ivaCalculado = iva * precioBase / 100;
        this.assignValueFunctionalArea(idFAIvaCalculado, ivaCalculado);
        break;
      }
    }
  }

  setPrecioBase(structure, param) {
    let precioOportunidad = null;
    let idFAPrecioBaseFactura = this.genericService.findFAWithTableField(1, "facturas_recibos", "base", structure["id_functional_parent_initial_dsb"]);
    let intern_data = this.genericService.getInternParam(structure, param);

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "clientes_oportunidades" && intern_data[i]["bd_field"] == "precio" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        precioOportunidad = intern_data[i]["value"];
        this.assignValueFunctionalArea(idFAPrecioBaseFactura, precioOportunidad);
        break;
      }
    }
  }

  setPrecioTotal(structure, param) {
    let precioBase = null;
    let ivaCalculado = null;
    let idFAPrecioTotalFactura = this.genericService.findFAWithTableField(1, "facturas_recibos", "total", structure["id_functional_parent_initial_dsb"]);
    let intern_data = this.genericService.getInternParam(structure, param);

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "facturas_recibos" && intern_data[i]["bd_field"] == "base" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        precioBase = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "facturas_recibos" && intern_data[i]["bd_field"] == "iva_calculado" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        ivaCalculado = intern_data[i]["value"];
      }
      if (ivaCalculado != null && precioBase != null) {
        let total = ivaCalculado + precioBase;
        this.assignValueFunctionalArea(idFAPrecioTotalFactura, total);
        break;
      }
    }
  }

  sumarImporteLinea(structure, param) {
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let precioUnitario = null;
    let unidades = null;
    let iva = null;
    let ngForIndex = null;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "facturas_lineas" && values[i]["field"] == "precio_unitario" && values[i]["value"]) {
        precioUnitario = +values[i]["value"];
        ngForIndex = parseInt(values[i]['functionalArea'].split('.')[1]);
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "facturas_lineas" && values[i]["field"] == "iva" && values[i]["value"]) {
        iva = +values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "facturas_lineas" && values[i]["field"] == "unidades" && values[i]["value"]) {
        unidades = +values[i]["value"];
      }
    }

    if (!isNaN(precioUnitario) && isFinite(precioUnitario) && !isNaN(unidades) && isFinite(unidades) && !isNaN(iva) && isFinite(iva)) {
      precioUnitario = Math.round((+precioUnitario + Number.EPSILON) * 100) / 100;
      let precioFinal = precioUnitario * unidades + (precioUnitario * unidades * iva / 100);
      let idFA = this.genericService.findFAWithTableField(1, "facturas_lineas", "precio", structure["id_functional_parent_initial_dsb"], ngForIndex);
      precioFinal = Math.round((+precioFinal + Number.EPSILON) * 100) / 100;
      let precioFinalDecimal = precioFinal.toString().replace('.', ',');
      this.assignValueFunctionalArea(idFA, precioFinalDecimal);
    }

    /*
    let idFAPrecioTotalLinea = this.genericService.findFAWithTableField(1, "facturas_lineas", "precio", structure["id_functional_parent_initial_dsb"]);
    let intern_data = this.genericService.getInternParam(structure, param);;
    let iva = null;
    let precioUnitario = null;
    let unidades = null;
    for (let i in intern_data) {
      if (intern_data[i]["database"] == 1 && intern_data[i]["table"] == "facturas_lineas" && intern_data[i]["field"] == "iva" && intern_data[i]["value"]) {
        iva = intern_data[i]["value"];
      }
      if (intern_data[i]["database"] == 1 && intern_data[i]["table"] == "facturas_lineas" && intern_data[i]["field"] == "precio_unitario" && intern_data[i]["value"]) {
        precioUnitario = intern_data[i]["value"];
      }
      if (intern_data[i]["database"] == 1 && intern_data[i]["table"] == "facturas_lineas" && intern_data[i]["field"] == "unidades" && intern_data[i]["value"]) {
        unidades = intern_data[i]["value"];
      }
      if(iva != null && precioUnitario != null && unidades != null) {
        let total = precioUnitario * iva /100 * unidades;
        this.assignValueFunctionalArea(idFAPrecioTotalLinea, total);
        break;
      }
    }
    */
  }

  fillEmailContent(structure, param) {
    let idFAContent = this.genericService.findFAWithTableField(1, "send_buffer_email", "content", structure["id_functional_parent_initial_dsb"]);
    let intern_data = this.genericService.getInternParam(structure, param);
    let nombre = "";
    let apellidos = "";
    let nombre_form = "";
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "clientes" && intern_data[i]["bd_field"] == "nombre" && intern_data[i]["value"]) {
        nombre = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "clientes" && intern_data[i]["bd_field"] == "apellidos" && intern_data[i]["value"]) {
        apellidos = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "forms_mkt_empresas" && intern_data[i]["bd_field"] == "nombre" && intern_data[i]["value"]) {
        nombre_form = intern_data[i]["value"];
      }
    }
    if (nombre != null && apellidos != null) {
      let mensaje = "Hola, " + nombre + " " + apellidos + " ha contestado al formulario " + nombre_form + ".";
      this.assignValueFunctionalArea(idFAContent, mensaje);
    }
  }

  calcularTotalProducto(structure, param) {
    let idFATotal = this.genericService.findFAWithTableField(1, "orders_cart_product", "total", structure["id_functional_parent_initial_dsb"]);
    let intern_data = this.genericService.getInternParam(structure, param);
    let cantidad = 0;
    let precio = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "orders_cart_product" && intern_data[i]["bd_field"] == "quantity" && intern_data[i]["value"]) {
        cantidad = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "orders_cart_product" && intern_data[i]["bd_field"] == "price" && intern_data[i]["value"]) {
        precio = intern_data[i]["value"];
      }
    }
    if (cantidad != 0 && precio != 0) {
      this.assignValueFunctionalArea(idFATotal, cantidad * precio);
    }
  }

  calculateAndChangeTicketPrice(structure, param) {
    let idFATotal = this.genericService.findFAWithTableField(1, "orders_cart_product", "total", structure["id_functional_parent_initial_dsb"]);
    let intern_data = this.genericService.getInternParam(structure, param);
    let importeAGastar = 0;
    let saldoRestante = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_rest_tarjeta_regalo_clientes" && intern_data[i]["bd_field"] == "comodin_importe" && intern_data[i]["value"]) {
        importeAGastar = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_tarjeta_regalo_clientes" && intern_data[i]["bd_field"] == "importe_restante" && intern_data[i]["value"]) {
        saldoRestante = intern_data[i]["value"];
      }
    }
    if (importeAGastar != 0 && saldoRestante != 0 && saldoRestante >= importeAGastar) {
      // Buscar idFA preu total ticket
      // Restar a saldo restant l'import a gastar
      // Assignar preu total la resta del preu total i import a gastar. Tmb assignar la resta del saldo restant i l'import a gastar a saldo restante
      // this.assignValueFunctionalArea(idFATotal, cantidad * precio);
    }
  }

  copyServicePrice(structure, param) {
    let idFAPrecioOportunidad = this.genericService.findFAWithTableField(1, "clientes_oportunidades", "precio", structure["id_functional_parent_initial_dsb"]);
    let intern_data = this.genericService.getInternParam(structure, param);
    let precio = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "clientes_oportunidades_comodin" && intern_data[i]["bd_field"] == "precio_comodin" && intern_data[i]["value"]) {
        precio = intern_data[i]["value"];
        break;
      }
    }
    if (precio != 0) {
      this.assignValueFunctionalArea(idFAPrecioOportunidad, precio);
    }
  }

  compartirInmueble(structure, param, finished, compartirUnoSolo = 0) {
    let idFAContent = this.genericService.findFAWithTableField(1, "send_buffer_email", "content", structure["id_functional_parent_initial_dsb"]);
    let validReq = this.formsArray(structure, param, false);
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    let mensaje = "";
    let numInm = 0;
    let esCotizacion = 0;

    if (validReq) {
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "send_buffer_email" && values[i]["field"] == "content" && values[i]["value"]) {
          mensaje = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id" && values[i]["value"] && values[i]["value"][0] > 0) {
          numInm = values[i]["value"].length;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones" && values[i]["field"] == "id_cotizacion" && values[i]["value"] && values[i]["value"][0] > 0) {
          esCotizacion = 1;
        }
      }
      if (mensaje != null) {
        if (compartirUnoSolo == 1) {
          if (esCotizacion == 1) {
            let link = this.buildURLFichaCotizacion(-1);
            mensaje += " <br><a href='" + link + "' traget='_blank'>Ficha cotización</a>";
          } else {
            let link = this.buildURLFichaInmueble(-1);
            mensaje += " <br><a href='" + link + "' traget='_blank'>Ficha inmueble</a>";
          }
        }
        else {
          for (let j = 0; j < numInm; j++) {
            let link = this.buildURLFichaInmueble(j);
            mensaje += " <br><a href='" + link + "' traget='_blank'>Ficha inmueble</a>";
          }
        }
        this.assignValueFunctionalArea(idFAContent, mensaje);
        setTimeout(() => {
          this.insertUpdateForm(structure, param, finished, 4);
        }, 500);
      }
    }
  }

  copyLinkForm(structure, param, iframe = 0) {
    let idForm = 0;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "forms_mkt_empresas" && intern_data[i]["bd_field"] == "id_form" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          idForm = intern_data[i]["value"];
        }
      }
    }
    let textToCopy = Values.ROOT_URL + "/" + idLanguage + "/" + nombreEmpresa + "/contacto-web/sth/" + idEmpresa + "&&" + idForm;
    if (iframe == 1) {
      textToCopy = '<iframe style="width: 100%;" src="' + textToCopy + '" frameBorder="0"></iframe>'
    }
    this.clipboard.copy(textToCopy);
    this.openSnackBar("Copiado en el portapapeles", 3000, ["green-snackbar"]);
  }

  openIncidenciaReserva(structure, param) {
    let idReserva = null;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "gest_alquiler_reservas" && intern_data[i]["bd_field"] == "id_reserva" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idReserva = intern_data[i]["value"];
        break;
      }
    }

    //Casos generals generic
    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

    let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "incidencia-reserva" + "/sth/" + idEmpresa + "-0&&" + idReserva + "&&0";
    window.open(url);
  }

  openContratoReserva(structure, param, from = 1) {
    let idContrato = null;
    let idPropietario = null;
    let idInquilino = null;
    let route = null;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    console.log(intern_data, "intern_dataintern_data");
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "gest_alquiler_contratos" && intern_data[i]["bd_field"] == "id_contrato" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idContrato = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "empresa_contratos_tipo" && intern_data[i]["bd_field"] == "url_statichtml" && intern_data[i]["value"]) {
        route = intern_data[i]["value"];
      }
      if (from == 2 && intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "id_propietario_comodin" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPropietario = intern_data[i]["value"];
      }
      if (from == 3 && intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "gest_alquiler_reservas_clientes" && intern_data[i]["bd_field"] == "id_cliente" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idInquilino = intern_data[i]["value"];
      }
    }

    //Casos generals generic
    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

    let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + route + "/sth/" + idEmpresa + "-" + this.authService.userId + "&&" + idContrato + "&&" + idPropietario + "&&" + idInquilino;
    window.open(url);
  }

  verCobro(structure, param) {
    let idCobro = null;
    let route = 'cobro-servicios';
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    console.log(intern_data, "intern_dataintern_data");
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "facturas_recibos" && intern_data[i]["bd_field"] == "id_factura_recibo" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idCobro = intern_data[i]["value"];
      }
    }

    //Casos generals generic
    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

    let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + route + "/sth/" + idEmpresa + "-0&&" + idCobro;
    window.open(url);
  }

  construirDireccion(structure, param) {
    let tipoVia = null;
    let nombreVia = null;
    let numeroVivienda = null;
    let bloqueVivienda = null;
    let escaleraVivienda = null;
    let plantaVivienda = null;
    let puertaVivienda = null;
    let idFAsDireccion = null;
    let intern_data = this.genericService.getInternParam(structure, param);
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "tipo_via_comodin" && intern_data[i]["value"]) {
        tipoVia = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "nombre_via_comodin" && intern_data[i]["value"]) {
        nombreVia = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "numero_vivienda_comodin" && intern_data[i]["value"]) {
        numeroVivienda = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "bloque_vivienda_comodin" && intern_data[i]["value"]) {
        bloqueVivienda = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "escalera_vivienda_comodin" && intern_data[i]["value"]) {
        escaleraVivienda = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "planta_vivienda_comodin" && intern_data[i]["value"]) {
        plantaVivienda = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "puerta_vivienda_comodin" && intern_data[i]["value"]) {
        puertaVivienda = intern_data[i]["value"];
      }
    }

    let result = tipoVia + " " + nombreVia + " Nº " + numeroVivienda;
    if (bloqueVivienda != null) result += (" Bloq. " + bloqueVivienda);
    if (escaleraVivienda != null) result += (" Esc. " + escaleraVivienda);
    if (plantaVivienda != null) result += (" Plt. " + plantaVivienda);
    if (puertaVivienda != null) result += (" Pta. " + puertaVivienda);

    if (structure['internal_routing']) this.genericService.findElementWithIdAndSetStatus(structure['internal_routing'], 2);
    //console.log("structure['internal_routing_id_functional_area']: ", structure['internal_routing_id_functional_area']);
    idFAsDireccion = structure['internal_routing_id_functional_area'].split(",");
    console.log("idFAsDireccion: ", idFAsDireccion);
    for (let i in idFAsDireccion) {
      let idFADir = this.genericService.findElementWithId(idFAsDireccion[i], false, false, true);
      console.log("i: ", i, idFADir);
      if (idFADir) {
        if (structure['id_functional_parent_initial_dsb'] && structure['id_functional_parent_initial_dsb'] > 0 &&
          (!idFADir['id_functional_parent_initial_dsb'] || idFADir['id_functional_parent_initial_dsb'] == 0 || idFADir['id_functional_parent_initial_dsb'] != structure['id_functional_parent_initial_dsb'])) {
          this.dialogRef.close(structure);
        }
        if (idFAsDireccion.length == 1 || !idFADir['tmp_value']) this.assignValueFunctionalArea(idFADir, result);
      }
    }

  }

  cleanDieta(structure, param) {
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);;
    let n_dieta = null;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "medicina_clientes_dietas_periodo" && values[i]["field"] == "id_dieta" && values[i]["value"] && values[i]["value"] > 0) {
        n_dieta = values[i]["value"];
        break;
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "medicina_clientes_dieta" && values[i]["field"] == "id_cliente_dieta" && values[i]["value"] && values[i]["value"] > 0) {
        n_dieta = values[i]["value"];
        break;
      }
    }

    if (n_dieta != null) {

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "dieta" + "/sth/" + idEmpresa + "-0&&" + n_dieta + "&&0";
      window.location.href = url;
    }
  }

  calculateImporteManualTotalRest(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let tableInsert = "rest_pedidos_cierres_caja";
    let importeTotal = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && (values[i]["table"] == "rest_pedidos_cierres_caja_payment_method" || values[i]["table"] == "rest_pedidos_cierres_caja_payment_method_snapshot") && values[i]["field"] == "importe_manual" && values[i]["value"] && values[i]["value"] > 0) {
        importeTotal = importeTotal + +values[i]["value"];
        if (values[i]["table"] == "rest_pedidos_cierres_caja_payment_method_snapshot") tableInsert = "rest_pedidos_cierres_caja_snapshot";
      }
    }

    if (!isNaN(importeTotal) && isFinite(importeTotal)) {
      importeTotal = Math.round((+importeTotal + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = importeTotal.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, tableInsert, "importe_manual", structure["id_functional_parent_initial_dsb"]);
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("El importe total no es un número válido.");
    }
  }

  calculateImporteIVAEncargo(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let importe = 0;
    let iva = 0;
    let importeTotal = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "encargo" && values[i]["field"] == "importe_sin_iva" && values[i]["value"] && values[i]["value"] > 0) {
        importe = values[i]["value"];
      }
      if (values[i]["database"] == 1 && values[i]["table"] == "encargo" && values[i]["field"] == "porcentaje_iva" && values[i]["value"] && values[i]["value"] > 0) {
        iva = values[i]["value"];
      }
    }

    if (importe < 0) importe = 0;
    if (iva < 0) iva = 0;
    if (importe >= 0 && iva >= 0) {
      importe = +importe;
      iva = +iva;
      importeTotal = +(importe * iva / 100) + importe;
      if (!isNaN(importeTotal) && isFinite(importeTotal)) {
        importeTotal = Math.round((+importeTotal + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importeTotal.toString().replace('.', ',');
        let idFA = this.genericService.findFAWithTableField(1, "encargo", "importe_con_iva", structure["id_functional_parent_initial_dsb"]);
        this.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    }
  }

  public agendaEstadoInitUpdate(structure, param) {
    if (!structure['estadoInit'] || structure['estadoInit'] == undefined) {
      let validReq = this.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
      let values = this.cloneVariable(valuesOriginal);
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "actividades_calendario" && values[i]["field"] == "estado" && values[i]["value"] && values[i]["value"] > 0) {
          if (values[i]["value"] != 1 && values[i]["value"] != 4) {
            //this.genericService.findElementWithIdAndSetStatus(34477, 2); //button guardar
            //this.genericService.findElementWithIdAndSetStatus(53810, 2); //button guardar
            this.genericService.findElementWithIdAndSetStatus(34233, 2); //button firmar
            structure['id_functional_status_general'] = 3;
            this.openSnackBar("¡Esta actividad no se debe modificar porque no está ni Abierta ni En proceso!", 7000, ["blue-snackbar",]);
          }
          break;
        }
      }
      structure['estadoInit'] = true;
    }
  }

  newPeriodoDietaSelected(structure, param) {
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let periodo = 0;
    let n_dieta = null;
    for (let i in values) {
      if (periodo == 0 && values[i]["database"] == 1 && values[i]["table"] == "medicina_clientes_dietas_periodo" && values[i]["field"] == "id_cliente_dieta_periodo" && values[i]["value"] && values[i]["value"] > 0) {
        periodo = values[i]["value"];
      }
      if (n_dieta == null && values[i]["database"] == 1 && values[i]["table"] == "medicina_clientes_dietas_periodo" && values[i]["field"] == "id_dieta" && values[i]["value"] && values[i]["value"] > 0) {
        n_dieta = values[i]["value"];
      }
      if (n_dieta == null && values[i]["database"] == 1 && values[i]["table"] == "medicina_clientes_dieta" && values[i]["field"] == "id_cliente_dieta" && values[i]["value"] && values[i]["value"] > 0) {
        n_dieta = values[i]["value"];
      }
    }

    if (periodo > 0 && n_dieta != null) {

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "dieta" + "/sth/" + idEmpresa + "-0&&" + n_dieta + "&&" + periodo;
      window.location.href = url;
    }
  }

  addProductsToNotes(structure, param) {
    let validReq = this.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.tmpArray));

    if (validReq) {
      let id_empresa = this.authService.getIdEmpresa();
      let id_producto = null;
      let nombre_fichero = null;

      let tablesOpciones = ["rest_pedidos_productos_opciones_medidas", "rest_pedidos_productos_opciones_suplementos", "rest_pedidos_productos_opciones_opciones", "rest_pedidos_productos_opciones_preparacion"];

      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_productos" && values[i]["field"] == "cantidad") {
          this.authService.productsCounter += +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_productos" && values[i]["field"] == "importe") {
          this.authService.productsPriceCounter += +values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_productos" && values[i]["field"] == "id_producto") {
          id_producto = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_productos_imagenes" && values[i]["field"] == "nombre_fichero") {
          nombre_fichero = values[i]["value"];
        }
        if (values[i]["database"] == 1 && tablesOpciones.includes(values[i]["table"])) {
          values[i]["value_label"] = [];
          for (let t of tablesOpciones) {
            if (values[i]["database"] == 1 && values[i]["table"] == t && values[i]["field"] == "id_opcion" && values[i]["value"] != null) {
              // console.log("HELLOW ", t, " . ", values[i]["value"]);
              if (!values[i]["value"][0]) values[i]["value"] = [values[i]["value"]];
              for (let j of values[i]["value"]) {
                let idFA = this.genericService.findFAWithTableField(1, t, "id_opcion", structure["id_functional_parent_initial_dsb"]);
                for (let k of this.genericService.selectOptions[idFA['id_query']]) {
                  if (j == k["value"]) values[i]["value_label"].push(k['text']);
                }
              }
            }
          }
        }
      }
      //      let url = "../empresas/restaurantes/"+ id_empresa + "/imagenes/productos/" +  id_producto + "/"+ nombre_fichero;
      let url = "https://movin.cloud/empresas/restaurantes/" + id_empresa + "/imagenes/productos/" + id_producto + "/" + nombre_fichero;
      values.push({ database: 1, table: "rest_productos_imagenes", field: "url", value: url });
      this.authService.arrayProductos.push(values);
      this.authService.productsPriceCounter = Math.round((this.authService.productsPriceCounter + Number.EPSILON) * 100) / 100;
      this.authService.productsPriceCounterLabel = this.cloneVariable(this.authService.productsPriceCounter).toString().replace(".", ",");

      localStorage.setItem("productsCounter", JSON.stringify(this.authService.productsCounter));
      localStorage.setItem("productsPriceCounter", JSON.stringify(this.authService.productsPriceCounter));
      localStorage.setItem("productsPriceCounterLabel", JSON.stringify(this.authService.productsPriceCounterLabel));
      localStorage.setItem("arrayProductos", JSON.stringify(this.authService.arrayProductos));
      this.dialog.closeAll();
    }
  }

  updateProducts(finished) {
    /*
      console.log("ARRAY PARA GUARDAR: ", this.arrayProductos);
      let id_pedido = null;
      let id_producto = null;
      let n_adultos = null;
      let n_ninos = null;
      let n_bebes = null;
      for (let producto in this.arrayProductos) {
      //  for 
      }*/
  }

  selectTarjetaRegalo(structure) {
    let idTarjeta = null;
    let paramIntern = [];
    let intern_data = this.genericService.getInternParam(structure, paramIntern);

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "tarjetas_regalo" && intern_data[i]["bd_field"] == "id_tarjeta") {
        idTarjeta = intern_data[i]["value"];
        break;
      }
    }
    let idFA = this.genericService.findFAWithTableField(1, "tarjetas_regalo_clientes", "id_tarjeta", structure["id_functional_parent_initial_dsb"]);
    this.assignValueFunctionalArea(idFA, idTarjeta);
  }

  selectRooms(structure) {
    let paramIntern = [];
    let intern_data = this.genericService.getInternParam(structure, paramIntern);

    let room = null;
    let name = null;
    let precio = null;
    let cantidad = null;
    let foundedRoom = false;
    let foundedName = false;
    let foundedPrecio = false;
    let foundedCantidad = false;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "hotel_estancias_tipo" && intern_data[i]["bd_field"] == "id_tipo") {
        room = intern_data[i]["value"];
        foundedRoom = true;
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "hotel_estancias_tipo" && intern_data[i]["bd_field"] == "nombre") {
        name = intern_data[i]["value"];
        foundedName = true;
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "hotel_estancias_tipo_precios" && (intern_data[i]["bd_field"] == "precio_base" || intern_data[i]["bd_field"] == "precio_comodin")) {
        precio = intern_data[i]["value"];
        foundedPrecio = true;
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "hotel_reservas_estancias_tipo" && intern_data[i]["bd_field"] == "cantidad") {
        cantidad = intern_data[i]["value"];
        foundedCantidad = true;
      }
      if (foundedPrecio && foundedRoom && foundedName && foundedCantidad) break;
    }

    if (room != null && precio != null) {
      precio = parseFloat(precio.replace(',', '.'));
      let idFA = this.genericService.findFAWithTableField(1, "comodin_hotel_reservas_estancias_tipo", "id_tipo_estancia", structure["id_functional_parent_initial_dsb"]);
      let actualVal = idFA["form"].get([idFA["id_functional_area"] + "-" + idFA["bd_field"]])["value"];
      let founded = false;
      if (actualVal && actualVal != '' && actualVal != undefined && actualVal.length != undefined) {
        console.log(actualVal, room, "ROOMS1");
        const index = this.authService.arrayEstancias.findIndex(estancia => estancia.nombre === name);
        for (let i in actualVal) {
          if (actualVal[i] == room && this.authService.arrayEstancias[index].cantidad > cantidad) {
            founded = true;
            actualVal.splice(i, 1);
            this.removeEstanciaHotel(room, name, precio, cantidad);
            this.openSnackBar("¡Habitación eliminada de tu pedido!", 5000, ["red-snackbar"]);
            break;
          }
        }
        if (!founded) {
          console.log("ROOM ADDED ", room, "to", actualVal);
          actualVal.push(room);
          this.addEstanciaHotel(room, name, precio, cantidad);
          this.openSnackBar("¡Habitación añadida a tu pedido!", 5000, ["green-snackbar"]);
        }
      } else {
        actualVal = [room];
        this.addEstanciaHotel(room, name, precio, cantidad);
        this.openSnackBar("¡Habitación añadida a tu pedido!", 5000, ["green-snackbar"]);
      }
      console.log(actualVal, room, "ROOMS2");
      this.assignValueFunctionalArea(idFA, actualVal);
    }
  }

  selectActivities(structure, finished) {
    let paramIntern = [];
    let intern_data = this.genericService.getInternParam(structure, paramIntern);

    let actividad = null;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "hotel_actividades" && intern_data[i]["bd_field"] == "id_actividad") {
        actividad = intern_data[i]["value"];
        break;
      }
    }

    if (actividad != null) {
      let idFA = this.genericService.findFAWithTableField(1, "hotel_actividades", "id_actividad", structure["id_functional_parent_initial_dsb"]);
      let actualVal = idFA["form"].get([idFA["id_functional_area"] + "-" + idFA["bd_field"]])["value"];
      let founded = false;
      if (actualVal && actualVal != '' && actualVal != undefined && actualVal.length != undefined) {
        for (let i in actualVal) {
          if (actualVal[i] == actividad) {
            founded = true;
            actualVal.splice(i, 1);
            this.openSnackBar("¡Actividad eliminada de tu reserva!", 5000, ["red-snackbar"]);
            break;
          }
        }
        if (!founded) {
          actualVal.push(actividad);
          this.openSnackBar("¡Actividad añadida de tu reserva!", 5000, ["green-snackbar"]);
        }
      } else {
        actualVal = [actividad];
        this.openSnackBar("¡Habitación añadida a tu pedido!", 5000, ["green-snackbar"]);
      }
      this.assignValueFunctionalArea(idFA, actualVal);
    }
    this.finishFunction(finished);
  }

  sendEmailInmueble(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      this.endpointService
        .sendEmailInmueble(
          values,
          this.authService.userId,
          this.authService.getIdEmpresa()
        )
        .subscribe((data) => { });
    } else {
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  getTextInmueble(structure, param, finished, type) {
    let idCompany = this.authService.empresaId;
    let language = null;
    let idProperty = null;
    let validReq = this.formsArray(structure, param, false);
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    let table = null;
    let field = null;

    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        language = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda" &&
        values[i]["field"] == "id"
      ) {
        idProperty = values[i]["value"];
      }
    }

    if (type == 1 || type == 4) {
      table = "whatsapp_cliente_comodin";
      field = "mensaje";
    } else if (type == 2 || type == 5) {
      table = "send_buffer_email";
      field = "content";
      //table = "email_comodin";
      //field = "mensaje";
    } else if (type == 3 || type == 6) {
      table = "send_buffer_email";
      field = "subject";
      //table = "email_comodin";
      //field = "asunto";
    }

    let value = -1;
    if (type == 1 || type == 2 || type == 3) {
      value = idCompany;
    } else if (type == 4 || type == 5 || type == 6) {
      value = idProperty;
    }

    this.endpointService
      .getTextInmueble(value, language, type)
      .subscribe((data) => {
        let message = "";
        if (
          data["response"].length > 0 &&
          data["response"][0]["mensaje"] !== undefined
        ) {
          message = data["response"][0]["mensaje"];
        }

        if (message != null && message != "") {
          let idFA = this.genericService.findFAWithTableField(1, table, field, structure["id_functional_parent_initial_dsb"]);
          this.assignValueFunctionalArea(idFA, message);
          if (type == 1 || type == 2 || type == 3) {
            this.openSnackBar(
              "¡Ya lo tienes! Puedes modificarlo para este envio, pero no se guardará como configuración para casos futuros.",
              15000,
              ["green-snackbar"]
            );
          } else if (type == 4 || type == 5 || type == 6) {
            this.openSnackBar(
              "¡Ya lo tienes! Puedes modificarlo para este envio, pero no se guardará como configuración para casos futuros. Para que se guarde es necesario que le des al botón una vez hayas modificado el texto.",
              15000,
              ["green-snackbar"]
            );
          }
        } else if (type == 1 || type == 2 || type == 3) {
          this.openSnackBar(
            "Parece que no se ha definido el mensaje por defecto de la empresa.",
            6000,
            ["red-snackbar"]
          );
        } else if (type == 4 || type == 5 || type == 6) {
          this.openSnackBar(
            "Parece que no se ha definido el mensaje por defecto del inmueble.",
            6000,
            ["red-snackbar"]
          );
        }
        finished = true;
      });
  }

  saveTextInmueble(structure, param, finished, type) {
    let idCompany = this.authService.empresaId;
    let language = null;
    let idProperty = null;
    let validReq = this.formsArray(structure, param, false);
    let values = JSON.parse(JSON.stringify(this.tmpArray));

    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        language = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda" &&
        values[i]["field"] == "id"
      ) {
        idProperty = values[i]["value"];
      }
    }

    let valueInsert = -1;
    if (type == 1) {
      valueInsert = this.genericService.findFAWithTableField(1, "whatsapp_cliente_comodin", "mensaje", structure["id_functional_parent_initial_dsb"])["tmp_value"];
    } else if (type == 2) {
      valueInsert = this.genericService.findFAWithTableField(1, "send_buffer_email", "content", structure["id_functional_parent_initial_dsb"])["tmp_value"];
      //valueInsert = this.genericService.findFAWithTableField(1, "email_comodin", "mensaje", structure["id_functional_parent_initial_dsb"])["tmp_value"];
    } else if (type == 3) {
      valueInsert = this.genericService.findFAWithTableField(1, "send_buffer_email", "subject", structure["id_functional_parent_initial_dsb"])["tmp_value"];
      //valueInsert = this.genericService.findFAWithTableField(1, "email_comodin", "asunto", structure["id_functional_parent_initial_dsb"])["tmp_value"];
    }

    let value = -1;
    if (type == 1 || type == 2 || type == 3) {
      value = idProperty;
    }

    this.endpointService
      .saveTextInmueble(value, language, type, valueInsert)
      .subscribe((data) => {
        this.openSnackBar("Guardado con éxito", 6000, ["green-snackbar"]);
        finished = true;
      });
  }

  updateImagePropertyCustom(finished) {
    this.endpointService
      .updateImagesProperty(this.arrayImagenes.flat())
      .subscribe((data) => {
        finished = true;
        this.openSnackBar("Cambios guardados correctamente", 15000, [
          "green-snackbar",
        ]);
        this.genericService.refreshStructure(0);
      });
  }

  vincularGoogleMail(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      this.endpointService.vincularGoogleGmail(values).subscribe((data) => {
        if (data["errorCode"] == 1) window.open(data["response"], "_self");
        this.finishFunction(finished);
        this.dialog.closeAll();
      });
    } else {
      this.finishFunction(finished);
    }
  }

  desvincularGoogleMail(structure, param, finished) { }

  showStaticReport(structure, param, finished) {
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let idUser = this.authService.userId;
    let nombreEmpresa = this.authService.companyGenericName;

    //Casos generals generic
    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

    let nombreReport =
      param.param_intern[
      "statichtml_routing_functional_area_language-internal_routing"
      ];
    let idReport =
      param.param_intern["statichtml_functional_area-id_functional_area"];
    let url =
      "../" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombreReport +
      "/sth/" +
      idEmpresa +
      "-" +
      idUser +
      "&&" +
      idReport +
      "&&0";
    window.open(url);
    this.finishFunction(finished);
  }

  sendWhatsAppClienteContactar(structure, param, finished, compartirUnoSolo = 0) {
    let validReq = this.formsArray(structure, param);
    let addInmueble = false;
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    let nTelefono = null;
    let nPrefijo = null;
    let mensaje = null;
    let numInm = 0;
    let esCotizacion = 0;
    if (validReq) {

      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_cliente_comodin" && values[i]["field"] == "telefono") {
          nTelefono = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_cliente_comodin" && values[i]["field"] == "prefijo_pais") {
          nPrefijo = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_cliente_comodin" && values[i]["field"] == "mensaje") {
          mensaje = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_contactar_comodin" && values[i]["field"] == "telefono") {
          nTelefono = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_contactar_comodin" && values[i]["field"] == "prefijo_pais") {
          nPrefijo = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_contactar_comodin" && values[i]["field"] == "mensaje") {
          mensaje = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "actividad_comodin" && values[i]["field"] == "es_actividad") {
          this.insertUpdateForm(structure, param, finished, 1, false, false);
        }
        if (compartirUnoSolo == 0) {
          if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id" && values[i]["value"] && values[i]["value"][0] > 0) {
            addInmueble = true;
            numInm = values[i]["value"].length;
          }
        }
        else {
          if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id" && values[i]["value"] && values[i]["value"] > 0) {
            addInmueble = true;
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones" && values[i]["field"] == "id_cotizacion" && values[i]["value"] && values[i]["value"] > 0) {
            addInmueble = true;
            esCotizacion = 1;
          }
        }
      }

      mensaje = mensaje.replace(/(?:\r\n|\r|\n)/g, "%0A"); // Saltos de pagina whatsApp
      mensaje = mensaje.replace(/&/g, "%26"); // & whatsApp
      mensaje = mensaje.replaceAll("+", "%2B"); // + whatsApp
      mensaje = mensaje.replaceAll("#", "%23"); // # whatsApp

      let url = "";
      if (addInmueble) {
        if (compartirUnoSolo == 1) {
          if (esCotizacion == 1) {
            url = this.buildURLFichaCotizacion(-1);
            url = encodeURIComponent(url);
            mensaje += "%0A%0A" + url; // Juntamos mensaje y url
            url = "";
          } else {
            url = this.buildURLFichaInmueble(-1);
            url = encodeURIComponent(url);
            mensaje += "%0A%0A" + url; // Juntamos mensaje y url
            url = "";
          }
        }
        else {
          for (let j = 0; j < numInm; j++) {
            url = this.buildURLFichaInmueble(j);
            url = encodeURIComponent(url);
            mensaje += "%0A%0A" + url; // Juntamos mensaje y url
            url = "";
          }
        }
      }

      if (!nPrefijo) nPrefijo = "";
      let urlWhats = "https://wa.me/" + nPrefijo + nTelefono + "?text=" + mensaje;
      window.open(urlWhats);
      this.finishFunction(finished);
    } else {
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  openFichaPropietario(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let url = this.buildURLFichaPropietario();
      window.open(url, "_blank");
      this.finishFunction(finished);
    } else {
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  selectImageAndAddInEventsTemplateCustom(structure, param, finished) {
    let paramIntern = [];
    let intern_data = this.genericService.getInternParam(structure, paramIntern);

    console.log("intern_data", this.cloneVariable(intern_data));
    let url = null;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "empresa_multimedia" && intern_data[i]["bd_field"] == "url") {
        url = intern_data[i]["value"];
        break;
      }
    }
    let fullUrl = "https://movin.cloud/empresas/" + this.authService.empresaId + "/media/" + url;
    this.templateService.currentElement["url"] = fullUrl;
    this.templateService.modifyElement("url");
    this.dialog.closeAll();
    this.finishFunction(finished);
  }

  openFichaInmueble(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let url = this.buildURLFichaInmueble(-1);
      window.open(url, "_blank");
      this.finishFunction(finished);
    } else {
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  openFichaCotizacion(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let url = this.buildURLFichaCotizacion(-1);
      window.open(url, "_blank");
      this.finishFunction(finished);
    } else {
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  async downloadPDF(from) {
    this.downloadingStaticPage = true;
    this.downloadPDFfunction(from);
  }

  downloadPDFfunction(from) {
    setTimeout(() => {
      const pageSizeMM = { // Dimensiones del papel A4 en milímetros
        width: 210,
        height: 297
      };
      const dpi = 300; // Resolución en puntos por pulgada
      const marginMM = 7; // Márgenes en milímetros
      const margin = marginMM * dpi / 25.4; // Convertir milímetros a puntos

      // Calcular el tamaño de la página del PDF en puntos
      let pageSize = {
        width: pageSizeMM.width * dpi / 25.4,
        height: (pageSizeMM.height * dpi / 25.4) - margin
      };
      console.log(pageSize, "pageSizepageSize")
      // Cal que document.getElementsByClassName("generic-type-0")[0] tingui width: 595px; (cas de DINA4)
      // per tal que html2canvas el converteixi en una imatge amb la mateixa amplada del PDF que volem generar
      // per tal que es pugui tallar verticalmente molt fàcilmente sense despproporcionar-lo horitzontalment
      const docDefinition: any = {
        content: [],
        pageSize: pageSize,
        pageOrientation: 'portrait',
        pageMargins: {
          top: margin,
          bottom: margin,
          left: 0,
          right: 0
        }
      };
      //let elements = document.getElementsByClassName("generic-type-3"); // classe de les taules per provar
      let elements = document.getElementsByClassName("printGenericSection");
      let croppedCanvases = [];
      if (elements.length > 0) {
        console.log(elements, "sdfdadfsdfs")
        this.createPDFFromElements(0, from, elements, docDefinition, croppedCanvases, margin);
      } else {
        let elements = document.getElementsByClassName("create-pdf-generic");
        let croppedCanvases = [];
        if (elements.length > 0) {
          this.createPDFFromElements(0, from, elements, docDefinition, croppedCanvases, margin);
        }
      }
    }, 500);
  }

  getAllHeightPDF(lastExtraHeight, pageSize, i) {
    if (lastExtraHeight > 300) {
      return lastExtraHeight + (pageSize * (i - 1));
    } else {
      return pageSize * i;
    }
  }

  /// Recursive function
  createPDFFromElements(index, from, elements, docDefinition, croppedCanvases, margin, lastExtraHeight = 0) {
    const element = elements[index];
    const elementWidth = element.offsetWidth;
    const scaleFactor = Math.min(
      docDefinition.pageSize.width / elementWidth
    );
    const options = { scale: scaleFactor, scrollY: element.offsetTop };
    html2canvas(elements[index] as HTMLElement, options).then(canvas => {
      const imgData = canvas.toDataURL('image/png', 1);
      const img = new Image();
      img.src = imgData;

      const imgPromise = new Promise<void>((resolve) => {
        img.onload = () => {
          resolve();
        };
      });

      imgPromise.then(() => {
        let i = 0;
        // Joan: considerMargin
        // Sembla que cal aplicar marges (funciona el tall de pàgina) per seccions/imatges (com taules) que ocupen més d'una pàgina
        // Per seccions/imatges que caben en una pàgina no cal aplicar marges
        // Exemple: Perquè no la talli la secció "Datos bancarios" (salti de pàgina) en el cas: http://localhost:4200/es/Rightplace/compromiso-arriendo/sth/2436-0&&16&&null&&null
        //       podem treure margin, per comptes de margin * 2 (o només margin) el PDF surt més compacte i millor, almenys en aquest cas
        //let pageSize = docDefinition.pageSize.height - margin * 2;
        let applyMargin = (img.height > docDefinition.pageSize.height ? 1 : 0);
        let pageSize = docDefinition.pageSize.height - (margin * 2 * applyMargin);
        let extraHeight = 0;
        let addedExtraHeight = false;
        // Joan: Afegeixo allHeightPdf per poder mostrar-ho al console log
        let allHeightPdf = this.getAllHeightPDF(lastExtraHeight, pageSize, i);
        console.log('allHeightPdf=' + allHeightPdf + ' vs img.height=' + img.height);
        while (allHeightPdf < img.height) { // Aquesta iteració es repeteix quan una secció/imatge ocupa més d'una pàgina. Exemple taules llargues.
          if (addedExtraHeight) {
            // Joan
            //pageSize = docDefinition.pageSize.height - margin * 2;
            pageSize = docDefinition.pageSize.height - (margin * 2 * applyMargin);
          } else if (lastExtraHeight > 300) {
            pageSize = lastExtraHeight;
            addedExtraHeight = true;
          }
          let actualPageHeight = this.getAllHeightPDF(lastExtraHeight, pageSize, i);
          let actualPageHeight1 = this.getAllHeightPDF(lastExtraHeight, pageSize, i + 1);
          // Joan: Separació entre "seccions" - Triar el que es prefereixi. Crec que el segon o tercer
          //let height = (actualPageHeight1 <= img.height ? pageSize : img.height - actualPageHeight + margin*2);
          //let height = (actualPageHeight1 <= img.height ? pageSize : img.height - actualPageHeight + margin);//*2);
          let height = (actualPageHeight1 <= img.height ? pageSize : img.height - actualPageHeight + (margin * applyMargin));//*2);
          //let height = (actualPageHeight1 <= img.height ? pageSize : img.height - actualPageHeight);// + margin);//*2);
          const croppedCanvas = document.createElement('canvas');
          croppedCanvas.width = docDefinition.pageSize.width;
          croppedCanvas.height = height;
          const croppedCtx = croppedCanvas.getContext('2d');
          // Joan: Només cal posar a 0 el sisè i setè argument
          //croppedCtx.drawImage(img, 0, actualPageHeight, img.width, height, docDefinition.pageMargins.left, docDefinition.pageMargins.top, docDefinition.pageSize.width, height);
          //croppedCtx.drawImage(img, 0, actualPageHeight, img.width, height, docDefinition.pageMargins.left, docDefinition.pageMargins.top, docDefinition.pageSize.width, height > img.height ? img.height : height);
          //croppedCtx.drawImage(img, 0, actualPageHeight, img.width, height, docDefinition.pageMargins.left, lastExtraHeight > 0 && actualPageHeight1 == pageSize ? 0 : docDefinition.pageMargins.top, docDefinition.pageSize.width, height);
          croppedCtx.drawImage(img, 0, actualPageHeight, img.width, height, 0, 0, docDefinition.pageSize.width, height);
          croppedCanvases.push(croppedCanvas);
          extraHeight = pageSize - height;
          console.log('extraHeight', extraHeight, 'height', height, 'actualPageHeight', actualPageHeight, 'actualPageHeight1', actualPageHeight1, 'pageSize', pageSize, 'i', i, 'img.height', img.height, 'lastExtraHeight', lastExtraHeight, 'margin', margin);
          i++;
          allHeightPdf = this.getAllHeightPDF(lastExtraHeight, pageSize, i);
          console.log('allHeightPdf=' + allHeightPdf + ' vs img.height=' + img.height);
        }

        if (index < elements.length - 1) {
          this.createPDFFromElements(index + 1, from, elements, docDefinition, croppedCanvases, margin, extraHeight);
        } else {
          for (let i = 0; i < croppedCanvases.length; i++) {
            const imageDefinition = {
              image: croppedCanvases[i].toDataURL('image/png', 1)
            };
            docDefinition.content.push(imageDefinition);
          }

          let nameFile = this.genericService.containerFunctionArea["file_name_staticHTML"];
          const pdfDoc = pdfMake.createPdf(docDefinition);

          if (from == 0) {
            pdfDoc.download(`${nameFile}.pdf`);
          } else {
            pdfDoc.print();
          }
          this.downloadingStaticPage = false;
        }
      });
    }).catch(error => {
      console.log('Error html2canvas', error);
      this.downloadingStaticPage = false;
    }).finally(() => {
    });
  }

  verForm(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let url = this.buildURLVerForm(structure, param);
      window.open(url, "_blank");
      this.finishFunction(finished);
    } else {
      this.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  buildURLVerForm(structure, param) {
    let idLanguage = null;
    let nombrePlantilla = null;
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);

    for (let i in intern_data) {
      if (
        intern_data[i]["id_db"] == 3 &&
        intern_data[i]["bd_table"] == "languages" &&
        intern_data[i]["bd_field"] == "label"
      ) {
        idLanguage = intern_data[i]["value"];
      }
      if (
        intern_data[i]["id_db"] == 3 &&
        intern_data[i]["bd_table"] == "statichtml_routing_functional_area_language" &&
        intern_data[i]["bd_field"] == "internal_routing"
      ) {
        nombrePlantilla = intern_data[i]["value"];
      }
    }

    return (
      Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth"
    );
  }


  buildURLFichaPropietario() {
    let idLanguage = null;
    let nombrePlantilla = null;
    //  let nombreEmpresa = this.authService.companyGenericName;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    let idUsuario = this.authService.userId;
    let idVivienda = 0;
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        idLanguage = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "plantilla"
      ) {
        nombrePlantilla = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "propiedad"
      ) {
        idVivienda = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda" &&
        values[i]["field"] == "gestionado_por_1"
      ) {
        idUsuario = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "id_empresa"
      ) {
        idEmpresa = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "url_empresa"
      ) {
        nombreEmpresa = values[i]["value"];
      }
    }

    return (
      Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth/" +
      idEmpresa +
      "-" +
      idUsuario +
      "&&" +
      idVivienda
    );
  }


  buildURLFichaInmueble(posInm) {
    let idLanguage = null;
    let nombrePlantilla = null;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    let idVivienda = 0;
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    for (let i in values) {
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "vivienda_comodin" &&
          values[i]["field"] == "idioma")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "vivienda_comodin" &&
          values[i]["bd_field"] == "idioma")
      ) {
        idLanguage = values[i]["value"];
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "vivienda_comodin" &&
          values[i]["field"] == "plantilla")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "vivienda_comodin" &&
          values[i]["bd_field"] == "plantilla")
      ) {
        nombrePlantilla = values[i]["value"];
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "vivienda" &&
          values[i]["field"] == "id")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "vivienda" &&
          values[i]["bd_field"] == "id")
      ) {
        if (posInm == -1) {
          idVivienda = values[i]["value"];
        } else {
          idVivienda = values[i]["value"][posInm];
        }
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "vivienda_comodin" &&
          values[i]["field"] == "id_empresa")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "vivienda_comodin" &&
          values[i]["bd_field"] == "id_empresa")
      ) {
        idEmpresa = values[i]["value"];
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "vivienda_comodin" &&
          values[i]["field"] == "url_empresa")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "vivienda_comodin" &&
          values[i]["bd_field"] == "url_empresa")
      ) {
        nombreEmpresa = values[i]["value"];
      }
    }

    if (nombreEmpresa == null && this.authService.companyGenericName) {
      nombreEmpresa = this.authService.companyGenericName;
    }
    if (idEmpresa == null && this.authService.empresaId) {
      idEmpresa = this.authService.empresaId;
    }

    return (
      Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth/" +
      idEmpresa +
      "&&" +
      idVivienda +
      "&&0"
    );
  }

  buildURLFichaCotizacion(posInm) {
    let idLanguage = null;
    let nombrePlantilla = null;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    let idCotizacion = 0;
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    for (let i in values) {
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["field"] == "idioma")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["bd_field"] == "idioma")
      ) {
        idLanguage = values[i]["value"];
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["field"] == "plantilla")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["bd_field"] == "plantilla")
      ) {
        nombrePlantilla = values[i]["value"];
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "gest_log_cotizaciones" &&
          values[i]["field"] == "id_cotizacion")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "gest_log_cotizaciones" &&
          values[i]["bd_field"] == "id_cotizacion")
      ) {
        if (posInm == -1) {
          idCotizacion = values[i]["value"];
        } else {
          idCotizacion = values[i]["value"][posInm];
        }
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["field"] == "id_empresa")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["bd_field"] == "id_empresa")
      ) {
        idEmpresa = values[i]["value"];
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["field"] == "url_empresa")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["bd_field"] == "url_empresa")
      ) {
        nombreEmpresa = values[i]["value"];
      }
    }

    if (nombreEmpresa == null && this.authService.companyGenericName) {
      nombreEmpresa = this.authService.companyGenericName;
    }
    if (idEmpresa == null && this.authService.empresaId) {
      idEmpresa = this.authService.empresaId;
    }

    return (
      Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth/" +
      idEmpresa +
      "&&" +
      idCotizacion
    );
  }

  buildURLStaticReport() {
    let idLanguage = null;
    let nombreReport = null;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    let idReport = 0;
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    for (let i in values) {
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "report_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        idLanguage = values[i]["value"];
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "statichtml_functional_area" &&
        values[i]["field"] == "internal_name"
      ) {
        nombreReport = values[i]["value"];
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "statichtml_functional_area" &&
        values[i]["field"] == "id_functional_area"
      ) {
        idReport = values[i]["value"];
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "report_comodin" &&
        values[i]["field"] == "id_empresa"
      ) {
        idEmpresa = values[i]["value"];
      }
      if (
        values[i]["database"] == 3 &&
        values[i]["table"] == "report_comodin" &&
        values[i]["field"] == "url_empresa"
      ) {
        nombreEmpresa = values[i]["value"];
      }
    }

    if (nombreEmpresa == null && this.authService.companyGenericName) {
      nombreEmpresa = this.authService.companyGenericName;
    }

    return (
      "https://movin.cloud" +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombreReport +
      "/sth/" +
      idEmpresa +
      "&&" +
      idReport +
      "&&0"
    );
  }

  showReportHojaVisita(structure, finished) {

    let idLanguage = this.authService.labelLanguage;
    let nombreReport = structure["internal_routing"];
    let nombreEmpresa = this.authService.companyGenericName;
    let idEmpresa = this.authService.empresaId;
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    let actividad = null;
    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "actividades_calendario" &&
        values[i]["field"] == "id"
      ) {
        actividad = values[i]["value"];
        break;
      }


    }

    let url =
      "https://movin.cloud" +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombreReport +
      "/sth/" +
      idEmpresa +
      "&&" +
      actividad
      ;

    window.open(url, "_blank");
    this.finishFunction(finished);

  }

  cambiarEmpresa(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    let id = null;
    let idEmpresa = null;
    let idEmpresaOld = this.authService.empresaId;;
    let found = false;
    for (let i in values) {
      if (values[i]["database"] == 1 && (values[i]["table"] == "vivienda" || values[i]["table"] == "clientes") && values[i]["field"] == "id_empresa") {
        idEmpresa = values[i]["value"];
      }
      if (structure["internal_routing"] == 1 && values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id") {
        id = values[i]["value"];
      }
      if (structure["internal_routing"] == 2 && values[i]["database"] == 1 && values[i]["table"] == "clientes" && values[i]["field"] == "id") {
        id = values[i]["value"];
      }
      if (idEmpresa > 0 && id > 0) {
        found = true;
        break;
      }
    }

    if (found && structure["internal_routing"] < 3) {
      this.endpointService.cambiarEmpresa(id, structure["internal_routing"], idEmpresa, idEmpresaOld).subscribe(data => {
        if (data["errorMessage"] == "Se ha cambiado de cuenta") {
          this.snackBar.open(data["errorMessage"], "x", {
            duration: 6000,
            panelClass: ["green-snackbar"],
          });
        }
        else {
          this.snackBar.open(data["errorMessage"], "x", {
            duration: 6000,
            panelClass: ["red-snackbar"],
          });
        }
      })
      this.genericService.refreshStructure(0);
    } else {
      this.snackBar.open("¡Vaya! Parece que ha habido un error al pasar los datos", "x", {
        duration: 6000,
        panelClass: ["red-snackbar"],
      });
    }
  }

  duplicarInmueble(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let idInmueble = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id") {
          idInmueble = values[i]["value"];
          break;
        }
      }

      this.endpointService.duplicarInmueble(idInmueble).subscribe(data => {
        this.snackBar.open(data["errorMessage"], "x", {
          duration: 6000,
          panelClass: ["green-snackbar"],
        });
        console.log(structure, structure["internal_routing"] !== undefined, structure["internal_routing"] !== "", structure["internal_routing"] != null);
        if (structure["internal_routing"] !== undefined && structure["internal_routing"] !== "" && structure["internal_routing"] != null) {
          this.addWindowParam(data["response"], 1, "vivienda", "id", structure['id_functional_parent_initial'], true, "output");
          this.route = structure["internal_routing"];
          this.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule = structure["id_functional_parent_initial"];
          this.goRouting(this.route, false);
        } else {
          this.addWindowParam(data["response"], 1, "vivienda", "id", structure['id_functional_parent_initial'], true, "input");
          this.genericService.refreshStructure();
        }
      })
    } else {
      this.finishFunction(finished);
      return EMPTY;
    }
  }

  duplicarMatching(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let idInteres = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "interes_cliente" && values[i]["field"] == "id") {
          idInteres = values[i]["value"];
          break;
        }
      }

      this.endpointService.duplicarInteres(idInteres).subscribe(data => {
        this.snackBar.open(data["errorMessage"], "x", {
          duration: 6000,
          panelClass: ["green-snackbar"],
        });
        console.log(structure, structure["internal_routing"] !== undefined, structure["internal_routing"] !== "", structure["internal_routing"] != null);
        if (structure["internal_routing"] !== undefined && structure["internal_routing"] !== "" && structure["internal_routing"] != null) {
          this.addWindowParam(data["response"], 1, "interes_cliente", "id", structure['id_functional_parent_initial'], true, "output");
          this.route = structure["internal_routing"];
          this.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule = structure["id_functional_parent_initial"];
          this.goRouting(this.route, false);
        } else {
          this.addWindowParam(data["response"], 1, "interes_cliente", "id", structure['id_functional_parent_initial'], true, "input");
          this.genericService.refreshStructure();
        }
      })
    } else {
      this.finishFunction(finished);
      return EMPTY;
    }
  }

  duplicarEstancia(structure, param, finished) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let idEstancia = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_estancias_tipo" && values[i]["field"] == "id_tipo") {
          idEstancia = values[i]["value"];
          break;
        }
      }

      this.endpointService.duplicarEstancia(idEstancia).subscribe(data => {
        this.snackBar.open(data["errorMessage"], "x", {
          duration: 6000,
          panelClass: ["green-snackbar"],
        });
        console.log(structure, structure["internal_routing"] !== undefined, structure["internal_routing"] !== "", structure["internal_routing"] != null);
        if (structure["internal_routing"] !== undefined && structure["internal_routing"] !== "" && structure["internal_routing"] != null) {
          this.addWindowParam(data["response"], 1, "hotel_estancias_tipo", "id_tipo", structure['id_functional_parent_initial'], true, "output");
          this.route = structure["internal_routing"];
          this.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule = structure["id_functional_parent_initial"];
          this.goRouting(this.route, false);
        } else {
          this.addWindowParam(data["response"], 1, "hotel_estancias_tipo", "id_tipo", structure['id_functional_parent_initial'], true, "input");
          this.genericService.refreshStructure();
        }
      })
    } else {
      this.finishFunction(finished);
      return EMPTY;
    }
  }

  private removeTableRelationWhenValue(structure, param) {
    if ((structure['id_functional_status_general'] != 5 || structure['tmp_value'] !== null) && structure['tmp_value'] !== 'null') {
      if (structure['id_table_relations'] !== 0) {
        structure['id_table_relations_init'] = this.cloneVariable(structure['id_table_relations']);
        structure['id_table_relations'] = 0;
      }
    } else {
      if (structure['id_table_relations_init']) structure['id_table_relations'] = this.cloneVariable(structure['id_table_relations_init']);
    }
  }

  productEgoStart($from, product) {
    if (this.authService.userId > 0) {
      this.endpointService
        .insertPlantillaEgoButtonAppGenericProduct(
          product,
          this.authService.userId,
          this.authService.getIdEmpresa(),
          $from
        )
        .subscribe((data) => {
          let egoButtonApp: any;
          if (data["errorCode"] === 0) {
            egoButtonApp = data["response"][0];
            if (egoButtonApp.id_plantilla_ego == 0) {
              this.snackBar.open("Plantilla no disponible", "x", {
                duration: 6000,
                panelClass: ["red-snackbar"],
              });
            }
          } else {
            this.snackBar.open("No se ha encontrado plantilla", "x", {
              duration: 6000,
              panelClass: ["red-snackbar"],
            });
          }
        });
    } else {
      this.endpointService
        .getIdPlantillaEgoButtonAppGenericProduct(product, $from)
        .subscribe((data) => {
          let idPlantillaEgo: any;
          if (data["errorCode"] === 0) {
            idPlantillaEgo = data["response"];
            if (idPlantillaEgo == 0) {
              this.snackBar.open("Plantilla no disponible", "x", {
                duration: 6000,
                panelClass: ["red-snackbar"],
              });
            } else {
              this.openNoti.emit({ id: idPlantillaEgo, button: false });
            }
          } else {
            this.snackBar.open("No se ha encontrado plantilla", "x", {
              duration: 6000,
              panelClass: ["red-snackbar"],
            });
          }
        });
    }
  }

  private validateEmailBackend(structure, param, finished) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let email = "";
    let contrasenya = "";
    for (let i in intern_data) {
      if (
        intern_data[i]["id_db"] == 1 &&
        intern_data[i]["bd_table"] == "empresa_email_config" &&
        intern_data[i]["bd_field"] == "email"
      ) {
        email = intern_data[i]["value"];
      }
      if (
        intern_data[i]["id_db"] == 1 &&
        intern_data[i]["bd_table"] == "empresa_email_config" &&
        intern_data[i]["bd_field"] == "contrasenya"
      ) {
        contrasenya = intern_data[i]["value"];
      }
    }

    if (email != "" && contrasenya != "") {
      this.endpointService
        .validateEmail(email, contrasenya)
        .subscribe((data) => {
          this.finishFunction(finished);
          if (data["errorCode"] === 0) {
            this.snackBar.open("Correo validado correctamente", "x", {
              duration: 6000,
              panelClass: ["green-snackbar"],
            });
            this.genericService.refreshStructure(0);
          } else {
            this.snackBar.open(
              "Las credenciales son incorrectas y no se ha validado este correo",
              "x",
              { duration: 6000, panelClass: ["red-snackbar"] }
            );
          }
        });
    } else {
      this.finishFunction(finished);
      this.snackBar.open("No se ha encontrado el email o la contraseña", "x", {
        duration: 6000,
        panelClass: ["red-snackbar"],
      });
    }
  }

  findProductFromParams(structure, param) {
    let product;
    let intern_data = {};

    if (structure == null) {
      structure = this.genericService.getAllStructures();
    }

    intern_data = this.genericService.getInternParam(structure, param);

    // Para que los coja, todos deben ser params_interns
    for (let i in intern_data) {
      if (
        intern_data[i]["id_db"] == 3 &&
        intern_data[i]["bd_table"] == "product_mkt" &&
        intern_data[i]["bd_field"] == "id_product"
      ) {
        product = intern_data[i]["value"];
      }
      /* SI HAY QUE COGER DE OTRO SITIO TAMBIÉN, SE PONE AQUÍ EL IF*/
    }
    return product;
  }

  copyTableField(structure, param) {
    let columnId = structure["internal_routing"];
    let textToCopy = "";
    for (let t in param) {
      if (t.includes(columnId)) {
        textToCopy = param[t];
      }
    }
    this.clipboard.copy(textToCopy);
    this.openSnackBar("Copiado en el portapapeles", 3000, ["green-snackbar"]);
  }

  public async goPrev(checkRouting, routing = null, validate = true) {
    if(this.genericService.historyIndex) {
      if(!this.genericService.redirectByTools) {
        const prev = this.genericService.getPreviousPage();
        console.log(prev, 'preeeeev')
        if (prev) {
          if (validate && this.genericService.getNumChanges() > 0) {
            const status = await this.genericService.openWarningDialog(1, 1);
            if (status) {
              this.genericService.updateHistoryIndex(-1);
              this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
              this.genericService.go(prev.url, checkRouting);
            }
          } else {
            this.genericService.updateHistoryIndex(-1);
            this.genericService.go(prev.url, checkRouting);
          }
        } else if (routing !== null) {
          this.goRouting(routing, checkRouting, validate);
        } else {
          this.genericService.refreshStructure(0);
        }
      } else {
        this.openSnackBar("La pantalla aún no se ha cargado completamente", 7000, ["red-snackbar"]);
      }
    }
  }

  public async goNext(checkRouting) {
    if(this.genericService.historyIndex < this.genericService.history.length - 1) {
      if(!this.genericService.redirectByTools) {
        const next = this.genericService.getNextPage();
        if (next) {
          if (this.genericService.getNumChanges() > 0) {
            const status = await this.genericService.openWarningDialog(1, 1);
            if (status) {
              this.genericService.updateHistoryIndex(1);
              this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
              this.genericService.go(next.url, checkRouting);
            }
          } else {
            this.genericService.updateHistoryIndex(1);
            this.genericService.go(next.url, checkRouting);
          }
        }
      } else {
        this.openSnackBar("La pantalla aún no se ha cargado completamente", 7000, ["red-snackbar"]);
      }
    }
  }

  public updateFormHistory(structure, id_dsb, parent, id_parent, id_functional_area, type, old, newValue, internal_old, internal_new, label, typeField = null, hasParameters = true) {
    // ACTUALIZAMOS EL HISTORIAL DE CAMBIOS
    if (structure && structure['id_functional_status_general'] != 2 && structure['hide'] == 0) {
      this.genericService.updateFormHistory(structure, id_dsb, parent, id_parent, id_functional_area, type, old, newValue, internal_old, internal_new, label, typeField, hasParameters, structure['isFieldComodin']);
    }
    // BUSCAMOS EL ELEMENTO MODIFICADO
    if (id_dsb > 0) parent = id_dsb;
    this.executeFunctionFromForm(parent, structure, newValue, old, type);
  }

  public executeFunctionFromForm(parent, structure, newValue, old, type) {
    if (structure["id_function"]) {
      if (!structure["field_changed"]) {
        let formsChanged = this.getFormsCurrentPage(parent, true);
        let formsChanged_pre = this.cloneVariable(formsChanged);
        console.log(this.cloneVariable(structure['id_function']), structure["id_functional_area"], 'id_functionid_functionid_function', formsChanged_pre, parent, this.genericService.formsChanged)
        for (let key in formsChanged_pre) {
          if (formsChanged_pre[key]["id_functional_area"] != structure["id_functional_area"]) continue;
          console.log(this.cloneVariable(structure['id_function']), structure["id_functional_area"], 'id_functionid_functionid_function 22222')
          if ((formsChanged_pre[key]["lastValueOnExecuteFunction"] === undefined && newValue !== old) || formsChanged_pre[key]["lastValueOnExecuteFunction"] !== newValue) {
            this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][formsChanged_pre[key]["type"] + formsChanged_pre[key]["id_functional_area"]]["lastValueOnExecuteFunction"] = newValue;
            // EJECUTAMOS FUNCIÓN
            console.log(this.cloneVariable(structure['id_function']), 'id_functionid_functionid_function 3333')
            this.getFunctionFormField(structure);
            structure["field_changed"] = true;
          }
        }
      } else {
        this.getFunctionFormField(structure);
      }
    }
  }

  public getFunctionFormField(structure, autoExecuteFunction = false) {
    if (structure['form'] && structure['form']["controls"] && structure["bd_field"] && structure['form']["controls"][structure["id_functional_area"] + "-" + structure["bd_field"]]) {
      structure['autoExecuteFunction'] = structure['form']["controls"][structure["id_functional_area"] + "-" + structure["bd_field"]].touched;
    }
    this.getFunction(structure, null, null, null, autoExecuteFunction, true);
  }

  public orderFormsChanged(formsChanged, getIsFieldComodin) {
    function swap(forms) {
      let ret = {};
      for (let key in forms) {
        if (getIsFieldComodin || !forms[key]["isFieldComodin"]) {
          ret[forms[key]["id"]] = forms[key];
          ret[forms[key]["id"]]["id_functional_area"] = key.split("_")[1];
          ret[forms[key]["id"]]["type"] = key.split("_")[0] + "_";
        }
      }
      return ret;
    }
    let formsSorted = swap(formsChanged);
    return formsSorted;
  }

  getFormsCurrentPage(parent_initial = 0, getIsFieldComodin = false) {
    if (parent_initial === 0) parent_initial = this.genericService.currentInitialArea["key"];
    if (this.genericService.formsChanged[parent_initial] && this.genericService.formsChanged[parent_initial][this.genericService.paramControlVariables[parent_initial]['indexParam']]) return this.orderFormsChanged(this.genericService.formsChanged[parent_initial][this.genericService.paramControlVariables[parent_initial]['indexParam']], getIsFieldComodin);
    return {};
  }

  duplicatePlantillaEmail(param, finished, structure, from = 0) {
    let validReq = this.formsArray(structure, finished, true);
    if (validReq) {
      let idPlantilla = -1;
      let idNewPlantilla = -1;
      let nombre = null;
      let descripcion = null;
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      for (let i in values) {
        if (from == 0) {
          if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "id") {
            idPlantilla = values[i]["value"];
          }
        } else {
          if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails_idiomas" && values[i]["field"] == "id_plantilla") {
            idPlantilla = values[i]["value"];
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "id") {
            idNewPlantilla = values[i]["value"];
          }
        }

        if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "nombre") {
          nombre = values[i]["value"];
        }

        if (values[i]["database"] == 1 && values[i]["table"] == "plantillas_emails" && values[i]["field"] == "descripcion") {
          descripcion = values[i]["value"];
        }
      }

      this.endpointService.duplicatePlantillaEmail(idPlantilla, this.authService.userId, this.authService.getIdCompany(), idNewPlantilla, nombre, descripcion).subscribe((data) => {
        if (data["errorCode"] === 0) {
          if (from == 0) {
            this.openSnackBar(
              "Plantilla duplicada correctamente",
              7000,
              ["green-snackbar"]
            );
            this.genericService.refreshStructure(0);
          } else {
            this.openSnackBar(
              "Plantilla insertada correctamente",
              7000,
              ["green-snackbar"]
            );
            this.genericService.refreshStructure(1);
          }
          finished = true;
          this.finishFunction(finished);
        }
      });
    }
  }

  getNgForId(structure) {
    return structure["duplicate_id_functional_area"];
    let ngForId = "-1";
    if (structure["ngForId"] && structure["ngForId"] != "-1" && typeof structure["ngForId"] === "string" && structure["ngForId"].includes(".")) {
      ngForId = "0";
      let parts = structure["ngForId"].split(".");
      if (parts && parts[1]) ngForId = parts[1];
    }
    return ngForId;
  }

  cloneVariable(varToClone) {
    return this.genericService.cloneVariable(varToClone);
  }

  loadPaymentData(structure) {
    let validReq = this.formsArray(structure, true, false);
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_payment") {
        return values[i]["value"];
      }
    }
  }

  updatePaymentMethod(structure, paid) {
    let validReq = this.formsArray(structure, true, false);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let id_payment = null;
      let id_payment_methods = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_payment") {
          id_payment = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_payment_methods") {
          id_payment_methods = values[i]["value"];
        }
      }

      if (id_payment !== null && id_payment_methods && paid !== null) {
        this.endpointService.updatePaymentMethod(id_payment, id_payment_methods, paid).subscribe((data) => {
        });

      }

    }
  }

  getPaymentPage(structure) {
    if (structure['internal_routing_id_functional_area'] && structure['internal_routing_id_functional_area'] != "" && structure['internal_routing_id_functional_area'] != 0) this.checkoutUrl = structure['internal_routing_id_functional_area'];
    let validReq = this.formsArray(structure, true, false);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let id_payment = null;
      let id_empresa = null;
      let id_payment_methods = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_payment") {
          id_payment = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_empresa") {
          id_empresa = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "payment_empresa" && values[i]["field"] == "id_payment_methods") {
          id_payment_methods = values[i]["value"];
        }
      }


      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      window.open("../es/empresa/" + this.checkoutUrl + "/sth/" + String(id_empresa) + "-0&&" + String(id_payment), "_self");
    }
  }

  private realizarPago(structure, param, finished, type = null, publicar = null) {
    //let structure_pre = this.cloneVariable(structure);
    if (structure['internal_routing_id_functional_area'] && structure['internal_routing_id_functional_area'] != "" && structure['internal_routing_id_functional_area'] != 0) this.checkoutUrl = structure['internal_routing_id_functional_area'];
    const v = this.insertFormData(structure, param, finished, type, publicar); // insert bd empresa
    if (v != null) {
      v.subscribe((data) => {
        switch (data["errorCode"]) {
          case 0: // INSERT OK -> hacer insert a payment_empresa
            // Obtenir fields

            //Casos generals generic
            this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

            let id_currency = 1; // TODO is hardcoded
            let id_empresa = this.authService.empresaId;
            let amount = this.genericService.paymentImport;
            let infoInsert = data["response"];
            let value_pk = null;

            for (let i in infoInsert) {
              if (infoInsert[i]['database'] == structure['id_db'] && infoInsert[i]['bd_table'] == structure['bd_table'] && infoInsert[i]['responseId']) {
                value_pk = infoInsert[i]['responseId'];
                break;
              }
            }
            //console.log("Camps obtinguts abans if: ", id_empresa, amount, structure['bd_table'], structure['bd_table'], structure['bd_field'], structure['internal_routing'], value_pk, id_currency)
            //Cridar BE per fer insert a payment_empresa
            if (amount !== null && value_pk !== null) {
              this.endpointService.makePay(id_empresa, amount, structure['id_db'], structure['bd_table'], structure['bd_field'], structure['internal_routing'], value_pk, id_currency).subscribe((data2) => {
                let id_payment = 0;
                if (data2["errorCode"] == 0) { // si s'ha fet l'insert correctament
                  id_payment = data2["response"];
                  window.open("../" + this.authService.labelLanguage + "/empresa/" + this.checkoutUrl + "/sth/" + String(id_empresa) + "-0&&" + String(id_payment), "_self");
                } else {
                  this.openSnackBar(
                    "Ha habido un problema al realizar el pago.",
                    12000,
                    ["red-snackbar"]
                  );
                }
              });
            }
            break;
        }
      });
    }
  }

  insertPedidoStaticRestaurant(structure) {
    this.endpointService.insertPedidoStaticRestaurant(this.authService.arrayProductos, this.authService.productsPriceCounter, this.authService.empresaId).subscribe((data) => {
      let id_ticket = data['response']['id_ticket'];
      let idLanguage = this.authService.labelLanguage;
      let idEmpresa = this.authService.getIdEmpresa();
      let nombreEmpresa = this.authService.companyGenericName;

      this.authService.arrayProductos = [];
      this.authService.productsCounter = 0;
      this.authService.productsPriceCounter = 0;
      this.authService.productsPriceCounterLabel = "0.00";

      localStorage.setItem("arrayProductos", JSON.stringify(this.authService.arrayProductos));
      localStorage.setItem("productsCounter", JSON.stringify(this.authService.productsCounter));
      localStorage.setItem("productsPriceCounter", JSON.stringify(this.authService.productsPriceCounter));
      localStorage.setItem("productsPriceCounterLabel", JSON.stringify(this.authService.productsPriceCounterLabel));

      this.route = structure["internal_routing"];
      structure["internal_routing"] = "../" + idLanguage + "/" + nombreEmpresa + "/pedido-cliente-restaurante" + "/sth/" + idEmpresa + "-0&&" + id_ticket;
      if (data['response']['id_mesa'] == -1) {
        this.openExternalUrl(structure);

      }
      else {
        this.openSnackBar(
          "Tu pedido se ha realizado correctamente!",
          7000,
          ["green-snackbar"]
        );
      }
      this.dialogRef.close(structure, []);
      this.finishFunction(true);
    });
  }

  addWindowParam(val, database, bd_table, bd_field, id_functional_parent_initial, clean = true, where = 'intern', newIndex = false, updateParamControl = true) {
    if (!this.genericService.paramControlVariables[id_functional_parent_initial]) {
      this.genericService.paramControlVariables[id_functional_parent_initial] = this.genericService.createNewParamVariable();
    }

    let params = this.genericService.paramControlVariables[id_functional_parent_initial];

    if (newIndex && params && params['indexParam']) {
      params['indexParam'] = params['params'].length;
    };

    let indexParam = params['indexParam'];
    if (clean) {
      if (params && params['params'] && params['params'][indexParam]) {
        params['params'][indexParam][where] = [];
      } else {
        params['params'][indexParam] = this.genericService.initializeParams();
      }
      let obj = { id_db: database, bd_table: bd_table, bd_field: bd_field, value: val }
      params['params'][indexParam][where].push(obj);
    } else {
      if (!params['params'][indexParam][where]) params['params'][indexParam][where] = [];
      let found = false;
      for (let j in params['params'][indexParam][where]) {
        if (params['params'][indexParam][where][j]['bd_table'] == bd_table && params['params'][indexParam][where][j]['id_db'] == database && params['params'][indexParam][where][j]['bd_field'] == bd_field) {
          params['params'][indexParam][where][j]['value'] = val;
          found = true;
          break;
        }
      }
      if (!found) {
        let obj = { id_db: database, bd_table: bd_table, bd_field: bd_field, value: val }
        params['params'][indexParam][where].push(obj);
      }
    }
    if (updateParamControl) this.genericService.updateParamControl();
  }

  toAllDayDate(structure) {
    let idFAsearchInicio = 0;
    if (structure['id_functional_parent_initial_dsb'] == 33949) { //dialog add 33949
      idFAsearchInicio = 33973;
    } else if (structure['id_functional_parent_initial_dsb'] == 34400) { //dialog edit 34400
      idFAsearchInicio = 34425;
    }

    if (idFAsearchInicio != 0) {
      let fecha_inicio_idFA = this.findFunctionalArea(this.authService.headerStructure, idFAsearchInicio);
      if (fecha_inicio_idFA && fecha_inicio_idFA['tmp_value']) {
        let fecha_inicio = this.cloneVariable(fecha_inicio_idFA['tmp_value']);
        fecha_inicio = fecha_inicio.split(" ")[0];
        let idFAInicio = this.genericService.findFAWithTableField(1, "actividades_calendario", "fecha_inicio", structure["id_functional_parent_initial_dsb"]);
        if (idFAInicio['id_functional_area'] != idFAsearchInicio) this.assignValueFunctionalArea(idFAInicio, fecha_inicio);
      }
    }
  }

  fechaFinActividad(structure) {
    if (structure['tmp_value']) {
      let originalDate = this.formatDate(this.cloneVariable(structure['tmp_value']));
      let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario", "fecha_fin", structure["id_functional_parent_initial_dsb"], -1, false);
      originalDate = originalDate.split(" ")[0];
      if (!idFA['tmp_value'] || idFA['tmp_value'] == null || idFA['tmp_value'] < originalDate || idFA['myVal'] == idFA['tmp_value']) {
        this.assignValueFunctionalArea(idFA, originalDate);
        idFA['myVal'] = this.cloneVariable(originalDate);
      }
    }
  }

  maxDateNow(structure, param) {
    let idFA = this.genericService.findFAWithTableField(1, "empresa_usuarios_entradas", "fecha_fin", structure["id_functional_parent_initial_dsb"]);

    if (structure['tmp_value']) {
      let tmpValue = this.cloneVariable(structure['tmp_value'])
      let newDate: Date = new Date(tmpValue);
      let newDateFormatted = this.formatDate(newDate);
      if (this.fecha_fin > newDateFormatted) {
        this.assignValueFunctionalArea(idFA, newDateFormatted);
        idFA['myVal'] = this.cloneVariable(newDateFormatted);
      }
      else {
        this.assignValueFunctionalArea(idFA, this.fecha_fin);
        idFA['myVal'] = this.cloneVariable(this.fecha_fin);
      }
    }
    else {
      this.fecha_fin = new Date();
      this.fecha_fin = this.formatDate(this.fecha_fin);
      this.assignValueFunctionalArea(idFA, this.fecha_fin);
      idFA['myVal'] = this.cloneVariable(this.fecha_fin);
    }
  }

  mergePedidosRestaurant(structure, param) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let pedidos = null;
      for (let i in values) {
        if (
          values[i]["database"] == 1 &&
          values[i]["table"] == "rest_pedidos" &&
          values[i]["field"] == "comodin_pedidos"
        ) {
          pedidos = values[i]["value"];
          break;
        }
      }

      if (pedidos != null && pedidos.length > 1) {
        this.endpointService.mergePedidosRestaurant(pedidos).subscribe((data) => { });
        this.openSnackBar("Pedidos juntados correctamente", 3500, ["lightgreen-snackbar",]);
        this.genericService.refreshStructure(0);
      }
      else {
        this.openSnackBar("Elige com a mínimo dos pedidos", 3500, ["lightred-snackbar",]);
      }
    }
  }

  movePedidoRestaurant(structure, param) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let pedidoOrigen = null;
      let juntarPedido = null;
      let mesaDestino = null;
      let pedidoDestino = null;
      console.log("VALVAL", this.cloneVariable(values));
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos" && values[i]["field"] == "id_pedido") {
          pedidoOrigen = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_mesas" && values[i]["field"] == "id_mesa") {
          mesaDestino = values[i]["value"];
        }
        else if (values[i]["database"] == 1 && values[i]["table"] == "comodin_mesas" && values[i]["field"] == "comodin_juntar_pedidos") {
          juntarPedido = values[i]["value"];
        }
        else if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos" && values[i]["field"] == "comodin_id_pedido") {
          pedidoDestino = values[i]["value"];
        }
      }
      let res = null;
      this.endpointService.movePedidoRestaurant(pedidoOrigen, mesaDestino, juntarPedido, pedidoDestino).subscribe((data) => {
        res = data["response"];
        if (res == 1) {
          this.genericService.refreshStructure(0);
        }
        else {
          this.goPrev(true, null);
        }

      });
      this.genericService.refreshStructure(0);
    }
  }


  addToCheckoutRestaurant(structure, param) {
    let validReq = this.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      console.log("LALALALA", values);
      let idPedidoProducto = null;
      let cantidadTotal = null;
      let importeTotal = null;
      let cantidadCobro = null;
      let cantidadCobro2 = null;
      let precioUnitario = null;
      let dividir = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos_productos") {
          if (values[i]["field"] == "id_pedido_producto") {
            idPedidoProducto = values[i]["value"];
          }
          else if (values[i]["field"] == "cantidad") {
            cantidadTotal = values[i]["value"];
          }
          else if (values[i]["field"] == "importe") {
            importeTotal = values[i]["value"];
          }
        }
        else if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos_productos") {
          if (values[i]["field"] == "comodin_cantidad") {
            cantidadCobro = values[i]["value"];
          }
          else if (values[i]["field"] == "comodin_cantidad2") {
            cantidadCobro2 = values[i]["value"];
          }
          else if (values[i]["field"] == "comodin_dividir_producto") {
            dividir = values[i]["value"];
          }
        }
      }
      if (dividir == 1) cantidadCobro = cantidadCobro2;
      this.endpointService.addToCheckoutRestaurant(idPedidoProducto, cantidadTotal, importeTotal, cantidadCobro).subscribe((data) => {
        this.dialog.closeAll();
        this.genericService.updateResults(this.genericService.getInternParam(structure, param), structure['id_functional_parent_initial']);
      });
    }
  }

  pasarInmueble(structure, paramIntern) {
    let intern_data = this.genericService.getInternParam(structure, paramIntern);
    let idVivienda = null;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "id" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idVivienda = intern_data[i]["value"];
        break;
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "actividades_viviendas" && intern_data[i]["bd_field"] == "id_vivienda" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idVivienda = intern_data[i]["value"];
        break;
      }
    }
    if (idVivienda !== null) {
      setTimeout(() => {
        let idFA = this.genericService.findFAWithTableField(1, "vivienda_comodin", "id", structure["id_functional_parent_initial_dsb"]);
        this.assignValueFunctionalArea(idFA, idVivienda);
        let idFABis = this.genericService.findFAWithTableField(1, "vivienda", "id", structure["id_functional_parent_initial_dsb"]);
        this.assignValueFunctionalArea(idFABis, idVivienda);
        let idFA2 = this.genericService.findFAWithTableField(1, "vivienda_comodin", "es_inmueble", structure["id_functional_parent_initial_dsb"]);
        console.log(idFA2, "idFA2idFA2idFA2")
        if (idFA2['tmp_value'] == 1) {
          this.openSnackBar("Si no se indica ningún inmueble, se añadirá el inmueble de la Demanda", 4000, ["lightgreen-snackbar"]);
        }
      }, 500);
    }
  }

  marcharTodos(structure, param) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let idPedido = null;
    // Agafar num pedido
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPedido = intern_data[i]["value"];
        break;
      }
    }
    if (idPedido > 0) {
      this.endpointService.marcharProductosPedido(idPedido).subscribe((data) => {
        this.openSnackBar('¡Pedido marchado!', 3000);
        this.printTicketService.printCommandRestaurant(idPedido);
        //this.goPrev(true, null, false);
        this.genericService.go("pedidos-actuales", true); // Esta hardcoded porque el goPrev hace cosas raras
      });
    }
    else this.openSnackBar("No se ha encontrado ningún pedido", 4000, ["lightred-snackbar"]);
  }

  anadirProductoDirecto(structure, param, finished) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let idPedido = null;
    let idProducto = null;
    let idOpcion = null;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPedido = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_productos" && intern_data[i]["bd_field"] == "id_producto" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idProducto = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_productos" && intern_data[i]["bd_field"] == "id_opcion_comodin" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idOpcion = intern_data[i]["value"];
      }
    }

    if (idOpcion && idOpcion != null && idOpcion > 0) {
      structure['id_function'] = 5;
      this.getFunction(structure, param, finished);
    } else {
      if (idPedido && idPedido != null && idPedido > 0) {
        if (idProducto && idProducto != null && idProducto > 0) {
          this.endpointService.anadirProductoDirecto(idPedido, idProducto).subscribe((data) => {
            this.genericService.updateResults(this.genericService.getInternParam(structure, param), structure['id_functional_parent_initial']);
            this.printTicketService.printCommandRestaurant(idPedido);
          });
        } else this.openSnackBar("No se ha encontrado ningún producto", 4000, ["lightred-snackbar"]);
      }
      else this.openSnackBar("No se ha encontrado ningún pedido", 4000, ["lightred-snackbar"]);
    }

  }

  productosEstadoCincoDirecto(structure, param, finished) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let idPedido = null;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPedido = intern_data[i]["value"];
      }
    }

    if (idPedido && idPedido != null && idPedido > 0) {
      this.endpointService.productosEstadoCincoDirecto(idPedido).subscribe((data) => {
        let struct2 = this.cloneVariable(structure);
        struct2['id_function'] = 5;
        this.getFunction(struct2, param, finished);
        this.genericService.updateResults(this.genericService.getInternParam(structure, param), structure['id_functional_parent_initial']);
        this.finishFunction(finished);
      });
    }
    else this.openSnackBar("No se ha encontrado ningún pedido", 4000, ["lightred-snackbar"]);


  }

  public printTicketRest(structure, param) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let idTicket = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_tickets" && intern_data[i]["bd_field"] == "id_ticket" && intern_data[i]["value"]) {
        idTicket = Number(intern_data[i]["value"]);
        break;
      }
    }
    if (idTicket > 0) {
      this.printTicketService.printTicketRestaurant(idTicket);
    }
    else this.openSnackBar("No se ha encontrado ningún ticket a imprimir", 4000, ["lightred-snackbar"]);
  }

  public printTicketActLudic(structure, param) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let idTicket = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "act_ludic_reservas_tickets" && intern_data[i]["bd_field"] == "id_ticket" && intern_data[i]["value"]) {
        idTicket = Number(intern_data[i]["value"]);
        break;
      }
    }
    if (idTicket > 0) {
      this.printTicketService.printTicketActLudic(idTicket);
    }
    else this.openSnackBar("No se ha encontrado ningún ticket a imprimir", 4000, ["lightred-snackbar"]);
  }

  public printCommandRest(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let pedidos = [];
    for (let i in values) {
      if (values[i]["database"] == 1 && (values[i]["table"] == "rest_pedidos" || values[i]["table"] == "rest_pedidos_productos") && values[i]["field"] == "id_pedido" && values[i]["value"] > 0) {
        if (!pedidos.includes(Number(values[i]["value"]))) {
          pedidos.push(Number(values[i]["value"]));
        }
      }
    }
    if (pedidos.length > 0) {
      for (let i in pedidos) {
        this.printTicketService.printCommandRestaurant(pedidos[i]);
        this.dialog.closeAll();
      }
    }
    else this.openSnackBar("No se ha encontrado ningún pedido a imprimir", 4000, ["lightred-snackbar"]);
  }

  public printCommandCancelRest(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let pedidos = [];
    for (let i in values) {
      if (values[i]["database"] == 1 && (values[i]["table"] == "rest_pedidos" || values[i]["table"] == "rest_pedidos_productos") && values[i]["field"] == "id_pedido" && values[i]["value"] > 0) {
        if (!pedidos.includes(Number(values[i]["value"]))) {
          pedidos.push(Number(values[i]["value"]));
        }
      }
    }
    if (pedidos.length > 0) {
      for (let i in pedidos) {
        this.printTicketService.printCommandCanceladosRestaurant(pedidos[i]);
        this.dialog.closeAll();
      }
    }
    else this.openSnackBar("No se ha encontrado ningún pedido a imprimir", 4000, ["lightred-snackbar"]);
  }

  public printMovementRest(structure, param) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let idMovement = 0;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_cierres_caja_movimientos" && intern_data[i]["bd_field"] == "id_movimiento" && intern_data[i]["value"]) {
        idMovement = Number(intern_data[i]["value"]);
        break;
      }
    }
    if (idMovement > 0) {
      this.printTicketService.printMovementRestaurant(idMovement);
    }
    else this.openSnackBar("No se ha encontrado ningún movimiento a imprimir", 4000, ["lightred-snackbar"]);
  }

  public printCierre(structure, param) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let idCierre = 0;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_cierres_caja" && intern_data[i]["bd_field"] == "id_cierre_caja" && intern_data[i]["value"]) {
        idCierre = Number(intern_data[i]["value"]);
        break;
      }
    }
    if (idCierre > 0) {
      this.printTicketService.printCierreCajaRestaurant(idCierre);
    }
    else this.openSnackBar("No se ha encontrado ningún cierre de caja a imprimir", 4000, ["lightred-snackbar"]);
  }

  public printSnapshot(structure, param) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let idCierreCajaSnapshot = 0;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 &&
        intern_data[i]["bd_table"] == "rest_pedidos_cierres_caja_snapshot" &&
        intern_data[i]["bd_field"] == "id_cierre_caja_snapshot" &&
        intern_data[i]["value"]) {
        idCierreCajaSnapshot = Number(intern_data[i]["value"]);
        break;
      }
    }
    if (idCierreCajaSnapshot > 0) {
      this.printTicketService.printCierreCajaSnapshotRestaurant(idCierreCajaSnapshot);
    }
    else this.openSnackBar("No se ha encontrado ningún cierre de caja a imprimir", 4000, ["lightred-snackbar"]);
  }

  public printRestCierreInserted(data) {
    console.log("data", data);
    for (let i in data) {
      console.log(data[i], data[i]["bd_table"] == "rest_pedidos_cierres_caja", data[i]["bd_field"] == "id_cierre_caja", data[i]["database"] == 1, data[i]["responseId"], data[i]["responseId"] > 0);
      if (data[i]["bd_table"] == "rest_pedidos_cierres_caja" && data[i]["bd_field"] == "id_cierre_caja" && data[i]["database"] == 1 && data[i]["responseId"] && data[i]["responseId"] > 0) {
        this.printTicketService.printCierreCajaRestaurant(+data[i]["responseId"]);
        break;
      }
    }
  }

  public printRestSnapshotInserted(data) {
    for (let i in data) {
      if (data[i]["bd_table"] == "rest_pedidos_cierres_caja_snapshot" && data[i]["bd_field"] == "id_cierre_caja_snapshot" && data[i]["database"] == 1 && data[i]["responseId"] && data[i]["responseId"] > 0) {
        this.printTicketService.printCierreCajaSnapshotRestaurant(+data[i]["responseId"]);
        break;
      }
    }
  }

  public printRestMovementInserted(data) {
    for (let i in data) {
      if (data[i]["bd_table"] == "rest_pedidos_cierres_caja_movimientos" && data[i]["bd_field"] == "id_movimiento" && data[i]["database"] == 1 && data[i]["responseId"] && data[i]["responseId"] > 0) {
        this.printTicketService.printMovementRestaurant(+data[i]["responseId"]);
        break;
      }
    }
  }

  public openCashRest() {
    this.printTicketService.openCashRestaurant(this.authService.getIdCompany());
  }

  public openBottomSheetMenu(structure, param) {
    this.bottomSheetRefMenu = this._bottomSheet.open(BottomsheetMenuComponent, {
      data: {
        structure: structure,
        param: param
      },
    });
  }

  public calcNaveSuperficies(structure, param) {
    let validReq = this.formsArray(structure, param, false);
    let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
    let values = this.cloneVariable(valuesOriginal);
    let superficieTotal = 0;
    for (let i in values) {
      if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["value"] && values[i]["value"] > 0
        && (values[i]["field"] == "m2_planta_baja" || values[i]["field"] == "m2_sotano" || values[i]["field"] == "m2_planta1" || values[i]["field"] == "m2_planta2" || values[i]["field"] == "m2_planta3" || values[i]["field"] == "m2_planta4")) {
        superficieTotal = superficieTotal + +values[i]["value"];
      }
    }

    if (!isNaN(superficieTotal) && isFinite(superficieTotal)) {
      superficieTotal = Math.round((+superficieTotal + Number.EPSILON) * 100) / 100;
      let importeTotalDecimal = superficieTotal.toString().replace('.', ',');
      let idFA = this.genericService.findFAWithTableField(1, "vivienda", "m2_apublicar", structure["id_functional_parent_initial_dsb"], -1, false);
      console.log(superficieTotal, "superficieTotal", idFA)
      this.assignValueFunctionalArea(idFA, importeTotalDecimal);
    } else {
      console.log("La superfície total no es un número válido.");
    }
  }

  public reloadTable(structure, param, finished) {
    let validReq = this.formsArray(structure, param, false);
    let allData = {
      paramInterns: this.genericService.getInternParam(structure, param),
      actualValueFields: JSON.parse(JSON.stringify(this.tmpArray)),
      dataAccount: this.genericService.getDataAccount(),
      faWithQueriesToRefresh: structure["internal_routing_id_functional_area"],
      staticHTML: this.genericService.staticHTML,
    };

    this.endpointService.reloadTable(allData).subscribe((data) => {
      if (data["response"] != null && data["response"] != "") {
        //Refrescamos el service de queries
        for (let key in data["response"]) {
          this.genericService.selectOptions[data["response"][key]["idQuery"]] = data["response"][key]["result"];
          this.genericService.selectOptionsHeader[data["response"][key]["idQuery"]] = data["response"][key]["result"];
          this.genericService.selectOptionsFooter[data["response"][key]["idQuery"]] = data["response"][key]["result"];
          let fake = null;
          this.genericService.selectOptionsChange = !this.genericService.selectOptionsChange;
          this.genericService.selectOptionsChangeEvent.next(this.genericService.selectOptionsChange);
          // this.getFunctionalAreaFindElementsWithIdQueryAndAssignValuesLoop(this.genericService.getAllStructures(1), data["response"][key]["idQuery"], data["response"][key]["result"]);
          this.genericService.refreshStructure(0);
        }
      }
      this.finishFunction(finished);
    });
  }


  public addEstanciaHotel(room, name, precio, cantidad) {
    const index = this.authService.arrayEstancias.findIndex(estancia => estancia.nombre === name);
    if (index !== -1) {
      this.authService.arrayEstancias[index].cantidad = cantidad;
    } else {
      this.authService.arrayEstancias.push({ nombre: name, cantidad: cantidad });
    }
    this.authService.arrayEstanciasLabel = this.authService.arrayEstancias.join('<br>');
    this.authService.estanciasCounter += 1;

    if (this.authService.daysCounter == 0 || this.authService.daysCounter == null) {

    }
    this.authService.estanciasPriceCounter = Math.round((this.authService.estanciasPriceCounter + precio * this.authService.daysCounter + Number.EPSILON) * 100) / 100;
    //this.authService.estanciasPriceCounter = Math.round((this.authService.estanciasPriceCounter + precio + Number.EPSILON) * 100) / 100;
    this.authService.estanciasPriceCounterLabel = this.cloneVariable(this.authService.estanciasPriceCounter).toString().replace(".", ",");

    localStorage.setItem("estanciasCounter", JSON.stringify(this.authService.estanciasCounter));
    localStorage.setItem("estanciasPriceCounter", JSON.stringify(this.authService.estanciasPriceCounter));
    localStorage.setItem("estanciasPriceCounterLabel", JSON.stringify(this.authService.estanciasPriceCounterLabel));
    localStorage.setItem("arrayEstancias", JSON.stringify(this.authService.arrayEstancias));
    localStorage.setItem("arrayEstanciasLabel", JSON.stringify(this.authService.arrayEstanciasLabel));
    console.log("NUMMM", this.authService.daysCounter, this.authService.estanciasCounter, this.authService.arrayEstancias, this.authService.estanciasPriceCounter, typeof (this.authService.estanciasPriceCounter), precio, typeof (precio));
  }

  public removeEstanciaHotel(room, name, precio, cantidad) {
    if (this.authService.estanciasCounter > 0) {
      this.authService.estanciasCounter -= 1;
      const index = this.authService.arrayEstancias.findIndex(estancia => estancia.nombre === name);

      if (index !== -1) {
        // Si la estancia ya está presente, actualizar la cantidad
        this.authService.arrayEstancias[index].cantidad = cantidad;
        if (this.authService.arrayEstancias[index].cantidad === 0) {
          // Si la cantidad es 0, eliminar completamente la estancia
          this.authService.arrayEstancias.splice(index, 1);
        }
      } else {
        // Si la estancia no está presente, agregarla al array
        this.authService.arrayEstancias = this.authService.arrayEstancias.filter(item => item !== name);
      }
      this.authService.arrayEstanciasLabel = this.authService.arrayEstancias.join('<br>');

      //this.authService.estanciasPriceCounter = Math.round((this.authService.estanciasPriceCounter - precio + Number.EPSILON) * 100) / 100;
      this.authService.estanciasPriceCounter = Math.round((this.authService.estanciasPriceCounter - precio * this.authService.daysCounter + Number.EPSILON) * 100) / 100;
      this.authService.estanciasPriceCounterLabel = this.cloneVariable(this.authService.estanciasPriceCounter).toString().replace(".", ",");

      localStorage.setItem("estanciasCounter", JSON.stringify(this.authService.estanciasCounter));
      localStorage.setItem("estanciasPriceCounter", JSON.stringify(this.authService.estanciasPriceCounter));
      localStorage.setItem("estanciasPriceCounterLabel", JSON.stringify(this.authService.estanciasPriceCounterLabel));
      localStorage.setItem("arrayEstancias", JSON.stringify(this.authService.arrayEstancias));
      localStorage.setItem("arrayEstanciasLabel", JSON.stringify(this.authService.arrayEstanciasLabel));
      console.log("NUMMM", this.authService.estanciasCounter, this.authService.arrayEstancias);
    }
  }

  public deleteAllPictures(structure, param, id_from = 0) {
    let validReq = this.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.tmpArray));

    if (validReq) {
      let idInmueble = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id") {
          idInmueble = values[i]["value"];
          break;
        }
      }
      if (idInmueble && id_from == 0) {
        //Borra totes les imatges
        this.endpointService.deleteAllPictures(idInmueble).subscribe((data) => {
          this.openSnackBar(
            "Imágenes borradas correctamente",
            7000,
            ["green-snackbar"]
          );
          this.genericService.refreshStructure(0);
        });
      }
      else if (idInmueble && id_from == 1) {
        //Borra només les imatges despublicades
        this.endpointService.deleteUnpublishedPictures(idInmueble).subscribe((data) => {
          this.openSnackBar(
            "Imágenes borradas correctamente",
            7000,
            ["green-snackbar"]
          );
          this.genericService.refreshStructure(0);
        });
      }
    }
  }

  public deleteAllDocs(structure, param) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let value = 0;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == structure["id_db"] && intern_data[i]["bd_table"] == structure["bd_table"] && intern_data[i]["bd_field"] == structure["bd_field"] && intern_data[i]["value"]) {
        value = Number(intern_data[i]["value"]);
        break;
      }
    }

    if (structure["id_db"] && structure["bd_table"] && structure["bd_field"] && value > 0) {
      this.endpointService.deleteAllDocs(structure["id_db"], structure["bd_table"], structure["bd_field"], value).subscribe((data) => {
        this.openSnackBar(
          "Documentos borrados correctamente",
          7000,
          ["green-snackbar"]
        );
        this.genericService.refreshStructure(0);
      });
    }
  }

  public async downloadAllPictures(structure, param) {
    let path = structure["id_functional_url"]["url"];
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = this.formsArray(structure, param);
    let idInmueble = null;

    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.tmpArray));
      let files = [];
      let nombres = [];
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda_marketing_imagenes" && values[i]["field"] == "nombre_fichero" && values[i]["value"]) {
          files.push(path + values[i]["value"]);
          nombres.push(values[i]["value"]);
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id" && values[i]["value"]) {
          idInmueble = values[i]["value"];
        }
      }

      const JSZip = require('jszip');
      const zip = new JSZip();

      // Download each file sequentially
      for (let i = 0; i < files.length; i++) {
        try {
          const response = await fetch(files[i]);
          // const response = await fetch(files[i], { mode: 'same-origin'});
          const blob = await response.blob();
          zip.file(`file${i + 1}.txt`, blob);
        } catch (error) {
          console.error(error);
        }
      }

      // Generate and download the ZIP file
      zip.generateAsync({ type: 'blob' })
        .then((zipBlob) => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(zipBlob);
          a.download = `imagenes-inmueble-${idInmueble}.zip`;
          a.click();
        })
        .catch((error) => {
          console.error('Error during ZIP file generation:', error);
        });
    }
  }

  public setPrecioNocheEstanciaVacio(structure, param, repeat = true) {
    let idFA = this.genericService.findFAWithTableField(1, "hotel_reservas", "precio_noche_estancia", structure["id_functional_parent_initial_dsb"]);
    let idFA2 = this.genericService.findFAWithTableField(1, "hotel_reservas_comodin", "precio_noche_estancia", structure["id_functional_parent_initial_dsb"]);
    if (!idFA2['tmp_value'] || idFA2['tmp_value'] === null || idFA2['tmp_value'] == "" || idFA2['tmp_value'] == -1) return;
    if (idFA['tmp_value'] && idFA2['tmp_value'] && idFA['tmp_value'] == idFA2['tmp_value']) return;
    if (!idFA['tmp_value'] || idFA['tmp_value'] === null || idFA['tmp_value'] == "" || idFA['tmp_value'] == -1) {
      this.assignValueFunctionalArea(idFA, this.cloneVariable(idFA2['tmp_value']));
      idFA['last_value_added_function'] = this.cloneVariable(idFA2['tmp_value']);
    } else {
      if (idFA['last_value_added_function'] == idFA['tmp_value']) {
        this.assignValueFunctionalArea(idFA, this.cloneVariable(idFA2['tmp_value']));
        idFA['last_value_added_function'] = this.cloneVariable(idFA2['tmp_value']);
      } else {
        this.openSnackBar("Precio/noche tendría que ser el que está asignado en la estancia (" + idFA2['tmp_value'] + "€), pero lo has modificado manualmente. Si tiene precio para el mes indicado anteriormente, pulsa el botón de Precio/noche", 12000, ["lightred-snackbar",]);
      }
    }
  }

  public refreshCheckOutReservaHotel(structure, param) {

    let validReq = this.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.tmpArray));
    console.log("CAAAAAaa", values);
    if (validReq) {
      let fechaInicio = null;
      let fechaFin = null;
      let n_adultos = null;
      let n_ninos = 0;
      let n_bebes = 0;

      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "fecha_entrada") {
          fechaInicio = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "fecha_salida") {
          fechaFin = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_adultos_habitacion") {
          n_adultos = values[i]["value"];
          if (typeof n_adultos === 'string') {
            n_adultos = parseInt(n_adultos, 10);
          }
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_ninos_habitacion") {
          n_ninos = values[i]["value"];
          if (typeof n_ninos === 'string') {
            n_ninos = parseInt(n_ninos, 10);
          }
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_bebes_habitacion") {
          n_bebes = values[i]["value"];
          if (typeof n_bebes === 'string') {
            n_bebes = parseInt(n_bebes, 10);
          }
        }
      }

      this.authService.numPersonas = n_adultos + n_ninos + n_bebes;
      this.authService.numAdultos = n_adultos;
      this.authService.numNinos = n_ninos;
      this.authService.numBebes = n_bebes;

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
      let lastUrl = JSON.parse(localStorage.getItem("lastUrl"));
      const paramsArray = lastUrl.split("&&");
      let remainingParams;
      if (fechaFin != null && fechaInicio != null) {
        remainingParams = paramsArray.slice(0, -5);
        remainingParams.push(fechaInicio, fechaFin, n_adultos, n_ninos, n_bebes);
      }
      else {
        remainingParams = paramsArray.slice(0, -3);
        remainingParams.push(n_adultos, n_ninos, n_bebes);
        fechaInicio = remainingParams[1];
        fechaFin = remainingParams[2];
        console.log("URL", remainingParams, paramsArray);
      }
      let url = remainingParams.join("&&");
      this.authService.logout(url, true);

      const date1: Date = new Date(fechaInicio);
      const date2: Date = new Date(fechaFin);

      // Calculate the difference in milliseconds
      const differenceInMilliseconds: number = Math.abs(date2.getTime() - date1.getTime());
      const numberOfDays: number = Math.ceil(differenceInMilliseconds / (24 * 60 * 60 * 1000));

      this.authService.reservaInfo = Array({ 'fecha_entrada': fechaInicio, 'fecha_salida': fechaFin, 'adultos': n_adultos, 'ninos': n_ninos, 'bebes': n_bebes, 'personas': (n_adultos) + (n_ninos) + n_bebes });
      this.authService.daysCounter = numberOfDays;
      localStorage.setItem("lastUrl", JSON.stringify(url));
      localStorage.setItem("reservaInfo", JSON.stringify(this.authService.reservaInfo));
      localStorage.setItem("numPersonas", JSON.stringify(this.authService.numPersonas));
      localStorage.setItem("numAdultos", JSON.stringify(this.authService.numAdultos));
      localStorage.setItem("numNinos", JSON.stringify(this.authService.numNinos));
      localStorage.setItem("numBebes", JSON.stringify(this.authService.numBebes));
      localStorage.setItem("daysCounter", JSON.stringify(this.authService.daysCounter));
    }
  }

  public calculateNewImportReservaHotel(structure, param) {
    let importe = null;
    if (this.genericService.staticHTML) {
      importe = this.authService.estanciasPriceCounter;
    } else {
      let validReq = this.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.tmpArray));
      let values = this.cloneVariable(valuesOriginal);
      let noches = 0;
      let precio = 0;
      let importeTotal = 0.00;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_noches") {
          noches = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "precio_noche_estancia") {
          precio = values[i]["value"];
        }
      }

      importeTotal = noches * precio;
      importeTotal = Math.round((importeTotal + Number.EPSILON) * 100) / 100;

      console.log("HELOO HOTEL", importeTotal, noches, precio);
      importe = importeTotal.toFixed(2).toString().replace('.', ',');
    }

    let idFA = this.genericService.findFAWithTableField(1, "hotel_reservas", "importe_total", structure["id_functional_parent_initial_dsb"]);
    this.assignValueFunctionalArea(idFA, importe);
  }

  endIncidencia() {
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;

    //Casos generals generic
    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

    let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + "incidencia-fin" + "/sth/" + idEmpresa;
    window.location.href = url;
  }

  public recalcularImporteTarifaActLudic(structure) {
    let query = "2329." + structure['ngForIdIndex'];
    let results = this.genericService.selectOptions[query];
    if (results && results.length > 0) {
      let val = results[0];
      console.log(val);
      let idFAMinutos = this.genericService.findFAWithTableField(1, "act_ludic_entradas_asistentes", "minutos", structure["id_functional_parent_initial_dsb"], structure["duplicate_id_functional_area"]);
      let idFARes = this.genericService.findFAWithTableField(1, "act_ludic_entradas_asistentes", "importe", structure["id_functional_parent_initial_dsb"]);
      let importeTarifa = +(val['importe_tarifa']);
      let importeFracciones = +(val['importe_fracciones']);
      let res = importeTarifa;

      console.log(idFAMinutos['tmp_value'], val['minutos_tarifa'], idFAMinutos['tmp_value'] > val['minutos_tarifa']);

      if (idFAMinutos['tmp_value'] && val['minutos_tarifa'] && importeFracciones && val['minutos_tarifa'] &&
        val['minutos_adicionales'] && idFAMinutos['tmp_value'] > val['minutos_tarifa']) {
        console.log("importes: ", importeTarifa, (importeFracciones * ((idFAMinutos['tmp_value'] - val['minutos_tarifa']) / val['minutos_adicionales'])));
        res = importeTarifa + (importeFracciones * ((idFAMinutos['tmp_value'] - val['minutos_tarifa']) / val['minutos_adicionales']));
      }
      console.log("EEEE res 1: ", res);
      this.assignValueFunctionalArea(idFARes, res);
      /*
        ROUND(IF(
        ? > act_ludic_tarifas.duracion_minutos,
        act_ludic_tarifas.importe + (act_ludic_tarifas_fracciones_adicionales.importe * (minuts / act_ludic_tarifas.duracion_minutos)),
        act_ludic_tarifas.importe
        ),2) AS value,
      */
    }
  }

  openInExteranlURL(route) {
    window.open(Values.ROOT_URL + "/" + this.authService.labelLanguage + "/" + route);
  }
}
