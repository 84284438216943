import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  CalendarMonthViewDay,
  CalendarEventTitleFormatter,
  CalendarEventTimesChangedEvent,
  CalendarDayViewBeforeRenderEvent,
  CalendarView,
  DAYS_OF_WEEK,
  CalendarDateFormatter
} from 'angular-calendar';
import {DeviceDetectorService} from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

import { GenericService } from 'app/services/generic/generic.service';
import { AuthService } from 'app/services/auth/auth.service';
import { V } from '@angular/cdk/keycodes';
import { FunctionsService } from 'app/services/functions/functions.service';
import { isSameDay, isSameMonth, parse } from 'date-fns';
import { EndpointService } from 'app/services/endpoint/endpoint.service';
import { ActivitiesService } from 'app/services/activities/activities.service';
import { EventType } from '../../../activities/objects/category';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @ViewChild('filterInput') filterInput: ElementRef | undefined;
  @Input() index: number;
  @Input() structure: any;
  @Input() struct = null;

  view: CalendarView = CalendarView.Week;
  public idQuery: number;
  viewDate: Date = new Date();
  CalendarView = CalendarView;
  locale = 'es';
  customEvents: CustomEvent[] = [];
  refresh: Subject<any> = new Subject();

  activeDayIsOpen = false;
  loaded = false;
  type: string;
  
  dayStartHour: number;
  dayEndHour: number;

  weekStartsOn: number = (this.authService && this.authService.dia_inicio_calendario) ? this.authService.dia_inicio_calendario : 1;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  filterSearch = false;
  showSearchBar = false;
  value = "";
  lastValue = "";
  customEventsInit = [];

  constructor(
    public genericService: GenericService,
    public authService: AuthService,
    public deviceService: DeviceDetectorService,
    public functionsService: FunctionsService,
    private endpointService: EndpointService,
    public activitiesService: ActivitiesService,
    public datepipe: DatePipe,
    ) { }

  ngOnInit(): void {
    this.structure[this.index]['id_function_old'] = this.functionsService.cloneVariable(this.structure[this.index]['id_function']);
    this.type = this.structure[this.index]['type'];
    this.setView(this.view);
    this.dayStartHour = +this.datepipe.transform(new Date('1970-01-01T00:00:00.0000'), 'HH');
    this.dayEndHour = +this.datepipe.transform(new Date('1970-01-01T23:00:00.0000'), 'HH');
    let events = this.genericService.selectOptions[+this.structure[this.index]['id_query']];

    Object.keys(events).forEach(row => {
      events[row]['start'] = ((events[row]['start_date']) == null) ? null : new Date((events[row]['start_date']));
      events[row]['end'] = ((events[row]['end_date']) == null) ? null : new Date((events[row]['end_date']));
      let type: EventType = new EventType(events[row]['id_type'], events[row]['type_name'], events[row]['type_color']);
      let action = {
        onClick: ({ event }: { event: CustomEvent }): void => {}
      };
      events[row]['type'] = type;
      events[row]['actions'] = [action];
      events[row]['color'] = {primary: events[row]['type_color'], secondary: events[row]['type_color']}

      this.customEvents.push(events[row]);
     }
    );
    this.customEventsInit = this.genericService.cloneVariable(this.customEvents);
    this.loaded = true;
  }

  setView(view: CalendarView) {
    console.log("setView calendar component");
    if(this.type == 'day' || view == CalendarView.Day) {
      this.structure[this.index]['type'] = 'Day';
      this.type = this.structure[this.index]['type'];
      this.view = CalendarView.Day;
    }
    else if(this.type == 'month' || view == CalendarView.Month) {
      this.structure[this.index]['type'] = 'Month';
      this.type = this.structure[this.index]['type'];
      this.view = CalendarView.Month;
    }
    else {
      this.structure[this.index]['type'] = 'Week';
      this.type = this.structure[this.index]['type'];
      this.view = CalendarView.Week;
    }
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  
  handleEvent(action: string, event: any): void {
    console.log(event, "event")
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    switch (action) {
      case 'Clicked':
        this.addActivityToWindowParam(event);
        this.structure[this.index]['id_function'] = this.functionsService.cloneVariable(this.structure[this.index]['id_function_old']);
        this.functionsService.getFunction(this.structure[this.index], event);
        break;
      case 'HourClicked':        
        const fechaOriginal = new Date(event.date);
        // Obtener la fecha y la hora en el formato deseado
        const fechaFormateada = fechaOriginal.toISOString().split('T')[0];
        const horaFormateada = fechaOriginal.toISOString().split('T')[1].substring(0, 8);
        let event2 = { "start_date": `${fechaFormateada} ${horaFormateada}`};
        this.addActivityToWindowParam(event2);
        
        this.structure[this.index]['id_function'] = this.structure[this.index]['id_function_selection_multiple'];
        this.functionsService.getFunction(this.structure[this.index], event2);
        break;
      case 'Dropped or resized':
        // this.refresh.next();
        break;
    }
  }

  addActivityToWindowParam(event) {
    const keys = Object.keys(event);
    this.structure[this.index]['paramsAdded'] = true;
    let id_functional_parent_initial = this.structure[this.index]['id_functional_parent_initial'];
    this.genericService.clearParamsWindow(id_functional_parent_initial);
    
    for (let i in event) {
      if (typeof event[i] === 'object' || Array.isArray(event[i])) continue;
      if(i == 'type' || i == 'actions' || i == 'color') continue;
      let bdTable = this.structure[this.index]['bd_table'];
      if(i == 'start' || i == 'end' || i == 'start_date' || i == 'end_date' || i == 'id_type' || i == 'type_name' || i == 'type_color' || i == 'title') {
        bdTable = "comodin_" + this.structure[this.index]['bd_table'];
      }
      this.functionsService.addWindowParam(event[i], this.structure[this.index]['id_db'], bdTable, i, id_functional_parent_initial, false, "output", false, false);
      this.functionsService.addWindowParam(event[i], this.structure[this.index]['id_db'], bdTable, i, id_functional_parent_initial, false, "intern", false, false);
    }

    this.genericService.updateParamControl();
  }
  
  dayClicked({ date, events }: { date: Date; events: CustomEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (events.length === 0) {
        this.activeDayIsOpen = false;
      } else if (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) {
        this.activeDayIsOpen = false;
      } else {
        this.viewDate = date;
        this.activeDayIsOpen = true;
      }
    }
  }

  openSearchBar() {
    this.showSearchBar = true;
    setTimeout(() => {
      if (this.filterInput && this.filterInput.nativeElement) {
        this.filterInput.nativeElement.focus();
      }
    });  
  }

  deleteFilter() {
    this.filterSearch = false;
    this.showSearchBar = false;
    this.value = "";
    this.lastValue = "";
    this.customEvents = this.genericService.cloneVariable(this.customEventsInit); 
  }

  searchValue(value) {
    this.lastValue = this.genericService.cloneVariable(this.value);
    this.value = value;
    this.showSearchBar = false;
    this.filter();
  }

  cleanFilter() {
    this.value = "";
    this.lastValue = "";
  }

  checkFilterString(obj) {
    let objCloned = this.genericService.cloneVariable(obj);
    let filter = this.genericService.limpiarCadena(this.value);
    if(!filter || filter == "") return true;
    return this.genericService.checkFilterStringObject(objCloned, filter);
  }

  filter() {
    let events = this.genericService.cloneVariable(this.customEventsInit);
    this.customEvents = events.filter(e =>
      this.checkFilterString(e)
    );
  }
}
