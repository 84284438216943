import { Component, OnInit, Input } from '@angular/core';
import { GenericService } from '../../services/generic/generic.service';
import { FunctionsService } from '../../services/functions/functions.service';

@Component({
  selector: 'app-menu-generic-elements',
  templateUrl: './menu-generic-elements.component.html',
  styleUrls: ['./menu-generic-elements.component.scss']
})
export class MenuGenericElementsComponent implements OnInit {

  constructor(
    public genericService: GenericService,
    public functionsService: FunctionsService,
  ) { }

  @Input() elements = null;
  @Input() show = true;
  elementsNatural = [];
  elementsSpecials = [];

  ngOnInit(): void {
    for(let i in this.elements) {
      if(this.elements[i]['id_type'] == 4 || this.elements[i]['id_type'] == 5) {
        this.elementsSpecials.push(this.elements[i]);
      } else {
        this.elementsNatural.push(this.elements[i]);
      }
    }
  }

  clickMenu(elementsSpecial) {
    if(elementsSpecial['id_type'] == 4) {
      this.functionsService.goRouting(elementsSpecial['route'], true)
    } else {
      let structure = {internal_routing_id_functional_area: elementsSpecial['id_functional_area'], id_functional_parent_initial: 89};
      this.functionsService.openDialog(structure, 1);
    }
  }

}
