import {Component, OnInit, HostListener, Output, Input, EventEmitter, OnDestroy} from '@angular/core';
import {Router} from "@angular/router";
import {GenericService} from "../services/generic/generic.service";
import {AuthService} from "../services/auth/auth.service";
import {RoutingService} from "../services/routing/routing.service";
import { Location } from '@angular/common';
import { EndpointService } from 'app/services/endpoint/endpoint.service';
import { AdviceReportService } from 'app/services/advice-report/advice-report.service';
import { FunctionsService } from 'app/services/functions/functions.service';
@Component({
  selector: 'app-generic-container',
  templateUrl: './generic-container.component.html',
  styleUrls: ['./generic-container.component.css']
})
export class GenericContainerComponent implements OnInit, OnDestroy {

  private moduleId: number;
  public structure = null;
  private containerStructure;
  public loadedStructure = false;
  private monitoreo;
  public classContainer= null;

  @Output() onOpenSidebar = new EventEmitter<string>();
  @Input() isSecondWindow = false;


  constructor(private router: Router,
              public genericService: GenericService,
              public authService: AuthService,
              public routingService: RoutingService,
              private location: Location,
              private endpoint: EndpointService,
              public adviceReportService: AdviceReportService,
              public functionsService: FunctionsService // Per poder activar la classe 'create-pdf' al html
              ) { }

  ngOnInit(): void {
    this.genericService.tablesActualRows = [];
    this.genericService.finishedLoadStructure = false;

    this.functionsService.fecha_inicio_reservas = null;
    this.functionsService.fecha_fin_reservas = null;
    this.functionsService.hora_inicio_reservas = null;
    this.functionsService.hora_fin_reservas = null;

    let url = this.router.url;
    this.routingService.prepareRouting();

    if(url[0] != '/') url = '/' + url;
    if(url.indexOf('%23') != -1){
      let re = "%23"
      url = url.replace(re, "#")
    }
    this.genericService.fetchStructure(0).subscribe((data): void => {
      this.routingService.moduleId = data['idModule'];
      this.genericService.originalInputsValues = [];
      if(!this.genericService.formsChanged[data['idModule']]) this.genericService.formsChanged[data['idModule']] = {};
      this.genericService.formsChanged[data['idModule']][this.genericService.paramControlVariables[data['idModule']]['indexParam']] = new Map();
      
      if(this.authService.isUserSessionActive()) this.classContainer = this.genericService.containerFunctionArea['class_custom'] + " session-active";
      else this.classContainer = this.genericService.containerFunctionArea['class_custom'] + " session-no-active";

      if(this.genericService.staticHTML) this.classContainer = this.genericService.containerFunctionArea['class_custom'] + " staticHTML";
      else this.classContainer = this.genericService.containerFunctionArea['class_custom'] + " not-staticHTML";

      
      if(!this.genericService.staticHTML) {
        if (url[0] != '/') url = '/' + url;
        this.genericService.pushPage({url: url});
        this.routingService.previousModule = null;
        this.location.replaceState(data['url'])
        
        this.genericService.breadcrumbs = data['breadcrumbs'].reverse()
        this.genericService.highlightHeaderComponents();
        this.routingService.loadedBreadcrumbs = true;
      }
      
      this.structure = data['structure'][0];
      this.containerStructure = data['structure'];
      this.genericService.genericContainerInstance = this;
      if(!this.genericService.formsChanged[this.routingService.moduleId]) this.genericService.formsChanged[this.routingService.moduleId] = {};
      this.genericService.formsChanged[this.routingService.moduleId][this.genericService.paramControlVariables[this.routingService.moduleId]['indexParam']] = new Map();
      this.genericService.renderedComponents = {};
      this.genericService.lastDeferContainer = -1;

      if(this.routingService.urlHashtag != null && this.routingService.urlHashtag != '') {
        for(let key in this.structure['child']) {
          const x = this.genericService.findElementWithIdLoop(this.genericService.getAllStructures(1), this.routingService.urlHashtag);
          if(x) {
              this.structure['child'][key]['id_functional_status_general'] = 1;
              this.location.replaceState(this.routingService.urlWithoutHashtag + '#' + x['child']['id_functional_area']);
          }
        }
      }

      this.loadedStructure = true;
      if(this.genericService.paramControlVariablesFAs[this.routingService.moduleId] == undefined) this.genericService.paramControlVariablesFAs[this.routingService.moduleId] = {};
      if(data['paramControlVariablesFAs'] && data['paramControlVariablesFAs'] !== undefined) this.genericService.paramControlVariablesFAs[this.routingService.moduleId][this.genericService.paramControlVariables[this.routingService.moduleId]['indexParam']] = data['paramControlVariablesFAs'];
      /*this.genericService.promiseAllComponentsRendered(this.genericService.renderedComponents).then(() => {
        this.genericService.renderedFinished = true;
        if(this.routingService.urlHashtag != null){
          this.genericService.scroll(this.routingService.urlHashtag);
        }
      });*/

      if(!this.genericService.staticHTML) {
        this.adviceReportService.newNotifications(this.authService.userId, this.routingService.moduleId, this.authService.getIdEmpresa());
        this.adviceReportService.newNotificationSuperior(this.authService.userId, this.routingService.moduleId, this.authService.getIdEmpresa());
        this.adviceReportService.questions(this.routingService.moduleId);
        //this.adviceReportService.tratarAccionesPendientes();
      }
      this.genericService.tabChanging = false;
    });
  }

  ngOnDestroy() {
    this.unloadNotification()
  }

  showChat() {
    this.onOpenSidebar.emit('chat');
  }

  unloadNotification() {
    if (this.monitoreo != null) {
      console.log("UPDATE MONITORING PANTALLA")
      this.endpoint.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {
      });
    }
  }

  changingTab() {
    if(this.genericService.tabChanging && this.loadedStructure) {
      this.structure = this.genericService.structureTab;
      this.genericService.finishedLoadStructure = true;
      this.loadedStructure = true;
      this.genericService.tabChanging = false;
    }
    return this.loadedStructure;
  }
}
