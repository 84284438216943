<ng-container *ngIf="loaded">
  <mat-accordion fxLayout="row wrap" fxFlex="100" *ngIf="!functionsService.downloadingStaticPage">
    <ng-container *ngFor="let e of structure[this.index]['child'] | keyvalue : genericService.returnZero">
      <mat-expansion-panel [expanded]="structure[this.index]['child'][e.key]['expansion_start_opened'] === 1"
      *ngIf="structure[this.index]['child'][e.key] && (structure[this.index]['child'][e.key]['child'] || structure[this.index]['creatingMode']) && structure[this.index]['child'][e.key]['id_functional_type'] == 7 && genericService.evaluateIfElements(structure[this.index], e.key) && structure[this.index]['child'][e.key]['id_functional_status_general'] !== 2 && genericService.checkChildActive(structure[this.index]['child'][e.key], false, false)"
  
      (opened)="setOpened(e.key)" (closed)="setClosed(e.key)"
      [fxFlex]="structure[this.index]['child'][e.key]['width_general']"
      [fxFlex.xs]="structure[this.index]['child'][e.key]['width_xs'] != 0 ? structure[this.index]['child'][e.key]['width_xs'] : structure[this.index]['child'][e.key]['width_general']"
      [fxFlex.sm]="structure[this.index]['child'][e.key]['width_s'] != 0 ? structure[this.index]['child'][e.key]['width_s'] : structure[this.index]['child'][e.key]['width_general']"
      [fxFlex.md]="structure[this.index]['child'][e.key]['width_m'] != 0 ? structure[this.index]['child'][e.key]['width_m'] : structure[this.index]['child'][e.key]['width_general']"
      [fxFlex.lg]="structure[this.index]['child'][e.key]['width_l'] != 0 ? structure[this.index]['child'][e.key]['width_l'] : structure[this.index]['child'][e.key]['width_general']"
      [fxFlex.xl]="structure[this.index]['child'][e.key]['width_xl'] != 0 ? structure[this.index]['child'][e.key]['width_xl'] : structure[this.index]['child'][e.key]['width_general']"
  
      [fxHide]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1"
      [fxHide.xs]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_xs']=='true' && structure[this.index]['child'][e.key]['hide_xs'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_xs'] == 1"
      [fxHide.sm]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_s']=='true' && structure[this.index]['child'][e.key]['hide_s'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_s'] == 1"
      [fxHide.md]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_m']=='true' && structure[this.index]['child'][e.key]['hide_m'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_m'] == 1"
      [fxHide.lg]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_l']=='true' && structure[this.index]['child'][e.key]['hide_l'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_l'] == 1"
      [fxHide.xl]="(structure[this.index]['child'][e.key]['hide']=='true' && structure[this.index]['child'][e.key]['hide'] != 0) || (structure[this.index]['child'][e.key]['hide_xl']=='true' && structure[this.index]['child'][e.key]['hide_xl'] != 0) || structure[this.index]['child'][e.key]['hide'] == 1 || structure[this.index]['child'][e.key]['hide_xl'] == 1"
  
      [ngClass]="structure[this.index]['child'][e.key]['id_functional_status_mkt'] != 1 ? structure[this.index]['child'][e.key]['class_custom']+ ' ' + 'mkt-no-available' : structure[this.index]['child'][e.key]['class_custom']"
      [ngStyle]="structure[this.index]['child'][e.key]?.['styleParsed']?.['style']"
  
      [id]="structure[this.index]['child'][e.key]['id_functional_area']"
      
      (click)="genericCreatorService.editOption(structure[this.index]['child'][e.key])">
        <mat-expansion-panel-header [matTooltip]="structure[this.index]['child'][e.key]['tooltip']">
          <mat-panel-title>
            {{ structure[this.index]['child'][e.key]['label'] }}
            <i *ngIf="structure[this.index]['child'][e.key]['icon'] && structure[this.index]['child'][e.key]['id_function']"
              class="{{structure[this.index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
              structure[this.index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
              structure[this.index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
              structure[this.index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
              structure[this.index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}" 
              (click)="iconClick(e, $event); $event.stopPropagation()"> {{ structure[this.index]['child'][e.key]['icon'] }} </i>
  
            <i *ngIf="structure[this.index]['child'][e.key]['icon'] && (!structure[this.index]['child'][e.key]['id_function'] || structure[this.index]['child'][e.key]['id_function'] == 0 || structure[this.index]['child'][e.key]['id_function'] === NULL|| structure[this.index]['child'][e.key]['id_function'] == undefined)"
              class="{{structure[this.index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
              structure[this.index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
              structure[this.index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
              structure[this.index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
              structure[this.index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"
            > {{ structure[this.index]['child'][e.key]['icon'] }} </i>
          </mat-panel-title>
          
          <mat-panel-description fxHide="false" fxHide.xs="true" *ngIf="structure[this.index]['child'][e.key]['text'] && structure[this.index]['child'][e.key]['text'] !== NULL && structure[this.index]['child'][e.key]['text'] !== undefined && structure[this.index]['child'][e.key]['text'] !== '' && structure[this.index]['child'][e.key]['precreated_menu_mobile'] === undefined">
            {{ structure[this.index]['child'][e.key]['text'] }}
          </mat-panel-description>
        </mat-expansion-panel-header>
  
        <ng-container *ngIf="(structure[this.index]['child'][e.key]['child'] && structure[this.index]['child'][e.key]['expansionActiveNow']) || structure[this.index]['creatingMode']">
          <app-generic [structure]="structure[this.index]['child'][e.key]" [param]="param"></app-generic>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  <div *ngIf="functionsService.downloadingStaticPage" style="padding: 10px;">
    <ng-container *ngFor="let e of structure[this.index]['child'] | keyvalue : genericService.returnZero">
      <ng-container *ngIf="structure[this.index]['child'][e.key] && structure[this.index]['child'][e.key]['child'] && structure[this.index]['child'][e.key]['id_functional_type'] == 7 && genericService.evaluateIfElements(structure[this.index], e.key) && structure[this.index]['child'][e.key]['id_functional_status_general'] !== 2 && genericService.checkChildActive(structure[this.index]['child'][e.key])">
        <h5 style="padding-top: 10px;">
          <mat-icon *ngIf="structure[this.index]['child'][e.key]['icon'] && structure[this.index]['child'][e.key]['icon'] != ''" class="{{structure[this.index]['child'][e.key]['icon_type'] == 'Outlined' ? 'material-icons-outlined' :
            structure[this.index]['child'][e.key]['icon_type']=='Filled' ? 'material-icons' :
            structure[this.index]['child'][e.key]['icon_type'] =='Round' ? 'material-icons-round' :
            structure[this.index]['child'][e.key]['icon_type'] =='Two-tone' ? 'material-icons-two-tone' :
            structure[this.index]['child'][e.key]['icon_type'] =='Sharp' ? 'material-icons-sharp' : 'material-icons'}}"> {{ structure[this.index]['child'][e.key]['icon'] }}</mat-icon>
          <span [innerHTML]="structure[this.index]['child'][e.key]['label'] | safeHtml"></span>
        </h5>
        <ng-container *ngIf="structure[this.index]['child'][e.key]['child']">
          <app-generic [structure]="structure[this.index]['child'][e.key]"></app-generic>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
