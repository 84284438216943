
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { FunctionsService } from '../../../services/functions/functions.service';
import {UntypedFormControl} from "@angular/forms";
import {GenericService} from "../../../services/generic/generic.service";
import {EndpointService} from "../../../services/endpoint/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";
import { RoutingService } from 'app/services/routing/routing.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit, OnDestroy {

  @Input() structure = null;
  @Input() index : number;

  public isLinear = false;
  public ids;
  public selectedIndex = 0;
  private monitoreo;
  public loaded = false;

  constructor(public functionsService: FunctionsService,
              public genericService: GenericService,
              private endpointService: EndpointService,
              private authService: AuthService,
              private routingService: RoutingService) {

  }

  ngOnInit(): void {
    console.log('this.structure', this.structure)
    if(this.structure[this.index]['type'] === 'horizontal-linear' || this.structure[this.index]['type'] === 'vertical-linear') this.isLinear =  true;
    else this.isLinear = false;
    if(this.isLinear) {
      this.structure[this.index]['selectedIndex'] = 0;
      this.structure[this.index]['child'] ? this.structure[this.index]['child'][0]['stepActiveNow'] = true : ''
      // this.structure[this.index]['child'][0]['stepActiveNow'] = true;
    } else {
      const id = this.routingService.urlHashtag;
      let finded = false;
      let count = -1;
      if(this.routingService.urlHashtag != null && this.structure[this.index]['child']){
        for(let i in this.structure[this.index]['child']){
          if(this.structure[this.index]['child'][i]['id_functional_type'] == 95 && this.genericService.evaluateIfElements(this.structure[this.index], i) && this.structure[this.index]['child'][i]['id_functional_status_general'] !== 2) {
            ++count;
            if(this.structure[this.index]['child'][i]['id_functional_area'] == id) {
              this.structure[this.index]['selectedIndex'] = count;
              this.structure[this.index]['child'][i]['stepActiveNow'] = true;        
              finded = true;
              break;
            }
          }
        }  
      } else {
        this.structure[this.index]['selectedIndex'] = 0;
        this.structure[this.index]['child'] ? this.structure[this.index]['child'][0]['stepActiveNow'] = true : ''
      }
      if(!finded) {
        this.structure[this.index]['selectedIndex'] = 0;
        this.structure[this.index]['child'] ? this.structure[this.index]['child'][0]['stepActiveNow'] = true : ''
        // this.structure[this.index]['child'][0]['stepActiveNow'] = true;
      }
    }
    if(this.structure[this.index]['child']){
      this.endpointService.insertMonitoringInformation(this.authService.getLoggedInUserId(), this.structure[this.index]['child'][0]['id_functional_area'], this.structure[this.index]['child'][0]['id_functional_area'], this.genericService.staticHTML, this.genericService.paramControlVariables).subscribe(data => {
        this.monitoreo = data['response'];
      });
    }    
    this.loaded = true;
  }

  ngOnDestroy() {
    this.endpointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {});
  }

  onClickEvent(eventTrigger, event) {
    console.log(this.index)
    if (eventTrigger['structure']) {
      this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
      this.functionsService.getFunction(this.structure[this.index]);
    }
  }

  findForm(subStructure : any) : UntypedFormControl {
    if(subStructure !== null && subStructure !== undefined) {
      if(subStructure['form'] !== null && subStructure['form'] !== undefined) return subStructure['form'];
      else {
        for(const key in subStructure['child']){
          return this.findForm(subStructure['child'][key]);
        }
      }
    }
  }

  stepperChange(event) {
    this.endpointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {
    });
    let count = -1;
    for(let i in this.structure[this.index]['child']) {
      if(this.structure[this.index]['child'][i]['id_functional_type'] == 95 && this.genericService.evaluateIfElements(this.structure[this.index], i) && this.structure[this.index]['child'][i]['id_functional_status_general'] !== 2) {
        ++count;
        if(count == event['selectedIndex']){
          this.structure[this.index]['selectedIndex'] = count;
          this.structure[this.index]['child'][i]['stepActiveNow'] = true;
          let id = this.structure[this.index]['child'][i]['id_functional_area'];
          this.endpointService.insertMonitoringInformation(this.authService.getLoggedInUserId(),id, id, this.genericService.staticHTML, this.genericService.paramControlVariables).subscribe(data => {
            this.monitoreo = data['response'];
          });
        } else {
          this.structure[this.index]['child'][i]['stepActiveNow'] = false;
        }
      } else {
        this.structure[this.index]['child'][i]['stepActiveNow'] = false;
      }
    }
  }
}
